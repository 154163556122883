import React from "react";
import { Button } from "@material-ui/core";
import { FormikValues } from "formik";
import { ModalT } from "../../wrapper/types";
import { AdditionalButtonDependedOnPropsT, AdditionalTabT } from "../types";
import { ThunkDispatchTyped } from "../../../actions/typedActions";

export interface RenderAdditionalActionButtonsDependedOnPropsT {
  additionalActionButtonsDependedOnProps?: AdditionalButtonDependedOnPropsT[];
  firstFormInitialValues: FormikValues;
  modal: ModalT;
  isSubmitting: boolean;
  side?: "right" | "left";
  dispatch: ThunkDispatchTyped;
}

export function RenderAdditionalActionButtonsDependedOnProps({
  additionalActionButtonsDependedOnProps,
  firstFormInitialValues,
  modal,
  isSubmitting,
  side = "right",
  dispatch
}: RenderAdditionalActionButtonsDependedOnPropsT) {
  if (!Array.isArray(additionalActionButtonsDependedOnProps)) {
    return null;
  }

  return (
    <>
      {additionalActionButtonsDependedOnProps
        .filter(
          ({ stickTo, conditionallyRender }) =>
            ((!stickTo && side === "right") || stickTo === side) &&
            (!conditionallyRender ||
              conditionallyRender({ firstFormInitialValues, modal }))
        )
        .map(({ text, onClick, style, color, variant }) => (
          <Button
            key={text}
            disabled={isSubmitting}
            variant={variant}
            onClick={() => onClick({ modal, firstFormInitialValues, dispatch })}
            style={{ ...style, margin: "0 2px" }}
            color={color}
          >
            {text}
          </Button>
        ))}
    </>
  );
}

interface RenderTabButtonsProps {
  tabs: AdditionalTabT[];
  activeTab: number;
  modal: ModalT;
  firstFormInitialValues: FormikValues;
  isSubmitting: boolean;
  arrayOfRefsToFormSubmitFunc: (null | Function)[];
  dispatch: ThunkDispatchTyped;
}
export function RenderTabButtons({
  tabs,
  activeTab,
  modal,
  firstFormInitialValues,
  isSubmitting,
  arrayOfRefsToFormSubmitFunc,
  dispatch
}: RenderTabButtonsProps) {
  const tab = tabs[activeTab];
  if (!tab || (tab && !tab.tabButtons)) {
    return null;
  }
  return (
    <>
      {/*
  // @ts-ignore */}
      {tab.tabButtons.map(
        ({ Btn, requiresFormSubmitRef, conditionallyRender }, bIndex) => {
          if (
            conditionallyRender &&
            !conditionallyRender({ modal, firstFormInitialValues })
          ) {
            return null;
          }
          if (requiresFormSubmitRef) {
            return (
              <Btn
                disabled={isSubmitting}
                actionHandler={() => {
                  const arrayOfRefsToFormSubmitFuncElement =
                    arrayOfRefsToFormSubmitFunc[activeTab];
                  if (arrayOfRefsToFormSubmitFuncElement)
                    arrayOfRefsToFormSubmitFuncElement();
                }}
                key={`${bIndex}`}
              />
            );
          }
          // @ts-ignore
          return <Btn key={`${bIndex}`} disabled={isSubmitting} />;
        }
      )}
    </>
  );
}
