import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { defaultErrorHandler } from "../../../utils/defaultErrorHandler";

type fn = ((r: AxiosResponse) => any) | null;

type constructRequestT = (
  axiosConfig: AxiosRequestConfig,
  handleResponse?: fn,
  handleError?: ((error: AxiosError) => any) | null
) => void;

export const constructRequest: constructRequestT = (
  axiosConfig,
  handleResponse,
  handleError
) => {
  axios(axiosConfig)
    .then(response => {
      if (handleResponse) {
        handleResponse(response);
      }
    })
    .catch(error => {
      if (handleError) {
        handleError(error);
      } else defaultErrorHandler(error);
    });
};
