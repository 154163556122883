import React from "react";
import { Route, Router, Switch, useHistory } from "react-router-dom";
import { Provider } from "react-redux";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { PersistGate } from "redux-persist/integration/react";
import { SnackbarProvider } from "notistack";
import ym, { YMInitializer } from "react-yandex-metrika";
import Login from "./login";
import Wrapper from "./wrapper";
import PrivateRoute from "./PrivateRoute";
import NoMatch from "./no-match";
import { persistor, store } from "../store";
import customGlobalHistory from "../customGlobalHistory";
import { RecoilRoot } from "recoil";
import { RecoilExternalStatePortal } from "../store/recoilAdapter";

const theme = createTheme({
  palette: {
    primary: { main: "#005853" },
    // primary: { main: "#005853" },
    secondary: { main: "#890706" }
  }
});

const HistoryListener = () => {
  const history = useHistory();
  history.listen(({ pathname }) => {
    ym("hit", pathname);
  });
  return null;
};

const App = () => (
  <>
    <YMInitializer
      accounts={[
        (process.env.REACT_APP_YANDEX_METRICA_ACCOUNT &&
          Number.parseInt(process.env.REACT_APP_YANDEX_METRICA_ACCOUNT, 10)) ||
          57641158
      ]}
      options={{
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true
        // webvisor: true
      }}
      version="2"
    />
    <ThemeProvider theme={theme}>
      <RecoilRoot>
        <RecoilExternalStatePortal />
        <Provider store={store}>
          <PersistGate loading={<p>Загрузка</p>} persistor={persistor}>
            <Router history={customGlobalHistory}>
              <HistoryListener />
              <SnackbarProvider maxSnack={3}>
                <Switch>
                  <Route path="/" exact component={Login} />
                  <Route path={["/error/:errorMessage"]} component={NoMatch} />
                  <PrivateRoute
                    path={[
                      "/journal/:category",
                      "/journal/:category/:uniqueId([/\\d]+)",
                      "/journal/:category/:page([a-z]+)",
                      "/journal/:category/:page/:uniqueId([/\\d]+)"
                    ]}
                    exact
                    /*
               // @ts-ignore */
                    component={Wrapper}
                  />
                  <Route path="/error/:errorMessage" component={NoMatch} />
                  <Route component={NoMatch} />
                </Switch>
              </SnackbarProvider>
            </Router>
          </PersistGate>
        </Provider>
      </RecoilRoot>
    </ThemeProvider>
  </>
);

export default App;
