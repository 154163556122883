import axios from "axios";
import { set } from "lodash";
import { defaultErrorHandler } from "../../utils/defaultErrorHandler";

export const createAxiosCancelToken = () => {
  return axios.CancelToken.source();
};

export const downLoadStuff = (config, source) =>
  axios
    .all(
      config.map(item =>
        axios.get(item.urlToDownload, { cancelToken: source.token })
      )
    )
    .then(response => {
      const downloaded = {};
      config.forEach(
        (
          {
            whereToStore,
            rebuildReceivedValue,
            postLoadDoSomethingThenPassResultAsProp
          },
          index
        ) => {
          const { data } = response[index];

          set(
            downloaded,
            whereToStore,
            rebuildReceivedValue ? rebuildReceivedValue(data) : data
          );

          if (postLoadDoSomethingThenPassResultAsProp) {
            const {
              func,
              passResultAs
            } = postLoadDoSomethingThenPassResultAsProp;
            set(downloaded, passResultAs, func(data));
          }
        }
      );
      return downloaded;
    })
    .catch(error => {
      if (axios.isCancel(error)) {
        return;
      }
      defaultErrorHandler(error);
    });
