import React, { MouseEvent, Suspense, useMemo, useState } from "react";
import { Button, CircularProgress, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { PanelButton } from "../../constants/types";
import { useShallowEqualSelector } from "../../store/hooks";

const getStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    position: "relative"
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
}));

export const DependedOnOnClickStateDownloadButton = ({
  text,
  variant,
  onClick,
  children,
  style,
  ...rest
}: Omit<PanelButton, "onClick"> & {
  onClick: (e: MouseEvent) => Promise<any> | void;
  children?: string;
  className?: string;
}) => {
  const classes = getStyles();
  const [isLoading, setIsLoading] = useState(false);

  const computedFilters = useShallowEqualSelector(
    state => state.filtersReducer.computedFilters
  );

  const memoizedOnClick = useMemo(
    () => () => {
      setIsLoading(true);
      // @ts-ignore
      onClick({ computedFilters }).finally(() => setIsLoading(false));
    },
    [computedFilters, onClick]
  );

  return (
    <div className={classes.wrapper}>
      <Button
        aria-controls="download-menu"
        aria-haspopup="true"
        variant="outlined"
        onClick={memoizedOnClick}
        disabled={isLoading}
        style={style}
        {...rest}
      >
        {text || children}
      </Button>
      {isLoading && (
        <CircularProgress
          size={24}
          className={classes.buttonProgress}
          style={{ color: style ? style.color : "white" }}
        />
      )}
    </div>
  );
};

export const withLoading = <P extends object>(
  Component: React.ComponentType<P>
) => (props: P) => {
  const classes = getStyles();
  const component = <Component {...(props as P)} />;
  return (
    <Suspense
      fallback={
        <div className={classes.wrapper}>
          {component}
          <CircularProgress size={24} className={classes.buttonProgress} />
        </div>
      }
    >
      {component}
    </Suspense>
  );
};

/* const styles: Record<string, CSSProperties> = {
  wrapper: {
    position: "relative"
  },
  buttonProgress: {
    color: "red",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
};

export const withLoading = <P extends object>(
  Component: React.ComponentType<P>
) =>
  class WithLoading extends React.Component<
    P,
    { promise: Promise<any> | null }
  > {
    constructor(props: P) {
      super(props);
      this.state = {
        promise: null
      };
    }

    componentDidCatch(e: Promise<any> | Error) {
      debugger;
      // Drake meme where he says no to errors here.
      if (e instanceof Error) {
        throw e;
      }

      // Drake meme where he says yes to promises here.
      if (e instanceof Promise) {
        this.setState(
          {
            promise: e
          },
          () => {
            // When the promise finishes, go back to rendering the original children.
            e.finally(() => {
              this.setState({ promise: null });
            });
          }
        );
      }

      // This line isn't compatible with the Drake meme format.
      else {
        throw e;
      }
    }

    render() {
      const { promise } = this.state;
      return (
        <div style={styles.wrapper}>
          <Component {...(this.props as P)} />
          {promise && (
            <CircularProgress size={24} style={styles.buttonProgress} />
          )}
        </div>
      );
    }
  }; */

export const WithLoadingButton = withLoading(Button);

/*
// Со всплывающим меню
export const DependedOnOnClickStateDownloadButton = ({
  text,
  variant,
  onClick,
  ...rest
}: Omit<PanelButton, "onClick"> & {
  onClick: (e: MouseEvent) => Promise<any>;
}) => {
  const classes = getStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const computedFilters = useShallowEqualSelector(
    state => state.filtersReducer.computedFilters
  );

  const onMenuItemClick = useMemo(
    () => (format: "xlsx" | "pdf"): void => {
      setOpen(false);
      setIsLoading(true);
      // @ts-ignore
      onClick({ computedFilters }).finally(() => setIsLoading(false));
    },
    [computedFilters, onClick]
  );

  const ref = useRef(null);
  return (
    <div className={classes.wrapper}>
      <Button
        aria-controls="download-menu"
        aria-haspopup="true"
        variant="outlined"
        onClick={() => setOpen(true)}
        disabled={isLoading}
        ref={ref}
        {...rest}
      >
        <PrintOutlined />
        {text}
      </Button>
      {isLoading && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
      <Menu
        id="download-menu"
        anchorEl={ref.current}
        open={open}
        onClose={() => setOpen(false)}
      >
        <MenuItem onClick={() => onMenuItemClick("pdf")}>Pdf</MenuItem>
        <MenuItem onClick={() => onMenuItemClick("xlsx")}>Xlsx</MenuItem>
      </Menu>
    </div>
  );
};
* */
