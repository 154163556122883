import forms from "./form";
import { PageT } from "../../../types";

const actionType: PageT = {
  navigationLabel: "Тип событий",
  requiresFunction: [
    {
      functionName: "administration_directory_modifier",
      allowedActions: ["view", "edit"]
    }
  ],
  title: "Журнал Типов событий",
  url: "/api/journalActions",
  filtersPanel: {
    filters: [{ label: "Наименование", name: "label", type: "text" }]
  },
  tableHeader: [
    {
      label: "Наименование",
      sortable: true,
      path: "label"
    },
    {
      label: "Возможность использования в Делах",
      sortable: false,
      path: "isWork"
    },
    {
      label: "Возможность использования в Богослужениях",
      sortable: true,
      path: "isMinistration"
    },
    {
      label: "Возможность использования в Заявках",
      sortable: true,
      path: "isClaim"
    }
  ],
  forms: { ...forms }
};
export default actionType;
