import React from "react";
import { AutoSizer } from "react-virtualized";
import MuiTable from "mui-virtualized-table";
import CompletePromiseThenRenderComponent, {
  defaultPreloader
} from "../completePromiseThenRenderComponent";
import { defaultErrorHandler, popHeadModal } from "../../actions/typedActions";

const historyTableConfigObj = [
  { dataKey: "createdTimestamp", label: "Дата события", relativeWidth: 1 },
  { dataKey: "initUser", label: "Инициатор", relativeWidth: 1 },
  { dataKey: "text", label: "Описание", relativeWidth: 2 }
];
const HistoryTable = ({ data, ...props }) => {
  return (
    <AutoSizer>
      {({ width, height }) => (
        <MuiTable
          data={data}
          columns={historyTableConfigObj.map(
            ({ label, dataKey, relativeWidth }) => ({
              name: dataKey,
              header: label,
              width: `${(100 / 4) * relativeWidth}%`,
              cellProps: { style: { paddingRight: 0 } }
            })
          )}
          isCellHovered={(column, rowData, hoveredColumn, hoveredRowData) =>
            rowData.uniqueId && rowData.uniqueId === hoveredRowData.uniqueId
          }
          {...props}
          includeHeaders
          fixedRowCount={1}
          width={width}
          height={height}
        />
      )}
    </AutoSizer>
  );
};
export const constructHistoryTab = () => ({
  tabTitle: "История изменений",
  tabContent: {
    Component: ({ modal, dispatch }) => (
      <div style={{ minHeight: "40vh", width: "100%" }}>
        <CompletePromiseThenRenderComponent
          config={[
            {
              whereToStore: "data",
              urlToDownload: `/api/commonHistory/search/allPresentByUuid?uuid=${modal.item.uuid}&projection=tab`,
              rebuildReceivedValue: ({ _embedded }) =>
                Object.values(_embedded)[0]
            }
          ]}
          Component={HistoryTable}
          PreloaderComponent={defaultPreloader}
          errorHandler={async e => {
            await dispatch(defaultErrorHandler(e));
            dispatch(popHeadModal);
          }}
        />
      </div>
    )
  }
});
