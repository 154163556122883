import React, { Dispatch, FC, useEffect, useState } from "react";
import { FieldArrayRenderProps } from "formik/dist/FieldArray";
import { useFormikContext } from "formik";
import { ShiftDuties, ShiftT } from "../scheduleStore";
import { cloneDeep, get } from "lodash";
import { v4 } from "uuid";
import { ReactSelectMUI } from "../../select/reactSelectMUI";
import { hramPriestsIsDailyDutyOnly } from "../utils";
import { LoadableBtn } from "../../buttons/Loadable";
import { EventType, RowType } from "../types";
import axios from "axios";
import { useDispatch } from "react-redux";
import {
  closeConfirmDialog,
  openConfirmDialog,
  openSnackBar
} from "../../../actions/typedActions";
import { overUserAtom, userChurchAtom } from "../../../store/userStore";
import { useRecoilValue } from "recoil";
import { isBefore, startOfToday } from "date-fns";
import { parseRussianDate } from "../../../constants/formaters/formatters";

function removePriestsFromShifts(adaptedOnDuty: ShiftDuties[]) {
  return adaptedOnDuty.map(shift => ({ ...shift, priests: [] }));
}

export async function checkSlotsAfterDutyRemoved(
  isSuccess: boolean,
  slotsCount: number,
  dispatch: Dispatch<any>,
  type: string | "allDay" | "oneShift" | "oneMan",
  callbackSuccess: () => void
) {
  if (!isSuccess) {
    dispatch(openSnackBar("Ошибка при попытке удаления"));
  } else {
    switch (type) {
      case "oneMan": {
        dispatch(openSnackBar("Дежурный успешно удален"));
        break;
      } //удалить одного дежурного
      case "oneShift": {
        dispatch(openSnackBar("Дежурные успешно удалены"));
        break;
      } //очистить список дежурных
      case "allDay": {
        dispatch(openSnackBar("Дежурства были отменены"));
        break;
      } //отменить дежурство
    }
    if (slotsCount > 0) {
      dispatch(
        openConfirmDialog({
          primaryButtonText: "Да",
          secondaryButtonText: "Нет",
          title: "Подтверждение",
          mainContent: (
            <>
              <p>Обнаружено забронированных бесед: {slotsCount} </p>{" "}
              <p>Вы хотите их удалить?</p>
            </>
          ),
          primaryButtonHandler: () => {
            callbackSuccess();
            dispatch(closeConfirmDialog());
            // dispatch(openSnackBar("Забронированные беседы (" + slotsCount + ") удалены"));
          }
        })
      );
    }
  }
}

export const DutyManager: FC<FieldArrayRenderProps> = ({
  name
}: FieldArrayRenderProps) => {
  const userChurchId = useRecoilValue(userChurchAtom)?.value;
  const overUser = useRecoilValue(overUserAtom);
  const dispatch = useDispatch();
  const {
    status: { baseOnDuty, optionsMap },
    status,
    values
  } = useFormikContext();

  const [onDuty, setOnDuty] = useState<ShiftDuties[]>(() =>
    cloneDeep(baseOnDuty as ShiftT[]).map(v => ({ ...v, priests: [] }))
  );

  const dayFieldValue: RowType = get(values, name.split(".").slice(0, 2));
  const duties = dayFieldValue?.duties;

  useEffect(() => {
    setOnDuty(prevState => {
      const adaptedOnDuty: ShiftDuties[] = cloneDeep(prevState);

      if (duties) {
        if (duties.length === 0) {
          //При очистке дежурных по всему дню
          return removePriestsFromShifts(adaptedOnDuty);
        }

        duties.forEach(shiftDuty => {
          const found = adaptedOnDuty.find(
            value =>
              value.startTime === shiftDuty.startTime.slice(0, 5) ||
              value.startTime === shiftDuty.startTime
          );
          if (found) {
            const priest = found.priests.find(
              value => value.value === shiftDuty.value
            );
            if (!priest) {
              found.priests.push(shiftDuty);
            }
          }
        });
      } else {
        //При очистке дежурных по всему дню
        return removePriestsFromShifts(adaptedOnDuty);
      }

      return adaptedOnDuty;
    });
  }, [duties]);

  const disabledInPast =
    status.readOnly ||
    isBefore(parseRussianDate(dayFieldValue.day), startOfToday());

  return (
    <div>
      {onDuty.map((value, index) => {
        const dutyName =
          value.name === "fromStartToFinish" ? "Время дежурства" : value.name;
        return (
          <div key={value.startTime} className={"onDuty"}>
            <div className="onDuty__description">
              <b>{dutyName}</b>
              <div>
                {value.startTime.slice(0, 5)} {" - "}{" "}
                {value.finalTime.slice(0, 5)}{" "}
              </div>
            </div>
            <ReactSelectMUI
              label="Дежурные"
              placeholder={""}
              isMulti
              isDisabled={disabledInPast}
              value={value.priests}
              onChange={async (v, action) => {
                const deleteAll = false;
                if (action.action === "clear") {
                  const { data } = await axios.post(
                    `/api/slots/shiftdutysandslot`,
                    null,
                    {
                      params: {
                        timeStart: value.startTime.slice(0, 5),
                        timeFinal: value.finalTime.slice(0, 5),
                        date: dayFieldValue.day,
                        hramId: userChurchId,
                        deleteAll
                      }
                    }
                  );
                  checkSlotsAfterDutyRemoved(
                    data.response,
                    data.count,
                    dispatch,
                    "oneShift",
                    () => {
                      axios
                        .post(`/api/slots/shiftdutysandslot`, null, {
                          params: {
                            timeStart: value.startTime.slice(0, 5),
                            timeFinal: value.finalTime.slice(0, 5),
                            date: dayFieldValue.day,
                            hramId: userChurchId,
                            deleteAll: true
                          }
                        })
                        .then(response => {
                          if (response.data.response == true) {
                            dispatch(
                              openSnackBar("Забронированные беседы удалены")
                            );
                          } else {
                            dispatch(openSnackBar("Ошибка при удалении"));
                          }
                        });
                    }
                  );
                }
                if (action.removedValue) {
                  const { data } = await axios.post(
                    `/api/slots/shiftdutyandslot`,
                    null,
                    {
                      params: {
                        timeStart: value.startTime.slice(0, 5),
                        timeFinal: value.finalTime.slice(0, 5),
                        date: dayFieldValue.day,
                        userId: action.removedValue.value,
                        deleteAll
                      }
                    }
                  );
                  checkSlotsAfterDutyRemoved(
                    data.response,
                    data.count,
                    dispatch,
                    "oneMan",
                    () => {
                      //удаление слотов при подтверждении
                      axios
                        .post(`/api/slots/shiftdutyandslot`, null, {
                          params: {
                            timeStart: value.startTime.slice(0, 5),
                            timeFinal: value.finalTime.slice(0, 5),
                            date: dayFieldValue.day,
                            userId: action.removedValue?.value,
                            deleteAll: true
                          }
                        })
                        .then(response => {
                          if (response.data.response == true) {
                            dispatch(
                              openSnackBar("Забронированные беседы удалены")
                            );
                          } else {
                            dispatch(openSnackBar("Ошибка при удалении"));
                          }
                        });
                    }
                  );
                }
                if (action.option) {
                  await axios.post<EventType[]>(
                    "/api/slots/duty",
                    {
                      days: [dayFieldValue.day],
                      performerId: action.option.value,
                      userId: overUser ? overUser.value : action.option.value,
                      shift: value,
                      deleteAll
                    },
                    {
                      params: {
                        userId: overUser ? overUser.value : action.option.value
                      }
                    }
                  );
                  dispatch(openSnackBar("Дежурный успешно добавлен"));
                }
                setOnDuty(prevState => {
                  const res = [...prevState];
                  // @ts-ignore
                  res[index].priests = v ?? [];
                  return res;
                });
              }}
              options={get(
                optionsMap,
                `${hramPriestsIsDailyDutyOnly}.options`,
                []
              )}
            />
          </div>
        );
      })}
      {/*<LoadableBtn
        color="primary"
        onClick={async () => {
          setFieldValue(name, onDuty);

          await Promise.all(
            onDuty
              .filter(value => value.priests.length)
              .map(
                async shiftDuty =>
                  await Promise.all(
                    shiftDuty.priests.map(priest =>
                      axios.post<EventType[]>("/api/slots/duty", {
                        days: [dayFieldValue.day],
                        performerId: priest.value,
                        shift: shiftDuty
                      })
                    )
                  )
              )
          );
        }}
      >
        Сохранить
      </LoadableBtn>*/}
    </div>
  );
};
