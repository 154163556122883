import { Grid } from "@material-ui/core";
import React, { useCallback } from "react";
import { Form } from "formik";
import { RenderBlock } from "../../renderBlock";
import { RenderBlockFormProps, RenderBlockFormT } from "../../@types/formikMUi";

export const RenderBlockForm: RenderBlockFormT = ({
  formObj,
  handleSubmit,
  style,
  submitOnEnterPress = false,
  id
}: RenderBlockFormProps) => {
  const wrappedSubmit = useCallback(
    ({ key }) => {
      if (key === "Enter") {
        handleSubmit();
      }
    },
    [handleSubmit]
  );
  return (
    // @ts-ignore
    <Form onKeyUp={submitOnEnterPress ? wrappedSubmit : undefined} id={id}>
      <div style={{ padding: "4px 16px 10px 16px", ...style }}>
        <Grid container spacing={1} direction="column">
          {formObj.blocks.map((block, bIndex) => (
            <RenderBlock
              block={block}
              bIndex={bIndex}
              key={block.blockTitle || bIndex}
            />
          ))}
        </Grid>
      </div>
    </Form>
  );
};
