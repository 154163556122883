import React, { useCallback, useMemo } from "react";
import { Button, IconButton, Theme } from "@material-ui/core";
import { Add, Done, OpenInNew, Publish, Remove } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { ArrayHelpers, FieldArrayRenderProps } from "formik";
import { v4 as uuidv4 } from "uuid";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { green } from "@material-ui/core/colors";
import { CellProps, EventType } from "./types";
import {
  ModalWithOptionalKey,
  openSnackBar,
  pushHeadModal
} from "../../actions/typedActions";
import { RootStateT } from "../../reducers";
import {
  openEventCard,
  useMemorizedEventSubmit,
  useMemorizedRemove
} from "./utils";
import { ActionButtonsStyle } from "../../reducers/schedule";
import { openPublishMinistrationConfirmDialog } from "./utils/journal";
import { publishBorderColor } from "./table/RenderDays";

type PrivateActionButtonsProps = CellProps & {
  currentEvent: EventType;
  arrayHelpers: FieldArrayRenderProps;
  eventIndex: number;
  successCallback: () => void;
  hasAdminRights: boolean;
  setEverythingPublished: (v: boolean) => void;
};

interface AddEventButtonProps {
  push: ArrayHelpers["push"];
  disabled: boolean;
  create: EventType["typeEnum"];
  variant: ActionButtonsStyle;
  isPersonal: boolean;
  userId: number;
}

const getStyles = makeStyles((theme: Theme) => ({
  colorGreen: {
    color: green["500"]
  },
  publishColor: {
    color: publishBorderColor
  }
}));

export const AddEventBtn: React.ComponentType<AddEventButtonProps> = ({
  push,
  disabled,
  create,
  variant,
  isPersonal,
  userId
}: AddEventButtonProps) => {
  const performers = isPersonal ? [{ value: userId }] : [];
  const newObj =
    create === "ministration"
      ? {
          uniqueId: uuidv4(),
          to: "",
          from: "",
          type: null,
          performers,
          canEdit: true,
          typeEnum: "ministration",
          description: "",
          published: false
        }
      : {
          uniqueId: uuidv4(),
          to: "",
          from: "",
          type: null,
          performers,
          canEdit: true,
          typeEnum: "slot",
          description: "",
          free: true,
          published: false
        };

  if (variant === "icon") {
    return (
      <IconButton
        disabled={disabled}
        onClick={(): void => push(newObj)}
        color="primary"
      >
        <Add />
      </IconButton>
    );
  }
  return (
    <Button
      size="small"
      disabled={disabled}
      onClick={(): void => push(newObj)}
      color="primary"
    >
      <Add /> Добавить {isPersonal ? "беседу" : "богослужение"}
    </Button>
  );
};

export const ActionButtons = ({
  currentEvent: {
    uniqueId,
    uuid,
    canEdit: canEditEvent,
    typeEnum,
    to,
    from,
    performers,
    type,
    description,
    published
  },
  eventIndex,
  arrayHelpers: {
    remove,
    replace,
    form: {
      validateForm,
      setFieldTouched,
      setFieldValue,
      status: { readOnly, isPersonal }
    }
  },
  rowIndex,
  rowData,
  rowData: { day },
  successCallback,
  hasAdminRights,
  setEverythingPublished
}: PrivateActionButtonsProps) => {
  const dispatch = useDispatch();
  const { openModal, openSnackBarWithMessage } = useMemo(
    () => ({
      openSnackBarWithMessage: (m: string) => dispatch(openSnackBar(m)),
      openModal: (m: ModalWithOptionalKey) => dispatch(pushHeadModal(m))
    }),
    [dispatch]
  );

  const { actionButtonsStyle } = useSelector((state: RootStateT) => ({
    actionButtonsStyle: state.schedule.actionButtonsStyle
  }));

  const memorizedSubmit = useMemorizedEventSubmit(
    typeEnum,
    isPersonal,
    setFieldTouched,
    rowIndex,
    eventIndex,
    validateForm,
    type,
    rowData,
    from,
    to,
    performers,
    description,
    uniqueId,
    setFieldValue,
    openSnackBarWithMessage,
    replace,
    published,
    setEverythingPublished
  );

  const memorizedRemove = useMemorizedRemove(
    uniqueId,
    remove,
    eventIndex,
    typeEnum,
    openSnackBarWithMessage
  );

  const memorizedOpenCard = useCallback(
    () =>
      openEventCard(
        uniqueId,
        typeEnum,
        hasAdminRights,
        canEditEvent,
        openModal,
        uuid,
        isPersonal,
        successCallback
      ),
    [
      canEditEvent,
      hasAdminRights,
      isPersonal,
      openModal,
      successCallback,
      typeEnum,
      uniqueId,
      uuid
    ]
  );

  const createEditDisabled =
    !canEditEvent ||
    (!isPersonal && typeEnum !== "ministration") ||
    (isPersonal && typeEnum !== "slot") ||
    readOnly;
  const classes = getStyles();
  const disabledPublish =
    !canEditEvent || readOnly || typeof uniqueId === "string";

  if (actionButtonsStyle === "icon") {
    return (
      <div>
        <div className="event-buttons_icon">
          <IconButton
            onClick={memorizedSubmit}
            disabled={createEditDisabled}
            className={clsx({ [classes.colorGreen]: !createEditDisabled })}
          >
            <Done />
          </IconButton>
          {!published && typeEnum === "ministration" && (
            <IconButton
              disabled={disabledPublish}
              onClick={() =>
                openPublishMinistrationConfirmDialog(
                  dispatch,
                  uniqueId,
                  successCallback
                )
              }
              className={clsx({
                [classes.publishColor]: !disabledPublish
              })}
            >
              <Publish />
            </IconButton>
          )}
          <IconButton
            disabled={!canEditEvent || readOnly}
            onClick={memorizedRemove}
          >
            <Remove />
          </IconButton>
          <IconButton
            disabled={typeof uniqueId === "string"}
            onClick={memorizedOpenCard}
          >
            <OpenInNew />
          </IconButton>
        </div>
      </div>
    );
  }

  return (
    <div className="event-buttons_text">
      <Button
        onClick={memorizedSubmit}
        disabled={createEditDisabled}
        size="small"
        className={clsx({ [classes.colorGreen]: !createEditDisabled })}
      >
        Сохранить
      </Button>
      {!published && typeEnum === "ministration" && (
        <Button
          size="small"
          disabled={disabledPublish}
          onClick={() =>
            openPublishMinistrationConfirmDialog(
              dispatch,
              uniqueId,
              successCallback
            )
          }
          className={clsx({
            [classes.publishColor]: !disabledPublish
          })}
        >
          Опубликовать
        </Button>
      )}
      <Button
        size="small"
        disabled={!canEditEvent || readOnly}
        onClick={memorizedRemove}
      >
        Удалить
      </Button>
      <Button
        size="small"
        disabled={typeof uniqueId === "string"}
        onClick={memorizedOpenCard}
      >
        Открыть
      </Button>
    </div>
  );
};
