import { Button, Grid } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";

export const PrivateAdditionalButtons = ({ buttons, dispatch }) => {
  const { computedFilters } = useSelector(state => ({
    computedFilters: state.filtersReducer.computedFilters
  }));
  return buttons.map(({ text, onClick, component: Component, ...rest }) => {
    const onClickHandler = () => onClick({ computedFilters, dispatch });
    return (
      <Grid item key={text}>
        {Component ? (
          <Component onClick={onClickHandler} {...rest}>
            {text}
          </Component>
        ) : (
          <Button onClick={onClickHandler} {...rest}>
            {text}
          </Button>
        )}
      </Grid>
    );
  });
};

export const AdditionalButtons = React.memo(PrivateAdditionalButtons);
