import React from "react";
import { Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import { FormikValues } from "formik";
import { addFiltersToUrl } from "../../actions/typedActions";
import {
  baseUrlsToDownLoadReport,
  excludedButtonsMap
} from "../../constants/pages/statistics/reports/journal";
import { downloadFile } from "../formikMUi/utils/downLoad";
import { DependedOnOnClickStateDownloadButton } from "../buttons/dependedOnOnClickStateDownloadButton";
import { BlockFormObj } from "../../constants/types";
import { dispatchOpenSnackBar } from "../../store";
import { defaultErrorHandler } from "../../utils/defaultErrorHandler";

const getStyles = makeStyles<Theme>(theme => ({
  xlsBtn: {
    color: theme.palette.getContrastText("#2c5d2d"),
    backgroundColor: "#2c5d2d",
    "&:hover": {
      backgroundColor: "#204020"
    }
  },
  pdfBtn: {
    color: theme.palette.getContrastText("#820b09"),
    backgroundColor: "#820b09",
    "&:hover": {
      backgroundColor: "#500b09"
    }
  },
  btn: {
    marginRight: theme.spacing(1)
  },
  textCont: {
    marginLeft: theme.spacing(2)
  }
}));

const errorHandler = (error: {
  response?: {
    status: number;
  };
}) =>
  error?.response?.status === 400
    ? dispatchOpenSnackBar(
        "Отчет не может быть сформирован для выбранных параметров. Обратитесь к системному администратору"
      )
    : defaultErrorHandler(error);

type DownLoadReportButtonsProps = {
  reportName: keyof typeof baseUrlsToDownLoadReport;
  filters: FormikValues;
  filterFO: BlockFormObj;
  isKeepingSchedule: boolean;
  isCreatingSlots: boolean;
};

const handleBoolean = (value: boolean): boolean => {
  return typeof value === "undefined" ? false : value;
};

export const DownloadReportButtons: React.FC<DownLoadReportButtonsProps> = ({
  reportName,
  filters,
  filterFO,
  isKeepingSchedule,
  isCreatingSlots
}: DownLoadReportButtonsProps) => {
  const excludedButtons = excludedButtonsMap[reportName] || [];
  const classes = getStyles();
  const url = baseUrlsToDownLoadReport[reportName];
  if (typeof url === "undefined") {
    throw new Error(`Не найдено Url для отчёта с таким именем: ${reportName}`);
  }
  return (
    <>
      {!excludedButtons.includes("xlsx") && (
        <DependedOnOnClickStateDownloadButton
          className={clsx(classes.xlsBtn, classes.btn)}
          variant="contained"
          text="Скачать в Xls"
          onClick={() => {
            if (typeof url === "function") {
              if (filters) {
                return downloadFile(
                  addFiltersToUrl(
                    filters,
                    url(
                      "xlsx",
                      handleBoolean(isKeepingSchedule),
                      handleBoolean(isCreatingSlots)
                    ),
                    filterFO
                  ),
                  `${reportName}.xlsx`,
                  errorHandler
                );
              }
            }
          }}
        />
      )}
      {!excludedButtons.includes("pdf") && (
        <DependedOnOnClickStateDownloadButton
          className={clsx(classes.pdfBtn, classes.btn)}
          variant="contained"
          onClick={() => {
            if (typeof url === "function") {
              if (filters) {
                return downloadFile(
                  addFiltersToUrl(
                    filters,
                    url(
                      "pdf",
                      handleBoolean(isKeepingSchedule),
                      handleBoolean(isCreatingSlots)
                    ),
                    filterFO
                  ),
                  `${reportName}.pdf`,
                  errorHandler
                );
              }
            }
          }}
        >
          Скачать в Pdf
        </DependedOnOnClickStateDownloadButton>
      )}
    </>
  );
};

export const DownLoadReportStep = () => {
  const classes = getStyles();
  return (
    <Typography variant="body1" className={classes.textCont}>
      Для формирования отчёта нажмите на соответствующую кнопку
    </Typography>
  );
};
