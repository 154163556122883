import React from "react";
import { FieldProps } from "formik";
import { checkIfFieldHasValidationError } from "../../utils/checkIfFieldHasValidationError";
import { formatToRussianDate } from "../../../../constants/formaters/formatters";
import { DayPickerInputMUI } from "../../../picker/DayPickerInputMUI";
import { getDisabledDays } from "./utils";

export const WrappedDayPickerInputMUI = ({
  field: { value, name, onBlur },
  form: { setFieldValue, touched, errors },
  readOnly,
  label,
  multiple = false,
  minDate,
  maxDate
}: FieldProps<any> & {
  readOnly: boolean;
  label?: string;
  multiple?: boolean;
  minDate?: Date;
  maxDate?: Date;
}) => {
  return (
    <DayPickerInputMUI
      multiple={multiple}
      label={label}
      value={value}
      onBlur={onBlur}
      name={name}
      onDayClick={(day, modifiers, e) => {
        if (multiple) {
          const prev = Array.isArray(value) ? value : [value];
          setFieldValue(name, [...prev, formatToRussianDate(day)]);
        } else {
          setFieldValue(name, formatToRussianDate(day));
        }
      }}
      disabledDays={getDisabledDays(minDate, maxDate)}
      clearValue={() => setFieldValue(name, "")}
      readOnly={readOnly}
      {...checkIfFieldHasValidationError(name, touched, errors)}
      maxDate={maxDate}
      minDate={minDate}
    />
  );
};
