import React, { useState } from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  LinearProgress,
  Theme,
  Typography
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/styles";
import { v4 } from "uuid";
import { FormikMUI } from "../formikMUi/formikNewRealisation";
import { RenderModalCreateFormProps } from "./types";
import { adaptTitle } from "./utils/adaptTitle";

export const getCreateFormStyles = makeStyles((theme: Theme) => ({
  DialogTitleRoot: {
    paddingBottom: "10px"
  },
  closeBtn: {
    position: "absolute",
    right: "24px",
    top: "15px"
  },
  tabTitle: {
    fontSize: "15px"
  },
  root: {
    overflowX: "hidden"
  }
}));

export const RenderModalCreateForm = ({
  initialValues,
  formObj,
  title,
  optionsMap,
  closeHandler,
  onSubmit,
  actionButtons,
  modal: { readOnlyFields, successCallback, readonly },
  variant
}: RenderModalCreateFormProps) => {
  const formId = v4();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const classes = getCreateFormStyles();
  return (
    <>
      <DialogTitle classes={{ root: classes.DialogTitleRoot }}>
        <Grid container justify="space-between" alignItems="center">
          {/* // todo временная адаптация пока не перейду на использование чисто formObj */}
          <Typography variant="h6">
            {adaptTitle(
              initialValues,
              title || formObj.title,
              formObj.headerText
            )}
          </Typography>
          <IconButton
            className={classes.closeBtn}
            onClick={() => closeHandler()}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.root}>
        <FormikMUI
          variant={variant}
          onSubmit={(values, formikBag) => {
            if (onSubmit) {
              onSubmit(values, formikBag, successCallback);
            } else if (typeof formObj.onSubmit === "function") {
              formObj.onSubmit(values, formikBag, successCallback);
            }
          }}
          formObj={formObj}
          id={formId}
          bindIsSubmitting={setIsSubmitting}
          initialValues={initialValues}
          optionsMap={optionsMap}
          readOnlyFields={readOnlyFields}
          readOnly={readonly}
        />
        {isSubmitting && <LinearProgress />}
      </DialogContent>
      <DialogActions>
        {/* Рендерит общие кнопки для всей модалки */}
        {actionButtons}
        {!readonly && (
          <Button
            color="primary"
            variant="contained"
            disabled={isSubmitting}
            type="submit"
            form={formId}
          >
            {formObj.primaryButtonText || "Сохранить"}
          </Button>
        )}
      </DialogActions>
    </>
  );
};
