import { baseTextAreaProps } from "../../baseFieldProps";
import { localizedYup } from "../../yup";
import { formatToRussianDate } from "../../formaters/formatters";
import { constructRequest } from "../../../components/modalWrapper/utils/baseRequests";
import { ExtendedBlockFormObj } from "../../../components/modalWrapper/types";
import { dispatchOpenSnackBar, dispatchPopHeadModal } from "../../../store";

export const monthWord: ExtendedBlockFormObj = {
  url: ({ initialValues }) => {
    if (initialValues && initialValues.date) {
      return `/api/ministration/day?date=${initialValues.date}`;
    }
    return `/api/ministration/day?date=${formatToRussianDate(new Date())}`;
  },
  onSubmit: (values, { setSubmitting }) => {
    constructRequest(
      {
        url: `/api/ministration/day?date=${values.date}`,
        method: "put",
        data: { ...values }
      },
      ({ status }) => {
        dispatchOpenSnackBar("Месяцеслов успешно изменен");
        setSubmitting(false);
        dispatchPopHeadModal();
      }
    );
  },
  title: "Месяцеслов",
  blocks: [
    {
      fields: [
        {
          label: "Дата",
          name: "date",
          type: "text",
          readOnly: true
        },
        {
          label: "Описание",
          name: "desciption",
          ...baseTextAreaProps
        },
        {
          type: "text",
          name: "uniqueId",
          hidden: true
        },
        {
          type: "text",
          name: "type",
          hidden: true
        },
        {
          type: "text",
          name: "hramId",
          hidden: true
        }
      ]
    }
  ],
  validationSchema: localizedYup.object({
    desciption: localizedYup.string().required(),
    date: localizedYup.string().required()
  })
};
