export const calcFieldWidth = field => {
  const widths = { xs: 12, sm: 12, md: 6, lg: 4, xl: 4 };
  // логика тут такая если для поля есть additionalProps а в них fullWidth, то
  if (
    (field.type === "select" && field.multiple === true) ||
    (field.additionalProps && field.additionalProps.fullWidth === true)
  ) {
    // return { xs: 12, sm: "auto", md: "auto", lg: "auto", xl: "auto" };
    return { xs: 12, sm: false, md: false, lg: false, xl: false };
  }
  return field.widths ? { ...widths, ...field.widths } : widths;
};
