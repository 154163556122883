import React, { useEffect, useRef } from "react";
import Grid from "@material-ui/core/Grid/Grid";
import Paper from "@material-ui/core/Paper/Paper";
import { Form, Formik, FormikProps, FormikValues } from "formik";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import { ObjectSchema } from "yup";
import { FormikHelpers } from "formik/dist/types";
import { calcFieldWidth } from "../formikMUi/utils/calcFieldWidth";
import { RenderField } from "../formikMUi/renderField";
import { AuthStateT } from "../../reducers/auth";
import { FiltersPanelT, OptionsMapType } from "../../constants/types";
import ShadowedOutlinedButton from "../buttons/ShadowedOutlinedButton";
import { ObjectShape } from "yup/lib/object";

const getStyles = makeStyles((theme: Theme) => ({
  button: {
    margin: theme.spacing(1)
  },
  input: {
    display: "none"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  panel: { marginBottom: theme.spacing(1), padding: theme.spacing(1) },
  field: {
    width: "100%"
  }
}));

export interface FilterPanelProps {
  filters: FiltersPanelT["filters"];
  computedFilters: FormikValues;
  optionsMap: OptionsMapType;
  auth: AuthStateT;
  validationSchema?: ObjectSchema<ObjectShape>;
  onReset?: (
    values: FormikValues,
    formikHelpers: FormikHelpers<FormikValues>
  ) => void;
  onSubmit: (
    values: FormikValues,
    formikHelpers: FormikHelpers<FormikValues>
  ) => void | Promise<any>;
}

const FilterPanel = ({
  filters,
  computedFilters,
  optionsMap = {},
  auth,
  validationSchema,
  onReset,
  onSubmit
}: FilterPanelProps) => {
  const classes = getStyles();
  const ref = useRef<FormikProps<any>>(null);
  useEffect(() => {
    if (ref.current) {
      ref.current.setValues(computedFilters);
    }
  }, [ref, computedFilters]);
  return (
    <Paper square elevation={5} className={classes.panel}>
      <Formik
        innerRef={ref}
        onReset={onReset}
        initialValues={computedFilters}
        onSubmit={onSubmit}
        initialStatus={{
          optionsMap
        }}
        validationSchema={validationSchema}
      >
        {({
          isSubmitting,
          values,
          status,
          initialValues,
          setFieldValue,
          handleReset,
          dirty
        }) => {
          return (
            // @ts-ignore
            <Form>
              <Grid container spacing={1}>
                {filters.map(filter => {
                  if (
                    filter.conditionallyRender &&
                    !filter.conditionallyRender(values, auth)
                  ) {
                    return null;
                  }
                  return (
                    <Grid key={filter.name} item {...calcFieldWidth(filter)}>
                      <RenderField
                        field={filter}
                        classes={classes}
                        readOnly={false}
                        initialValues={initialValues}
                        setFieldValue={setFieldValue}
                        status={status}
                      />
                    </Grid>
                  );
                })}

                {/* Панель с кнопками */}
                <Grid
                  item
                  style={{ alignSelf: "flex-end", marginLeft: "auto" }}
                >
                  <Grid container spacing={1}>
                    <Grid item>
                      <ShadowedOutlinedButton
                        variant="outlined"
                        onClick={handleReset}
                        disabled={isSubmitting}
                      >
                        Отменить
                      </ShadowedOutlinedButton>
                    </Grid>
                    <Grid item>
                      <ShadowedOutlinedButton
                        type="submit"
                        variant="outlined"
                        disabled={isSubmitting || !dirty}
                        color="primary"
                      >
                        Применить
                      </ShadowedOutlinedButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Paper>
  );
};

export default FilterPanel;
