import {
  rebuildReceivedOptionsLabelAsLabelUniqueIdAsValue,
  rebuildReceivedOptionsLabelAsLabelUniqueIdAsValueAddMainPerformerFlag
} from "../../rebuildRecieved";
import { baseTextAreaProps, baseTimeFieldProps } from "../../baseFieldProps";
import { blocksT, SelectValueT } from "../../types";
import { localizedYup, timeSchema } from "../../yup";
import { constructRequest } from "../../../components/modalWrapper/utils/baseRequests";
import { rebuildValues } from "../../../components/modalWrapper/utils/rebuildValuesBeforeSending";
import { doBeforeSend } from "../../baseFormProps";
import { defaultErrorHandler } from "../../../utils/defaultErrorHandler";
import { fullWidth, halfWith } from "../../widths";
import { PerformerT } from "../../../components/schedule/types";
import { DayDescription } from "../../../components/schedule/dialogs/DayDescription";
import { ExtendedBlockFormObj } from "../../../components/modalWrapper/types";
import { dispatchOpenSnackBar, dispatchPopHeadModal } from "../../../store";

export const rebuildMinistrationPerformersBeforeSend = (value: SelectValueT) =>
  Array.isArray(value)
    ? value.map(
        ({ label, value: v, isMainPerformer, __isNew__ }: PerformerT) => ({
          fullName: label,
          id: __isNew__ ? -1 : v,
          isMainPerformer: Boolean(isMainPerformer),
          isExternal: Boolean(__isNew__)
        })
      )
    : [];

const blocks: blocksT = [
  {
    fields: [
      {
        label: "Служба",
        name: "objectTypeCode",
        type: "select",
        dictUrl: "/api/ministration/type",
        rebuildReceivedOptions: rebuildReceivedOptionsLabelAsLabelUniqueIdAsValue,
        placeholder: "Выберите службу",
        widths: halfWith
      },
      {
        name: "objectTypeCodeFiller",
        type: "spaceFiller",
        widths: halfWith
      },
      {
        label: "Дата",
        name: "date",
        type: "date",
        minDate: new Date()
      },
      {
        label: "Время начала",
        name: "start",
        ...baseTimeFieldProps
      },
      {
        label: "Время окончания",
        name: "end",
        ...baseTimeFieldProps
      },
      {
        label: "Описание дня",
        name: "displayOnlyDescription",
        type: "custom",
        additionalProps: {
          deps: ["date"]
        },
        component: DayDescription,
        conditionallyRender: values => values.date,
        doBeforeSend: () => undefined,
        widths: fullWidth
      },
      {
        label: "Священнослужитель",
        name: "performers",
        type: "select",
        dictUrl: "/api/ministration/priest",
        isCreatable: true,
        multiple: true,
        rebuildReceivedOptions: rebuildReceivedOptionsLabelAsLabelUniqueIdAsValueAddMainPerformerFlag,
        doBeforeSend: rebuildMinistrationPerformersBeforeSend,
        placeholder: "Выберите священнослужителей",
        multiValueFlipPath: "isMainPerformer",
        selectAllOptions: {
          label: "Всё духовенство"
        }
      },
      {
        label: "Описание",
        name: "description",
        ...baseTextAreaProps
      },
      {
        label: "Включать в отчёты",
        name: "isReport",
        defaultValue: true,
        readOnly: true,
        type: "checkbox"
      },
      {
        label: "Опубликовать",
        name: "isPublished",
        type: "checkbox"
      }
    ]
  }
];

export const createMinistration: ExtendedBlockFormObj = {
  title: "Новое богослужение",
  doBeforeSend,
  onSubmit: (values, { setSubmitting }, successCallback) => {
    const newValues = rebuildValues(
      {
        ...values
      },
      {
        blocks,
        doBeforeSend
      }
    );
    constructRequest(
      {
        method: "post",
        url: "/api/ministration",
        data: {
          ...newValues,
          dateTimestamp: `${newValues.date} ${newValues.start}`,
          dateEndTimestamp: `${newValues.date} ${newValues.end}`
        }
      },
      ({ status }) => {
        setSubmitting(false);
        if (status === 200 || status === 201) {
          dispatchOpenSnackBar('Объект "Богослужения" был успешно создан');
          dispatchPopHeadModal();
          if (typeof successCallback === "function") {
            successCallback({
              selectedDates: [values.date]
            });
          }
        }
      },
      er => {
        setSubmitting(false);
        defaultErrorHandler(er);
      }
    );
  },
  blocks,
  validationSchema: localizedYup.object({
    objectTypeCode: localizedYup
      .object()
      .nullable()
      .required(),
    performers: localizedYup.array(localizedYup.object().nullable()).min(1),
    description: localizedYup.string(),
    date: localizedYup.string().required(),
    start: timeSchema.required(),
    // @ts-ignore
    end: timeSchema.compareTimeTo(localizedYup.ref("start")).required(),
    isReport: localizedYup.bool()
  })
};
