import { get, set } from "lodash";
import { dataRestDoBeforeSend } from "../../../constants/baseFormProps";
import { IField } from "../../../constants/types";
import { ExtendedBlockFormObj, InitialValuesT } from "../types";

export const rebuildValues = (
  values: InitialValuesT,
  { blocks, doBeforeSend }: ExtendedBlockFormObj
) => {
  const reconstructedValues = { ...values };

  function rebuildField(fields: IField[]) {
    fields.forEach(field => {
      const { type, doBeforeSend: fieldLevelCustomDoBeforeSend, name } = field;
      const currentFieldValue = get(values, name);
      if (type === "fieldArray") {
        currentFieldValue.forEach((fieldArray: any, index: number) =>
          rebuildField(
            // @ts-ignore todo подумать как правильно типизировать
            field.fields.map(arrayField => ({
              ...arrayField,
              name: `${name}.${index}.${arrayField.name}`
            }))
          )
        );
      }

      if (fieldLevelCustomDoBeforeSend) {
        set(
          reconstructedValues,
          name,
          fieldLevelCustomDoBeforeSend(currentFieldValue, values)
        );
      } else if (
        // fieldLevelCustomDoBeforeSend === false по логике долже отменять процесс пересборки значения
        typeof fieldLevelCustomDoBeforeSend !== "undefined" &&
        !fieldLevelCustomDoBeforeSend
      ) {
        return currentFieldValue;
      } else {
        const func = doBeforeSend && doBeforeSend[type];
        if (func) {
          set(reconstructedValues, name, func(currentFieldValue));
        } else if (
          field.type === "select" &&
          typeof field.dictUrl === "string"
        ) {
          const fnc = dataRestDoBeforeSend[type];
          if (fnc)
            set(
              reconstructedValues,
              name,
              fnc(currentFieldValue, field.dictUrl)
            );
        }
      }
    });
  }

  blocks.forEach(({ fields }) => {
    rebuildField(fields);
  });
  return reconstructedValues;
};
