import React, { useRef, useState } from "react";
import { useFormikContext } from "formik";
import { Button, Popover, TextField } from "@material-ui/core";
import { Placemark, YMapsApi } from "react-yandex-maps";
import { Maps, YandexEvent } from "./maps";
import { defaultPreloader } from "./completePromiseThenRenderComponent";
import { CustomField } from "../constants/types";

// Создание метки.
function createPlacemark(coords: number[], ymaps: YMapsApi) {
  return new ymaps.Placemark(
    coords,
    {
      iconCaption: "поиск..."
    },
    {
      preset: "islands#violetDotIconWithCaption",
      draggable: true
    }
  );
}

export const YmapsSelectCoordinates: CustomField["component"] = ({
  readOnly
}) => {
  const {
    setFieldValue,
    values: { coordinationLatitude, coordinationLongtitude }
  } = useFormikContext<any>();
  const [open, setOpen] = useState(false);
  const btnRef = useRef(null);
  const [[latitude, longtitude], setCoords] = useState([
    Number.parseFloat(coordinationLatitude) || 0,
    Number.parseFloat(coordinationLongtitude) || 0
  ]);
  const id = "coordSelect";
  const ymaps = useRef<YMapsApi | null>(null);
  const placeMarkRef = useRef<any | null>(null);
  return (
    <>
      <Button
        style={{ height: "100%" }}
        disabled={readOnly}
        onClick={() => setOpen(true)}
        ref={btnRef}
        aria-describedby={id}
        color="primary"
      >
        Указать расположение на карте
      </Button>
      <Popover
        open={open}
        // anchorEl={ref.current}
        anchorReference="anchorPosition"
        anchorPosition={{ top: 200, left: 400 }}
        id={id}
        onClose={(event, reason) => {
          setOpen(false);
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <div
          style={{
            display: "grid",
            alignItems: "center",
            justifyItems: "center",
            justifyContent: "center",
            padding: "6px"
          }}
        >
          <Maps
            preloader={defaultPreloader}
            mapProps={{
              width: "500px",
              height: "400px",
              defaultState: { center: [55.75, 37.57], zoom: 9 }
            }}
            getCoordsOnClick={(coords: [number, number]) => {
              setCoords(coords);
              // Если метка уже создана – просто передвигаем ее.
              if (placeMarkRef.current) {
                placeMarkRef.current.geometry.setCoordinates(coords);
              }
              // Если нет – создаем.
              else if (ymaps.current) {
                placeMarkRef.current = createPlacemark(coords, ymaps.current);
                // ymaps.current.geoObjects.add(placeMarkRef.current);
                // Слушаем событие окончания перетаскивания на метке.
                placeMarkRef.current.events.add("dragend", function() {
                  const coordinates = placeMarkRef.current.geometry.getCoordinates();
                  if (
                    Array.isArray(
                      placeMarkRef.current.geometry.getCoordinates()
                    )
                  ) {
                    setCoords(coordinates);
                    // getAddress();
                  }
                });
              }
            }}
            bindYmapsObj={ymapsobj => {
              ymaps.current = ymapsobj;
            }}
            modules={["Placemark"]}
          >
            <Placemark
              geometry={[latitude, longtitude]}
              options={{ draggable: true }}
              onDragend={(e: YandexEvent) => {
                const position = e.get("target").geometry.getCoordinates() as [
                  number,
                  number
                ];
                if (Array.isArray(position)) {
                  setCoords(position);
                }
              }}
            />
          </Maps>
          <div
            style={{
              display: "grid",
              gridGap: "4px",
              gridTemplateColumns: "repeat(3, auto)",
              padding: "4px"
            }}
          >
            <TextField value={latitude} label="Широта" />
            <TextField value={longtitude} label="Долгота" />
            <Button
              style={{
                alignSelf: "flex-end"
              }}
              color="primary"
              variant="outlined"
              onClick={() => {
                setOpen(false);
                setFieldValue("coordinationLatitude", latitude || "");
                setFieldValue("coordinationLongtitude", longtitude || "");
              }}
            >
              Выбрать
            </Button>
          </div>
        </div>
      </Popover>
    </>
  );
};
