import { defaultErrorHandler } from "../../../utils/defaultErrorHandler";
import { rebuildReceivedOptionsLabelAsLabelUniqueIdAsValueAddMainPerformerFlag } from "../../rebuildRecieved";
import { baseTimeFieldProps } from "../../baseFieldProps";
import { fullWidth } from "../../widths";
import { formatToRussianDate } from "../../formaters/formatters";
import { localizedYup, timeSchema } from "../../yup";
import { constructRequest } from "../../../components/modalWrapper/utils/baseRequests";
import { rebuildValues } from "../../../components/modalWrapper/utils/rebuildValuesBeforeSending";
import { doBeforeSend } from "../../baseFormProps";
import { blocksT } from "../../types";
import { ExtendedBlockFormObj } from "../../../components/modalWrapper/types";
import { dispatchOpenSnackBar, dispatchPopHeadModal } from "../../../store";

const blocks: blocksT = [
  {
    description:
      "При выборе длительного интервала времени при создании слота будут созданы слоты кратные длительности времени Таинства/беседы",
    fields: [
      {
        name: "isReport",
        label: "Включать в отчет",
        defaultValue: true,
        type: "checkbox",
        widths: fullWidth
      },
      {
        label: "Священнослужитель",
        name: "performerId",
        type: "select",
        dictUrl: ({ isPersonal }) =>
          isPersonal
            ? "/api/events/log/priestByUser?"
            : "/api/ministration/priest?",
        async: true,
        rebuildReceivedOptions: rebuildReceivedOptionsLabelAsLabelUniqueIdAsValueAddMainPerformerFlag,
        placeholder: "Выберите священнослужителя",
        widths: fullWidth
      },
      {
        name: "isPersonal",
        type: "checkbox",
        hidden: true
      },
      {
        label: "Время начала",
        name: "fromTime",
        ...baseTimeFieldProps,
        widths: fullWidth
      },
      {
        label: "Время окончания",
        name: "toTime",
        ...baseTimeFieldProps,
        widths: fullWidth
      },
      {
        label: "Неделя",
        name: "days",
        type: "date-new",
        additionalProps: {
          disabledDays: { before: new Date() }
        },
        doBeforeSend: (days: Date[]) =>
          days.map(day => formatToRussianDate(day)),
        defaultValue: [],
        widths: fullWidth
      },
      {
        name: "codeCommonAction",
        type: "text",
        defaultValue: 1,
        hidden: true
      }
    ]
  }
];

export const createSlot: ExtendedBlockFormObj = {
  title: "Новый слот",
  onSubmit: (values, { setSubmitting }, successCallback) => {
    constructRequest(
      {
        method: "post",
        url: "/api/slots",
        data: rebuildValues(values, {
          blocks,
          doBeforeSend
        })
      },
      ({ status }) => {
        setSubmitting(false);
        if (status === 200 || status === 201) {
          dispatchOpenSnackBar('Объект "Слот" был успешно создан');
          dispatchPopHeadModal();
          if (typeof successCallback === "function") {
            successCallback({
              selectedDates: values.days
            });
          }
        }
      },
      er => {
        setSubmitting(false);
        defaultErrorHandler(er);
      }
    );
  },
  blocks,
  validationSchema: localizedYup.object({
    isReport: localizedYup.bool(),
    performerId: localizedYup
      .object()
      .nullable()
      .required(),
    fromTime: timeSchema.required(),
    // @ts-ignore
    toTime: timeSchema.compareTimeTo(localizedYup.ref("fromTime")).required(),
    days: localizedYup
      .array(localizedYup.date())
      .min(1)
      .required()
  }),
  primaryButtonText: "Сформировать"
};
