import { selector } from "recoil";
import { userChurchAtom } from "../../store/userStore";
import axios from "axios";
import { OptionT } from "../../constants/types";

export type Identifiably = {
  id: string;
};

export type PeriodT = {
  startTime: string;
  finalTime: string;
};

export type ShiftT = PeriodT & { name?: string };

export type ShiftDuties = ShiftT & {
  priests: OptionT[];
};

export async function loadChurchShifts(church: OptionT | null) {
  if (church) {
    const response = await axios.get<ShiftT[]>(
      `/api/hram/${church.value}/shifts`
    );
    return response.data;
  }
  return [];
}

export const loadDuties = selector<ShiftT[]>({
  key: "loadDuties",
  get: async (opts: any) => {
    const church = opts.get(userChurchAtom);
    return await loadChurchShifts(church);
  }
});
