import { baseJournal } from "./basejournal";
import { PageT } from "../../types";
import { Work } from "@material-ui/icons";
import { addPerformer } from "../claim/inWork/journal";

const progress: PageT = {
  ...baseJournal,
  icon: Work,
  navigationLabel: "Подтвержденные беседы",
  url: () => addPerformer("/api/slot/journal/progress")
};
export default progress;
