import React, { memo, useState } from "react";
import {
  Button,
  Checkbox,
  Accordion,
  FormControlLabel,
  Grid,
  makeStyles,
  Theme,
  Typography,
  AccordionSummary,
  AccordionDetails,
  Popover,
  useMediaQuery
} from "@material-ui/core";
import {
  AddOutlined,
  ExpandLess,
  ExpandMore,
  FileCopyOutlined,
  NavigateBefore,
  NavigateNext
} from "@material-ui/icons";
import { isEqual } from "lodash";
import { addMonths, addWeeks, subMonths, subWeeks } from "date-fns";
import axios from "axios";
import { DownloadScheduleButton } from "./downloadScheduleButton";
import { ScheduleSettings } from "./dialogs/settings";
import { ReactSelectMUI } from "../select/reactSelectMUI";
import { DayPickerMUI } from "../picker/DayPickerMUI";
import { getSelectedDays } from "./utils/journal";
import { ManagementPanelProps } from "./types";
import { defaultErrorHandler } from "../../actions/typedActions";
import { formatToRussianDate } from "../../constants/formaters/formatters";
import { publishBorderColor } from "./table/RenderDays";
import { useRecoilValue } from "recoil";
import { overUserAtom } from "../../store/userStore";

const ExpandMoreRendered = <ExpandMore />;

const getStyles = makeStyles<Theme>(theme => ({
  actionPanel: {
    border: `1.2px solid ${theme.palette.primary.main}`,
    marginBottom: theme.spacing(1),
    zIndex: 3,
    overflow: "auto",
  },
  actionBtn: {},
  noMarginNoPadding: {
    margin: 0,
    padding: 0
  },
  summary: {
    marginTop: "6px",
    marginBottom: "6px",
    justifyContent: "space-between",
    wordBreak: "keep-all"
  },
  summaryText: {
    margin: 0,
    fontSize: "1.2rem"
  },
  prevWeekBtn: { marginRight: "4px" }
}));

export const ManagementPanel = memo(
  ({
    setDate,
    openModal,
    ministrationConfig,
    workItemConfig,
    slotConfig,
    copyBtnConfig,
    successCallback,
    isPersonal,
    selectedEventType,
    selectedPerformer,
    date,
    selectedRange,
    setSelectedRange,
    actionButtonsStyle,
    setButtonsStyle,
    showAllEvents,
    setSelectedEventType,
    setShowAllEvents,
    filters,
    optionsMap,
    setSelectedPerformer,
    openSnackBar,
    everythingPublished,
    openConfirmDialog,
    closeConfirmDialog
  }: ManagementPanelProps) => {
    const classes = getStyles();
    const performer = useRecoilValue(overUserAtom);
    const [expanded, setExpanded] = useState(false);
    const matches = true;

    const [
      createPopover,
      setCreatePopover
    ] = React.useState<HTMLButtonElement | null>(null);
    const handleCreatePopover = (
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      setCreatePopover(event.currentTarget);
    };
    const handleCreatePopoverClose = () => {
      setCreatePopover(null);
    };
    const isFirstBreakpoint = useMediaQuery("(max-width: 660px");
    const isSecondBreakpoint = useMediaQuery("(max-width: 840px");
    const isThirdBreakpoint = useMediaQuery("(max-width: 1020px");
    const isFourthBreakpoint = useMediaQuery("(max-width: 1340px");

    // const matches = useMediaQuery("(min-width:900px)");
    return (
      <Accordion
        expanded={expanded}
        className={classes.actionPanel}
        TransitionProps={{ unmountOnExit: true }}
        aria-controls="schedule-management-panel-content"
        id="schedule-management-panel-header"
      >
        <AccordionSummary
          classes={{ content: classes.summary }}
          onClick={() => setExpanded(!expanded)}
        >
          {matches && (
            <Typography
              className={classes.summaryText}
              style={
                isSecondBreakpoint && !expanded
                  ? { maxWidth: "110px" }
                  : isThirdBreakpoint && !expanded
                  ? { maxWidth: "155px" }
                  : isFirstBreakpoint && expanded
                  ? { maxWidth: "110px" }
                  : isSecondBreakpoint && expanded
                  ? { maxWidth: "255px" }
                  : {}
              }
            >
              Панель навигации и управления
            </Typography>
          )}
          <div
            style={
              isSecondBreakpoint
                ? { maxWidth: "180px" }
                : isThirdBreakpoint
                ? { maxWidth: "220px" }
                : isFourthBreakpoint
                ? { maxWidth: "350px" }
                : {}
            }
          >
            {!expanded && (
              <Button
                variant="outlined"
                color="primary"
                size="small"
                className={classes.prevWeekBtn}
                onClick={event => {
                  event.stopPropagation();
                  if (selectedRange === "week") {
                    setDate(subWeeks(date, 1));
                  } else setDate(subMonths(date, 1));
                }}
              >
                <NavigateBefore />
                {matches && selectedRange === "week"
                  ? "Предыдущая седмица"
                  : "Предыдущий месяц"}
              </Button>
            )}
            {!expanded && !everythingPublished && !isPersonal && (
              <Button
                variant="outlined"
                color="primary"
                size="small"
                className={classes.prevWeekBtn}
                style={{
                  height: "32px",
                  borderColor: publishBorderColor,
                  color: publishBorderColor
                }}
                onClick={event => {
                  event.stopPropagation();
                  const selectedDays = getSelectedDays(date, selectedRange);
                  const from = formatToRussianDate(selectedDays.from as Date);
                  const to = formatToRussianDate(selectedDays.to as Date);
                  openConfirmDialog({
                    mainText: `опубликовать Богослужения в диапазоне от ${from} до ${to}`,
                    primaryButtonHandler: () =>
                      axios
                        .patch("/api/ministration/published/make", undefined, {
                          params: {
                            from,
                            to
                          }
                        })
                        .then(value => {
                          closeConfirmDialog();
                          openSnackBar("Богослужения были опубликованы");
                          successCallback();
                        })
                        .catch(reason => defaultErrorHandler(reason))
                  });
                }}
              >
                Опубликовать
              </Button>
            )}
            {!expanded && (
              <Button
                variant="outlined"
                color="primary"
                size="small"
                className={classes.prevWeekBtn}
                onClick={event => {
                  event.stopPropagation();
                  if (selectedRange === "week") {
                    setDate(addWeeks(date, 1));
                  } else setDate(addMonths(date, 1));
                }}
              >
                {matches && selectedRange === "week"
                  ? "Следующая седмица"
                  : "Следующий месяц"}
                <NavigateNext />
              </Button>
            )}
          </div>
          <div>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              style={{ wordBreak: "keep-all" }}
            >
              {expanded ? "Свернуть" : "Раскрыть"}
              {expanded ? <ExpandLess /> : <ExpandMore />}
            </Button>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Popover
            open={Boolean(createPopover)}
            anchorEl={createPopover}
            onClose={handleCreatePopoverClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            elevation={0}
          >
            <Grid>
              {ministrationConfig.createBtn.show && (
                <Grid item>
                  <Button
                    disabled={ministrationConfig.createBtn.disabled}
                    variant="outlined"
                    className={classes.actionBtn}
                    onClick={() => {
                      openModal({
                        mode: "createMinistration",
                        category: "schedule",
                        successCallback
                      });
                    }}
                    fullWidth
                    style={{ justifyContent: "flex-start", marginTop: 3 }}
                  >
                    <AddOutlined />
                    Богослужение
                  </Button>
                </Grid>
              )}
              {slotConfig.createBtn.show && (
                <Grid item>
                  <Button
                    disabled={slotConfig.createBtn.disabled}
                    variant="outlined"
                    className={classes.actionBtn}
                    onClick={() => {
                      openModal({
                        mode: "createSlot",
                        category: "schedule",
                        initialValues: {
                          isPersonal
                        },
                        successCallback
                      });
                    }}
                    fullWidth
                    style={{ justifyContent: "flex-start", marginTop: 3 }}
                  >
                    <AddOutlined />
                    Беседа
                  </Button>
                </Grid>
              )}
              {workItemConfig.createBtn.show && (
                <Grid item>
                  <Button
                    disabled={workItemConfig.createBtn.disabled}
                    variant="outlined"
                    className={classes.actionBtn}
                    onClick={() => {
                      openModal({
                        mode: "createWorkItem",
                        category: "schedule",
                        initialValues: {
                          isPersonal,
                          performer,
                          isReport: !isPersonal
                        },
                        successCallback
                      });
                    }}
                    fullWidth
                    style={{ justifyContent: "flex-start", marginTop: 3 }}
                  >
                    <AddOutlined />
                    Дело
                  </Button>
                </Grid>
              )}
            </Grid>
          </Popover>
          <Grid
            container
            spacing={1}
            style={ (isFirstBreakpoint || isSecondBreakpoint)
              ? { flexDirection: "column" } : {} }
          >
            <Grid item>
              <Grid
                item
                style={{
                  width: "100%",
                  overflow: "auto",
                  display: "flex",
                  flexDirection: isFourthBreakpoint ? "column" : "row",
                }}
              >
                <div
                  style={{
                    display: "inline-flex",
                    gridGap: 5,
                    paddingRight: 5,
                    flexDirection: isSecondBreakpoint ? "column" : "row"
                  }}
                >
                  <Grid item style={{ width: 240, minWidth: 150 }}>
                    <ReactSelectMUI
                      value={selectedEventType}
                      placeholder="Выберите событие из списка"
                      options={
                        isPersonal
                          ? optionsMap.allEventTypes.options
                          : optionsMap.ministrationEventTypes.options
                      }
                      label="Событие"
                      onChange={(value, action) => {
                        if (
                          typeof value !== "undefined" &&
                          !Array.isArray(value)
                        ) {
                          // @ts-ignore
                          setSelectedEventType(value);
                          if (!isPersonal) {
                            setShowAllEvents(false);
                          }
                        }
                      }}
                    />
                  </Grid>
                  {filters.priests && (
                    <Grid item style={{ width: 240 }}>
                      <ReactSelectMUI
                        value={selectedPerformer}
                        options={
                          isPersonal
                            ? optionsMap.personalPriests.options
                            : optionsMap.hramPriests.options
                        }
                        placeholder="Выберите священнослужителя"
                        label="Священнослужитель"
                        onChange={(value, action) => {
                          if (
                            typeof value !== "undefined" &&
                            !Array.isArray(value)
                          ) {
                            // @ts-ignore
                            setSelectedPerformer(value);
                          }
                        }}
                      />
                    </Grid>
                  )}
                  {(ministrationConfig.createBtn.show ||
                    slotConfig.createBtn.show ||
                    workItemConfig.createBtn.show) && (
                    <Grid item style={ isSecondBreakpoint
                      ? { alignSelf: "flex-start" } : { alignSelf: "flex-end" } }>
                      <Button
                        disabled={
                          ministrationConfig.createBtn.disabled &&
                          slotConfig.createBtn.disabled &&
                          workItemConfig.createBtn.disabled
                        }
                        variant="outlined"
                        className={classes.actionBtn}
                        onClick={handleCreatePopover}
                        style={{ minHeight: 38, width: 85 }}
                      >
                        {/*<AddOutlined />*/}
                        Создать
                      </Button>
                    </Grid>
                  )}
                </div>
                <div style={{ display: "inline-flex", gridGap: 5 }}>
                  <Grid item style={{ width: 150 }}>
                    <ReactSelectMUI
                      value={
                        selectedRange == "week"
                          ? { value: "week", label: "СЕДМИЦА" }
                          : { value: "month", label: "МЕСЯЦ" }
                      }
                      placeholder="Выберете интервал отображения"
                      options={[
                        { value: "week", label: "СЕДМИЦА" },
                        { value: "month", label: "МЕСЯЦ" }
                      ]}
                      label="Интервал"
                      onChange={(value, action) => {
                        // @ts-ignore
                        setSelectedRange(value.value);
                      }}
                      isClearable={false}
                    />
                  </Grid>
                  <Grid item style={{ alignSelf: "flex-end", width: 50 }}>
                    <DownloadScheduleButton
                      ministration={selectedEventType}
                      selectedRange={selectedRange}
                      showAllEvents={showAllEvents}
                      isPersonal={isPersonal}
                      user={selectedPerformer}
                      date={date}
                      isIconOnly
                      style={{ minWidth: 50, minHeight: 38 }}
                    />
                  </Grid>
                  {copyBtnConfig.show && (
                    <Grid item style={{ alignSelf: "flex-end", width: 50 }}>
                      <Button
                        style={{ minWidth: 50, minHeight: 38 }}
                        disabled={copyBtnConfig.disabled}
                        variant="outlined"
                        className={classes.actionBtn}
                        onClick={() => {
                          openModal({
                            mode: "copySchedule",
                            category: "schedule",
                            initialValues: {
                              isPersonal
                            },
                            successCallback
                          });
                        }}
                      >
                        <FileCopyOutlined />
                        {/*Копировать*/}
                      </Button>
                    </Grid>
                  )}

                  <Grid item style={{ alignSelf: "flex-end", width: 50 }}>
                    <ScheduleSettings
                      actionButtonsStyle={actionButtonsStyle}
                      setButtonsStyle={setButtonsStyle}
                    />
                  </Grid>
                  {filters.showAllTypes && (
                    <Grid
                      item
                      style={{
                        alignSelf: "flex-end",
                        minHeight: 38,
                        minWidth: 150
                      }}
                    >
                      <FormControlLabel
                        style={{ minHeight: 38 }}
                        label="Показать все события"
                        onChange={(event1, checked) => {
                          if (checked) {
                            setSelectedEventType(null);
                          }
                          setShowAllEvents(checked);
                        }}
                        control={
                          <Checkbox
                            style={{ minHeight: 38 }}
                            indeterminate={showAllEvents === null}
                            color="primary"
                            checked={showAllEvents}
                          />
                        }
                      />
                    </Grid>
                  )}
                </div>
              </Grid>
            </Grid>
            <Grid
              item
              style={ isSecondBreakpoint
                ? { marginRight: "auto" } : { marginLeft: "auto" } }
            >
              <Accordion
                elevation={1}
                square
                classes={{
                  root: classes.noMarginNoPadding
                }}
                style={{ marginTop: 11 }}
              >
                <AccordionSummary
                  expandIcon={ExpandMoreRendered}
                  style={{ minHeight: 38 }}
                >
                  Календарь
                </AccordionSummary>
                <AccordionDetails>
                  <DayPickerMUI
                    showOutsideDays
                    showWeekNumbers
                    selectedDays={getSelectedDays(date, selectedRange)}
                    onDayClick={day => setDate(day)}
                  />
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  },
  isEqual
);
