/* eslint-disable react/jsx-no-duplicate-props */
import { TextFieldProps } from "@material-ui/core/TextField";
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  IconButton,
  InputAdornment,
  Popover,
  TextField
} from "@material-ui/core";
import { CalendarToday, Close } from "@material-ui/icons";
import {
  addDays,
  endOfMonth,
  endOfWeek,
  isBefore,
  isValid,
  startOfMonth,
  startOfWeek,
  subDays,
  subWeeks
} from "date-fns";
import { DayPickerProps } from "react-day-picker";
import { DayPickerMUI, DayPickerMUIProps } from "./DayPickerMUI";
import {
  baseDateFnsOptions,
  formatToRussianDate,
  parseRussianDate
} from "../../constants/formaters/formatters";
import { getRegExpFilteredValue } from "../input/utils";
import "./dateRange.css";
import { MaskedInputMUI } from "../input/maskedInputMUI";
import { dateMask } from "./DayPickerInputMUI";
import { createAutoCorrectedDatePipe } from "text-mask-addons/dist/textMaskAddons";

type ValueT = Date[] | string | string[];

export type DateRangePickerInputProps = Omit<TextFieldProps, "value"> &
  DayPickerMUIProps & {
    value: ValueT;
    readOnly?: boolean;
    clearValue: () => void;
    multiple?: boolean;
    onSelected?: (selectedDays: string[]) => void;
  };

function isSelectingFirstDay(from: Date | null, day: Date, to: Date | null) {
  const isBeforeFirstDay = from && isBefore(day, from);
  const isRangeSelected = from && to;
  return !from || isBeforeFirstDay || isRangeSelected;
}

function useUpdateFromTo(
  value: ValueT,
  setFrom: (from: Date) => void,
  setEnteredTo: (enteredTo: Date) => void,
  setTo: (to: Date) => void
) {
  useEffect(() => {
    if (typeof value === "string") {
      const indexOfDelimiter = value.lastIndexOf(" - ");
      if (indexOfDelimiter !== -1) {
        const newFrom = parseRussianDate(value.slice(0, indexOfDelimiter));
        if (isValid(newFrom)) {
          setFrom(newFrom);
        }
        if (value.length < indexOfDelimiter + 3) {
          const newTo = parseRussianDate(value.slice(indexOfDelimiter + 3));
          if (isValid(newTo)) {
            setEnteredTo(newTo);
            setTo(newTo);
          }
        }
      }
    } else if (Array.isArray(value) && value.length === 2) {
      const [first, second] = value;
      setFrom(typeof first === "string" ? parseRussianDate(first) : first);
      const newTo =
        typeof second === "string" ? parseRussianDate(second) : second;
      setTo(newTo);
      setEnteredTo(newTo);
    }
  }, [setEnteredTo, setFrom, setTo, value]);
}

export const dateRangeMask = [...dateMask, " ", "-", " ", ...dateMask];

export type PipeT = (
  conformedValue: string,
  config: any
) => false | string | { value: string; indexesOfPipedChars: number[] };

export const dateRangePipe: PipeT = (conformedValue, config) => {
  const datePipe = createAutoCorrectedDatePipe("dd.mm.yyyy");
  const resultOfFirstPipe = datePipe(conformedValue, config);
  const valueAfterFirstPipe = resultOfFirstPipe.value;
  if (valueAfterFirstPipe) {
    const indexOfDelimiter = valueAfterFirstPipe.indexOf(" - ");
    if (indexOfDelimiter > -1) {
      const indexAfterDelimiter = indexOfDelimiter + 3;
      resultOfFirstPipe.value =
        resultOfFirstPipe.value.slice(0, indexAfterDelimiter) +
        datePipe(valueAfterFirstPipe.slice(indexAfterDelimiter), config).value;
    }
  }
  return resultOfFirstPipe;
};

export const DateRangePickerInput = ({
  value,
  error,
  helperText,
  readOnly,
  label,
  inputProps,
  clearValue,
  disabledDays,
  onSelected
}: DateRangePickerInputProps) => {
  const [open, setOpen] = useState(false);
  const id = open ? "simple-popover" : undefined;
  const inputRef = useRef(null);

  const [from, setFrom] = useState<Date | null>(null);
  const [to, setTo] = useState<Date | null>(null);

  const [enteredTo, setEnteredTo] = useState<Date | null>(null);
  const onDayMouseEnter = (day: Date) => {
    if (!isSelectingFirstDay(from, day, to)) {
      setEnteredTo(day);
    }
  };

  useUpdateFromTo(value, setFrom, setEnteredTo, setTo);

  const computedModifiers =
    (from &&
      enteredTo && {
        start: from,
        end: enteredTo
      }) ||
    undefined;
  const selectedDays: DayPickerProps["selectedDays"] =
    (from && enteredTo && [from, { from, to: enteredTo }]) || undefined;
  if (Array.isArray(selectedDays) && from && to) {
    selectedDays[0] = {
      from,
      to
    };
  }

  const [rawNewValue, setRawNewValue] = useState("");

  useEffect(() => {
    if (Array.isArray(value) && value.length === 2) {
      setRawNewValue(`${value[0]} - ${value[1]}`);
    } else if (typeof value === "string") {
      setRawNewValue(value);
    }
  }, [value]);
  return (
    <div>
      <MaskedInputMUI
        ref={inputRef}
        mask={dateRangeMask}
        pipe={dateRangePipe}
        value={rawNewValue}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          if (event.currentTarget) {
            const { value: typedValue } = event.target;
            if (typedValue) {
              setRawNewValue(typedValue);
              const [newFrom, newTo] = typedValue
                .split(" - ")
                .map(parseRussianDate);
              if (isValid(newFrom) && isValid(newTo)) {
                setFrom(newFrom);
                setEnteredTo(newTo);
                setTo(newTo);
                if (typeof onSelected === "function" && newFrom && newTo) {
                  onSelected([
                    formatToRussianDate(newFrom),
                    formatToRussianDate(newTo)
                  ]);
                }
              }
            }
          }
        }}
        label={label}
        error={error}
        helperText={helperText}
        inputProps={{ ...inputProps, readOnly }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                size="small"
                onClick={() => {
                  if (!readOnly) {
                    setOpen(true);
                  }
                }}
              >
                <CalendarToday />
              </IconButton>
              <IconButton
                onClick={event => {
                  if (!readOnly) {
                    event.stopPropagation();
                    if (typeof clearValue === "function") {
                      setRawNewValue("");
                      clearValue();
                    }
                  }
                }}
                size="small"
              >
                <Close fontSize="small" rotate={90} />
              </IconButton>
            </InputAdornment>
          )
        }}
        fullWidth
      />
      <Popover
        id={id}
        open={open}
        anchorEl={inputRef.current}
        onClose={(event, reason) => {
          setOpen(!open);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <div
          style={{
            display: "flex",
            textAlign: "left"
          }}
        >
          <div
            style={{
              width: "160px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              paddingLeft: "8px",
              paddingTop: "25px",
              alignItems: "flex-start"
            }}
          >
            <Button
              size="small"
              color="primary"
              onClick={() => {
                const today = new Date();
                setFrom(today);
                setTo(today);
                setEnteredTo(today);
              }}
            >
              Сегодня
            </Button>
            <Button
              size="small"
              color="primary"
              onClick={() => {
                const date = new Date();
                setFrom(startOfWeek(date, baseDateFnsOptions));
                const newTo = endOfWeek(date, baseDateFnsOptions);
                setTo(newTo);
                setEnteredTo(newTo);
              }}
            >
              Эта неделя
            </Button>
            <Button
              size="small"
              color="primary"
              onClick={() => {
                const date = subWeeks(new Date(), 1);
                setFrom(startOfWeek(date, baseDateFnsOptions));
                const newTo = endOfWeek(date, baseDateFnsOptions);
                setTo(newTo);
                setEnteredTo(newTo);
              }}
            >
              Прошлая неделя
            </Button>
            <Button
              size="small"
              color="primary"
              onClick={() => {
                const date = new Date();
                const newTo = endOfMonth(date);
                setFrom(startOfMonth(date));
                setTo(newTo);
                setEnteredTo(newTo);
              }}
            >
              Этот месяц
            </Button>
            <div style={{ margin: "auto" }}>
              <TextField
                label="Дней до сегодняшнего дня"
                style={{ margin: "8px", paddingTop: "8px" }}
                InputLabelProps={{ shrink: true, style: { width: "190px" } }}
                onChange={({ target: { value: inputRawValue } }) => {
                  const today = new Date();
                  const numberOfDaysBefore = Number.parseInt(
                    getRegExpFilteredValue(inputRawValue, /\d+/),
                    10
                  );
                  // eslint-disable-next-line no-restricted-globals
                  if (!isNaN(numberOfDaysBefore)) {
                    setFrom(subDays(today, numberOfDaysBefore));
                    setEnteredTo(today);
                    setTo(today);
                  }
                }}
              />
              <TextField
                label="Дней, начиная c сегодня"
                style={{ margin: "8px" }}
                InputLabelProps={{ shrink: true, style: { width: "190px" } }}
                onChange={({ target: { value: inputRawValue } }) => {
                  const today = new Date();
                  const numberOfDaysBefore = Number.parseInt(
                    getRegExpFilteredValue(inputRawValue, /\d+/),
                    10
                  );
                  // eslint-disable-next-line no-restricted-globals
                  if (!isNaN(numberOfDaysBefore)) {
                    setFrom(today);
                    const newTo = addDays(today, numberOfDaysBefore);
                    setTo(newTo);
                    setEnteredTo(newTo);
                  }
                }}
              />
            </div>
          </div>
          <DayPickerMUI
            className="Range"
            numberOfMonths={2}
            disabledDays={disabledDays}
            selectedDays={selectedDays}
            modifiers={computedModifiers}
            showWeekDays
            onDayClick={(day, modifiers, e) => {
              if (!modifiers.disabled) {
                if (from && to && day >= from && day <= to) {
                  setFrom(null);
                  setTo(null);
                  setEnteredTo(null);
                  return;
                }
                if (isSelectingFirstDay(from, day, to)) {
                  setEnteredTo(null);
                  setFrom(day);
                  setTo(null);
                } else {
                  setTo(day);
                  setEnteredTo(day);
                }
              }
            }}
            onDayMouseEnter={onDayMouseEnter}
          />
        </div>
        <div style={{ float: "right", marginTop: "-12px" }}>
          <Button
            variant="outlined"
            onClick={() => setOpen(false)}
            style={{ margin: "4px" }}
          >
            Закрыть
          </Button>
          <Button
            variant="outlined"
            color="primary"
            style={{ margin: "4px" }}
            onClick={() => {
              if (typeof onSelected === "function") {
                if (from) {
                  const formattedFrom = formatToRussianDate(from);
                  onSelected([
                    formattedFrom,
                    (to && formatToRussianDate(to)) || formattedFrom
                  ]);
                  setOpen(false);
                }
              }
            }}
          >
            Принять
          </Button>
        </div>
      </Popover>
    </div>
  );
};
