import forms from "./forms";
import { constructRebuildReceivedOptionsLabelLabelValueLabel } from "../../rebuildRecieved";
import { PageT } from "../../types";

export const library: PageT = {
  requiresFunction: [
    {
      functionName: "administrator_library_modifier",
      allowedActions: ["create", "view", "edit", "delete"]
    }
    /*  {
      functionName: "administrator_structure_library_modifier",
      allowedActions: ["create", "view", "edit", "delete"]
    },
    {
      functionName: "administrator_hram_library_modifier",
      allowedActions: ["create", "view", "edit", "delete"]
    },
    {
      functionName: "priest_library_modifier",
      allowedActions: ["create", "view", "edit", "delete"]
    },
    {
      functionName: "assistant_library_modifier",
      allowedActions: ["create", "view", "edit", "delete"]
    } */
  ],
  filtersPanel: {
    filters: [
      {
        label: "Тематика",
        name: "type",
        type: "select",
        placeholder: "Выберите тематику",
        dictUrl: "/api/libraryDictType/search/present",
        rebuildReceivedOptions: constructRebuildReceivedOptionsLabelLabelValueLabel
      },
      {
        label: "Наименование",
        name: "label",
        type: "text"
      },
      {
        label: "Автор",
        name: "authorRef",
        type: "text"
      },
      {
        label: "Ответственный за публикацию",
        name: "fullname",
        type: "text"
      }
    ]
  },
  url: "/api/library/journalLibrary",
  tableHeader: [
    {
      label: "Тематика",
      sortable: true,
      path: "type"
    },
    {
      label: "Наименование",
      sortable: true,
      path: "label"
    },
    {
      label: "Автор",
      sortable: true,
      path: "authorRef"
    },
    {
      label: "Признак публикации",
      sortable: true,
      width: "15%",
      path: "published"
    },
    {
      label: "Ответственный за публикацию",
      sortable: true,
      path: "fullname"
    },
    {
      label: "Дата добавления",
      sortable: true,
      width: "15%",
      path: "createdTimestamp"
    }
  ],
  forms: { ...forms }
};
