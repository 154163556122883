import {
  ActionCreator,
  ActionCreatorsMapObject,
  bindActionCreators
} from "redux";
import { useDispatch, useSelector } from "react-redux";
import { DependencyList, useMemo } from "react";

import { shallowEqual } from "react-redux";
import { RootStateT } from "../reducers";

export function useActions<A>(
  actions: ActionCreatorsMapObject<A> | ActionCreator<A>[],
  deps?: DependencyList
) {
  const dispatch = useDispatch();
  return useMemo(
    () => {
      if (Array.isArray(actions)) {
        return actions.map(a => bindActionCreators(a, dispatch));
      }
      return bindActionCreators(actions, dispatch);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps ? [actions, dispatch, ...deps] : [actions, dispatch]
  );
}

export function useShallowEqualSelector<
  TState = RootStateT,
  TSelected = Partial<RootStateT>
>(selector: (state: TState) => TSelected) {
  return useSelector(selector, shallowEqual);
}
