import React, { CSSProperties } from "react";
import { Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

interface HeaderCellProps {
  label?: string;
  width?: CSSProperties["width"];
  style?: CSSProperties;
}

const getStyles = makeStyles((theme: Theme) => ({
  cell: {
    fontSize: "18px",
    fontWeight: 500
  },
  sticky: {
    position: "sticky",
    top: 0,
    zIndex: 1,
    backgroundColor: "white"
  }
}));

export const HeaderCell = ({ label, width, style }: HeaderCellProps) => {
  const classes = getStyles();
  return (
    <div className="header" style={style}>
      <Typography className={classes.cell} align="center">
        {label}
      </Typography>
    </div>
  );
};
