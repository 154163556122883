import React, { ComponentType, CSSProperties, memo, useMemo } from "react";
import Select, { components } from "react-select";
import Creatable from "react-select/creatable";
import Async from "react-select/async";
import { useTheme } from "@material-ui/styles";
import { Checkbox, FormHelperText, Theme, Typography } from "@material-ui/core";
import { MultiValueProps } from "react-select/src/components/MultiValue";
import { SelectComponentsConfig } from "react-select/src/components";
import { ContainerProps } from "react-select/src/components/containers";
import AsyncCreatableSelect from "react-select/async-creatable";
import { isEqual } from "lodash";
import { OptionT } from "../../constants/types";
import { useMemorizedOptions } from "./hooks";
import { ReactSelectMUIProps } from "./types";
import stylesModule from "./styles.module.css";

const CustomMultiValue = (props: MultiValueProps<OptionT>) => {
  const { children, ...rest } = props;
  const {
    setValue,
    getValue,
    data,
    selectProps: { multiValueFlipPath },
  } = props;
  const valueToFlip = data[multiValueFlipPath];
  return (
    <components.MultiValue {...rest}>
      <div>
        {multiValueFlipPath && (
          <Checkbox
            style={{
              margin: 0,
              padding: 0,
            }}
            size="small"
            color="primary"
            onChange={() => {
              const obj = getValue();
              if (obj && Array.isArray(obj)) {
                const arr = [...obj];
                arr[arr.findIndex((o) => o.label === data.label)] = {
                  ...data,
                  [multiValueFlipPath]: !valueToFlip,
                };
                setValue(arr, "select-option");
              }
            }}
            checked={valueToFlip}
          />
        )}
        {children}
      </div>
    </components.MultiValue>
  );
};

const CustomSelectContainer: ComponentType<ContainerProps<OptionT, boolean>> = (
  props: ContainerProps<OptionT, boolean>
) => {
  const {
    selectProps: {
      textFieldProps: { label, error, helperText },
    },
  } = props;
  const { children, ...rest } = props;
  const errorColor: CSSProperties = {
    color: "red",
  };
  return (
    <components.SelectContainer {...rest}>
      <Typography
        style={error ? errorColor : undefined}
        className={stylesModule.label}
      >
        {label}
      </Typography>
      {children}
      <FormHelperText error={error} hidden={!helperText}>
        {helperText}
      </FormHelperText>
    </components.SelectContainer>
  );
};

const redefinedComponents: SelectComponentsConfig<OptionT, boolean> = {
  MultiValue: CustomMultiValue,
  SelectContainer: CustomSelectContainer,
};

const noOptionsMessageFnc = () => "Нет доступных для выбора элементов";

const formatCreateLabel = (inputValue: string) => `Создать: ${inputValue}`;

const defaultLoadingMessage = () => "Идет загрузка данных";

export const ReactSelectMUI = memo(
  ({
    isMulti = false,
    label,
    isClearable = true,
    options = [],
    value = null,
    isDisabled = false,
    placeholder = "Введите критерий поиска",
    onChange,
    textFieldProps = {},
    onBlur,
    menuPortalTarget,
    isLoading = false,
    isCreatable = false,
    multiValueFlipPath,
    selectAllOptions,
    styles,
    async,
    loadingMessage = defaultLoadingMessage,
    loadOptions,
    control,
    defaultOptions = true,
    cacheOptions = true,
  }: ReactSelectMUIProps) => {
    const theme: Theme = useTheme();

    const selectStyles: ReactSelectMUIProps["styles"] =
      useMemo((): ReactSelectMUIProps["styles"] => {
        const color = textFieldProps.error ? "red" : undefined;
        return {
          control: (base) => {
            const computedStyles: CSSProperties = { ...base };
            if (textFieldProps.error) {
              computedStyles.color = color;
              computedStyles.borderColor = color;
            }
            return computedStyles;
          },
          placeholder: (base) => {
            const newPlaceHolder: CSSProperties = {
              ...base,
            };
            if (textFieldProps.error) {
              newPlaceHolder.color = color;
            }
            return newPlaceHolder;
          },
          input: (base: CSSProperties) => ({
            ...base,
            color: theme.palette.text.primary,
            "& input": {
              font: "inherit",
            },
          }),
          ...styles,
        };
      }, [styles, textFieldProps.error, theme.palette.text.primary]);

    const memorizedOptions = useMemorizedOptions(
      options,
      isMulti,
      value,
      selectAllOptions
    );

    const computedTextFieldProps = {
      label,
      InputLabelProps: {
        shrink: true,
      },
      ...textFieldProps,
    };

    if (isCreatable && async) {
      return (
        <AsyncCreatableSelect
          cacheOptions={cacheOptions}
          defaultOptions={defaultOptions}
          // @ts-ignore
          loadOptions={loadOptions}
          isLoading={isLoading}
          styles={selectStyles}
          isClearable
          isDisabled={isDisabled}
          textFieldProps={computedTextFieldProps}
          options={memorizedOptions}
          components={redefinedComponents}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          isMulti={isMulti}
          closeMenuOnSelect={!isMulti}
          formatCreateLabel={formatCreateLabel}
          menuPlacement="auto"
          menuPosition="fixed"
          // noOptionsMessage={noOptionsMessageFnc}
          closeMenuOnScroll
          onBlur={onBlur}
          multiValueFlipPath={multiValueFlipPath}
          loadingMessage={loadingMessage}
        />
      );
    }

    if (async) {
      return (
        <Async
          cacheOptions={cacheOptions}
          defaultOptions={defaultOptions}
          // @ts-ignore
          loadOptions={loadOptions}
          isLoading={isLoading}
          styles={selectStyles}
          isClearable={isClearable}
          isDisabled={isDisabled}
          textFieldProps={computedTextFieldProps}
          options={memorizedOptions}
          components={redefinedComponents}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          isMulti={isMulti}
          closeMenuOnSelect={!isMulti}
          formatCreateLabel={formatCreateLabel}
          menuPlacement="auto"
          menuPosition="fixed"
          noOptionsMessage={noOptionsMessageFnc}
          closeMenuOnScroll
          onBlur={onBlur}
          multiValueFlipPath={multiValueFlipPath}
          loadingMessage={loadingMessage}
        />
      );
    }

    if (isCreatable) {
      return (
        <Creatable
          isLoading={isLoading}
          styles={selectStyles}
          isClearable={isClearable}
          isDisabled={isDisabled}
          textFieldProps={computedTextFieldProps}
          options={memorizedOptions}
          components={redefinedComponents}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          isMulti={isMulti}
          closeMenuOnSelect={!isMulti}
          formatCreateLabel={formatCreateLabel}
          menuPlacement="auto"
          menuPosition="fixed"
          noOptionsMessage={noOptionsMessageFnc}
          closeMenuOnScroll
          onBlur={onBlur}
          multiValueFlipPath={multiValueFlipPath}
          loadingMessage={loadingMessage}
        />
      );
    }

    return (
      <Select
        label={label}
        isLoading={isLoading}
        control={control}
        styles={selectStyles}
        isClearable={isClearable}
        isDisabled={isDisabled}
        textFieldProps={computedTextFieldProps}
        options={memorizedOptions}
        components={redefinedComponents}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        isMulti={isMulti}
        closeMenuOnSelect={!isMulti}
        menuPosition="fixed"
        menuPlacement="auto"
        noOptionsMessage={noOptionsMessageFnc}
        closeMenuOnScroll
        onBlur={onBlur}
        menuPortalTarget={menuPortalTarget}
        multiValueFlipPath={multiValueFlipPath}
        loadingMessage={loadingMessage}
      />
    );
  },
  isEqual
);
