import { RangeModifier } from "react-day-picker";
import { endOfMonth, endOfWeek, startOfMonth, startOfWeek } from "date-fns";
import axios from "axios";
import { SelectedRangeT, SuccessCallBack } from "../types";
import { baseDateFnsOptions } from "../../../constants/formaters/formatters";
import {
  closeConfirmDialog,
  defaultErrorHandler,
  openConfirmDialog,
  ThunkDispatchTyped
} from "../../../actions/typedActions";
import { dispatchOpenSnackBar } from "../../../store";

export function getSelectedDays(
  date: Date,
  selectedRange: SelectedRangeT
): RangeModifier {
  switch (selectedRange) {
    case "month": {
      return {
        from: startOfMonth(date),
        to: endOfMonth(date)
      };
    }
    case "week": {
      return {
        from: startOfWeek(date, baseDateFnsOptions),
        to: endOfWeek(date, baseDateFnsOptions)
      };
    }
    default: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const never: never = selectedRange;
      return {
        from: new Date(),
        to: new Date()
      };
    }
  }
}

export function openPublishMinistrationConfirmDialog(
  dispatch: ThunkDispatchTyped,
  uniqueId: string | number,
  successCallBack: SuccessCallBack
) {
  dispatch(
    openConfirmDialog({
      mainText: "опубликовать Богослужение",
      primaryButtonHandler: () => {
        axios
          .patch(`/api/ministration/publisher/${uniqueId}`)
          .then(() => {
            dispatch(closeConfirmDialog());
            dispatchOpenSnackBar("Богослужение было опубликованно");
            successCallBack();
          })
          .catch(reason => dispatch(defaultErrorHandler(reason)));
      }
    })
  );
}
