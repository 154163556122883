import React, { useCallback, useEffect, useRef, useState } from "react";
import { AutoSizer } from "react-virtualized";
import {
  IconButton,
  LinearProgress,
  Paper,
  TextField,
  Typography
} from "@material-ui/core";
import { Send } from "@material-ui/icons";
import { FormikValues } from "formik";
import axios from "axios";
import { defaultErrorHandler } from "../../utils/defaultErrorHandler";
import { isDatePast } from "../../constants/utils";
import { dispatchOpenSnackBar } from "../../store";

interface MessageT {
  destination: "FROM_PRIEST" | "FROM_USER";
  sendLocalDateTime: string;
  senderName: string;
  message: string;
}

function downloadMessages(
  urlToGetAll: string,
  uniqueId: number,
  setMessages: (ms: MessageT[]) => void,
  setIsLoading: (v: boolean) => void
) {
  setIsLoading(true);
  axios
    .get<MessageT[]>(`${urlToGetAll}/${uniqueId}`)
    .then(({ data }) => {
      setIsLoading(false);
      setMessages(data);
    })
    .catch(er => {
      setIsLoading(false);
      defaultErrorHandler(er);
    });
}

function useRepeatableMessageDownloading(
  urlToGetAll: string,
  uniqueId: number,
  setMessages: (value: React.SetStateAction<MessageT[]>) => void,
  setIsLoading: (value: React.SetStateAction<boolean>) => void
) {
  const timer = useRef<number | undefined>();
  const downloadMsgs = useCallback(() => {
    downloadMessages(urlToGetAll, uniqueId, setMessages, setIsLoading);
    // @ts-ignore
    timer.current = setTimeout(downloadMsgs, 15000);
  }, [setIsLoading, setMessages, uniqueId, urlToGetAll]);

  useEffect(() => {
    downloadMsgs();
    return () => {
      clearTimeout(timer.current);
    };
  }, [downloadMsgs, uniqueId, urlToGetAll]);
  return { timer, downloadMsgs };
}

export const ChatMui = ({
  flipPredicate = "FROM_PRIEST",
  firstFormInitialValues: { uniqueId, toTime, endTimestamp, commonAction },
  urlToGetAll,
  urlToSendNewMessage
}: {
  flipPredicate: "FROM_PRIEST" | "FROM_USER";
  firstFormInitialValues: FormikValues;
  urlToGetAll: string;
  urlToSendNewMessage: string;
}) => {
  const [newMessage, setNewMessage] = useState<string>("");
  const [messages, setMessages] = React.useState<MessageT[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { timer, downloadMsgs } = useRepeatableMessageDownloading(
    urlToGetAll,
    uniqueId,
    setMessages,
    setIsLoading
  );

  const ref = useRef(null);

  const isOverdue = isDatePast(toTime || endTimestamp);
  const overdueMessage = commonAction
    ? "Переписка по завершенному (несостоявшемуся) таинству невозможна"
    : "Переписка по завершенной (несостоявшейся) беседе невозможна";

  useEffect(() => {
    const { current } = ref;
    if (current) {
      // @ts-ignore
      current.scrollTop = current.scrollHeight;
    }
  }, [messages]);
  return (
    <div style={{ height: "66vh", width: "inherit" }}>
      <AutoSizer disableWidth>
        {({ height, width }) => (
          <div style={{ width: "100%" }}>
            <div
              ref={ref}
              style={{
                boxSizing: "border-box",
                overflow: "auto",
                width,
                height: height - 90
              }}
            >
              <div
                style={{
                  width: "100%"
                }}
              >
                {messages.map(
                  ({ message, destination, senderName, sendLocalDateTime }) => {
                    const isOwn = destination === flipPredicate;
                    return (
                      <div
                        key={`${senderName}${sendLocalDateTime}`}
                        style={{
                          display: "flex",
                          flexDirection: isOwn ? "row-reverse" : "row",
                          padding: "8px"
                        }}
                      >
                        <div>
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "nowrap",
                              alignItems: "center",
                              justifyContent: "space-between"
                            }}
                          >
                            <Typography
                              align={isOwn ? "right" : "left"}
                              variant="body2"
                              style={{ fontWeight: 401, marginRight: "8px" }}
                            >
                              {`${senderName} `}
                            </Typography>
                            <Typography
                              align={isOwn ? "right" : "left"}
                              style={{ fontSize: "11px", margin: "4px" }}
                              variant="body2"
                            >
                              {sendLocalDateTime}
                            </Typography>
                          </div>
                          <Paper elevation={3} style={{ padding: "10px" }}>
                            {message}
                          </Paper>
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
              {isLoading && <LinearProgress />}
            </div>
            <div
              style={{
                height: "84px",
                display: "flex",
                flexWrap: "nowrap",
                alignItems: "center",
                justifyContent: "space-between"
              }}
            >
              <TextField
                variant="outlined"
                multiline
                rows={2}
                rowsMax={2}
                disabled={isOverdue}
                value={isOverdue ? overdueMessage : newMessage}
                onChange={({ currentTarget }) => {
                  if (currentTarget) {
                    setNewMessage(currentTarget.value);
                  }
                }}
                style={{ flex: "1 1 auto" }}
              />
              {isOverdue
                ? null
                : (
                  <IconButton
                    disabled={isSubmitting}
                    onClick={() => {
                      setIsSubmitting(true);
                      if (newMessage && newMessage.length > 0) {
                        clearTimeout(timer.current);
                        axios
                          .post<MessageT>(`${urlToSendNewMessage}/${uniqueId}`, {
                            message: newMessage
                          })
                          .then((): void => {
                            setNewMessage("");
                            downloadMsgs();
                            setIsSubmitting(false);
                          })
                          .catch((error) => {
                            console.log(error);
                            dispatchOpenSnackBar("Ошибка при отправке сообщения");
                            const isOverdue = isDatePast(toTime || endTimestamp);
                            if (isOverdue) {
                              dispatchOpenSnackBar(overdueMessage);
                            }
                          });
                      } else setIsSubmitting(false);
                    }}
                  >
                    Отправить
                    <Send style={{ marginLeft: "4px" }} />
                  </IconButton>
                )
              }
            </div>
          </div>
        )}
      </AutoSizer>
    </div>
  );
};
