import React from "react";
import * as PropTypes from "prop-types";
import Typography from "@material-ui/core/es/Typography/Typography";
import { makeStyles } from "@material-ui/styles";
import { Button } from "@material-ui/core";

const getStyles = makeStyles(theme => ({
  container: { height: "93vh" },
  btn: {
    margin: theme.spacing(1)
  },
  messageContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "Space-Around",
    height: "100%",
    width: "100%"
  }
}));

const defaultErrorMessage = "Страница не найдена";

const getErrorMessage = params => {
  if (params) {
    const { errorMessage } = params;
    if (errorMessage) {
      return errorMessage;
    }
  }
  return defaultErrorMessage;
};

export const DisplayErrorMessage = ({ errorMessage }) => {
  const classes = getStyles();
  return (
    <div className={classes.messageContainer}>
      <Typography variant="h3">{errorMessage}</Typography>
    </div>
  );
};

DisplayErrorMessage.propTypes = {
  errorMessage: PropTypes.string.isRequired
};

const NoMatch = ({ match: { params }, history }) => {
  const classes = getStyles();
  const pushToMainPage = () => history.push("/");
  return (
    <div className={classes.container}>
      <Button
        color="primary"
        variant="contained"
        onClick={pushToMainPage}
        className={classes.btn}
      >
        На главную
      </Button>
      <DisplayErrorMessage errorMessage={getErrorMessage(params)} />
    </div>
  );
};

NoMatch.propTypes = {
  match: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired
};

export default NoMatch;
