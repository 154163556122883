import React, { Component, FC } from "react";
import CompletePromiseThenRenderComponent, {
  defaultPreloader
} from "./completePromiseThenRenderComponent";
import { getInitialValues } from "./modalWrapper/utils/constructConfigArrayForLoaderComponent";
import { findFieldsWithDictThenConstructArrayToDownload } from "../utils/commonFormFunctions";
import { FormikMUI } from "./formikMUi/formikNewRealisation";
import { constructRequest } from "./modalWrapper/utils/baseRequests";
import { rebuildValues } from "./modalWrapper/utils/rebuildValuesBeforeSending";
import { doBeforeSend } from "../constants/baseFormProps";
import { openSnackBar } from "../actions/typedActions";
import { dispatchPopHeadModal } from "../store";
import { defaultErrorHandler } from "../utils/defaultErrorHandler";
import { ModalT } from "./wrapper/types";
import { ExtendedBlockFormObj } from "./modalWrapper/types";

export type BaseTabProps = { modal: ModalT; firstFormInitialValues: any };

export const HramTab: FC<BaseTabProps & {
  formObj: ExtendedBlockFormObj;
}> = ({
  firstFormInitialValues: { uniqueId },
  modal: { mode },
  modal,
  formObj,
  ...rest
}) => (
  <CompletePromiseThenRenderComponent
    config={[
      getInitialValues(modal, formObj, "initialValues"),
      ...findFieldsWithDictThenConstructArrayToDownload(formObj)
    ]}
    Component={FormikMUI}
    readOnly={mode !== "edit"}
    onSubmit={(values: any, { setSubmitting }: any) => {
      constructRequest(
        {
          url: `/api/hram/${uniqueId}`,
          method: "patch",
          data: rebuildValues(values, {
            blocks: formObj.blocks,
            doBeforeSend
          })
        },
        () => {
          openSnackBar("Изменения успешно применены");
          setSubmitting(false);
          dispatchPopHeadModal();
        }
      );
    }}
    formObj={formObj}
    errorHandler={defaultErrorHandler}
    PreloaderComponent={defaultPreloader}
    {...rest}
  />
);
