import { Button } from "@material-ui/core";
import React from "react";
import { dispatchPushModal } from "../../../store";
import { ModalT } from "../../wrapper/types";
import { CustomField } from "../../../constants/types";

export const LinkBtn: CustomField["component"] = ({
  value: { object_id, uuid, type }
}) => {
  const modal: ModalT = {
    category: "",
    mode: "",
    item: {
      uniqueId: object_id,
      uuid
    }
  };

  let noOpen = false;
  switch (type) {
    case "slot": {
      modal.mode = "viewSlot";
      modal.category = "schedule";
      modal.openedTab = 1;
      if (modal.item) {
        modal.item.isPersonal = true;
      }
      break;
    }
    case "claim": {
      modal.mode = "view";
      modal.category = "claim";
      modal.page = "inWork";
      modal.openedTab = 1;
      break;
    }
    default: {
      noOpen = true;
    }
  }

  return (
    <Button
      // реализация через ссылку с перехом в журнал
      // href={`/journal/mvActions/mvOut/${uniqueId}`}
      disabled={noOpen}
      onClick={() => dispatchPushModal(modal)}
      color="primary"
      size="small"
    >
      Перейти в карточку
    </Button>
  );
};
