import { useEffect } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { PageT } from "../../constants/types";
import {
  computeUrlThenFetchJournalData,
  forbidFiltersValueReset,
  initializeFilters,
  loadFiltersOptions,
  pushHeadModal,
  resetSortable,
  setBaseUrl,
  setFilterPanelOptionsMap,
  setFiltersPanelFiltersConfig,
  setPage,
  throttledLoadBadgesContent,
  toggleFilterPanel,
} from "../../actions/typedActions";
import { customRouteParametersHandler } from "../modalWrapper/utils/customRouteParametersHandler";
import {
  findFieldsWithDictThenConstructArrayToDownloadWithOutBlocks,
  getOptionsMapBeforeLoading,
} from "../../utils/commonFormFunctions";
import { AuthStateT } from "../../reducers/auth";

export function useOpenModalIfUrlContainsUniqueId(
  uniqueId: string | undefined,
  memorizedInitialParameters: PageT,
  category: string,
  page: string | undefined
) {
  const dispatch = useDispatch();
  // Отроет модалку если будет передан UniqueId
  useEffect(() => {
    if (uniqueId) {
      customRouteParametersHandler(uniqueId, memorizedInitialParameters.url)
        .then((data) => {
          if (typeof data.uniqueId === "undefined" || data.uniqueId === null) {
            throw new Error(
              "не было получено необходимого параметра: uniqueId"
            );
          }
          const resultingModal = { category, page, mode: "view", item: data };
          dispatch(pushHeadModal(resultingModal));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [category, dispatch, memorizedInitialParameters.url, page, uniqueId]);
}

export function useOnCategoryOrPageChange(
  auth: AuthStateT,
  category: string,
  page?: string,
  memorizedInitialParameters?: PageT
) {
  const dispatch = useDispatch();

  // выполняет очистку при переходе в другую категорию или на другую страницу, а также строит новый Url и скачивает данные
  useEffect(() => {
    const filtersSource = axios.CancelToken.source();
    (async () => {
      if (memorizedInitialParameters?.filtersPanel?.filters) {
        dispatch(
          initializeFilters(
            memorizedInitialParameters.filtersPanel.filters,
            auth
          )
        );
      }

      dispatch(toggleFilterPanel(true));
      dispatch(resetSortable());
      dispatch(setPage(1));
      dispatch(throttledLoadBadgesContent());
      if (memorizedInitialParameters?.url) {
        if (memorizedInitialParameters.filtersPanel) {
          const { filters } = memorizedInitialParameters.filtersPanel;
          dispatch(setFiltersPanelFiltersConfig(filters));
          dispatch(
            setFilterPanelOptionsMap(getOptionsMapBeforeLoading(filters))
          );
          // скачивает для панели фильтрации options
          dispatch(
            loadFiltersOptions(
              findFieldsWithDictThenConstructArrayToDownloadWithOutBlocks({
                fields: filters,
              }),
              filtersSource
            )
          );
        }
        if (typeof memorizedInitialParameters.url === "function") {
          const url = await memorizedInitialParameters.url();
          dispatch(setBaseUrl(url));
        } else {
          dispatch(setBaseUrl(memorizedInitialParameters.url));
        }
        dispatch(computeUrlThenFetchJournalData());
        dispatch(forbidFiltersValueReset(false));
      }
    })();
    return () => {
      filtersSource.cancel("Cancelling in cleanup");
    };
  }, [auth, dispatch, memorizedInitialParameters]);
}
