import { endOfMonth, endOfWeek, startOfMonth, startOfWeek } from "date-fns";
import { ru } from "date-fns/locale";
import { SelectedRangeT } from "./types";
import { formatToRussianDate } from "../../constants/formaters/formatters";
import { OptionT } from "../../constants/types";

export const getSelectedDatesStartFinish = (
  selectedRange: SelectedRangeT,
  date: Date
) => {
  if (selectedRange === "week") {
    return [
      startOfWeek(date, { locale: ru }),
      endOfWeek(date, {
        locale: ru
      })
    ];
  }
  if (selectedRange === "month") {
    return [startOfMonth(date), endOfMonth(date)];
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const never: never = selectedRange;
  // не должно произойти
  return [date, date];
};

export const addDateRangeParams = (
  selectedRange: SelectedRangeT,
  date: Date,
  defaultStr?: string
) => {
  const result = defaultStr || "";
  if (selectedRange === "week") {
    return `&from=${formatToRussianDate(
      startOfWeek(date, { locale: ru })
    )}&to=${formatToRussianDate(
      endOfWeek(date, {
        locale: ru
      })
    )}${result}`;
  }
  return `&from=${formatToRussianDate(
    startOfMonth(date)
  )}&to=${formatToRussianDate(endOfMonth(date))}${result}`;
};

export function constructParams(
  selectedRange: SelectedRangeT,
  date: Date,
  isPersonal: boolean,
  showAllEvents: boolean,
  performer?: OptionT | null,
  ministrationType?: OptionT | null,
  userId?: number | null,
) {
  let result = "";
  if (performer && performer.value && !isPersonal) {
    result += `&performerId=${performer.value}`;
  }
  if (ministrationType && ministrationType.value) {
    result += `&commonActionId=${ministrationType.value}`;
  }
  if (!isPersonal) {
    result += `&allTypes=${!ministrationType && showAllEvents}`;
  }
  if (isPersonal) {
    result += `&allTypes=${!ministrationType}`;
    if (performer) result += `&userId=${performer.value}`;
  }
  if (!isPersonal && userId) {
    result += `&userId=${userId}`;
  }
  return addDateRangeParams(selectedRange, date, result);
}
