import { FormikValues } from "formik";
import axios from "axios";
import { fullWidth, halfWith } from "../../widths";
import { ChatMui } from "../../../components/chat";
import { baseTextAreaProps } from "../../baseFieldProps";
import { constructRequest } from "../../../components/modalWrapper/utils/baseRequests";
import {
  closeConfirmDialog,
  closeConfirmDialogFormik,
  MakePropertiesOptional,
  openConfirmDialog
} from "../../../actions/typedActions";
import {
  disableConfirmDialogPrimaryBtn,
  dispatchFetchJournalData,
  dispatchOpenConfirmDialogFormik,
  dispatchOpenSnackBar,
  dispatchPopHeadModal,
  store
} from "../../../store";
import { localizedYup } from "../../yup";
import { ExtendedBlockFormObj } from "../../../components/modalWrapper/types";
import { ConfirmDialogFormikT } from "../../../reducers/wrapperReducer";
import { FormikMUIProps } from "../../../components/formikMUi/formikNewRealisation";
import { getRecoilExternalLoadable } from "../../..//store/recoilAdapter";
import { overUserAtom, OverUserValueT } from "../../../store/userStore";

const primaryButtonHandlerFactory = (uniqueId: number) => (
  values: FormikValues
) =>
  constructRequest(
    {
      url: `/api/slots/result/${uniqueId}`,
      method: "patch",
      data: { ...values, result: values.result ? values.result.value : false }
    },
    ({ status }) => {
      disableConfirmDialogPrimaryBtn(true);
      if (status === 200) {
        dispatchOpenSnackBar("Указан результат к слоту…");
      }
      store.dispatch(closeConfirmDialogFormik());
      dispatchPopHeadModal();
    }
  );
const completeConfig: MakePropertiesOptional<
  ConfirmDialogFormikT,
  "open" | "primaryButtonText" | "secondaryButtonText" | "primaryButtonHandler"
> &
  Partial<FormikMUIProps> = {
  title: "Результат встречи",
  formObj: {
    blocks: [
      {
        fields: [
          {
            label: "Комментарий",
            name: "comment",
            ...baseTextAreaProps
          },
          {
            label: "Результат",
            name: "result",
            type: "select",
            placeholder: "Выберите результат",
            widths: fullWidth,
            options: [
              { label: "Состоялась", value: "true" },
              { label: "Не состоялась", value: "false" }
            ]
          }
        ]
      }
    ],
    validationSchema: localizedYup.object({
      result: localizedYup
        .object()
        .nullable()
        .required(),
      comment: localizedYup.string()
    })
  },
  initialValues: {
    comment: "",
    result: null
  },
  primaryButtonText: "Подтвердить"
};

export const viewSlot: ExtendedBlockFormObj = {
  url: "/api/slots",
  title: "Беседы",
  headerText: "fromTime",
  blocks: [
    {
      fields: [
        {
          label: "Священнослужитель",
          name: "performerLabel",
          type: "text",
          widths: halfWith
        },
        {
          label: "Тип",
          name: "codeCommonLabel",
          type: "text",
          widths: halfWith
        },
        {
          label: "Время начала",
          name: "fromTime",
          type: "text",
          rebuildReceivedValue: (v) => v.slice(0, -3),
        },
        {
          label: "Время окончания",
          name: "toTime",
          type: "text",
          rebuildReceivedValue: (v) => v.slice(0, -3),
        },
        {
          label: "Статус",
          name: "statusLabel",
          type: "text"
        },
        {
          label: "Имя мирянина",
          name: "userName",
          type: "text"
        },
        {
          label: "Телефон мирянина",
          name: "phone",
          type: "text"
        },
        {
          label: "Подтверждено",
          name: "isConfirm",
          type: "checkbox"
        },
        {
          name: "isReport",
          label: "Включать в отчет",
          readOnly: true,
          type: "checkbox",
          widths: fullWidth
        },
        {
          name: "statusEnum",
          type: "text",
          hidden: true
        },
        {
          name: "uniqueId",
          type: "text",
          hidden: true
        },
        {
          name: "userId",
          type: "text",
          hidden: true
        },
        {
          name: "canMakeResult",
          type: "checkbox",
          hidden: true
        }
      ]
    }
  ],
  additionalTabs: [
    {
      tabTitle: "Переписка",
      tabContent: {
        Component: ChatMui,
        passProps: {
          urlToGetAll: "/api/slots/message/performer",
          urlToSendNewMessage: "/api/slots/message/performer"
        }
      },
      conditionallyRender: ({
        firstFormInitialValues: { statusEnum },
        modal: { item, additionalModalProps }
      }) =>
        statusEnum === "reserv" &&
        ((item && item.isPersonal) ||
          (additionalModalProps && additionalModalProps.allowedAllActions))
    }
  ],
  additionalActionButtonsDependedOnProps: [
    {
      text: "Отменить беседу",
      stickTo: "left",
      variant: "outlined",
      color: "secondary",
      onClick: async ({ firstFormInitialValues: { uniqueId, userName, fromTime } }) => {
        const overUser = await getRecoilExternalLoadable<OverUserValueT>(
          overUserAtom
        );
        store.dispatch(
          openConfirmDialog({
            mainText: `отменить Беседу № "${uniqueId}" с ${userName || ""} на ${fromTime} ?`,
            primaryButtonHandler: () => {
              disableConfirmDialogPrimaryBtn(true);
              constructRequest(
                {
                  url: `/api/slots/annul/${uniqueId}`,
                  method: "patch",
                  params: {
                    userId: overUser?.contents?.value
                  }
                },
                () => {
                  disableConfirmDialogPrimaryBtn(true);
                  store.dispatch(closeConfirmDialog());
                  dispatchPopHeadModal();
                  dispatchFetchJournalData();
                  dispatchOpenSnackBar(
                    `Беседа № "${uniqueId}" была отменена`
                  );
                }
              );
            }
          })
        );
      },
      conditionallyRender: ({
        firstFormInitialValues: { statusEnum },
        modal: { item, additionalModalProps }
      }) =>
        statusEnum === "reserv" &&
        ((item && item.isPersonal) ||
          (additionalModalProps && additionalModalProps.allowedAllActions))
    },
    {
      text: "Подтвердить",
      stickTo: "left",
      variant: "contained",
      style: {
        backgroundColor: "#357a38",
        color: "white"
      },
      onClick: ({ firstFormInitialValues: { uniqueId } }) => {
        axios.post(`/api/slots/confirmation/${uniqueId}`).then(({ status }) => {
          if (status === 200) {
            dispatchOpenSnackBar(`Слот №${uniqueId} успешно подтвержден`);
          }
          dispatchPopHeadModal();
        });
      },
      conditionallyRender: ({
        firstFormInitialValues: { isConfirm, statusEnum },
        modal: { item, additionalModalProps }
      }) =>
        !isConfirm &&
        statusEnum === "reserv" &&
        ((item && item.isPersonal) ||
          (additionalModalProps && additionalModalProps.allowedAllActions))
    },
    {
      text: "Результат встречи",
      stickTo: "left",
      variant: "contained",
      style: {
        backgroundColor: "#357a38",
        color: "white"
      },
      onClick: ({ firstFormInitialValues: { uniqueId } }) => {
        dispatchOpenConfirmDialogFormik({
          ...completeConfig,
          primaryButtonHandler: primaryButtonHandlerFactory(uniqueId)
        });
      },
      conditionallyRender: ({
        firstFormInitialValues: { canMakeResult, statusEnum },
        modal: { item, additionalModalProps }
      }) =>
        canMakeResult &&
        statusEnum === "reserv" &&
        ((item && item.isPersonal) ||
          (additionalModalProps && additionalModalProps.allowedAllActions))
    },
    {
      text: "Заблокировать мирянина",
      stickTo: "left",
      variant: "contained",
      style: {
        backgroundColor: "indigo",
        color: "white"
      },
      onClick: ({
        firstFormInitialValues: { uniqueId, userName, userId }
      }) => {
        store.dispatch(
          openConfirmDialog({
            mainText: `заблокировать мирянина ${userName || ""}?`,
            primaryButtonHandler: () => {
              disableConfirmDialogPrimaryBtn(true);
              constructRequest(
                {
                  url: `/api/slots/block/${uniqueId}/${userId}`,
                  method: "post"
                },
                ({ status }) => {
                  disableConfirmDialogPrimaryBtn(true);
                  if (status === 200) {
                    dispatchOpenSnackBar(
                      `Мирянин ${userName || ""} заблокирован`
                    );
                  }
                  store.dispatch(closeConfirmDialog());
                  dispatchPopHeadModal();
                  dispatchFetchJournalData();
                }
              );
            }
          })
        );
      },
      conditionallyRender: ({
        firstFormInitialValues,
        modal: { item, additionalModalProps }
      }) =>
        firstFormInitialValues &&
        firstFormInitialValues.statusEnum === "end" &&
        ((item && item.isPersonal) ||
          (additionalModalProps && additionalModalProps.allowedAllActions))
    }
  ]
};
