import {
  SET_ACCESS_TOKEN,
  SET_AUTH,
  SET_AUTHENTICATED,
  SET_CURRENT_USER_OBJECT,
  SET_LOGIN_ERROR_MESSAGE,
  SET_SHOW_CHANGE_USER_SCRT_FORM,
  SET_TRAINING_LINK
} from "../constants/actionTypeConstants";

export type MemberTypeT =
  | "EPARCHY"
  | "VICARIATE"
  | "DEANERY"
  | "HRAM"
  | "MITROPOLIA"
  | null;

export interface AuthStateT {
  accessToken: string | null;
  isAuthenticated: boolean;
  errorMessage: string;
  role: string;
  surnameInitials: string;
  functions: string[];
  userId: number;
  memberId: number;
  memberFullName: string;
  previousUserId: number;
  showChangePasswordForm: boolean;
  memberType: MemberTypeT;
  trainingLink: string | null;
}
export interface SetAccessTokenACT {
  type: "SET_ACCESS_TOKEN";
  payload: string;
}

export interface SetAuthenticatedActionT {
  type: "SET_AUTHENTICATED";
  payload: boolean;
}

export interface SetLoginErrorMessage {
  type: "SET_LOGIN_ERROR_MESSAGE";
  payload: {
    errorMessage: string;
  };
}

export interface SetCurrentUserObjActionT {
  type: "SET_CURRENT_USER_OBJECT";
  payload: {
    role: string;
    functions: string[];
    surnameInitials: string;
    userId: number;
    memberId: number;
    memberFullName: string;
    memberType: MemberTypeT;
    trainingLink: string | null;
  };
}

export interface SetAuthActionT {
  type: "SET_AUTH";
  payload: { isAuthenticated: boolean; accessToken: string };
}

export interface SetShowPasswordChangeFormActionT {
  type: typeof SET_SHOW_CHANGE_USER_SCRT_FORM;
  payload: boolean;
}

export interface SetTrainingLink {
  type: typeof SET_TRAINING_LINK;
  payload: string | null;
}

export type AuthActionT =
  | SetAuthActionT
  | SetAccessTokenACT
  | SetAuthenticatedActionT
  | SetLoginErrorMessage
  | SetCurrentUserObjActionT
  | SetShowPasswordChangeFormActionT
  | SetTrainingLink;

const initialState: AuthStateT = {
  accessToken: null,
  isAuthenticated: false,
  errorMessage: "",
  role: "",
  surnameInitials: "",
  functions: [],
  userId: -1,
  previousUserId: -1,
  memberId: -1,
  memberFullName: "",
  showChangePasswordForm: false,
  memberType: null,
  trainingLink: null
};

export default function authReducer(
  state: AuthStateT = initialState,
  action: AuthActionT
): AuthStateT {
  switch (action.type) {
    case SET_ACCESS_TOKEN: {
      // добавление токена в стор
      return {
        ...state,
        accessToken: action.payload,
        errorMessage: ""
      };
    }
    case SET_AUTHENTICATED: {
      // установка флага Авторизованн ли пользователь
      return {
        ...state,
        isAuthenticated: action.payload
      };
    }
    case SET_AUTH: {
      const { isAuthenticated, accessToken } = action.payload;
      return {
        ...state,
        isAuthenticated,
        accessToken
      };
    }
    case SET_CURRENT_USER_OBJECT: {
      const {
        role,
        functions,
        surnameInitials,
        userId,
        memberId,
        memberFullName,
        memberType,
        trainingLink
      } = action.payload;
      return {
        ...state,
        role,
        functions,
        surnameInitials,
        userId,
        memberId,
        memberFullName,
        memberType,
        trainingLink
      };
    }
    case SET_LOGIN_ERROR_MESSAGE: {
      return {
        ...state,
        errorMessage: action.payload.errorMessage
      };
    }
    case SET_SHOW_CHANGE_USER_SCRT_FORM: {
      return {
        ...state,
        showChangePasswordForm: action.payload
      };
    }
    case SET_TRAINING_LINK: {
      return {
        ...state,
        trainingLink: action.payload
      };
    }
    default:
      return state;
  }
}
