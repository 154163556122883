import { get } from "lodash";
import { OptionsMapType, SelectField } from "../../../../constants/types";
import { Props } from "react-select/base";

export const getInitialState = (
  name: string,
  optionsMap: OptionsMapType,
  customOptionsName?: string,
  hardCodedOptions?: Props["options"],
  fieldConfig?: SelectField
) => {
  const lastIndexOfDot = name.lastIndexOf(".");
  return (
    get(optionsMap, customOptionsName || name) ||
    (lastIndexOfDot < name.length &&
      optionsMap[name.slice(lastIndexOfDot + 1)]) || {
      options: hardCodedOptions ?? [],
      loaded: true
    }
  );
};
