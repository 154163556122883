import React, { useMemo, useState } from "react";
import { useFormikContext } from "formik";
import { ReactSelectMUI } from "../../../select/reactSelectMUI";
import { WrappedReactSelectT } from "./types";
import {
  useAsyncOptions,
  useLoadOptions,
  useMemorizedOnChange,
  useUpdateOptionsDependingOnOptionsMap
} from "./hooks";
import { getInitialState } from "./utils";
import {AsyncProps} from "react-select/src/Async";
import {OptionT} from "../../../../constants/types";

export const WrappedReactSelect = ({
  async,
  control,
  customOnChange,
  customOptionsName,
  customValueGetter,
  error,
  fieldConfig,
  isClearable,
  isCreatable = false,
  isDisabled,
  isMulti,
  label = "",
  multiValueFlipPath,
  name,
  onBlur,
  options: hardCodedOptions,
  placeholder,
  selectAllOptions,
  styles,
  value,
}: WrappedReactSelectT) => {
  const {
    status,
    status: { optionsMap },
    setStatus,
    values,
    setFieldTouched,
    setFieldValue,
    initialValues
  } = useFormikContext<any>();
  const [{ loaded, options }, setInternalOptionsMap] = useState(
    getInitialState(
      name,
      optionsMap,
      customOptionsName,
      hardCodedOptions,
      fieldConfig
    )
  );

  // ВНИМАНИЕ: кастыль для того чтоб выполнить 1 хук из 2-х
  const asyncOptionsLoad =
    (fieldConfig && typeof fieldConfig.dictUrl === "function") || false;

  useUpdateOptionsDependingOnOptionsMap(
    name,
    optionsMap,
    options,
    setInternalOptionsMap,
    customOptionsName,
    !asyncOptionsLoad
  );

  // useAsyncOptions(values, setInternalOptionsMap, asyncOptionsLoad, fieldConfig);

  // Интересная функция!? условно создает и запоминает новую функцию, которая будет вызываться не чаще чем раз в 800мс
  const loadOptions = useLoadOptions(values, status.auth, fieldConfig);

  const textFieldProps = { helperText: error, error: !!error };

  let placeHolderText;
  if (!loaded) {
    placeHolderText = "Идет загрузка данных...";
  } else if (placeholder) {
    placeHolderText = placeholder;
  } else if (isDisabled && value == null) {
    placeHolderText = "";
  }

  const memorizedSetFieldTouched = useMemo(
    () => () => setFieldTouched(name, true),
    [name, setFieldTouched]
  );

  const memorizedOnChange = useMemorizedOnChange(
    setFieldValue,
    name,
    options,
    initialValues,
    status,
    setStatus,
    customOnChange
  );

  return (
    <ReactSelectMUI
      options={options}
      isMulti={isMulti}
      name={name}
      isClearable={isClearable}
      onMenuClose={memorizedSetFieldTouched}
      textFieldProps={textFieldProps}
      isDisabled={isDisabled}
      isLoading={isDisabled ? false : !loaded}
      isCreatable={isCreatable}
      placeholder={placeHolderText}
      label={label}
      async={async}
      control={control}
      value={
        customValueGetter && typeof customValueGetter === "function"
          ? customValueGetter(value)
          : value
      }
      onBlur={onBlur}
      onChange={memorizedOnChange}
      multiValueFlipPath={multiValueFlipPath}
      selectAllOptions={selectAllOptions}
      styles={styles}
      loadOptions={loadOptions as AsyncProps<OptionT>["loadOptions"]}
      {...fieldConfig?.additionalProps}
    />
  );
};
