import React from "react";
import MuiTable, { IMuiVirtualizedTableProps } from "mui-virtualized-table";
import { connect } from "react-redux";
import * as PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import "./tableOverride.css";
import { Theme } from "@material-ui/core";
import {
  setPage,
  setRowsPerPage,
  ThunkDispatchTyped
} from "../actions/typedActions";
import { RootStateT } from "../reducers";

const getStyles = makeStyles<Theme>(theme => ({
  primaryColor: {
    color: theme.palette.primary.main
  }
}));

export interface PaginatedTableT extends IMuiVirtualizedTableProps<any> {
  fetchJournalData: Function;
  count: number;
  page: number;
  rowsPerPage: number;
  setRowsPerPage: (v: number) => void;
  setPage: (v: number) => void;
}

const PaginatedTable = ({
  data,
  fetchJournalData,
  count = 0,
  setRowsPerPage,
  rowsPerPage,
  page,
  setPage,
  ...rest
}: PaginatedTableT) => {
  const classes = getStyles();
  return (
    <MuiTable
      data={data}
      pagination={{
        classes: {
          root: classes.primaryColor,
          selectIcon: classes.primaryColor
        },
        backIconButtonProps: {
          color: "primary"
        },
        nextIconButtonProps: {
          color: "primary"
        },
        count,
        rowsPerPage,
        page: page - 1, // material-ui's <TablePagination /> is 0-based
        // rowsPerPageOptions: [5, 10, 25, 100, 1000],
        onPageChange: (e, pageLocal) => {
          setPage(pageLocal + 1);
          fetchJournalData();
        },
        onChangeRowsPerPage: e => {
          setPage(1);
          setRowsPerPage(Number.parseInt(e.target.value, 10));
          fetchJournalData();
        },
        labelDisplayedRows: ({ from, to, count }) =>
          `${from}-${to} из ${count}`,
        labelRowsPerPage: "Строк на странице"
      }}
      isCellHovered={(column, rowData, hoveredColumn, hoveredRowData) =>
        rowData.uniqueId && rowData.uniqueId === hoveredRowData.uniqueId
      }
      {...rest}
      fixedRowCount={1}
      includeHeaders
    />
  );
};

PaginatedTable.defaultProps = {} as any;

PaginatedTable.propTypes = {
  setPage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  setRowsPerPage: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired
} as any;

const mapDispatchToProps = (dispatch: ThunkDispatchTyped) => ({
  setRowsPerPage: (rowsPerPage: number) =>
    dispatch(setRowsPerPage(rowsPerPage)),
  setPage: (page: number) => dispatch(setPage(page))
});

const mapStateToProps = (state: RootStateT) => ({
  rowsPerPage: state.journalReducer.pagination.rowsPerPage,
  page: state.journalReducer.pagination.page
});

export default connect(mapStateToProps, mapDispatchToProps)(PaginatedTable);
