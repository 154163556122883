import React, { useMemo, useRef, useState } from "react";
import { connect } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress
} from "@material-ui/core";
import * as PropTypes from "prop-types";
import { FormikHelpers, FormikValues } from "formik";
import { cancelButton } from "./modalWrapper/buttons";
import { FormikMUI } from "./formikMUi/formikNewRealisation";
import CompletePromiseThenRenderComponent, {
  defaultPreloader
} from "./completePromiseThenRenderComponent";
import { findFieldsWithDictThenConstructArrayToDownload } from "../utils/commonFormFunctions";
import { defaultErrorHandler } from "../utils/defaultErrorHandler";
import { RootStateT } from "../reducers";
import { closeConfirmDialogFormik } from "../actions/typedActions";
import { ConfirmDialogFormikT } from "../reducers/wrapperReducer";
import { rebuildValues } from "./modalWrapper/utils/rebuildValuesBeforeSending";

export interface ConfirmDialogFormikProps {
  confirmDialog: ConfirmDialogFormikT;
  closeConfirmDialogFormik: () => void;
}

const ConfirmDialogFormik = ({
  confirmDialog: {
    title,
    open,
    initialValues,
    primaryButtonHandler,
    formObj,
    primaryButtonText,
    additionalStatusProps,
    secondaryButtonText
  },
  closeConfirmDialogFormik: closeDialog
}: ConfirmDialogFormikProps) => {
  const refToFormSubmitFunc = useRef<null | Function>(null);
  const bindFormSubmit = useMemo(
    () => (ref: Function) => {
      refToFormSubmitFunc.current = ref;
    },
    []
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <CompletePromiseThenRenderComponent
          config={findFieldsWithDictThenConstructArrayToDownload(formObj)}
          Component={FormikMUI}
          errorHandler={defaultErrorHandler}
          PreloaderComponent={defaultPreloader}
          initialValues={initialValues}
          formObj={formObj}
          onSubmit={(
            values: FormikValues,
            actions: FormikHelpers<FormikValues>
          ) => primaryButtonHandler(rebuildValues(values, formObj), actions)}
          bindFormSubmit={bindFormSubmit}
          additionalStatusProps={additionalStatusProps}
          bindIsSubmitting={setIsSubmitting}
        />
        {isSubmitting && <LinearProgress />}
      </DialogContent>
      <DialogActions>
        {cancelButton(closeDialog, secondaryButtonText)}
        <Button
          variant="contained"
          color="primary"
          disabled={isSubmitting}
          onClick={() => {
            const { current } = refToFormSubmitFunc;
            if (typeof current === "function") {
              current();
            }
          }}
        >
          {primaryButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmDialogFormik.propTypes = {
  confirmDialog: PropTypes.shape({
    open: PropTypes.bool.isRequired,
    formObj: PropTypes.shape({}).isRequired,
    primaryButtonText: PropTypes.string.isRequired,
    initialValues: PropTypes.shape({}).isRequired,
    title: PropTypes.string.isRequired,
    primaryButtonHandler: PropTypes.func.isRequired
  }).isRequired,
  closeConfirmDialogFormik: PropTypes.func.isRequired
} as any;

const mapStateToProps = (state: RootStateT) => ({
  confirmDialog: state.wrapperReducer.confirmDialogFormik
});
const mapDispatchToProps = {
  closeConfirmDialogFormik
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmDialogFormik);
