import { emailMask } from "text-mask-addons";
import { fullWidth, halfWith } from "../../widths";
import {
  baseNumberProps,
  baseTextAreaProps,
  uploadFileInCommonFiles
} from "../../baseFieldProps";

import { Block, BlockFormObj, OptionT, PageT } from "../../types";
import { FakeField } from "./FakeField";
import {
  CompletePromiseThenRenderComponent,
  CompletePromiseThenRenderComponentProps
} from "../../../components/completePromiseThenRenderComponent";
import {
  SettingsJournal,
  SettingsJournalProps
} from "../../../components/SettingsJournal";
import { rebuildValues } from "../../../components/modalWrapper/utils/rebuildValuesBeforeSending";
import axios from "axios";
import { Settings } from "@material-ui/icons";
import { allFieldsRequired } from "../../baseFormProps";
import { ContainsName } from "../../rebuildRecieved";
import { CalendarSyncButton } from "./CalendarSyncButton";
import { SetWeddingButton } from "./SetWeddingButton";

const rebuildReceivedValue = (v: string) => {
  if (v) {
    return JSON.parse(v);
  }
  return null;
};

const fieldDoBeforeSend = (value: OptionT) => JSON.stringify(value);

const blocks: Block[] = [
  {
    blockTitle: "СИНХРОНИЗАЦИЯ ПРАВОСЛАВНОГО КАЛЕНДАРЯ",
    fields: [
      {
        name: "sync_server_host",
        label: "",
        type: "text",
        widths: halfWith
      },
      {
        name: "ignoreMe",
        type: "custom",
        component: CalendarSyncButton
      },
      {
        name: "wedding_year",
        label: "Год",
        ...baseNumberProps(),
        defaultValue: () => {
          const today = new Date();
          return today.getFullYear();
        },
        widths: halfWith
      },
      {
        name: "ignoreMe",
        type: "custom",
        component: SetWeddingButton
      },
    ]
  },
  {
    blockTitle: "ПАРАМЕТРЫ ПОЧТОВОГО СЕРВЕРА",
    fields: [
      {
        name: "mail_server_username",
        label: "",
        type: "text"
      },
      {
        name: "mail_server_password",
        label: "",
        type: "password"
      },
      {
        name: "mail_server_port",
        label: "",
        ...baseNumberProps({ allowLeadingZeroes: true })
      },
      {
        name: "mail_server_host",
        label: "",
        type: "text"
      },
      {
        name: "mail_delivery_from_personal",
        label: "",
        type: "text"
      },
      {
        name: "mail_delivery_from_address",
        label: "",
        type: "formatted",
        mask: emailMask
      },

      {
        name: "mail_server_ssl",
        label: "",
        type: "checkbox"
      },
      {
        name: "mail_server_auth",
        label: "",
        type: "checkbox"
      }
    ]
  },
  {
    blockTitle: "ПАРАМЕТРЫ СМС СЕРВИСА",
    fields: [
      {
        name: "phone",
        label: "",
        type: "text"
      },
      {
        name: "sms_server_host",
        label: "",
        type: "text"
      },
      {
        name: "sms_database_login",
        label: "",
        type: "text"
      },
      {
        name: "sms_database_password",
        label: "",
        type: "text"
      }
    ]
  },
  {
    blockTitle: "НАСТРОЙКИ УВЕДОМЛЕНИЙ СВЯЩЕННОСЛУЖИТЕЛЕЙ",
    fields: [
      {
        name: "notification_frequency_event",
        label: "",
        ...baseNumberProps()
      },
      {
        name: "notification_frequency_raw_claim",
        label: "",
        ...baseNumberProps()
      }
    ]
  },
  {
    blockTitle: "АНКЕТИРОВАНИЕ",
    fields: [
      {
        name: "questioning_baptism",
        label: "",
        type: "file",
        rebuildReceivedValue,
        doBeforeSetFieldValue: uploadFileInCommonFiles,
        doBeforeSend: fieldDoBeforeSend,
        widths: fullWidth,
        showHint: false
      },
      {
        name: "questioning_wedding",
        label: "",
        type: "file",
        rebuildReceivedValue,
        doBeforeSetFieldValue: uploadFileInCommonFiles,
        doBeforeSend: fieldDoBeforeSend,
        widths: fullWidth,
        showHint: false
      }
    ]
  },
  {
    blockTitle: "РАБОТА С БЕСЕДАМИ",
    fields: [
      {
        name: "conversation_frequency_recording_person",
        label: "",
        ...baseNumberProps(),
        widths: halfWith
      }
    ]
  },
  {
    blockTitle: "РАБОТА С ЗАЯВКАМИ",
    fields: [
      {
        name: "claim_not_less_days",
        label: "",
        ...baseNumberProps(),
        widths: halfWith
      },
      {
        name: "claim_not_more_days",
        label: "",
        ...baseNumberProps(),
        widths: halfWith
      },
      {
        name: "claim_auto_remove",
        label: "",
        ...baseNumberProps(),
        widths: fullWidth
      },
      {
        name: "claim_auto_transfer_to_free",
        label: "",
        ...baseNumberProps(),
        widths: fullWidth
      }
    ]
  },
  {
    blockTitle: "РАБОТА С РАСПИСАНИЕМ",
    fields: [
      {
        name: "period_ministration_check",
        label: "",
        ...baseNumberProps(),
        widths: halfWith
      },
      {
        name: "period_ministration_check_day",
        label: "",
        ...baseNumberProps(),
        widths: halfWith
      }
    ]
  },
  {
    blockTitle: "ПАРАМЕТРЫ БЛОКИРОВКИ УЧЕТНЫХ ЗАПИСЕЙ",
    fields: [
      {
        name: "other_count_lock",
        label: "",
        ...baseNumberProps()
      },
      {
        name: "other_disclaimer_text",
        label: "",
        ...baseTextAreaProps
      },
      {
        name: "other_disclaimer_enable",
        label: "",
        type: "checkbox"
      }
    ]
  },
  {
    blockTitle: "Работа с фотографиями",
    fields: [
      {
        name: "photo_max_size",
        label: "",
        ...baseNumberProps(),
        rebuildReceivedValue: (v: string) => Number.parseInt(v, 10) || ""
      }
    ]
  },
  {
    blockTitle: "Авторизация",
    fields: [
      {
        name: "auth_max_count_of_failed_attempts",
        ...baseNumberProps({})
      },
      { name: "auth_lock_user_for", ...baseNumberProps({}) }
    ]
  },
  {
    blockTitle: "Ссылки на видеолекции",
    fields: [
      {
        name: "training_video_for_structure_administrators",
        type: "text"
      },
      { name: "training_video_for_priests", type: "text" },
      { name: "training_video_for_hram_admins", type: "text" }
    ]
  },
  {
    blockTitle: "Блокировка разделов",
    fields: [
      {
        name: "menuConf",
        type: "fieldArray",
        widths: fullWidth,
        doBeforeSend: fieldDoBeforeSend,
        extendable: false,
        removable: false,
        fields: [
          {
            name: "label",
            type: "custom",
            readOnly: true,
            widths: { xl: 2 },
            component: FakeField
          },
          {
            name: "web",
            type: "checkbox",
            label: "WEB",
            widths: { xl: 1 }
          },
          {
            name: "ios",
            type: "checkbox",
            label: "МП iOS",
            widths: { xl: 1 }
          },
          {
            name: "android",
            type: "checkbox",
            label: "МП Android",
            widths: { xl: 1 }
          },
          {
            type: "text",
            name: "message",
            label: "Сообщение",
            widths: { xl: 7 }
          }
        ]
      }
    ]
  },
  {
    blockTitle: "Работа с поиском",
    fields: [
      {
        name: "max_suggestions_count",
        ...baseNumberProps()
      }
    ]
  }
];

const formObj: BlockFormObj = {
  onSubmit: values =>
    axios.patch("/api/settings", rebuildValues(values, formObj)),
  blocks,
  validate: allFieldsRequired()
};

export function mergeSettingsValues(
  this: BlockFormObj,
  data: Record<string, OptionT & ContainsName>
): BlockFormObj {
  return {
    ...this,
    blocks: this.blocks.map(value => ({
      ...value,
      fields: value.fields.map(field => {
        const foundField = data[field.name];
        if (field.name === "menuConf" && field.type === "fieldArray") {
          return {
            ...field,
            defaultValue: foundField
          };
        }
        return {
          ...field,
          label: foundField?.label ?? field.label,
          defaultValue: field.rebuildReceivedValue
            ? field.rebuildReceivedValue(foundField.value, {})
            : foundField?.value ?? field.defaultValue
        };
      })
    }))
  };
}

const config: CompletePromiseThenRenderComponentProps<
  SettingsJournalProps
>["config"] = [
  {
    whereToStore: "initialValues",
    urlToDownload: "/api/settings",
    postLoadDoSomethingThenPassResultAsProp: {
      passResultAs: "formObj",
      func: mergeSettingsValues.bind(formObj)
    }
  }
];

const settings: PageT<CompletePromiseThenRenderComponentProps<
  SettingsJournalProps
>> = {
  requiresFunction: [
    { functionName: "administration_global_modifier", allowedActions: ["edit"] }
  ],
  navigationLabel: "Настройки",
  title: "Системные настройки",
  icon: Settings,
  passProps: {
    confObj: {
      title: "Системные настройки"
    },
    Component: SettingsJournal,
    config
  },
  Component: CompletePromiseThenRenderComponent
};

export default settings;
