import React from "react";
import MaskedInput, { MaskedInputProps } from "react-text-mask";
import { TextField } from "@material-ui/core";
import { TextFieldProps } from "@material-ui/core/TextField";

function TextMaskCustom({
  mask,
  pipe,
  showMask,
  inputRef,
  ...other
}: MaskedInputProps & { inputRef: React.RefObject<HTMLInputElement> }) {
  return <MaskedInput {...other} mask={mask} pipe={pipe} />;
}

export const MaskedInputMUI = React.forwardRef<
  HTMLDivElement,
  TextFieldProps & MaskedInputProps
>(
  (
    {
      label = "",
      mask,
      pipe,
      keepCharPositions,
      InputProps,
      ...rest
    }: TextFieldProps & MaskedInputProps,
    ref
  ) => {
    const value = { rest };
    return (
      <TextField
        label={label}
        ref={ref}
        InputProps={{
          ...InputProps,
          inputComponent: TextMaskCustom as any,
          inputProps: { mask, pipe, keepCharPositions }
        }}
        {...rest}
        InputLabelProps={{ shrink: !!value }}
      />
    );
  }
);
