import { AxiosRequestConfig } from "axios";
import { localizedYup, timeSchema } from "../../yup";
import { blocksT } from "../../types";
import { constructRequest } from "../../../components/modalWrapper/utils/baseRequests";
import { defaultErrorHandler } from "../../../utils/defaultErrorHandler";
import {
  rebuildReceivedOptionsLabelAsLabelUniqueIdAsValue,
  rebuildReceivedOptionsLabelAsLabelUniqueIdAsValueAddMainPerformerFlag
} from "../../rebuildRecieved";
import { baseTextAreaProps, baseTimeFieldProps } from "../../baseFieldProps";
import { rebuildMinistrationPerformersBeforeSend } from "./createMinistration";
import { halfWith } from "../../widths";
import { rebuildValues } from "../../../components/modalWrapper/utils/rebuildValuesBeforeSending";
import { doBeforeSend } from "../../baseFormProps";
import { ExtendedBlockFormObj } from "../../../components/modalWrapper/types";
import { dispatchOpenSnackBar, dispatchPopHeadModal } from "../../../store";

const blocks: blocksT = [
  {
    description:
      "Для заполнения расписания вы можете воспользоваться мастером первоначальной настройки",
    fields: [
      {
        label: "Копировать неделю",
        type: "checkbox",
        name: "isCopy",
        customOnChange: ({ setFieldValue, value }) => {
          if (value) {
            setFieldValue("isTemplateCreation", false);
          }
        }
      },
      {
        label: "Создать шаблон для недели",
        name: "isTemplateCreation",
        type: "checkbox",
        defaultValue: true,
        customOnChange: ({ setFieldValue, value }) => {
          if (value) {
            setFieldValue("isCopy", false);
          }
        }
      },
      {
        label: "Начало недели",
        name: "date",
        readOnly: true,
        type: "text"
      }
    ]
  },
  {
    conditionallyRender: values => values.isCopy,
    fields: [
      {
        label: "Записи с",
        name: "copy.start",
        type: "date"
      },
      {
        label: "Записи по",
        name: "copy.end",
        type: "date"
      }
    ]
  },
  {
    conditionallyRender: values => values.isTemplateCreation,
    fields: [
      {
        label: "Дежурный",
        name: "duty",
        type: "select",
        placeholder: "Выберите дежурного",
        dictUrl: "/api/ministration/priest",
        rebuildReceivedOptions: rebuildReceivedOptionsLabelAsLabelUniqueIdAsValueAddMainPerformerFlag
      },
      {
        name: "template",
        type: "fieldArray",
        widths: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
        addNewItemMessage: "Добавить событие",
        fields: [
          {
            label: "Служба",
            name: "action",
            type: "select",
            placeholder: "Выберите службу",
            dictUrl: "/api/ministration/type",
            rebuildReceivedOptions: rebuildReceivedOptionsLabelAsLabelUniqueIdAsValue,
            widths: halfWith
          },
          {
            name: "objectTypeCodeFiller",
            type: "spaceFiller",
            widths: halfWith
          },
          {
            label: "Время начала",
            name: "from",
            ...baseTimeFieldProps
          },
          {
            label: "Время окончания",
            name: "to",
            ...baseTimeFieldProps
          },
          {
            label: "Священнослужитель",
            name: "priests",
            type: "select",
            placeholder: "Выберите священнослужителей",
            dictUrl: "/api/ministration/priest",
            isCreatable: true,
            multiple: true,
            rebuildReceivedOptions: rebuildReceivedOptionsLabelAsLabelUniqueIdAsValueAddMainPerformerFlag,
            doBeforeSend: rebuildMinistrationPerformersBeforeSend,
            multiValueFlipPath: "isMainPerformer",
            selectAllOptions: {
              label: "Всё духовенство"
            }
          },
          {
            label: "Описание",
            name: "description",
            ...baseTextAreaProps
          },
          {
            label: "Включать в отчёты",
            name: "isReport",
            defaultValue: true,
            readOnly: true,
            type: "checkbox"
          }
        ]
      }
    ]
  }
];

export const scheduleCreationMaster: ExtendedBlockFormObj = {
  title: "Заполнение расписания богослужений",
  onSubmit: (values, { setSubmitting }, successCallback) => {
    const { copy, isCopy, isTemplateCreation, template, duty } = rebuildValues(
      values,
      { blocks, doBeforeSend }
    );
    const axiosConfig: AxiosRequestConfig = { method: "post" };
    if (isCopy) {
      axiosConfig.url = "/api/copy";

      axiosConfig.params = {
        jtype: "ministration",
        start: copy.start,
        end: copy.end,
        destination: values.date
      };
    } else if (isTemplateCreation) {
      axiosConfig.url = "/api/ministration/master";
      axiosConfig.data = {
        ministrations: template,
        duty,
        date: values.date
      };
    }

    constructRequest(
      axiosConfig,
      ({ status }) => {
        if (status === 200) {
          dispatchOpenSnackBar("Неделя была успешно заполнена");
        }
        setSubmitting(false);
        dispatchPopHeadModal();
        if (typeof successCallback === "function") {
          successCallback();
        }
      },
      error => {
        setSubmitting(false);
        defaultErrorHandler(error);
      }
    );
  },
  blocks,
  validationSchema: localizedYup.object({
    isTemplateCreation: localizedYup.bool(),
    isCopy: localizedYup.bool(),
    copy: localizedYup.object().when("isCopy", {
      is: true,
      then: localizedYup.object().shape({
        start: localizedYup.string().required(),
        end: localizedYup.string()
      })
    }),
    duty: localizedYup.object().nullable(),
    template: localizedYup.array().when("isTemplateCreation", {
      is: true,
      then: localizedYup.array(
        localizedYup.object({
          action: localizedYup
            .object()
            .nullable()
            .required(),
          priests: localizedYup.array(localizedYup.object().nullable()).min(1),
          description: localizedYup.string(),
          from: timeSchema.required(),
          // @ts-ignore
          to: timeSchema.compareTimeTo(localizedYup.ref("from")).required(),
          isReport: localizedYup.bool()
        })
      )
    })
  })
};
