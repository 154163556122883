import { DayPickerProps } from "react-day-picker";

export function getDisabledDays(
  minDate?: Date,
  maxDate?: Date
): DayPickerProps["disabledDays"] {
  let disabledDays: DayPickerProps["disabledDays"];

  if (minDate && maxDate) {
    disabledDays = {
      from: minDate,
      to: maxDate
    };
  }

  if (minDate) {
    disabledDays = {
      before: minDate
    };
  }

  if (maxDate) {
    disabledDays = {
      after: maxDate
    };
  }
  return disabledDays;
}
