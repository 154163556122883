const strReducer = (accumulator: string, currentValue: string): string =>
  accumulator + currentValue;

export function getRegExpFilteredValue(
  value: string,
  regExp?: RegExp,
  maxLength?: number
) {
  let filteredValue = "";

  if (regExp && value && value !== "") {
    const matchedSymbols = value.match(regExp);
    if (matchedSymbols !== null) {
      filteredValue = matchedSymbols.slice(0, maxLength).reduce(strReducer, "");
    }
  } else {
    filteredValue = value.slice(0, maxLength);
  }
  return filteredValue;
}
