import { FormikValues } from "formik";
import { IField } from "../../../constants/types";
import { AuthStateT } from "../../../reducers/auth";
import { BaseOnDropFunctionT } from "../@types/formikMUi";
import { defaultErrorHandler } from "../../../utils/defaultErrorHandler";

export function defineFieldVisibility(
  field: IField,
  values: FormikValues,
  auth: AuthStateT,
  name: string
) {
  return (
    field.hidden !== true &&
    (!field.conditionallyRender ||
      (field.conditionallyRender &&
        field.conditionallyRender(values, auth, name)))
  );
}

export const baseOnDrop: BaseOnDropFunctionT = (
  files,
  field,
  setFieldValue,
  status,
  initialValues
) => {
  const data = new FormData();
  data.append("file", files[0]);
  if (field.doBeforeSetFieldValue) {
    field
      .doBeforeSetFieldValue<string>(data, initialValues)
      // @ts-ignore
      .then(({ data: fileId }) => {
        setFieldValue(field.name, {
          label: files[0].name,
          value: fileId
        });
      })
      // @ts-ignore
      .catch(error => {
        defaultErrorHandler(error);
      });
  }
};
