import React, { useEffect, useMemo } from "react";
import { FieldArrayRenderProps } from "formik";
import { Grid, IconButton, Theme, Typography } from "@material-ui/core";
import { AddOutlined, Remove } from "@material-ui/icons";
import { get } from "lodash";
import { makeStyles } from "@material-ui/styles";
import { Changeable, IField } from "../../constants/types";
import { constructInitialValuesForEmptyFormWithOutBlocks } from "./utils/initialValuesUtils";
import { calcFieldWidth } from "./utils/calcFieldWidth";
import { RenderField } from "./renderField";
import { defineFieldVisibility } from "./utils/fieldRenderHelpers";
import { AuthStateT } from "../../reducers/auth";

type FieldArrayWrapperProps = FieldArrayRenderProps &
  Changeable & {
    fields: IField[];
    addNewItemMessage?: string;
    auth: AuthStateT;
    readOnly: boolean;
    preventAddingDefaultItemToEmptyArray?: boolean;
    allowToDeleteLastItem?: boolean;
  };

const getStyles = makeStyles((theme: Theme) => ({
  item: {
    boxSizing: "border-box",
    border: `1px solid ${theme.palette.primary.light}`,
    borderRadius: "5px",
    padding: theme.spacing(2),
    position: "relative"
  },
  removeBtn: {
    position: "absolute",
    right: theme.spacing(0.5),
    top: theme.spacing(0.5),
    zIndex: 4
  },
  field: {
    width: "100%"
  }
}));

export const FieldArrayWrapper = ({
  name,
  form: { values, status, setFieldValue, initialValues },
  fields,
  push,
  remove,
  addNewItemMessage,
  auth,
  readOnly,
  allowToDeleteLastItem = false,
  preventAddingDefaultItemToEmptyArray = false,
  extendable = true,
  removable = true
}: FieldArrayWrapperProps) => {
  const currentFieldValue = get(values, name, []);
  const emptyFields = useMemo(
    () =>
      constructInitialValuesForEmptyFormWithOutBlocks({ fields }, status.auth),
    [fields, status.auth]
  );
  useEffect(() => {
    if (
      currentFieldValue.length === 0 &&
      !preventAddingDefaultItemToEmptyArray
    ) {
      push(emptyFields);
    }
  }, [
    currentFieldValue.length,
    emptyFields,
    fields,
    preventAddingDefaultItemToEmptyArray,
    push
  ]);
  const classes = getStyles();
  const shouldRenderRemoveBtn =
    (currentFieldValue.length > 1 || allowToDeleteLastItem) &&
    removable &&
    !readOnly;
  return (
    <Grid container direction="column">
      {currentFieldValue.map((value: any, index: number) => (
        <Grid
          key={index}
          item
          classes={{ item: classes.item }}
          style={
            currentFieldValue.length - 1 !== index
              ? { marginBottom: "16px" }
              : undefined
          }
        >
          <Grid container spacing={1}>
            {shouldRenderRemoveBtn && (
              <IconButton
                color="secondary"
                onClick={() => remove(index)}
                className={classes.removeBtn}
              >
                <Remove />
              </IconButton>
            )}
            {fields.map((field, fieldIndex) => {
              const computedName = `${name}.${index}.${field.name}`;
              if (!defineFieldVisibility(field, values, auth, computedName)) {
                return null;
              }
              const computedField: IField = {
                ...field,
                name: computedName
              };
              if (computedField.type === "select") {
                computedField.customOptionsName = field.name;
              }
              return (
                <Grid item {...calcFieldWidth(field)} key={fieldIndex}>
                  <RenderField
                    field={computedField}
                    classes={classes}
                    readOnly={readOnly}
                    status={status}
                    setFieldValue={setFieldValue}
                    initialValues={initialValues}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      ))}
      {!readOnly && extendable && (
        <Grid item style={{ display: "flex", alignItems: "center" }}>
          <IconButton color="primary" onClick={() => push(emptyFields)}>
            <AddOutlined />
          </IconButton>
          {addNewItemMessage && (
            <Typography color="primary">{addNewItemMessage}</Typography>
          )}
        </Grid>
      )}
    </Grid>
  );
};
