import React, { CSSProperties, useState } from "react";
import { DropzoneOptions, FileError, useDropzone } from "react-dropzone";
import {
  FormHelperText,
  Grid,
  IconButton,
  Typography
} from "@material-ui/core";
import {
  Done,
  InsertDriveFileOutlined,
  VerticalAlignBottomOutlined
} from "@material-ui/icons";
import CircularProgress from "@material-ui/core/CircularProgress";
import { FieldInputProps } from "formik";
import { commonFilesUrl, downloadFile } from "../utils/downLoad";
import { FileFieldValueT } from "../../../constants/types";
import { MakePropertiesOptional } from "../../../actions/typedActions";
import { useSetRecoilState } from "recoil";
import { snackBarState } from "../../../store/snackBar";

const baseStyle: CSSProperties = {
  height: "100%",
  borderWidth: 1,
  borderColor: "#1c375a66",
  borderStyle: "dashed",
  borderRadius: 5,
  padding: "16px"
};
const activeStyle: CSSProperties = {
  borderColor: "#1676f380",
  backgroundColor: "#eee",
  background: "#1676f31a",
  transition: "background-color 0.1s, border-color 0.1s"
};
const rejectStyle: CSSProperties = {
  borderColor: "#c66",
  backgroundColor: "#eee"
};

/* const separator = {
  borderTop: "1px solid #1a39601a"
}; */

const textStyle: CSSProperties = {
  marginLeft: "8px"
};

const downloadButtonWrapper: CSSProperties = { position: "absolute", right: 0 };

const downloadButtonProgress: CSSProperties = {
  position: "absolute",
  right: 0,
  zIndex: 1
};

export type WrappedReactDropZoneMUIProps = MakePropertiesOptional<
  FieldInputProps<FileFieldValueT>,
  "onBlur" | "onChange"
> & {
  label?: string;
  disabled: boolean;
  error: boolean;
  helperText?: string;
  onDrop?: DropzoneOptions["onDropAccepted"];
  accept?: string | string[];
  customUrlToDownLoadFile?: (value: any) => string;
  showHint?: boolean;
};

const fileErrorMsgs: Record<FileError["code"], string> = {
  "file-too-large": "Размер файла превышает допустимый",
  "file-invalid-type": "Некорректный тип файла "
};

export const WrappedReactDropZoneMUI: React.FC<WrappedReactDropZoneMUIProps> = ({
  value,
  name,
  disabled,
  label: controlLabel = "",
  onDrop,
  customUrlToDownLoadFile,
  accept,
  error,
  helperText,
  showHint = true,
}: WrappedReactDropZoneMUIProps) => {
  const { label: fileName, value: fileId } = value
    ? (Array.isArray(value) && value.length > 0 && value[0]) || value
    : { label: "", value: "" };

  const [downloading, setDownloading] = useState(false);
  const showSnackBar = useSetRecoilState(snackBarState);
  const {
    getRootProps,
    getInputProps,
    isDragAccept,
    isDragReject
  } = useDropzone({
    onDropAccepted: onDrop,
    onDropRejected: fileRejections => {
      fileRejections.forEach(({ errors }) =>
        errors.forEach(err =>
          showSnackBar({
            open: true,
            message: fileErrorMsgs[err.code] ?? "Некорректный файл"
          })
        )
      );
    },
    disabled,
    multiple: false,
    maxSize: 10485760,
    accept
  });
  let styles = { ...baseStyle };
  styles = isDragAccept ? { ...styles, ...activeStyle } : styles;
  styles = isDragReject || error ? { ...styles, ...rejectStyle } : styles;
  return (
    <div style={{ marginTop: "4px", position: "relative" }}>
      <div
        style={{
          position: "absolute",
          left: "8px",
          top: "-8px",
          backgroundColor: "white"
        }}
      >
        <Typography>{controlLabel}</Typography>
      </div>
      <Grid
        container
        alignItems="flex-start"
        justify="flex-start"
        direction="column"
        {...getRootProps()}
        style={styles}
      >
        <Grid container alignItems="center">
          <input {...getInputProps()} />
          {(value === null || (value && value.value === null)) &&
            !disabled &&
            !isDragReject && (
              <>
                <InsertDriveFileOutlined />
                <Typography color="textPrimary" style={textStyle}>
                  Перенесите файл
                </Typography>
              </>
            )}
          {(value === null || (value && value.value === null)) && disabled && (
            <Typography color="textPrimary" style={textStyle}>
              Файл отсутствует
            </Typography>
          )}
          {value && fileName && (
            <Grid container>
              <Grid
                key={fileName}
                container
                justify="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Grid container alignItems="center">
                    <Done />
                    <Typography style={textStyle}>{fileName}</Typography>
                    <div style={downloadButtonWrapper}>
                      <IconButton
                        disabled={downloading}
                        onClick={event => {
                          if (event) {
                            event.stopPropagation();
                          }
                          setDownloading(true);
                          downloadFile(
                            customUrlToDownLoadFile
                              ? customUrlToDownLoadFile(value)
                              : `${commonFilesUrl}/${fileId}`,
                            fileName
                          ).finally(() => setDownloading(false));
                        }}
                      >
                        <VerticalAlignBottomOutlined />
                      </IconButton>
                      {downloading && (
                        <CircularProgress
                          size={50}
                          style={downloadButtonProgress}
                        />
                      )}
                    </div>
                  </Grid>
                </Grid>
                {/* <Grid item>
                        <Close />
                      </Grid> */}
              </Grid>
            </Grid>
          )}
        </Grid>
        {showHint && (
          <Typography
            color={ (isDragReject && accept === "image/*")
              ? "error" : "textSecondary" }
            style={{ marginTop: "0.7rem", fontSize: "0.8rem" }}
          >
            Возможно добавление фотографий форматов jpg, jpeg, tiff, png, bmp. Размер файла должен быть менее 10Мб
          </Typography>
        )}
      </Grid>
      {error && <FormHelperText error={error}>{helperText}</FormHelperText>}
    </div>
  );
};
