import React from "react";
import { Field, FieldArray, useField } from "formik";
import { TextField } from "@material-ui/core";
import { WrappedReactSelect } from "./wrappedComponents/select/wrappedReactSelect";
import { WrappedCheckBoxMUI } from "./wrappedComponents/wrappedCheckBoxMUI";
import { WrappedReactDropZoneMUI } from "./wrappedComponents/wrappedReactDropZoneMUI";
import { createUniqueCheckFunction } from "./utils/isValueUnique";
import { WrappedReactDayPicker } from "./wrappedComponents/datePickers/wrappedReactDayPicker";
import { FieldArrayWrapper } from "./FieldArrayWrapper";
import { WrappedDayPickerInputMUI } from "./wrappedComponents/datePickers/WrappedDayPickerInputMUI";
import { PasswordTextField } from "./wrappedComponents/passwordTextField";
import { WrappedDateRangePickerInput } from "./wrappedComponents/datePickers/WrappedDateRangePickerInput";
import { getUserFunctions } from "../../store";
import { MaskedInputMUI } from "../input/maskedInputMUI";
import { RenderFieldProps } from "./@types/formikMUi";
import { baseOnDrop } from "./utils/fieldRenderHelpers";
import {
  BaseSelect,
  IField,
  OptionT,
  SelectField
} from "../../constants/types";

type Val<T extends IField> = T["type"] extends BaseSelect ? OptionT : string;

export const RenderField: React.FC<RenderFieldProps> = ({
  field,
  classes,
  readOnly,
  initialValues,
  setFieldValue,
  status,
  status: { readOnlyFields, auth }
}) => {
  const { type, name, label, additionalProps } = field;

  const fieldLevelReadOny =
    typeof field.readOnly === "function"
      ? field.readOnly(getUserFunctions(), status)
      : field.readOnly;

  const computedReadOnly =
    readOnly ||
    fieldLevelReadOny ||
    (Array.isArray(readOnlyFields) && readOnlyFields.some(n => n === name)) ||
    false;

  let validate;
  const fieldLevelValidation = field.validate;
  if (
    (field.type === "formatted" || field.type === "text") &&
    field.uniqueCheck
  ) {
    validate = createUniqueCheckFunction(
      initialValues,
      name,
      field.uniqueCheck
    );
  } else if (fieldLevelValidation) {
    validate = fieldLevelValidation;
  }
  const [fieldFormik, meta] = useField<any>({
    name,
    validate
  });

  const error = !!(meta.touched && meta.error);
  const helperText = (error && meta.error) || field.helperText;

  switch (field.type) {
    case "text": {
      return (
        <TextField
          variant="standard"
          name={fieldFormik.name}
          value={fieldFormik.value}
          onBlur={fieldFormik.onBlur}
          //@ts-ignore
          onChange={fieldFormik.onChange}
          label={label}
          className={classes.field}
          error={error}
          helperText={helperText}
          {...additionalProps}
          InputProps={{
            readOnly: computedReadOnly
          }}
        />
      );
    }
    case "select": {
      return (
        <WrappedReactSelect
          name={fieldFormik.name}
          value={fieldFormik.value}
          onBlur={fieldFormik.onBlur}
          //@ts-ignore
          onChange={fieldFormik.onChange}
          label={label}
          fieldConfig={field}
          options={field.options}
          isMulti={field.multiple || false}
          isDisabled={computedReadOnly}
          async={field.async}
          isCreatable={field.isCreatable}
          multiValueFlipPath={field.multiValueFlipPath}
          selectAllOptions={field.selectAllOptions}
          error={helperText}
          placeholder={field.placeholder}
          // customOnChange={field.customOnChange}
          // todo проверить есть ли где код который использует customValueGetter? иначе удалить след строку и из типизации компонента враппера
          // customValueGetter={field.customValueGetter}
          customOptionsName={field.customOptionsName}
          customOnChange={field.customOnChange}
        />
      );
    }
    case "formatted": {
      return (
        <MaskedInputMUI
          variant="standard"
          name={fieldFormik.name}
          value={fieldFormik.value}
          onBlur={fieldFormik.onBlur}
          //@ts-ignore
          onChange={fieldFormik.onChange}
          InputLabelProps={{ shrink: !!fieldFormik.value }}
          error={error}
          helperText={helperText}
          label={label}
          mask={field.mask}
          pipe={field.pipe}
          keepCharPositions={field.keepCharPositions}
          className={classes.field}
          {...additionalProps}
          InputProps={{
            readOnly: computedReadOnly
          }}
        />
      );
    }
    case "checkbox": {
      return (
        <WrappedCheckBoxMUI
          name={fieldFormik.name}
          value={fieldFormik.value}
          onBlur={fieldFormik.onBlur}
          //@ts-ignore
          onChange={fieldFormik.onChange}
          label={label}
          error={error}
          helperText={helperText}
          className={classes.field}
          disabled={computedReadOnly}
          {...additionalProps}
        />
      );
    }
    case "password": {
      return (
        <PasswordTextField
          variant="standard"
          name={fieldFormik.name}
          value={fieldFormik.value}
          onBlur={fieldFormik.onBlur}
            //@ts-ignore
          onChange={fieldFormik.onChange}
          label={label}
          className={classes.field}
          helperText={helperText}
          error={error}
          {...additionalProps}
          InputProps={{
            readOnly: computedReadOnly
          }}
        />
      );
    }
    case "date-range": {
      return (
        <WrappedDateRangePickerInput
          {...fieldFormik}
          label={label}
          readOnly={computedReadOnly}
          minDate={field.minDate}
          maxDate={field.maxDate}
          error={error}
          helperText={helperText}
        />
      );
    }
    case "date": {
      return (
        <Field
          name={name}
          label={label}
          readOnly={computedReadOnly}
          className={classes.field}
          minDate={field.minDate}
          maxDate={field.maxDate}
          component={WrappedDayPickerInputMUI}
        />
      );
    }
    case "file": {
      const customOnDrop = field.onDrop;
      const onDrop = customOnDrop
        ? (files: File[]) =>
            customOnDrop(files, field, setFieldValue, status, initialValues)
        : (files: File[]) =>
            baseOnDrop(files, field, setFieldValue, status, initialValues);
      return (
        <WrappedReactDropZoneMUI
          {...fieldFormik}
          label={label}
          error={error}
          helperText={helperText}
          onDrop={onDrop}
          customUrlToDownLoadFile={field.customUrlToDownLoadFile}
          disabled={computedReadOnly}
          // className={classes.field}
          accept={field.accept}
          showHint={field.showHint}
        />
      );
    }
    case "fieldArray": {
      return (
        <FieldArray
          name={name}
          validateOnChange={false}
          render={props => (
            <FieldArrayWrapper
              fields={field.fields}
              addNewItemMessage={field.addNewItemMessage}
              preventAddingDefaultItemToEmptyArray={
                field.preventAddingDefaultItemToEmptyArray
              }
              allowToDeleteLastItem={field.allowToDeleteLastItem}
              {...props}
              auth={auth}
              readOnly={computedReadOnly}
              extendable={field.extendable}
              removable={field.removable}
            />
          )}
        />
      );
    }
    case "spaceFiller": {
      return <div className={classes.field} />;
    }
    case "date-new": {
      return (
        <WrappedReactDayPicker
          {...fieldFormik}
          name={name}
          error={error}
          helperText={helperText}
          // @ts-ignore
          label={label}
          {...additionalProps}
        />
      );
    }
    case "address": {
      return null;
    }
    case "custom": {
      const { component: Component } = field;
      return (
        <Component
          name={fieldFormik.name}
          value={fieldFormik.value}
          onBlur={fieldFormik.onBlur}
          //@ts-ignore
          onChange={fieldFormik.onChange}
          error={error}
          helperText={helperText}
          label={label}
          readOnly={computedReadOnly}
          {...additionalProps}
        />
      );
    }
    default: {
      throw new Error(
        `Для поля name: ${name} не найдено обработчика для типа: ${type}`
      );
    }
  }
};
