export const initialState = {
  activeStep: 0,
  stepsPassProps: [],
  isLoading: true
};

export const init = stepsConfig => {
  return {
    ...initialState,
    stepsPassProps: stepsConfig.map(() => ({}))
  };
};

export const setIsLoading = () => ({ type: "setIsLoading" });
export const decreaseActiveStep = dispatch => dispatch({ type: "decrement" });

export const setStepPassProps = (index, value) => ({
  type: "setStepPassProps",
  payload: { index, value: { ...value } }
});

export const increaseActiveStep = passPropsToNextStep => ({
  type: "increment",
  payload: passPropsToNextStep
});

export function reducer(state, action) {
  switch (action.type) {
    case "increment": {
      const copy = state.stepsPassProps.slice();
      const nextActiveStepIndex = state.activeStep + 1;
      copy[state.activeStep] = {
        ...state.stepsPassProps[state.activeStep]
      };
      copy[nextActiveStepIndex] = {
        ...state.stepsPassProps[nextActiveStepIndex],
        ...action.payload
      };
      return {
        ...state,
        activeStep: nextActiveStepIndex,
        stepsPassProps: copy
      };
    }
    case "decrement": {
      const copy = state.stepsPassProps.slice();
      const prevActiveStep = state.activeStep - 1;
      copy[prevActiveStep] = {
        ...state.stepsPassProps[prevActiveStep]
      };
      copy[state.activeStep] = {};
      return {
        ...state,
        activeStep: prevActiveStep,
        setStepPassProps: copy
      };
    }
    case "setStepPassProps": {
      const { index, value } = action.payload;
      const copy = state.stepsPassProps.slice();
      copy[index] = { ...state.stepsPassProps[index], ...value };
      return {
        ...state,
        stepsPassProps: copy,
        isLoading: false
      };
    }
    case "setIsLoading": {
      return {
        ...state,
        isLoading: action.payload
      };
    }
    default:
      throw new Error(`Не найдено такого обработчика: ${action.type}`);
  }
}
