import { v4 as uuidV4 } from "uuid";
import {
  CLOSE_MODAL_BY_KEY,
  MODAL_TAB_HAS_CHANGED,
  NEXT_PAGE,
  POP_HEAD_MODAL,
  PREVIOUS_PAGE,
  PUSH_MODAL,
  REPLACE_HEAD_MODAL,
  RESET_SORTABLE,
  SAVE_FETCHED_DATA,
  SET_BASE_URL,
  SET_COMPUTED_URL,
  SET_JOURNAL_IS_LOADING_CONTENT,
  SET_MODAL_STATE_INITIAL_VALUES,
  SET_PAGE,
  SET_ROWS_PER_PAGE,
  SET_SORT,
  SET_SORTABLE
} from "../constants/actionTypeConstants";
import { ModalT } from "../components/wrapper/types";
import { BaseJournalRow } from "../actions/typedActions";

const initialState = {
  fetchedData: [],
  totalElements: 0,
  sortable: [],
  modalState: {
    openedModals: []
  },
  openedTab: 0,
  newEntity: {},
  baseUrl: "",
  computedUrl: "",
  pagination: {
    rowsPerPage: 10,
    page: 1
  },
  loadingContent: true
};

type sortValueT = "asc" | "desc";

interface SortableT {
  path: string;
  value: sortValueT;
}

export interface JournalReducerStateT {
  fetchedData: BaseJournalRow[];
  totalElements?: number;
  sortable: SortableT[];
  modalState: {
    openedModals: ModalT[];
  };
  openedTab: number;
  newEntity: {};
  baseUrl: string | (() => string);
  computedUrl: string;
  pagination: {
    rowsPerPage: number;
    page: number;
  };
  loadingContent: boolean;
}

export default function journalReducer(
  state: JournalReducerStateT = initialState,
  action: any
) {
  switch (action.type) {
    case SET_JOURNAL_IS_LOADING_CONTENT: {
      return { ...state, loadingContent: action.payload };
    }
    case SAVE_FETCHED_DATA:
      return {
        ...state,
        fetchedData: action.payload.data || [],
        totalElements: action.payload.totalElements || 0
      };
    case SET_SORT: {
      const desiredIndex = state.sortable.findIndex(
        el => el.path === action.payload.path
      );

      if (desiredIndex === -1) {
        return {
          ...state,
          sortable: state.sortable.concat({
            path: action.payload.path,
            value: "asc"
          })
        };
      }

      switch (state.sortable[desiredIndex].value) {
        case "asc": {
          const newState = {
            ...state,
            sortable: state.sortable.slice()
          };

          newState.sortable[desiredIndex].value = "desc";

          return newState;
        }
        case "desc": {
          return {
            ...state,
            sortable: state.sortable.filter(
              sort => sort.path !== action.payload.path
            )
          };
        }
        default:
          return state;
      }
    }
    case SET_SORTABLE: {
      const sortable: {
        value?: SortableT["value"];
        path: SortableT["path"];
      } = { path: action.payload };
      if (!state.sortable[0]) {
        sortable.value = "desc";
      } else if (state.sortable[0].value === "desc") {
        sortable.value = "asc";
      } else {
        return {
          ...state,
          sortable: []
        };
      }

      return {
        ...state,
        sortable: [sortable]
      };
    }
    case RESET_SORTABLE: {
      return {
        ...state,
        sortable: []
      };
    }
    case POP_HEAD_MODAL: {
      return {
        ...state,
        modalState: {
          ...state.modalState,
          openedModals: state.modalState.openedModals.slice(
            0,
            state.modalState.openedModals.length - 1
          )
        }
      };
    }
    case PUSH_MODAL: {
      const { openedModals } = state.modalState;
      return {
        ...state,
        modalState: {
          ...state.modalState,
          openedTab: action.payload.openedTab || state.openedTab,
          openedModals: [...openedModals, { ...action.payload, key: uuidV4() }]
        }
      };
    }
    case REPLACE_HEAD_MODAL: {
      const res = state.modalState.openedModals.slice();
      res[state.modalState.openedModals.length - 1] = action.payload;
      return {
        ...state,
        modalState: {
          ...state.modalState,
          openedModals: res
        }
      };
    }
    case CLOSE_MODAL_BY_KEY: {
      return {
        ...state,
        modalState: {
          ...state.modalState,
          openedModals: state.modalState.openedModals.filter(
            ({ key }) => key !== action.payload
          )
        }
      };
    }
    case MODAL_TAB_HAS_CHANGED: {
      return {
        ...state,
        openedTab: action.payload.openedTab ? action.payload.openedTab : 0
      };
    }
    case SET_MODAL_STATE_INITIAL_VALUES: {
      return {
        ...state,
        initialValues: action.payload.slice()
      };
    }
    case SET_COMPUTED_URL: {
      return {
        ...state,
        computedUrl: action.payload.slice()
      };
    }
    case SET_ROWS_PER_PAGE: {
      return {
        ...state,
        pagination: { ...state.pagination, rowsPerPage: action.payload }
      };
    }
    case SET_PAGE: {
      return {
        ...state,
        pagination: { ...state.pagination, page: action.payload }
      };
    }
    case NEXT_PAGE: {
      return {
        ...state,
        pagination: { ...state.pagination, page: state.pagination.page + 1 }
      };
    }
    case PREVIOUS_PAGE: {
      return {
        ...state,
        pagination: { ...state.pagination, page: state.pagination.page - 1 }
      };
    }
    case SET_BASE_URL: {
      return {
        ...state,
        baseUrl: action.payload
      };
    }
    default:
      return state;
  }
}
