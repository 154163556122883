import { composeWithDevTools } from "redux-devtools-extension";
import { Action, applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
// import throttle from "lodash/throttle";
// import { loadState, saveState } from "../localStorage";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web and AsyncStorage for react-native
import { AxiosError } from "axios";
import { OptionsObject } from "notistack";
import reducers, { RootStateT } from "../reducers";
import {
  closeConfirmDialogFormik,
  fetchJournalData,
  MakePropertiesOptional,
  openConfirmDialogFormik,
  openSnackBar,
  popHeadModal,
  popHeadModalThenPushModal,
  pushHeadModal,
  setTokenThenLoadUserInfo
} from "../actions/typedActions";
import { ConfirmDialogFormikT } from "../reducers/wrapperReducer";
import { FormikMUIProps } from "../components/formikMUi/formikNewRealisation";
import { ModalT } from "../components/wrapper/types";
import { DISABLE_PRIMARY_BTN_CONFIRM_DIALOG } from "../constants/actionTypeConstants";
import { defaultErrorHandler } from "../utils/defaultErrorHandler";
import { PersistState } from "redux-persist/es/types";

// const persistedState = loadState();

const persistConfig = {
  key: "tip-vis",
  storage
};

const persistedReducer = persistReducer<RootStateT, Action>(
  persistConfig,
  reducers
);

/* export default () => {
  const store = createStore(
    persistedReducer,
    composeWithDevTools(applyMiddleware(thunk))
  );
  const persistor = persistStore(store);
  return { store, persistor };
}; */

interface PersistPartial {
  _persist: PersistState;
}

export const store = createStore<RootStateT & PersistPartial, Action, any, any>(
  persistedReducer,
  process.env.NODE_ENV === "development"
    ? composeWithDevTools(applyMiddleware(thunk))
    : applyMiddleware(thunk)
);
export const persistor = persistStore(store);

/*
const store = createStore(
  reducers,
  persistedState,
  composeWithDevTools(applyMiddleware(thunk))
);
*/

export const dispatchUserAuthSetFunctions = (token: string) =>
  store.dispatch(setTokenThenLoadUserInfo(token));
export const allowedActions = () =>
  store.getState().wrapperReducer.allowedActions;
export const getAuth = () => store.getState().auth;
export const getUserFunctions = () => {
  return store.getState().auth.functions;
};
export const computedUrl = () => store.getState().journalReducer.computedUrl;
export const disableConfirmDialogPrimaryBtn = (value: boolean) =>
  store.dispatch({
    type: DISABLE_PRIMARY_BTN_CONFIRM_DIALOG,
    payload: { primaryButtonDisabled: value }
  });
export const dispatchCloseConfirmDialogFormik = () =>
  store.dispatch(closeConfirmDialogFormik());
export const dispatchOpenSnackBar = (
  message: string,
  options?: OptionsObject
) => store.dispatch(openSnackBar(message, options));
/* const openConfirmDialog = payload =>
  store.dispatch({
    type: TOGGLE_CONFIRM_DIALOG,
    payload: { open: true, ...payload }
  }); */
export const dispatchOpenConfirmDialogFormik = (
  payload: MakePropertiesOptional<
    ConfirmDialogFormikT,
    "open" | "primaryButtonText" | "secondaryButtonText"
  > &
    Partial<FormikMUIProps>
) => store.dispatch(openConfirmDialogFormik(payload));
export const dispatchFetchJournalData = () => store.dispatch(fetchJournalData);
export const dispatchPopHeadModal = () => store.dispatch(popHeadModal());
export const dispatchPushModal = (modal: ModalT) =>
  store.dispatch(pushHeadModal(modal));
/* store.subscribe(
  throttle(() => {
    const { auth, collapsableMenuReducer, wrapperReducer } = store.getState();
    saveState({
      auth: {
        accessToken: auth.accessToken,
        isAuthenticated: auth.isAuthenticated,
        role: auth.role,
        functions: auth.functions,
        surnameInitials: auth.surnameInitials
      },
      wrapperReducer: { allowedActions: wrapperReducer.allowedActions },
      collapsableMenuReducer
    });
  }),
  5000
); */
export const callDefaultErrorHandlerThenPopModal = (er: AxiosError) =>
  defaultErrorHandler(er).then(() => dispatchPopHeadModal());
// ,
export const dispatchPopHeadModalThenPushModal = (modal: ModalT) =>
  store.dispatch(popHeadModalThenPushModal(modal));
// export default store;
export const getUserId = () => store.getState().auth.userId;
