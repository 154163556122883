import React, { useEffect, useState } from "react";
import axios from "axios";
import { addWeeks, endOfWeek, startOfWeek, subWeeks } from "date-fns";
import { FormikValues } from "formik";
import clsx from "clsx";
import { AutoSizer } from "react-virtualized";
import { Button } from "@material-ui/core";
import { NavigateBefore, NavigateNext } from "@material-ui/icons";
import { CollectionType, PerformerResponseT } from "../types";
import { adaptScheduleCollection } from "../utils";
import { defaultPreloader } from "../../completePromiseThenRenderComponent";
import styles from "./styles.module.css";
import { DayPickerInputMUI } from "../../picker/DayPickerInputMUI";
import {
  baseDateFnsOptions,
  formatToRussianDate
} from "../../../constants/formaters/formatters";
import { ModalT } from "../../wrapper/types";
import { ContainsLabel } from "../../../constants/rebuildRecieved";

export type TabProps = {
  firstFormInitialValues: FormikValues;
  modal: ModalT;
};

const emptyEvents = (
  <>
    <div />
    <div />
    <div />
  </>
);

function joinExtractLabels<T extends ContainsLabel>(objects: T[] | null) {
  if (objects === null) {
    return "";
  }
  return objects.map(({ label }) => label).join(", ");
}

export const ScheduleView: React.FC<TabProps> = ({
  modal: { item },
  firstFormInitialValues
}: TabProps) => {
  const [loading, setLoading] = useState(true);
  const [collection, setCollection] = useState<CollectionType>([]);

  const today = new Date();
  const initialFrom = startOfWeek(today, baseDateFnsOptions);
  const [from, setFrom] = useState(initialFrom);
  const initialTo = endOfWeek(today, baseDateFnsOptions);
  const [to, setTo] = useState(initialTo);
  const structureMemberId =
    firstFormInitialValues?.member?.value || firstFormInitialValues.uniqueId;
  useEffect(() => {
    setLoading(true);
    axios
      .get<CollectionType<PerformerResponseT>>("/api/events/log", {
        params: {
          from: formatToRussianDate(from),
          to: formatToRussianDate(to),
          structureMemberId
        }
      })
      .then(({ data }) => {
        setCollection(adaptScheduleCollection(data));
        setLoading(false);
      })
      .catch(e => {
        console.error(e);
      });
  }, [from, structureMemberId, to]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.filters}>
        <DayPickerInputMUI
          variant="outlined"
          label="С"
          value={from}
          onDayClick={day => setFrom(day)}
          clearValue={() => setFrom(initialFrom)}
          disabledDays={{ after: to }}
        />
        <DayPickerInputMUI
          variant="outlined"
          label="По"
          value={to}
          onDayClick={day => setTo(day)}
          clearValue={() => setTo(initialTo)}
          disabledDays={{ before: from }}
        />
        <Button
          size="small"
          variant="outlined"
          color="primary"
          className={styles.btn}
          onClick={() => {
            const date = subWeeks(from, 1);
            setFrom(startOfWeek(date, baseDateFnsOptions));
            setTo(endOfWeek(date, baseDateFnsOptions));
          }}
        >
          <NavigateBefore />
          Предыдущая седмица
        </Button>
        <Button
          size="small"
          variant="outlined"
          color="primary"
          className={styles.btn}
          onClick={() => {
            const date = addWeeks(to, 1);
            setFrom(startOfWeek(date, baseDateFnsOptions));
            setTo(endOfWeek(date, baseDateFnsOptions));
          }}
        >
          Следующая седмица
          <NavigateNext />
        </Button>
      </div>
      {loading ? (
        defaultPreloader({})
      ) : (
        <AutoSizer disableWidth>
          {({ height }) => (
            <div
              className={styles.gridM}
              style={{ height: height - 50, overflow: "auto" }}
            >
              <div className={styles.header}>День</div>
              <div className={styles.header}>Дежурный</div>
              <div className={clsx(styles.gridN, styles.header)}>
                <div>Время</div>
                <div>Событие</div>
                <div>Духовенство</div>
              </div>
              {collection.map(({ day, duties, events }) => (
                <React.Fragment key={day}>
                  <div>{day}</div>
                  <div>{joinExtractLabels(duties)}</div>
                  <div className={styles.gridN}>
                    {events.length === 0
                      ? emptyEvents
                      : events.map(
                          ({ from, to, type, performers, uniqueId }) => (
                            <React.Fragment key={uniqueId}>
                              <div>{`${from.slice(0, 5)} - ${to.slice(
                                0,
                                5
                              )}`}</div>
                              <div>{type.label}</div>
                              <div>{joinExtractLabels(performers)}</div>
                            </React.Fragment>
                          )
                        )}
                  </div>
                </React.Fragment>
              ))}
            </div>
          )}
        </AutoSizer>
      )}
    </div>
  );
};
