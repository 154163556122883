import baseJournal from "../journal";
import { Done } from "@material-ui/icons";
import { PageT } from "../../../types";
import { addPerformer } from "../inWork/journal";

const claimCompleted: PageT = {
  ...baseJournal,
  icon: Done,
  navigationLabel: "Подтвержденные заявки",
  url: async () => addPerformer("/api/getCloseClaimObjectsList")
};
export default claimCompleted;
