import { ScheduleJournal } from "../../../components/schedule/scheduleJournal";
import { createSlot } from "./createSlot";
import { createWorkItem } from "./createWorkItem";
import { copySchedule, copyScheduleDay } from "./copySchedule";
import { scheduleCreationMaster } from "./scheduleCreationMaster";
import { createMinistration } from "./createMinistration";
import { adaptFormObjToModalWindow } from "../../utils";
import { monthWord } from "./monthWord";
import { computePropsForFormikMUI } from "../../../components/modalWrapper/utils/computePropsForForMikMUI";
import { viewMinistration } from "./viewMinistration";
import { viewWorkItem } from "./viewWorkItem";
import { viewSlot } from "./viewSlot";
import { PageT } from "../../types";

export const schedule: PageT = {
  requiresFunction: [
    {
      functionName: "administrator_hram_schedule_modifier",
      allowedActions: ["view"]
    },
    {
      functionName: "priest_hram_schedule_view",
      allowedActions: ["view"]
    },
    {
      functionName: "assistant_hram_schedule_view",
      allowedActions: ["view"]
    }
  ],
  passProps: {
    confObj: {
      title: "Расписание богослужений и дежурств в храме:",
      urlToDownloadCollection: "/api/events/log?",
      filters: [
        {
          name: "priest",
          label: "Священнослужитель",
          type: "select",
          placeholder: "Выберите священнослужителя",
          dictUrl: "/api/sysUsers/search/"
        }
      ]
    }
  },
  Component: ScheduleJournal,
  forms: {
    createSlot: adaptFormObjToModalWindow({
      formObj: createSlot,
      maxWidth: "sm"
    }),
    createWorkItem: adaptFormObjToModalWindow({
      formObj: createWorkItem,
      maxWidth: "md",
      mode: "create"
    }),
    copySchedule: adaptFormObjToModalWindow({
      formObj: copySchedule,
      maxWidth: "sm"
    }),
    copyScheduleDay: adaptFormObjToModalWindow({
      formObj: copyScheduleDay,
      maxWidth: "sm"
    }),
    scheduleCreationMaster: adaptFormObjToModalWindow({
      formObj: scheduleCreationMaster
    }),
    createMinistration: adaptFormObjToModalWindow({
      formObj: createMinistration,
      maxWidth: "md"
    }),
    editMonthWord: adaptFormObjToModalWindow({
      formObj: monthWord,
      mode: "edit"
    }),
    viewMonthWord: adaptFormObjToModalWindow({
      formObj: monthWord,
      readOnly: true,
      mode: "view"
    }),
    viewSlot: computePropsForFormikMUI({ formObj: viewSlot, mode: "view" }),
    viewMinistration: computePropsForFormikMUI({
      formObj: viewMinistration,
      mode: "view"
    }),
    editMinistration: computePropsForFormikMUI({
      formObj: viewMinistration,
      mode: "edit"
    }),
    viewWorkItem: computePropsForFormikMUI({
      formObj: viewWorkItem,
      mode: "view"
    }),
    editWorkItem: computePropsForFormikMUI({
      formObj: viewWorkItem,
      mode: "edit"
    })
  }
};
