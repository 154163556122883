import React, { useEffect, useRef } from "react";
import { FieldArray, Form, FormikProps, FormikValues } from "formik";
import {
  Fab,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  Theme,
  Typography
} from "@material-ui/core";
import { get } from "lodash";
import { Add as AddIcon, Delete } from "@material-ui/icons";
import * as PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { v4 as uuidV4} from "uuid";
import clsx from "clsx";
import { createEmptyFieldArrayObj } from "../../utils/initialValuesUtils";
import { RenderField } from "../../renderField";
import { FieldArrayT, TableFormObj } from "../../../../constants/types";
import { AuthStateT } from "../../../../reducers/auth";

// @ts-ignore
const useStyles = makeStyles<Theme>(theme => ({
  isDeleted: {
    color: "#a94442",
    backgroundColor: "#f2dede",
    borderColor: "#ebccd1",
    border: "1px solid transparent",
    borderRadius: "4px"
  },
  listSubItem: {
    padding: theme.spacing(1),
    width: "100%"
  },
  headerItem: {
    width: "100%"
  },
  listItem: {
    position: "relative"
  },
  lastItem: {
    paddingTop: theme.spacing(2),
    // position: "sticky",
    display: "flex",
    justifyContent: "center",
    // bottom: "0",
    // zIndex: 2,
    // backgroundColor: "white",
    paddingBottom: "0"
  },
  removeBtn: {
    position: "absolute",
    right: "0"
  },
  divider: {
    marginLeft: "8px",
    height: "1px",
    backgroundColor: "white",
    flex: "1  1 auto"
  },
  field: {
    width: "100%"
  },
  fabContainer: {
    marginBottom: -theme.spacing(3)
  },
  container: {
    position: "relative",
    minWidth: "20vw",
    minHeight: "20vh"
  },
  header: {
    position: "sticky !important",
    zIndex: 2,
    top: 0,
    borderBottom: "2px solid rgba(0, 0, 0, 0.12)",
    backgroundColor: "#eeeeee",
    paddingTop: "0 !important",
    paddingBottom: 0,
    marginBottom: theme.spacing(1)
  },
  deleteBtn: {
    right: "-8px"
  },
  secondaryAction: { paddingRight: "0", paddingLeft: "0" }
}));

let previousValuesLength = 0;

export interface RenderTableFormProps {
  confObj: TableFormObj;
  values: {
    [x: string]: any[];
  };
  readOnly: boolean;
  initialValues: FormikValues;
  setFieldValue: FormikProps<FormikValues>["setFieldValue"];
  auth: AuthStateT;
  status: FormikProps<FormikValues>["status"];
  id?: string;
}

export const RenderTableForm = ({
  confObj,
  values,
  readOnly,
  initialValues,
  setFieldValue,
  auth,
  status,
  id
}: RenderTableFormProps) => {
  let fieldArrayFistField: FieldArrayT = {
    name: "",
    type: "fieldArray",
    fields: []
  };
  const temp = confObj.fields[0];
  if (temp.type === "fieldArray") {
    fieldArrayFistField = temp;
  }
  const emptyFieldArrayObj = createEmptyFieldArrayObj(
    fieldArrayFistField.fields,
    auth
  );
  const classes = useStyles();
  let arrayHelpersRef;
  const addBtnRef = useRef<HTMLDivElement | null>(null);
  let maxCountOfColumns = 0;
  fieldArrayFistField.fields.forEach(({ width = 1, hidden }) => {
    if (!hidden) {
      maxCountOfColumns += width;
    }
  });
  const widths: number[] = [];
  const minColumnWidth = 100 / maxCountOfColumns;
  fieldArrayFistField.fields.forEach(({ width = 1 }) => {
    widths.push(minColumnWidth * width);
  });
  useEffect(() => {
    // пришлось сделать переменную в которой хранится пред-я длина массива
    const { current } = addBtnRef;
    if (
      current &&
      current.scrollIntoView &&
      values &&
      values[fieldArrayFistField.name] &&
      values[fieldArrayFistField.name].length !== previousValuesLength
    ) {
      previousValuesLength = values[fieldArrayFistField.name].length;
      current.scrollIntoView({ behavior: "smooth" });
    }
  }, [fieldArrayFistField.name, values]);
  // className={classes.header}
  return (
    <Form id={id}>
      <div className={classes.container}>
        <List>
          <ListItem classes={{ root: classes.header }} disableGutters>
            {fieldArrayFistField.fields.map(
              ({ label, hidden = false }, index) =>
                !hidden && (
                  <span
                    className={clsx(classes.listSubItem, classes.headerItem)}
                    key={label || uuidV4()}
                    style={{ width: `${widths[index]}%` }}
                  >
                    <Typography
                      variant="subtitle1"
                      style={{
                        fontSize: "15px"
                      }}
                    >
                      {label}
                    </Typography>
                  </span>
                )
            )}
          </ListItem>
          <FieldArray
            name={fieldArrayFistField.name}
            render={arrayHelpers => {
              arrayHelpersRef = arrayHelpers;
              return (
                <>
                  {(values &&
                    values[fieldArrayFistField.name] &&
                    values[fieldArrayFistField.name].length > 0 &&
                    values[fieldArrayFistField.name].map((row, index) => (
                      <ListItem
                        key={index}
                        className={clsx(classes.listItem, {
                          [classes.isDeleted]: row.isDeleted === true
                        })}
                        disableGutters
                      >
                        {fieldArrayFistField.fields.map((field, fieldIndex) => {
                          if (field.hidden === true) {
                            return null;
                          }
                          return (
                            <span
                              key={field.name || uuidV4() + index}
                              className={classes.listSubItem}
                              style={{ width: `${widths[fieldIndex]}%` }}
                            >
                              <RenderField
                                field={{
                                  ...field,
                                  label: "",
                                  name:
                                    field.type === "custom"
                                      ? `${fieldArrayFistField.name}.[${index}]`
                                      : `${fieldArrayFistField.name}.[${index}].${field.name}`
                                }}
                                classes={classes}
                                readOnly={readOnly}
                                initialValues={initialValues}
                                setFieldValue={setFieldValue}
                                status={status}
                              />
                            </span>
                          );
                        })}
                        {!row.isDeleted && !readOnly && (
                          <ListItemSecondaryAction>
                            <IconButton
                              className={classes.deleteBtn}
                              type="button"
                              onClick={() =>
                                arrayHelpers.replace(index, {
                                  ...get(
                                    values,
                                    `${fieldArrayFistField.name}.[${index}]`
                                  ),
                                  isDeleted: true
                                })
                              }
                            >
                              <Delete />
                            </IconButton>
                          </ListItemSecondaryAction>
                        )}
                      </ListItem>
                    ))) || (
                    <ListItem>
                      <Grid container justify="center">
                        <Typography variant="body1">
                          Значения отсутствуют
                        </Typography>
                      </Grid>
                    </ListItem>
                  )}
                </>
              );
            }}
          />
        </List>
        {!readOnly && (
          <Grid container justify="center">
            <div ref={addBtnRef}>
              <Fab
                size="small"
                color="primary"
                aria-label="Add"
                onClick={() => arrayHelpersRef.push(emptyFieldArrayObj)}
              >
                <AddIcon />
              </Fab>
            </div>
          </Grid>
        )}
      </div>
    </Form>
  );
};

RenderTableForm.defaultProps = {
  readOnly: false
} as any;

RenderTableForm.propTypes = {
  confObj: PropTypes.shape({}).isRequired,
  values: PropTypes.shape({}).isRequired,
  readOnly: PropTypes.bool,
  initialValues: PropTypes.shape({}).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  auth: PropTypes.shape({}).isRequired
} as any;
