import React from "react";
import { Button, withStyles } from "@material-ui/core";

const style = {
  root: {
    boxShadow:
      "0px 1px 2px 0px rgb(207, 207, 207),0px 2px 2px 0px rgb(241, 241, 241),0px 3px 1px -2px rgb(241, 241, 241)"
  }
};

const ShadowedOutlinedButton = ({
  onClick,
  children,
  color,
  classes,
  ...rest
}) => (
  <Button
    onClick={onClick}
    {...rest}
    variant="outlined"
    color={color}
    classes={{ root: classes.root }}
  >
    {children}
  </Button>
);

export default withStyles(style, { withTheme: true })(ShadowedOutlinedButton);
