import React, { useEffect, useState } from "react";
import { LinearProgress, TextField } from "@material-ui/core";
import { useFormikContext } from "formik";
import axios from "axios";
import { makeStyles } from "@material-ui/styles";
import { defaultErrorHandler } from "../../../utils/defaultErrorHandler";
import { CustomField } from "../../../constants/types";

// фикс чтоб у селектов значения выпадалки не скрывались
const getStyles = makeStyles({
  label: {
    zIndex: 0
  }
});

export const DayDescription: CustomField["component"] = ({ label }) => {
  const {
    values: { date }
  } = useFormikContext<any>();
  const classes = getStyles();
  const [loading, setLoading] = useState(true);

  const [desc, setDesc] = useState<string>("");

  // const dependedOnValues = deps.map(depPath => get(values, depPath));

  useEffect(() => {
    setLoading(true);
    const source = axios.CancelToken.source();
    axios
      // да на беке написано с опечаткой
      .get<{ desciption: string | null }>(
        `/api/ministration/day?date=${date}`,
        {
          cancelToken: source.token
        }
      )
      .then(({ data: { desciption } }) => {
        setLoading(false);
        if (desciption) {
          setDesc(desciption);
        }
      })
      .catch(defaultErrorHandler);
    return () => source.cancel();
  }, [date]);
  return (
    <div style={{ maxHeight: "200px" }}>
      <TextField
        value={desc}
        label={label}
        disabled
        multiline
        rowsMax={6}
        fullWidth
        variant="outlined"
        InputLabelProps={{
          classes: {
            outlined: classes.label
          }
        }}
      />
      {loading && <LinearProgress />}
    </div>
  );
};
