import React from "react";
import { Button, IconButton } from "@material-ui/core";
import { FileCopyOutlined, Remove } from "@material-ui/icons";
import { isBefore, startOfToday } from "date-fns";
import { FormikValues, useFormikContext } from "formik";
import axios from "axios";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { AddEventBtn } from "../actionButtons";
import { ActionButtonsStyle } from "../../../reducers/schedule";
import { ModalT } from "../../wrapper/types";
import { parseRussianDate } from "../../../constants/formaters/formatters";
import {
  defaultErrorHandler,
  openSnackBar,
} from "../../../actions/typedActions";
import { OptionT } from "../../../constants/types";
import { userChurchAtom } from "../../../store/userStore";
import { useRecoilValue } from "recoil";
import { checkSlotsAfterDutyRemoved } from "./DutyManager";
import { CollectionType } from "../types";

export interface DayButtonsProps {
  disabled: boolean;
  actionButtonsStyle: ActionButtonsStyle;
  openModal: (modal: ModalT) => void;
  isPersonal: boolean;
  day: string;
  push: (v: any) => void;
  userId: number;
  successCallback: (values?: FormikValues) => void;
  clearDuty?: Function;
  duties?: OptionT[] | null;
}

export const CopyButton = ({
  actionButtonsStyle,
  openModal,
  isPersonal,
  day,
  disabled,
  successCallback,
}: {
  disabled: boolean;
  actionButtonsStyle: ActionButtonsStyle;
  openModal: (modal: ModalT) => void;
  isPersonal: boolean;
  day: string;
  successCallback: (values?: FormikValues) => void;
}) => {
  const { values } = useFormikContext<{ days: CollectionType }>();

  const dispatch = useDispatch();

  const openCopyModal = () => {
    const hasUnsavedEvent = values.days.some((day) =>
      day.events.some((ev) => typeof ev.uniqueId === "string")
    );
    if (!hasUnsavedEvent) {
      openModal({
        category: "schedule",
        mode: "copyScheduleDay",
        initialValues: {
          start: day,
          isPersonal,
        },
        successCallback,
      });
    } else {
      dispatch(openSnackBar("Сохраните события прежде чем копировать день"));
    }
  };

  if (actionButtonsStyle === "icon") {
    return (
      <IconButton disabled={disabled} onClick={openCopyModal}>
        <FileCopyOutlined />
      </IconButton>
    );
  }
  return (
    <Button
      size="small"
      disabled={disabled}
      onClick={openCopyModal}
      color="primary"
    >
      <FileCopyOutlined /> Копировать день
    </Button>
  );
};

function ClearDuty(
  dispatch: Dispatch<any>,
  day: string,
  successCallback: (values?: FormikValues) => void,
  churchId: string,
  clearDuty?: Function
) {
  axios
    .post(
      "/api/slots/dutysandslot",
      {},
      {
        params: { date: day, hramId: churchId, deleteAll: "false" },
      }
    )
    .then((response) => {
      checkSlotsAfterDutyRemoved(
        response.data.response,
        response.data.count,
        dispatch,
        "allDay",
        () => {
          axios
            .post(
              "/api/slots/dutysandslot",
              {},
              {
                params: { date: day, hramId: churchId, deleteAll: "true" },
              }
            )
            .then((resp) => {
              if (resp.data.response == true) {
                dispatch(openSnackBar("Забронированные беседы удалены"));
              } else {
                dispatch(openSnackBar("Ошибка при удалении"));
              }
            });
        }
      );
      // dispatch(openSnackBar("Дежурства были отменены"));
      if (clearDuty) {
        clearDuty();
      }
      // successCallback({ selectedDates: [day] });
    })
    .catch((e) => dispatch(defaultErrorHandler(e)));
}

export const DayButtons = ({
  disabled,
  actionButtonsStyle,
  openModal,
  day,
  isPersonal,
  push,
  userId,
  successCallback,
  clearDuty,
  duties,
}: DayButtonsProps) => {
  const churchId = useRecoilValue(userChurchAtom)?.value;
  const dispatch = useDispatch();
  const removeDutyOnClick = () =>
    ClearDuty(dispatch, day, successCallback, churchId, clearDuty);
  const disabledInPast =
    disabled || isBefore(parseRussianDate(day), startOfToday());
  return (
    <div style={{ display: actionButtonsStyle === "text" ? "grid" : "block" }}>
      <CopyButton
        openModal={openModal}
        isPersonal={isPersonal}
        day={day}
        disabled={disabled}
        actionButtonsStyle={actionButtonsStyle}
        successCallback={successCallback}
      />
      <AddEventBtn
        variant={actionButtonsStyle}
        disabled={disabledInPast}
        push={push}
        create={isPersonal ? "slot" : "ministration"}
        isPersonal={isPersonal}
        userId={userId}
      />
      {!isPersonal &&
        (actionButtonsStyle === "text" ? (
          <Button
            size="small"
            disabled={disabledInPast}
            color="primary"
            onClick={removeDutyOnClick}
          >
            <Remove />
            Отменить дежурство
          </Button>
        ) : (
          <IconButton
            size="small"
            disabled={disabledInPast}
            color="primary"
            onClick={removeDutyOnClick}
          >
            <Remove />
          </IconButton>
        ))}
    </div>
  );
};
