import React from "react";
import "react-day-picker/lib/style.css";
import { DayModifiers, DayPickerProps } from "react-day-picker";
import { isSameDay } from "date-fns";
import { FieldInputProps, useFormikContext } from "formik";
import { Typography } from "@material-ui/core";
import { DayPickerMUI } from "../../../picker/DayPickerMUI";

type onDayClick = (
  day: Date,
  modifiers: DayModifiers,
  e: React.MouseEvent<HTMLDivElement>
) => void;

export type WrappedReactDayPickerProps = FieldInputProps<Date[]> & {
  error?: boolean;
  helperText?: string;
} & Partial<DayPickerProps> & {
    format: (date: Date) => any;
    parse: dateFns["parse"] | ((rawDate: string) => Date);
  };

const modifiersStyles = {
  today: {
    color: "inherit"
  }
};

export const WrappedReactDayPicker = ({
  value: days,
  name,
  format,
  parse,
  disabledDays,
  error,
  helperText
}: WrappedReactDayPickerProps) => {
  const { setFieldValue } = useFormikContext();
  const handleDayClick: onDayClick = (day, { selected }) => {
    const res = [...days];
    if (selected) {
      const selectedIndex = res.findIndex(selectedDay =>
        isSameDay(selectedDay, day)
      );
      res.splice(selectedIndex, 1);
    } else {
      res.push(day);
    }
    setFieldValue(name, res);
  };
  return (
    <div style={{ display: "flex" }}>
      <div
        style={error ? { border: " 1px solid rgba(255,0,0,0.6)" } : undefined}
      >
        <DayPickerMUI
          disabledDays={disabledDays}
          selectedDays={days}
          onDayClick={handleDayClick}
          modifiersStyles={modifiersStyles}
        />
      </div>
      {!!error && (
        <Typography style={{ margin: "8px" }} color="error">
          {helperText}
        </Typography>
      )}
    </div>
  );
};
