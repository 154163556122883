import React from "react";
import { TextField } from "@material-ui/core";
import { TextFieldProps } from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/styles";
import { FieldProps } from "formik";
import { checkIfFieldHasValidationError } from "../utils/checkIfFieldHasValidationError";

// фикс чтоб у селектов значения выпадалки не скрывались
const getStyles = makeStyles({
  label: {
    zIndex: 0
  }
});

export const WrappedTextField = ({
  field,
  form: { touched, errors },
  multiline = false,
  variant,
  ...restTextFieldProps
}: TextFieldProps & FieldProps<any>) => {
  const textFieldProps = checkIfFieldHasValidationError(
    field.name,
    touched,
    errors
  );
  const classes = getStyles();
  return (
    // @ts-ignore todo Понять как правильно обрабатыввать такую ситуцию поле: variant должно принимать 1 значение, а возможно отправить 3
    <TextField
      {...field}
      {...textFieldProps}
      multiline={multiline}
      variant={variant || multiline ? "outlined" : "standard"}
      InputLabelProps={{
        shrink: restTextFieldProps.placeholder ? true : undefined,
        classes: {
          outlined: classes.label
        }
      }}
      style={
        multiline
          ? {
              margin: "8px 0"
            }
          : undefined
      }
      {...restTextFieldProps}
    />
  );
};
