import {
  GroupedOptionsType,
  GroupType,
  OptionsType
} from "react-select/src/types";
import { Props } from "react-select/base";
import { useMemo } from "react";
import { OptionT, SelectAllOptionsT } from "../../constants/types";
import { ReactSelectMUIProps } from "./types";

export function useMemorizedOptions(
  options: GroupedOptionsType<OptionT> | OptionsType<OptionT>,
  isMulti: boolean,
  value: ReactSelectMUIProps["value"],
  selectAllOptions?: SelectAllOptionsT
) {
  return useMemo(() => {
    let computedOptions = options;
    if (
      isMulti &&
      selectAllOptions &&
      value &&
        Array.isArray(value) &&
        options.length !==
          // eslint-disable-next-line no-underscore-dangle
          value.filter((el: OptionT | GroupType<OptionT>) => !el.__isNew__)
            .length
    ) {
      if (
        options.some((value1: OptionT | GroupType<OptionT>) => value1.options)
      ) {
        // @ts-ignore todo Понять как обработать type
        computedOptions = [
          {
            label: "",
            options: [
              {
                label:
                  typeof selectAllOptions === "object"
                    ? selectAllOptions.label
                    : "Выбрать всё",
                value: "selectAllOptions"
              }
            ]
          },
          ...options
        ];
      } else {
        // @ts-ignore todo Понять как обработать type
        computedOptions = [
          {
            label:
              typeof selectAllOptions === "object"
                ? selectAllOptions.label
                : "Выбрать всё",
            value: "selectAllOptions"
          },
          ...options
        ];
      }
    }
    return computedOptions;
  }, [isMulti, options, selectAllOptions, value]);
}
