import { dispatchOpenSnackBar } from "../store";

const handleErrorStatus = status => {
  if (status === 500 || status === 400) {
    return Promise.resolve(
      dispatchOpenSnackBar(
        `Произошла внутренняя ошибка во время проведения операции`
      )
    );
  }
  if (status === 404 || status === 204) {
    return Promise.resolve(
      dispatchOpenSnackBar(`Не найдено запрашиваемого ресурса`)
    );
  }
  if (status === 403) {
    return Promise.resolve(dispatchOpenSnackBar("Недостаточно прав"));
  }
  if (status === 409) {
    return Promise.resolve(
      dispatchOpenSnackBar("Исполнитель(ли) не доступн(ы) на выбранное время")
    );
  }
  return Promise.resolve();
};

export const defaultErrorHandler = error => {
  if (
    error &&
    error.response &&
    error.response.data &&
    error.response.data.status
  ) {
    return handleErrorStatus(error.response.data.status);
  }
  if (error && error.response && error.response.status) {
    return handleErrorStatus(error.response.status);
  }
  return Promise.resolve();
};

export const MESSAGE_400_500 = `Произошла внутренняя ошибка во время проведения операции`;
export const MESSAGE_404_204 = `Не найдено запрашиваемого ресурса`;
export const MESSAGE_403 = "Недостаточно прав";
export const MESSAGE_409 = "Исполнитель(ли) не доступн(ы) на выбранное время";
