import { IField, OptionT } from "../types";
import { DoBeforeSendFormLevelT } from "../../components/modalWrapper/types";
import { FormikConfig } from "formik";

export const doBeforeSend: DoBeforeSendFormLevelT = {
  select: (selected: OptionT | OptionT[] | null | undefined) => {
    if (selected) {
      if (Array.isArray(selected)) {
        return selected.map(({ value }) => value);
      }
      return selected.value;
    }
    return selected;
  },
  file: (selected: OptionT | null) => (selected ? selected.value : null)
};

export type DataRestDoBeforeSendT = {
  [x in IField["type"]]?: (p: any, dictUrl: string) => any;
};

const constructURI = (value: any, dictUrl: string) =>
  `${dictUrl.split("/search")[0]}/${value}`;

export const dataRestDoBeforeSend: DataRestDoBeforeSendT = {
  select: (selected: OptionT | OptionT[] | null, dictUrl: string) => {
    if (selected) {
      if (Array.isArray(selected)) {
        return selected.map(({ value }) =>
          value ? constructURI(value, dictUrl) : null
        );
      }
      return constructURI(selected.value, dictUrl);
    }
    return selected;
  },
  file: (selected: OptionT | null) => (selected ? selected.value : null)
};

export const allFieldsRequired: <T = any>(
  except?: (keyof T)[]
) => FormikConfig<T>["validate"] = (except = []) => values => {
  const errors: Record<string, string> = {};
  Object.entries(values).forEach(([k, v]) => {
    // @ts-ignore
    if (!except?.includes(k) && !v) errors[k] = requiredMsg;
  });
  return errors;
};
