import forms from "./form";
import { phoneMask } from "../../masks";
import { rebuildReceivedOptionsLabelAsLabelUniqueIdAsValue } from "../../rebuildRecieved";
import { PageT } from "../../types";

const users: PageT = {
  requiresFunction: [
    {
      functionName: "administration_user_modifier",
      allowedActions: ["create", "view", "edit", "delete"]
    },
    {
      functionName: "administrator_hram_user_modifier",
      allowedActions: ["create", "view", "edit", "delete"]
    },
    {
      functionName: "administrator_structure_user_modifier",
      allowedActions: ["create", "view", "edit", "delete"]
    },
    {
      functionName: "member_user_view",
      allowedActions: ["view"]
    }
  ],
  title: "Журнал Пользователей",
  url: "/api/journalUsers",
  filtersPanel: {
    filters: [
      { label: "ФИО", name: "fullName", type: "text" },
      {
        label: "Обращение",
        name: "accost",
        type: "text"
      },
      {
        label: "Место служения",
        placeholder: "Выберите место служения",
        name: "memberId",
        type: "select",
        dictUrl: "/api/member/structureObjects?withLimit=true",
        async: true,
        rebuildReceivedOptions: false
      },
      {
        label: "Роль",
        placeholder: "Выберите роль",
        name: "codeRole",
        type: "select",
        dictUrl: "/api/user/role",
        rebuildReceivedOptions: rebuildReceivedOptionsLabelAsLabelUniqueIdAsValue
      },
      {
        label: "Телефон",
        name: "phone",
        type: "formatted",
        mask: phoneMask
      }
    ]
  },
  tableHeader: [
    {
      label: "ФИО",
      sortable: true,
      path: "fullName",
      width: "20%"
    },
    {
      label: "Обращение",
      sortable: false,
      path: "accost",
      width: "20%"
    },
    {
      label: "Место служения",
      sortable: true,
      path: "memberName",
      width: "35%"
    },
    {
      label: "Роль",
      sortable: true,
      path: "role",
      width: "15%"
    },
    {
      label: "Телефон",
      sortable: false,
      path: "phone",
      width: "15%"
    }
  ],
  forms: { ...forms }
};
export default users;
