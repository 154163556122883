import {
  AppBar,
  Badge,
  Button,
  IconButton,
  Theme,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { AccountCircle, Mail, Menu as MenuIcon } from "@material-ui/icons";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { isEqual } from "lodash";
import axios from "axios";
import {
  computeUrlThenFetchJournalData,
  loadUnreadMessagesCount,
  logout,
  pushHeadModal,
  setBaseUrl,
  toggleLeftMenu,
} from "../../actions/typedActions";
import { RootStateT } from "../../reducers";
import { ModalT } from "../wrapper/types";
import { getSystemName } from "./utils";
import { ReactSelectMUI } from "../select/reactSelectMUI";
import { useRecoilState, useRecoilValue } from "recoil";
import { overUserAtom, overUsersAtom, OverUserT } from "../../store/userStore";
import { INITIAL_PARAMETERS } from "../../constants/intialParameters";
import { useParams } from "react-router";
import { PageT } from "../../constants/types";

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  menuButton: {
    marginLeft: 6,
    marginRight: 18,
  },
  grow: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
  },
}));

const CustomAppBar = () => {
  const classes = useStyles();
  const [overUser, setOverUser] = useRecoilState(overUserAtom);
  const overUsers = useRecoilValue(overUsersAtom);
  useEffect(() => {
    if (overUsers.length >= 1) setOverUser(overUsers[0]);
  }, [overUsers, setOverUser]);
  const dispatch = useDispatch();

  const { category, page } = useParams<any>();

  const { signOut, loadUnreadMessagesCountMapped, openModal, toggleSideMenu } =
    useMemo(
      () => ({
        toggleSideMenu: () => dispatch(toggleLeftMenu()),
        openModal: (m: ModalT) => dispatch(pushHeadModal(m)),
        signOut: async () => {
          await axios.get("/oauth/logout");
          dispatch(logout());
        },
        loadUnreadMessagesCountMapped: () =>
          dispatch(loadUnreadMessagesCount()),
      }),
      [dispatch]
    );

  const { userId, isTestEnvironment, surnameInitials, unreadMessagesCount } =
    useSelector(
      (state: RootStateT) => ({
        userId: state.auth.userId,
        surnameInitials: state.auth.surnameInitials,
        unreadMessagesCount: state.wrapperReducer.unreadMessagesCount,
        isTestEnvironment: state.wrapperReducer.isTestEnvironment,
      }),
      isEqual
    );

  useEffect(() => {
    const repeatable = setInterval(() => {
      loadUnreadMessagesCountMapped();
    }, 60000);
    return () => {
      clearInterval(repeatable);
    };
  }, [loadUnreadMessagesCountMapped]);
  return (
    <AppBar
      position="static"
      className={classes.appBar}
      square
      color="primary"
      style={{
        backgroundColor: "#005853",
        color: "#fff",
        transition: "width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms"
      }}
    >
      <Toolbar color="primary">
        <img
          src="/logo.png"
          alt="logo"
          style={{
            maxHeight: "50px",
            color: "inherit",
          }}
        />
        <IconButton
          onClick={toggleSideMenu}
          className={classes.menuButton}
          color="inherit"
          aria-label="Menu"
        >
          <MenuIcon />
        </IconButton>
        <div className={classes.grow}>
          <Typography variant="subtitle1" color="inherit">
            {getSystemName(isTestEnvironment)}
          </Typography>
        </div>
        {overUsers.length >= 1 && (
          <ReactSelectMUI
            label="Вы работаете от имени"
            value={overUser}
            isMulti={false}
            isClearable={false}
            options={overUsers}
            placeholder={() => ""}
            onChange={async (u) => {
              setOverUser(u as OverUserT);
              setTimeout(async () => {
                const rawCfg = INITIAL_PARAMETERS[category];
                const cfg: PageT | undefined = page
                  ? // @ts-ignore
                    rawCfg.pages[page]
                  : rawCfg.page;
                if (cfg && typeof cfg.url === "function") {
                  const url = await cfg.url();
                  dispatch(setBaseUrl(url));
                  dispatch(computeUrlThenFetchJournalData());
                }
              }, 100);
            }}
            styles={{
              container: (base) => ({ ...base, width: "300px", zIndex: 99 }),
              menuList: (base) => ({ ...base, color: "#333333" }),
            }}
          />
        )}
        <IconButton
          color="inherit"
          onClick={() => {
            openModal({
              category: "utility",
              mode: "unreadMessages",
              readonly: true,
            });
          }}
        >
          <Badge max={99} badgeContent={unreadMessagesCount} color="secondary">
            <Mail />
          </Badge>
        </IconButton>
        <Button
          color="inherit"
          onClick={() => {
            openModal({
              category: "serviceMan",
              mode: "profile",
              item: { uniqueId: userId },
            });
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              variant="subtitle1"
              color="inherit"
              noWrap
              style={{ marginRight: "4px" }}
            >
              {surnameInitials}
            </Typography>
            <AccountCircle />
          </div>
        </Button>
        <Button variant="text" color="inherit" size="medium" onClick={signOut}>
          Выйти
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default React.memo(CustomAppBar);
