export const SET_FILTERS_OPTIONS_MAP = "SET_FILTERS_OPTIONS_MAP";
export const SET_BASE_URL = "SET_BASE_URL";
export const SET_PAGE = "SET_PAGE";
export const NEXT_PAGE = "NEXT_PAGE";
export const PREVIOUS_PAGE = "PREVIOUS_PAGE";
export const SET_ROWS_PER_PAGE = "SET_ROWS_PER_PAGE";
export const SET_ACTIVE_STEP = "SET_ACTIVE_STEP";
export const SET_COMPUTED_URL = "SET_COMPUTED_URL";
export const REPLACE_HEAD_MODAL = "REPLACE_HEAD_MODAL";
export const POP_HEAD_MODAL = "POP_HEAD_MODAL";
export const PUSH_MODAL = "PUSH_MODAL";
export const CLOSE_MODAL_BY_KEY = "CLOSE_MODAL_BY_KEY";
export const SET_ALLOWED_ACTIONS = "SET_ALLOWED_ACTIONS";
export const OPEN_CONFIRM_DIALOG_FORMIK = "OPEN_CONFIRM_DIALOG_FORMIK";
export const CLOSE_CONFIRM_DIALOG_FORMIK = "CLOSE_CONFIRM_DIALOG_FORMIK";
export const SET_CURRENT_USER_OBJECT = "SET_CURRENT_USER_OBJECT";
export const TOGGLE_CONFIRM_DIALOG = "TOGGLE_CONFIRM_DIALOG";
export const HIDE_CONFIRM_DIALOG = "HIDE_CONFIRM_DIALOG";
export const DISABLE_PRIMARY_BTN_CONFIRM_DIALOG =
  "DISABLE_PRIMARY_BTN_CONFIRM_DIALOG";
export const TOGGLE_SNACKBAR = "TOGGLE_SNACKBAR";
export const CLOSE_ALL_SNACKBARS = "CLOSE_ALL_SNACKBARS";
export const CLOSE_SNACKBAR_BY_ID = "CLOSE_SNACKBAR_BY_ID";
export const MODAL_TAB_HAS_CHANGED = "MODAL_TAB_HAS_CHANGED";
export const TOOGLE_MENU_COLLAPSED = "TOOGLE_MENU_COLLAPSED";
export const SET_MODAL_STATE_INITIAL_VALUES = "SET_MODAL_STATE_INITIAL_VALUES";
export const SET_LOGIN_ERROR_MESSAGE = "SET_LOGIN_ERROR_MESSAGE";
export const SET_SORT = "SET_SORT";
export const SET_SORTABLE = "SET_SORTABLE";
export const RESET_SORTABLE = "RESET_SORTABLE";
export const SAVE_FETCHED_DATA = "SAVE_FETCHED_DATA";
export const TOGGLE_IS_COLLAPSED = "TOGGLE_IS_COLLAPSED";
export const SET_AUTHENTICATED = "SET_AUTHENTICATED";
export const SET_ACCESS_TOKEN = "SET_ACCESS_TOKEN";
export const SET_COMPUTED_FILTER = "SET_COMPUTED_FILTER";
export const RESET_COMPUTED_FILTERS = "RESET_COMPUTED_FILTERS";
export const SET_COMPUTED_FILTERS = "SET_COMPUTED_FILTERS";
export const SET_AUTH = "SET_AUTH";
export const SET_EXPANDED_INDEX = "SET_EXPANDED_INDEX";
export const SET_LOADING = "SET_LOADING";
export const SET_DICTS = "SET_DICTS";
export const TOOGLE_SIGN_DIALOG = "TOOGLE_SIGN_DIALOG";
export const OPEN_SIGN_DIALOG = "OPEN_SIGN_DIALOG";
export const CLOSE_SIGN_DIALOG = "CLOSE_SIGN_DIALOG";
export const SET_SCHEDULE_JOURNAL_ACTION_BTNS_DISPLAY =
  "SET_SCHEDULE_JOURNAL_ACTION_BTNS_DISPLAY";
export const SET_BADGES_CONTENT = "SET_BADGES_CONTENT";
export const SET_UNREAD_MESSAGES_COUNT = "SET_UNREAD_MESSAGES_COUNT";
export const SET_IS_TEST_ENVIRONMENT = "SET_IS_TEST_ENVIRONMENT";
export const SET_FILTERS_CONFIG = "SET_FILTERS_CONFIG";
export const SET_JOURNAL_IS_LOADING_CONTENT = "SET_JOURNAL_IS_LOADING_CONTENT";
export const SET_SHOW_CHANGE_USER_SCRT_FORM = "SET_SHOW_CHANGE_USER_SCRT_FORM";
export const SET_FILTERS_APPLIED = "SET_FILTERS_APPLIED";
export const SET_TRAINING_LINK = "SET_TRAINING_LINK";
export const ON_PAGE_CHANGE_RESET_FILTERS = "ON_PAGE_CHANGE_RESET_FILTERS";
