import { CSSProperties } from "react";
import memoizeOne from "memoize-one";
import { HiddenColumnsT } from "../types";
import { IERequiredEventStyles, IERequiredStyles } from "./types";

function getInternetExplorerRequiredStylesPrivate(
  rowIndex: number,
  hiddenColumns: HiddenColumnsT
): IERequiredStyles {
  const msStyles: Record<
    "day" | "mothWord" | "duties" | "events",
    CSSProperties
  > = {
    day: {
      // @ts-ignore
      msGridRow: rowIndex,
      msGridColumn: 1
    },
    mothWord: {
      // @ts-ignore
      msGridRow: rowIndex,
      msGridColumn: 2
    },
    duties: {
      // @ts-ignore
      msGridRow: rowIndex,
      msGridColumn: 3
    },
    events: {
      // @ts-ignore
      msGridRow: rowIndex,
      msGridColumn: 4
    }
  };

  if (hiddenColumns.day) {
    // @ts-ignore
    msStyles.mothWord.msGridColumn -= 1;
    // @ts-ignore
    msStyles.duties.msGridColumn -= 1;
    // @ts-ignore
    msStyles.events.msGridColumn -= 1;
  }

  if (hiddenColumns.monthWord) {
    // @ts-ignore
    msStyles.duties.msGridColumn -= 1;
    // @ts-ignore
    msStyles.events.msGridColumn -= 1;
  }

  if (hiddenColumns.duties) {
    // @ts-ignore
    msStyles.events.msGridColumn -= 1;
  }
  return msStyles;
}

export const getInternetExplorerRequiredStyles = memoizeOne(
  getInternetExplorerRequiredStylesPrivate
);

function getInternetExplorerEventRequiredStylesPrivate(
  eventIndex: number
): IERequiredEventStyles {
  return {
    time: {
      // @ts-ignore
      msGridColumn: 1,
      msGridRow: eventIndex
    },
    type: {
      // @ts-ignore
      msGridColumn: 2,
      msGridRow: eventIndex
    },
    performers: {
      // @ts-ignore
      msGridColumn: 3,
      msGridRow: eventIndex
    },
    buttons: {
      // @ts-ignore
      msGridColumn: 4,
      msGridRow: eventIndex
    }
  };
}

export const getInternetExplorerEventRequiredStyles = memoizeOne(
  getInternetExplorerEventRequiredStylesPrivate
);
