import React from "react";
import {
  ExpandLess,
  ExpandMore,
  MoveToInbox,
  SvgIconComponent
} from "@material-ui/icons";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { AuthStateT } from "../../reducers/auth";
import { INITIAL_PARAMETERS } from "../../constants/intialParameters";

export const getArrowSymbol = (
  index: number,
  expandedIndex: number,
  value: boolean
) => {
  const isCollapsed = index === expandedIndex ? value : false;
  return isCollapsed ? <ExpandLess /> : <ExpandMore />;
};

export function getCategoryIcon(
  classes: any,
  CustomCategoryIcon: SvgIconComponent | undefined,
  category: string,
  activeCategory: string
) {
  return (
    <ListItemIcon
      classes={{
        root: classes.listIconRoot
      }}
    >
      {CustomCategoryIcon ? (
        <CustomCategoryIcon
          color={category === activeCategory ? "primary" : undefined}
        />
      ) : (
        <MoveToInbox
          color={category === activeCategory ? "primary" : undefined}
        />
      )}
    </ListItemIcon>
  );
}

export function getLabel(category: string, authState: AuthStateT): string {
  const { label } = INITIAL_PARAMETERS[category];
  if (typeof label === "function") {
    return label(authState);
  }
  return label;
}
