import { AnyAction } from "redux";
import {
  SET_EXPANDED_INDEX,
  TOOGLE_MENU_COLLAPSED
} from "../constants/actionTypeConstants";

const initialState = {
  expandedIndex: -1,
  value: false,
  sideMenuMaximized: true,
  currentlyActiveIndex: -1
};

export interface CollapsibleReducerState {
  expandedIndex: number;
  value: boolean;
  sideMenuMaximized: boolean;
  currentlyActiveIndex: number;
}

export default function collapsibleMenuReducer(
  state: CollapsibleReducerState = initialState,
  action: AnyAction
): CollapsibleReducerState {
  switch (action.type) {
    case SET_EXPANDED_INDEX:
      return {
        ...state,
        expandedIndex: action.payload.expandedIndex,
        value:
          state.expandedIndex !== action.payload.expandedIndex
            ? true
            : !state.value
      };
    case TOOGLE_MENU_COLLAPSED: {
      return {
        ...state,
        sideMenuMaximized: !state.sideMenuMaximized
      };
    }
    default:
      return state;
  }
}
