import { get } from "lodash";

export const checkIfFieldHasValidationError = (name, touched, errors) => {
  const isFieldTouched = get(touched, name, false);
  const errorMessage = get(errors, name, "");
  return {
    error: Boolean(isFieldTouched && errorMessage),
    helperText: isFieldTouched ? errorMessage : undefined
  };
};
