import React, { FC, SyntheticEvent } from "react";
import { BaseTabProps } from "../HramTab";
import { useDispatch } from "react-redux";
import { pushHeadModal } from "../../actions/typedActions";
import {
  DataGrid,
  GridColDef,
  GridRowParams,
  GridRowsProp,
  MuiEvent
} from "@material-ui/data-grid";
import { createTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";

type SubStructure = {
  id: number;
  uniqueId: number;
  label: string;
};

export const SubStructuresTab: FC<BaseTabProps & {
  data: SubStructure[];
}> = ({ modal, firstFormInitialValues, data }) => {
  const dispatch = useDispatch();
  const columns: GridColDef[] = [
    { field: "label", headerName: "Наименование", flex: 1 }
  ];
  const rows: GridRowsProp = data;

  const rowClickHandler = (
    params: GridRowParams,
    event: MuiEvent<SyntheticEvent>
  ) => {
    dispatch(
      pushHeadModal({
        category: "churchStructure",
        mode: "view",
        item: params.row as SubStructure
      })
    );
  };
  const defaultTheme = createTheme();
  const useStyles = makeStyles(
    theme => ({
      root: {
        border: 0,
        "& .MuiDataGrid-columnsContainer": {
          backgroundColor: "#eeeeee",
          borderBottom: "2px solid rgba(0, 0, 0, 0.12)"
        },
        "& .MuiDataGrid-iconSeparator": {
          display: "none"
        },
        "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
          borderRight: "none"
        },
        "& .MuiDataGrid-row.Mui-selected": {
          backgroundColor: "#799dcb61"
        }
      }
    }),
    { defaultTheme }
  );
  const classes = useStyles();
  return data.length ? (
    <div style={{ width: "100%" }}>
      <DataGrid
        autoHeight
        rows={rows}
        columns={columns}
        onRowClick={rowClickHandler}
        style={{ cursor: "pointer" }}
        hideFooter={true}
        disableColumnMenu={true}
        className={classes.root}
      />
    </div>
  ) : (
    <div style={{ textAlign: "center" }}>Подчиненные ОЕ не добавлены</div>
  );
};
