import axios from "axios";
import {
  createNumberMask,
  emailMask
} from "text-mask-addons/dist/textMaskAddons";
import { FormikProps, FormikValues } from "formik";
import { conformToMask, MaskedInputProps } from "react-text-mask";
import {
  phoneMask,
  time24HMaskWithOutSeconds,
  time24HMaskWithSeconds
} from "../masks";
import { FileField, FormattedField } from "../types";

interface BaseFormattedT {
  type: "formatted";
  mask: MaskedInputProps["mask"];
}

export const baseTextAreaProps: {
  type: "text";
  additionalProps: {
    multiline: boolean;
    fullWidth: boolean;
    rowsMax: number;
  };
} = {
  type: "text",
  additionalProps: {
    multiline: true,
    fullWidth: true,
    rowsMax: 6
  }
};

export const uploadFileInCommonFiles = (value: FormData) =>
  axios.post("/api/file/save", value, {
    params: {
      "Content-Type": "multipart/form-data"
    }
  });

export const uploadFileInFileSystem = (
  type: "user" | "hram" | "library",
  url?: string
) => (value: FormData) =>
  axios.post(url || "/api/file/privateUpload", value, {
    params: {
      "Content-Type": "multipart/form-data",
      type
    }
  });

export const uploadFileInFileSystemByUuid = (
  type: "user" | "hram" | "library",
  url?: string,
  params?: any
) => (value: FormData) =>
  axios.post(url || "/api/file/upload", value, {
    params: {
      "Content-Type": "multipart/form-data",
      type,
      ...params
    }
  });

export const downLoadFileFromFileSystemProps: Pick<
  FileField,
  "customUrlToDownLoadFile" | "rebuildReceivedValue" | "accept"
> = {
  rebuildReceivedValue: (path: string) => {
    if (!path) {
      return null;
    }
    let computedIndex = 0;
    const indexOfReverseSlash = path.lastIndexOf("/");
    const indexOfSlash = path.lastIndexOf("\\");
    if (indexOfReverseSlash !== -1) {
      computedIndex = indexOfReverseSlash + 1;
    } else if (indexOfSlash !== -1) {
      computedIndex = indexOfSlash + 1;
    }
    const fileName = path.slice(computedIndex);
    // file.jpg название "пустого" файла. приходит из бэка
    if (fileName === "file.jpg") {
      return null;
    }
    return {
      label: fileName,
      value: path
    };
  },
  customUrlToDownLoadFile: ({ value }) =>
    `/api/file/downloadByPath?path=${encodeURI(value)}`,
  accept: "image/*"
};

export const autoCorrectedTimePipe: MaskedInputProps["pipe"] = (
  conformedValue,
  config
) => {
  const { rawValue } = config;
  if (rawValue.length > 0) {
    const firstChar = Number.parseInt(rawValue[0], 10);
    if (firstChar > 2) {
      return {
        value: conformToMask(
          `0${firstChar}`,
          time24HMaskWithSeconds(`0${firstChar}`),
          {}
        ).conformedValue,
        indexesOfPipedChars: [0]
      };
    }
    if (rawValue.length > 3) {
      const thirdChar = Number.parseInt(
        rawValue[2] !== ":" ? rawValue[2] : rawValue[3] || "",
        10
      );
      if (thirdChar > 5) {
        return {
          value: conformToMask(
            `${rawValue.slice(0, 2)}0${thirdChar}`,
            time24HMaskWithSeconds(rawValue),
            {}
          ).conformedValue,
          indexesOfPipedChars: [2]
        };
      }
    }
  }
  return conformedValue;
};

export const autoCorrectedTimePipeWithoutSeconds: MaskedInputProps["pipe"] = (
  conformedValue,
  config
) => {
  const { rawValue } = config;
  if (rawValue.length > 0) {
    const firstChar = Number.parseInt(rawValue[0], 10);
    if (firstChar > 2) {
      return {
        value: conformToMask(
          `0${firstChar}`,
          time24HMaskWithOutSeconds(`0${firstChar}`),
          {}
        ).conformedValue,
        indexesOfPipedChars: [0]
      };
    }
    if (rawValue.length > 3) {
      const thirdChar = Number.parseInt(
        rawValue[2] !== ":" ? rawValue[2] : rawValue[3] || "",
        10
      );
      if (thirdChar > 5) {
        return {
          value: conformToMask(
            `${rawValue.slice(0, 2)}0${thirdChar}`,
            time24HMaskWithOutSeconds(rawValue),
            {}
          ).conformedValue,
          indexesOfPipedChars: [2]
        };
      }
    }
  }
  return conformedValue;
};

export const timeRegExpWithoutSeconds = /^\d{2}:\d{2}$/;

export const timeDoBeforeSend = (value: string) => {
  if (timeRegExpWithoutSeconds.test(value)) {
    return `${value}:00`;
  }
  return value;
};

export const baseTimeFieldProps: Pick<
  FormattedField,
  "type" | "mask" | "pipe" | "doBeforeSend"
> = {
  type: "formatted",
  // mask: time24HMaskWithSeconds,
  mask: time24HMaskWithOutSeconds,
  pipe: autoCorrectedTimePipeWithoutSeconds,
  doBeforeSend: timeDoBeforeSend
  // showMask: true,
  // guide: true,
  // keepCharPositions: true
};

interface BaseNumberProps {
  prefix?: string;
  suffix?: string;
  includeThousandsSeparator?: boolean;
  thousandsSeparatorSymbol?: string;
  allowDecimal?: boolean;
  decimalSymbol?: string;
  decimalLimit?: number;
  integerLimit?: number;
  requireDecimal?: boolean;
  allowLeadingZeroes?: boolean;
  allowNegative?: boolean;
  [x: string]: any;
}

export const baseNumberProps = ({
  prefix = "",
  includeThousandsSeparator = false,
  ...rest
}: BaseNumberProps = {}): Pick<FormattedField, "mask" | "type"> => ({
  type: "formatted",
  mask: createNumberMask({
    prefix,
    includeThousandsSeparator,
    ...rest
  })
});

export const basePhoneProps: BaseFormattedT = {
  type: "formatted",
  mask: phoneMask
};

export const baseEmailProps: BaseFormattedT = {
  type: "formatted",
  mask: emailMask
};

export function constructValidateUsingUserFunctions(
  ...userFunctions: string[]
) {
  return function validateUsingUserFunctions(value: any) {
    const context: {
      formik: FormikProps<FormikValues>;
      // @ts-ignore мне не понятна типизация this
    } = this;
    if (
      context &&
      context.formik &&
      context.formik.status &&
      context.formik.status.auth
    ) {
      const { functions } = context.formik.status.auth;
      if (
        Array.isArray(functions) &&
        functions.some(x => userFunctions.includes(x)) &&
        !value
      ) {
        return "Обязательное поле";
      }
    }
    return "";
  };
}
