/* autoprefixer grid: autoplace */
import React, { memo, useMemo } from "react";
import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { AutoSizer } from "react-virtualized";
import { Formik } from "formik";
import { isEqual } from "lodash";
import { CollectionType, ScheduleTableProps } from "./types";
import { defaultPreloader } from "../completePromiseThenRenderComponent";
import { RenderHeader } from "./TableHeader";
import "./styles.css";
import { scheduleTableValidationSchema } from "./table/scheduleTableValidationSchema";
import { RenderRows } from "./table/RenderDays";

// eslint-disable-next-line @typescript-eslint/no-empty-function
function fakeOnSubmit() {}

export interface TableStateType {
  days: CollectionType;
}

// @ts-ignore
const isIE = /* @cc_on!@ */ false || !!document.documentMode;

const ScheduleTablePrivate: React.FC<ScheduleTableProps> = ({
  collection,
  optionsMap,
  isLoading,
  readOnly,
  hiddenColumns,
  isPersonal,
  showAllEvents,
  successCallback,
  openModal,
  actionButtonsStyle,
  hasAdminRights,
  userId,
  setEverythingPublished,
  baseOnDuty
}: ScheduleTableProps) => {
  const initialValues: TableStateType = useMemo(
    () => ({
      days: collection
    }),
    [collection]
  );

  const initialStatus = useMemo(
    () => ({
      optionsMap,
      readOnly,
      isPersonal,
      baseOnDuty
    }),
    [isPersonal, optionsMap, readOnly, baseOnDuty]
  );

  if (isLoading) {
    return (
      <div
        style={{
          alignItems: "center",
          justifyContent: "center",
          display: "flex"
        }}
      >
        {defaultPreloader({
          message: "Идет загрузка данных таблицы",
          size: 150
        })}
      </div>
    );
  }

  return (
    <div style={{ flex: "1 1 auto" }}>
      <AutoSizer>
        {({ width, height }) => (
          <div
            style={{
              height,
              width: width - 10,
              overflow: "auto",
              // todo при отказе от IE удалить
              display: isIE ? "-ms-grid" : "grid",
              msGridColumns: `${!hiddenColumns.day ? "100px" : ""}${
                !hiddenColumns.monthWord ? " 300px" : ""
              }${
                !hiddenColumns.duties ? " 300px" : ""
              } minmax(min-content, 1500px)`,
              gridTemplateColumns:
                // магия я вычисляю настройку шаблона css grid динамически в зависимости от скрытых колонок
                `${!hiddenColumns.day ? "200px" : ""}${
                  !hiddenColumns.monthWord ? " 300px" : ""
                }${!hiddenColumns.duties ? " 300px" : ""} auto`,
              borderBottom: "1px solid rgba(224, 224, 224, 1)",
              borderLeft: "1px solid rgba(224, 224, 224, 1)",
              gridTemplateRows: `${!hiddenColumns.day ? "auto" : ""}${
                !hiddenColumns.monthWord ? " auto" : ""
              }${!hiddenColumns.duties ? " auto" : ""} auto`,
              msGridRows: `${!hiddenColumns.day ? "auto" : ""}${
                !hiddenColumns.monthWord ? " auto" : ""
              }${!hiddenColumns.duties ? " auto" : ""} auto`
            }}
            className="grid"
          >
            <RenderHeader hiddenColumns={hiddenColumns} />
            <Formik
              enableReinitialize
              initialValues={initialValues}
              onSubmit={fakeOnSubmit}
              validateOnChange={false}
              validateOnBlur={false}
              initialStatus={initialStatus}
              validationSchema={scheduleTableValidationSchema}
            >
              <RenderRows
                hiddenColumns={hiddenColumns}
                openModal={openModal}
                successCallback={successCallback}
                hasAdminRights={hasAdminRights}
                showAllEvents={showAllEvents}
                isPersonal={isPersonal}
                actionButtonsStyle={actionButtonsStyle}
                readOnly={readOnly}
                userId={userId}
                setEverythingPublished={setEverythingPublished}
              />
            </Formik>
          </div>
        )}
      </AutoSizer>
    </div>
  );
};

export const ScheduleTable = memo(ScheduleTablePrivate, isEqual);
