import {
  CalendarToday,
  ContactMail,
  Group,
  Home,
  InsertChart,
  LibraryBooks,
  Message,
  PermContactCalendar,
  QuestionAnswer,
  Settings,
  ViewList
} from "@material-ui/icons";
import users from "./pages/users/journal";
import settings from "./pages/settings/journal";
import { schedule } from "./pages/schedule/journal";
import { churchStructure } from "./pages/churchStructure/journal";
import actionType from "./pages/directory/actionType/journal";
import { library } from "./pages/library/journal";
import claimNew from "./pages/claim/new/journal";
import claimInWork from "./pages/claim/inWork/journal";
import claimFree from "./pages/claim/free/journal";
import claimCompleted from "./pages/claim/completed/journal";
import { InitialParametersT } from "./types";
import { personalSchedule } from "./pages/personalSchedule/journal";
import { utility } from "./pages/utility/journal";
import reserved, { getPendingSlotsCountUrl } from "./pages/slots/reserved";
import progress from "./pages/slots/progress";
import end from "./pages/slots/end";
import notwork from "./pages/slots/notwork";
import { faq } from "./pages/faq/journal";
import statistics from "./pages/statistics/reports/journal";

export const INITIAL_PARAMETERS: InitialParametersT = {
  schedule: {
    label: "Расписание храма",
    icon: CalendarToday,
    page: schedule
  },
  personalSchedule: {
    label: "Личное расписание",
    icon: PermContactCalendar,
    page: personalSchedule
  },
  slots: {
    label: "Беседы",
    icon: ContactMail,
    badge: { url: getPendingSlotsCountUrl },
    pages: {
      reserved,
      progress,
      end,
      notwork
    }
  },
  claim: {
    label: "Заявки",
    icon: Message,
    badge: {
      url: "/api/getAllNewClaimObjects/count"
    },
    pages: {
      new: claimNew,
      free: claimFree,
      inWork: claimInWork,
      submitting: claimCompleted
    }
  },
  churchStructure: {
    label: authState => {
      const { functions, memberType } = authState;
      const defaultLabel = "Структура церкви";
      if (functions.includes("administrator_member_modifier")) {
        return defaultLabel;
      }
      if (functions.includes("administrator_structure_member_modifier")) {
        switch (memberType) {
          case "EPARCHY":
            return "Епархия";
          case "HRAM":
            return "Мой храм";
          case "DEANERY":
            return "Благочиние";
          case "MITROPOLIA":
            return "Митрополия";
          case "VICARIATE":
            return "Викариатство";
          default:
            return defaultLabel;
        }
      }
      if (
        functions.includes("administrator_hram_member_modifier") ||
        functions.includes("priest_member_view")
      ) {
        return "Мой храм";
      }
      return defaultLabel;
    },
    icon: Home,
    page: churchStructure
  },
  serviceMan: {
    label: "Служители",
    icon: Group,
    page: users
  },
  library: {
    label: "Информация",
    icon: LibraryBooks,
    page: library
  },
  directory: { label: "Справочники", icon: ViewList, pages: { actionType } },
  settings: {
    label: "Настройки",
    icon: Settings,
    page: settings
  },
  faq: {
    label: "Вопросы и ответы",
    icon: QuestionAnswer,
    page: faq
  },
  statistics: {
    label: "Статистика",
    icon: InsertChart,
    page: statistics
  },
  utility: {
    // Она не появится ни у кого я в ней храню модалки, которые не могу отнести ни к 1 катергории
    label: "Утилитарная категория",
    hidden: true,
    page: utility
  }
};
