import { useDispatch } from "react-redux";
import { useMemo } from "react";
import { CancelTokenSource } from "axios";
import { FormikValues } from "formik";
import { isWithinInterval } from "date-fns";
import {
  ModalWithOptionalKey,
  pushHeadModal,
  ScheduleButtonsVariants,
  setScheduleButtonsStyle
} from "../../../actions/typedActions";
import { CollectionType, SelectedRangeT } from "../types";
import { OptionT } from "../../../constants/types";
import { getSelectedDatesStartFinish } from "../constructParams";
import { parseRussianDate } from "../../../constants/formaters/formatters";
import { downLoadCollection } from "./index";

export function useMemorizedActions() {
  const dispatch = useDispatch();
  return useMemo(() => {
    return {
      openModal: (m: ModalWithOptionalKey) => dispatch(pushHeadModal(m)),
      setButtonsStyle: (v: ScheduleButtonsVariants) =>
        dispatch(setScheduleButtonsStyle(v))
    };
  }, [dispatch]);
}

export function useSuccessCallback(
  selectedRange: SelectedRangeT,
  date: Date,
  setCollectionIsLoading: (v: boolean) => void,
  urlToDownloadCollection: string,
  isPersonal: boolean,
  showAllEvents: boolean,
  selectedPerformer: OptionT | null,
  selectedEventType: OptionT | null,
  setCollection: (d: CollectionType) => void,
  source: CancelTokenSource,
  setEverythingPublished: (v: boolean) => void,
  userId?: number | null,
): (values?: FormikValues) => void {
  return useMemo(
    () => (values?: FormikValues) => {
      const potentiallyAffectedDates: string[] | Date[] | undefined =
        values && values.selectedDates;

      if (Array.isArray(potentiallyAffectedDates)) {
        const [start, end] = getSelectedDatesStartFinish(selectedRange, date);
        if (
          potentiallyAffectedDates.some((value: Date | string) =>
            isWithinInterval(
              typeof value === "string" ? parseRussianDate(value) : value,
              { start, end }
            )
          )
        ) {
          // todo сделать метод на беке принимающий массив дней чтоб можно было конкретно обновлять затронутые дни
          downLoadCollection(
            setCollectionIsLoading,
            urlToDownloadCollection,
            selectedRange,
            date,
            isPersonal,
            showAllEvents,
            selectedPerformer,
            selectedEventType,
            setCollection,
            source,
            setEverythingPublished,
            userId,
          );
        }
      } else {
        // безусловное скачивание объектов для всего диапозона дат
        downLoadCollection(
          setCollectionIsLoading,
          urlToDownloadCollection,
          selectedRange,
          date,
          isPersonal,
          showAllEvents,
          selectedPerformer,
          selectedEventType,
          setCollection,
          source,
          setEverythingPublished,
          userId,
        );
      }
    },
    [
      date,
      isPersonal,
      selectedEventType,
      selectedPerformer,
      selectedRange,
      setCollection,
      setCollectionIsLoading,
      setEverythingPublished,
      showAllEvents,
      source,
      urlToDownloadCollection,
      userId,
    ]
  );
}
