import { baseJournal } from "./basejournal";
import { PageT } from "../../types";
import { FiberNew } from "@material-ui/icons";
import { getRecoilExternalLoadable } from "../../../store/recoilAdapter";
import { overUserAtom, OverUserValueT } from "../../../store/userStore";
import { addPerformer } from "../claim/inWork/journal";

export const getPendingSlotsCountUrl = async () => {
  let url = "/api/slot/journal/count";
  const overUserAt = await getRecoilExternalLoadable<OverUserValueT>(
    overUserAtom
  );
  if (overUserAt) {
    if (overUserAt.contents) {
      url += "?userId=" + overUserAt.contents.value;
    }
  }
  return url;
};

const reserved: PageT = {
  ...baseJournal,
  icon: FiberNew,
  navigationLabel: "Запросы на беседу",
  badge: { url: getPendingSlotsCountUrl },
  url: () => addPerformer("/api/slot/journal/reserved")
};
export default reserved;
