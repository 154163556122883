import React from "react";
import { shallowEqual } from "react-redux";
import { Dialog } from "@material-ui/core";
import { ModalWrapperProps } from "./types";

const ModalWrapper = ({
  config: {
    component: InnerComponent,
    functionsToExecute = [],
    forwardedProps,
    maxWidth,
    ...restConfigProps
  },
  hideModal,
  modal,
  ...rest
}: ModalWrapperProps) => {
  const additionalPassedProps: any = {};
  functionsToExecute.forEach(({ func, passResultAs }) => {
    additionalPassedProps[passResultAs] = func(modal);
  });
  return (
    <Dialog
      open
      disableEscapeKeyDown
      disableBackdropClick
      fullWidth
      maxWidth={maxWidth || "lg"}
    >
      <InnerComponent
        {...forwardedProps}
        closeHandler={hideModal}
        {...restConfigProps}
        {...additionalPassedProps}
        modal={modal}
        {...rest}
      />
    </Dialog>
  );
};

export default React.memo(ModalWrapper, shallowEqual);
