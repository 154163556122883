import { localizedYup, timeSchema } from "../../../constants/yup";

export const scheduleTableValidationSchema = localizedYup.object().shape({
  days: localizedYup.array(
    localizedYup.object({
      events: localizedYup.array(
        localizedYup.object({
          to: timeSchema
            // @ts-ignore
            .compareTimeTo(localizedYup.ref("from"))
            .required(" "),
          from: timeSchema.required(" "),
          type: localizedYup
            .object()
            .nullable()
            .required(),
          typeEnum: localizedYup.string(),
          performers: localizedYup
            .array(localizedYup.object())
            .min(1, "Введите имя священнослужителя")
            .nullable()
            .required()
        })
      )
    })
  )
});
