import {
  baseTextAreaProps,
  baseTimeFieldProps,
  downLoadFileFromFileSystemProps,
  uploadFileInFileSystem
} from "../../baseFieldProps";
import { isTimeAfter, localizedYup, timeSchema } from "../../yup";
import { fullWidth } from "../../widths";
import { time24HMaskWithOutSeconds } from "../../masks";
import { ExtendedBlockFormObj } from "../../../components/modalWrapper/types";

export const hramTabFormObj: ExtendedBlockFormObj = {
  url: "/api/hram",
  blocks: [
    {
      blockTitle: "",
      fields: [
        {
          label: "Фотография",
          name: "photo",
          type: "file",
          doBeforeSetFieldValue: uploadFileInFileSystem("hram"),
          ...downLoadFileFromFileSystemProps,
          widths: fullWidth
        },
        {
          label: "Алтари храма",
          name: "description",
          ...baseTextAreaProps
        },
        {
          label: "Веб-страница",
          name: "webUrl",
          type: "text"
        },
        {
          label: "Активность",
          name: "isActive",
          type: "text",
          readOnly: true,
          rebuildReceivedValue: value => {
            return value ? "Да" : "Нет";
          },
          doBeforeSend: v => v==='Да'
        },
        {
          label: "Контакты",
          name: "contacts",
          ...baseTextAreaProps
        },
        {
          label: "Адрес электронной почты",
          name: "email",
          ...baseTextAreaProps
        },
        {
          label: "История",
          name: "history",
          ...baseTextAreaProps
        },
        {
          label: "Святыни",
          name: "religion",
          ...baseTextAreaProps
        },
        {
          label: "Время начало работы Храма",
          name: "startWorkTime",
          ...baseTimeFieldProps
        },
        {
          label: "Время окончания работы Храма",
          name: "finishWorkTime",
          ...baseTimeFieldProps
        },
        {
          label: "Используются смены",
          name: "isShift",
          type: "checkbox"
        },
        {
          name: "shifts",
          type: "fieldArray",
          label: "Смены",
          fields: [
            { name: "name", type: "text", label: "Название смены" },
            {
              type: "formatted",
              mask: time24HMaskWithOutSeconds,
              name: "startTime",
              label: "Начало"
            },
            {
              type: "formatted",
              mask: time24HMaskWithOutSeconds,
              name: "finalTime",
              label: "Окончание"
            }
          ],
          conditionallyRender: values =>
            Array.isArray(values.shifts) && values.isShift,
          defaultValue: [],
          rebuildReceivedValue: v => (v ? JSON.parse(v) : []),
          doBeforeSend: v => JSON.stringify(v),
          widths: fullWidth
        },
        {
          label: "Отображение священнослужителей при записи на беседу",
          name: "visiblePriests",
          type: "checkbox"
        }
      ]
    },
    {
      blockTitle: "Блок деятельности",
      fields: [
        {
          label: "Образовательная",
          name: "workEducation",
          ...baseTextAreaProps
        },
        {
          label: "Социальная",
          name: "workSocial",
          ...baseTextAreaProps
        },
        {
          label: "Миссионерская",
          name: "workMission",
          ...baseTextAreaProps
        },
        {
          label: "Другая",
          name: "workElse",
          ...baseTextAreaProps
        }
      ]
    }
  ],
  validationSchema: localizedYup.object({
    photo: localizedYup.object().nullable(),
    description: localizedYup.string(),
    webUrl: localizedYup.string().nullable(),
    contacts: localizedYup.string(),
    history: localizedYup.string(),
    religion: localizedYup.string(),
    startWorkTime: localizedYup.string().required(),
    finishWorkTime: localizedYup.string().required(),
    visiblePriests: localizedYup.bool(),
    isShift: localizedYup.bool(),
    shifts: localizedYup.array().when("isShift", {
      is: true,
      then: localizedYup
        .array()
        .nullable()
        .of(
          localizedYup.object({
            name: localizedYup.string().required(),
            startTime: localizedYup.string().required(),
            finalTime: timeSchema
              .required()
              .test(
                "shiftError",
                "Время окончания смены должно быть позднее её начала",
                function(this, value) {
                  return isTimeAfter(value, this.parent.startTime);
                }
              )
          })
        )
        .test("shiftArrayError", "", function(this, shifts) {
          let result = true;
          if (shifts) {
            for (let i = 0; i < shifts.length; i++) {
              result = isTimeAfter(
                  shifts[i]?.startTime,
                  shifts[i - 1]?.finalTime,
                  true
              );
              if (!result) {
                return this.createError({
                  path: `shifts[${i}].startTime`,
                  message:
                      "Время начала новой смены должно быть позднее окончания предыдущей"
                });
              }
            }
          }
          return result;
        })
    })
  })
};
