import { useEffect, useMemo } from "react";
import axios, {
  AxiosPromise,
  AxiosRequestConfig,
  CancelTokenSource,
} from "axios";
import { isAfter, isSameDay, startOfWeek } from "date-fns";
import { ArrayHelpers, FormikProps, FormikValues } from "formik";
import { get } from "lodash";
import {
  CollectionType,
  EventType,
  HiddenColumnsT,
  PerformerResponseT,
  PerformerT,
  RowType,
  SelectedEventType,
  SelectedRangeT,
} from "../types";
import { OpenModalT } from "../../../actions/typedActions";
import { addDateRangeParams, constructParams } from "../constructParams";
import { defaultErrorHandler } from "../../../utils/defaultErrorHandler";
import {
  mapLabelAsLabelCodeAsValue,
  rebuildReceivedOptionsLabelAsLabelUniqueIdAsValueAddMainPerformerFlag,
  rebuildReceivedOptionsLabelAsLabelUniqueIdAsValueAddMainPerformerFlagIsDailyDutyOnly,
} from "../../../constants/rebuildRecieved";
import {
  baseDateFnsOptions,
  formatToRussianDate,
  parseRussianDate,
} from "../../../constants/formaters/formatters";
import { rebuildMinistrationPerformersBeforeSend } from "../../../constants/pages/schedule/createMinistration";
import { OptionsMapType, OptionT } from "../../../constants/types";
import { useRecoilValue } from "recoil";
import { overUserAtom } from "../../../store/userStore";

export const hramPriestsIsDailyDutyOnly = "hramPriestsIsDailyDutyOnly";

export const scheduleEventsNaturalOrder = (
  { from: fromA }: EventType,
  { from: fromB }: EventType
) => {
  if (fromA > fromB) {
    return 1;
  }
  if (fromA < fromB) {
    return -1;
  }
  // a должно быть равным b
  return 0;
};

export function useCreationMaster(
  masterCreation: boolean,
  selectedRange: SelectedRangeT,
  date: Date,
  openModal: OpenModalT,
  successCallback: () => void
): void {
  // выполняет провекрку нужно ли открыть мастер создания
  useEffect(() => {
    if (masterCreation) {
      const source = axios.CancelToken.source();
      axios
        .get<boolean>(
          `/api/events/log/check?${addDateRangeParams(selectedRange, date)}`,
          {
            cancelToken: source.token,
          }
        )
        .then(({ data }) => {
          if (data) {
            openModal({
              mode: "scheduleCreationMaster",
              category: "schedule",
              initialValues: {
                date: formatToRussianDate(
                  startOfWeek(date, baseDateFnsOptions)
                ),
              },
              successCallback,
            });
          }
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            defaultErrorHandler(error);
          }
        });
      return () => {
        source.cancel("Cancelling in cleanup");
      };
    }
  }, [date, openModal, selectedRange, masterCreation, successCallback]);
}

export const mapLabelAsLabelUniqueIdAsValue = ({
  label,
  uniqueId,
}: {
  label: string;
  uniqueId: number;
}): OptionT => ({
  label,
  value: uniqueId,
});

export const eventTypeRebuild = (data: any[]): OptionT[] =>
  data.map(mapLabelAsLabelCodeAsValue);

export const objsToDownload = [
  {
    name: "allEventTypes",
    url: "/api/events/log/action",
    rebuildReceived: eventTypeRebuild,
  },
  {
    name: "slotEventTypes",
    url: "/api/events/log/slot",
    rebuildReceived: eventTypeRebuild,
  },
  {
    name: "ministrationEventTypes",
    url: "/api/ministration/type",
    rebuildReceived: eventTypeRebuild,
  },
  {
    name: "hramPriests",
    url: "/api/ministration/priest",
    rebuildReceived:
      rebuildReceivedOptionsLabelAsLabelUniqueIdAsValueAddMainPerformerFlag,
  },
  {
    name: "personalPriests",
    url: "/api/events/log/priestByUser",
    rebuildReceived:
      rebuildReceivedOptionsLabelAsLabelUniqueIdAsValueAddMainPerformerFlag,
  },
];

export const getEmptyOptionsMap = () => {
  const emptyOptionsMap: OptionsMapType = {};
  objsToDownload.forEach(({ name }) => {
    emptyOptionsMap[name] = { loaded: false, options: [] };
  });
  return emptyOptionsMap;
};

export function useOptionsMapDownloader(
  setOptionsMap: (v: OptionsMapType) => void,
  isPersonal?: boolean
): void {
  // загружает данные для выпадалок
  useEffect(() => {
    const source = axios.CancelToken.source();
    axios
      .all(
        objsToDownload.map(({ url }) =>
          axios.get(url, { cancelToken: source.token })
        )
      )
      .then((response) => {
        /* setEventTypes(typesResponse.data.map(mapLabelAsLabelUniqueIdAsValue));
        setPriests(
          rebuildReceivedOptionsLabelAsLabelUniqueIdAsValueAddMainPerformerFlag(
            priestsResponse.data
          )
        ); */
        const resultingOptionsMap: OptionsMapType = {};
        response.forEach(({ data }, index) => {
          const { rebuildReceived, name } = objsToDownload[index];
          resultingOptionsMap[name] = rebuildReceived
            ? { loaded: true, options: rebuildReceived(data) }
            : { loaded: true, options: data };
          if (name === "hramPriests") {
            resultingOptionsMap[hramPriestsIsDailyDutyOnly] = {
              loaded: true,
              options:
                rebuildReceivedOptionsLabelAsLabelUniqueIdAsValueAddMainPerformerFlagIsDailyDutyOnly(
                  resultingOptionsMap[name].options as any
                ),
            };
          }
        });
        setOptionsMap(resultingOptionsMap);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          defaultErrorHandler(error);
        }
      });
    return () => {
      source.cancel("Cancelling in cleanup");
    };
  }, [setOptionsMap]);
}

export function useResetFilters(
  setShowAllEvents: (v: boolean) => void,
  setSelectedEventType: (v: OptionT | null) => void,
  setSelectedPerformer: (v: OptionT | null) => void,
  isPersonal: boolean
): void {
  // сбрасывает Фильтрацию при переходе между журналами
  useEffect(() => {
    setShowAllEvents(false);
    setSelectedEventType(null);
    if (!isPersonal) {
      setSelectedPerformer(null);
    }
  }, [
    isPersonal,
    setSelectedEventType,
    setSelectedPerformer,
    setShowAllEvents,
  ]);
}

export const mapPerformerFormBackEnd = ({
  isMainPerformer,
  isExternal,
  label,
  value,
}: PerformerResponseT): PerformerT => {
  const resultingPerformer: PerformerT = {
    label,
    value: isExternal ? value : Number.parseInt(value, 10),
    isMainPerformer,
  };
  if (isExternal) {
    // eslint-disable-next-line no-underscore-dangle
    resultingPerformer.__isNew__ = isExternal;
  }
  return resultingPerformer;
};

export const mapPerformersFromBackEnd = (
  performers: PerformerResponseT[]
): PerformerT[] =>
  Array.isArray(performers) ? performers.map(mapPerformerFormBackEnd) : [];

const mapEventsFromBackEnd = (
  events: EventType<PerformerResponseT>[]
): EventType[] =>
  events.map((ev) => ({
    ...ev,
    performers: mapPerformersFromBackEnd(ev.performers),
  }));

export function adaptScheduleCollection(
  data: CollectionType<PerformerResponseT>
): CollectionType {
  return data.map((row) => {
    return {
      ...row,
      events: mapEventsFromBackEnd(row.events),
    };
  });
}

export function downLoadCollection(
  setIsLoading: (v: boolean) => void,
  urlToDownloadCollection: string,
  selectedRange: SelectedRangeT,
  date: Date,
  isPersonal: boolean,
  showAllEvents: boolean,
  selectedPerformer: OptionT | null,
  selectedEventType: OptionT | null,
  setCollection: (v: CollectionType) => void,
  source: CancelTokenSource,
  setEverythingPublished: (v: boolean) => void,
  userId?: number | null
): void {
  setIsLoading(true);
  const url = `${urlToDownloadCollection}${constructParams(
    selectedRange,
    date,
    isPersonal,
    showAllEvents,
    selectedPerformer,
    selectedEventType,
    userId
  )}`;
  axios
    .get<CollectionType<PerformerResponseT>>(url, {
      cancelToken: source.token,
    })
    .then(({ data }) => {
      if (data && Array.isArray(data)) {
        setCollection(adaptScheduleCollection(data));
        // value day '01.01.2020 00:00' - время не меняется т.к. оно есть только у события
        setEverythingPublished(
          !data.some((value) => {
            const dateLeft = parseRussianDate(value.day);
            const dateRight = new Date();
            return (
              !value.everythingPublished &&
              (isSameDay(dateLeft, dateRight) || isAfter(dateLeft, dateRight))
            );
          })
        );
        setIsLoading(false);
      }
    })
    .catch((error) => {
      if (!axios.isCancel(error)) {
        defaultErrorHandler(error);
        setIsLoading(false);
      }
    });
}

export function useGetInitialValues(
  setIsLoading: (v: boolean) => void,
  urlToDownloadCollection: string,
  selectedRange: SelectedRangeT,
  date: Date,
  isPersonal: boolean,
  showAllEvents: boolean,
  selectedPerformer: OptionT | null,
  selectedEventType: SelectedEventType,
  setCollection: (v: CollectionType) => void,
  // source: CancelTokenSource,
  setEverythingPublished: (v: boolean) => void,
  userId?: number | null
): void {
  // загружает коллецкию при измении Выбранной даты, диапозона, типа события, или выборе конкретного свещеннослужителя
  useEffect(() => {
    const source = axios.CancelToken.source();
    downLoadCollection(
      setIsLoading,
      urlToDownloadCollection,
      selectedRange,
      date,
      isPersonal,
      showAllEvents,
      selectedPerformer,
      selectedEventType,
      setCollection,
      source,
      setEverythingPublished,
      userId
    );
    return () => {
      source.cancel("Cancelling in cleanup");
    };
  }, [
    date,
    selectedRange,
    showAllEvents,
    selectedPerformer,
    selectedEventType,
    urlToDownloadCollection,
    isPersonal,
    setIsLoading,
    setCollection,
    setEverythingPublished,
    userId,
  ]);
}

export function useJournalConfiguration(
  userFunctions: string[],
  isPersonal: boolean
) {
  return useMemo(() => {
    const allActionsAllowed = userFunctions.some(
      (x) => x === "administrator_hram_schedule_modifier"
    );

    const hiddenColumns: HiddenColumnsT = {
      monthWord: isPersonal,
      duties: isPersonal,
      day: !isPersonal,
      priest: isPersonal,
    };

    return {
      hasAdminRights: allActionsAllowed,
      ministrationConfig: {
        createBtn: {
          show: !isPersonal,
          disabled: !allActionsAllowed,
        },
      },
      slotConfig: {
        createBtn: {
          show: true,
          disabled: !allActionsAllowed && !isPersonal,
        },
      },
      workItemConfig: {
        createBtn: {
          show: true,
          disabled: !allActionsAllowed && !isPersonal,
        },
      },
      copyBtnConfig: {
        show: true,
        disabled: !allActionsAllowed && !isPersonal,
      },
      tableReadOnly: !isPersonal && !allActionsAllowed,
      masterCreation: allActionsAllowed && !isPersonal,
      hiddenColumns,
      filters: {
        showAllTypes: !isPersonal,
        priests: !isPersonal,
      },
    };
  }, [userFunctions, isPersonal]);
}

export function useMemorizedRemove(
  uniqueId: number | string,
  remove: (i: number) => void,
  eventIndex: number,
  typeEnum: EventType["typeEnum"],
  openSnackBarWithMessage: (m: string) => void
) {
  return useMemo(() => {
    return (): void => {
      if (typeof uniqueId === "string") {
        remove(eventIndex);
      } else {
        switch (typeEnum) {
          case "ministration": {
            axios
              .delete(`/api/ministration/${uniqueId}`)
              .then(({ data }): void => {
                remove(eventIndex);
                openSnackBarWithMessage(`Богослужение №${uniqueId} удалено`);
              })
              .catch((reason): void => {
                defaultErrorHandler(reason);
              });
            break;
          }
          case "slot": {
            axios
              .delete(`/api/slots/${uniqueId}`)
              .then(({ data }): void => {
                remove(eventIndex);
                openSnackBarWithMessage(`Слот №${uniqueId} удален`);
              })
              .catch((reason): void => {
                defaultErrorHandler(reason);
              });
            break;
          }
          case "workItem": {
            axios
              .delete(`/api/workitem/delete/${uniqueId}`)
              .then(({ status }): void => {
                if (status === 200) {
                  remove(eventIndex);
                  openSnackBarWithMessage(`Дело №${uniqueId} удалено`);
                }
              })
              .catch((reason): void => {
                defaultErrorHandler(reason);
              });
            break;
          }
          default:
            break;
        }
      }
    };
  }, [eventIndex, openSnackBarWithMessage, remove, typeEnum, uniqueId]);
}

const createRequest: <
  T = EventType<PerformerResponseT> | EventType<PerformerResponseT>[]
>(
  url: string,
  uniqueId: number | string,
  obj: any
) => AxiosPromise<T> = (url, uniqueId, obj) => {
  const requestConf: AxiosRequestConfig = { data: obj };
  if (typeof uniqueId === "string") {
    requestConf.url = url;
    requestConf.method = "post";
  } else {
    requestConf.url = `${url}/${uniqueId}`;
    requestConf.method = "patch";
  }
  return axios(requestConf);
};

export function useMemorizedEventSubmit(
  typeEnum: EventType["typeEnum"],
  isPersonal: boolean,
  setFieldTouched: FormikProps<FormikValues>["setFieldTouched"],
  rowIndex: number,
  eventIndex: number,
  validateForm: () => void,
  type: OptionT | null,
  rowData: RowType,
  from: string,
  to: string,
  performers: PerformerT[],
  description: string,
  uniqueId: number | string,
  setFieldValue: FormikProps<FormikValues>["setFieldValue"],
  openSnackBarWithMessage: (m: string) => void,
  replace: ArrayHelpers["replace"],
  published: boolean,
  setEverythingPublished: (v: boolean) => void
) {
  const overUser = useRecoilValue(overUserAtom);
  return useMemo(() => {
    return async (): Promise<void> => {
      if (
        (typeEnum === "ministration" && !isPersonal) ||
        (typeEnum === "slot" && isPersonal)
      ) {
        setFieldTouched(`days.${rowIndex}.events.${eventIndex}.to`);
        setFieldTouched(`days.${rowIndex}.events.${eventIndex}.from`);
        setFieldTouched(`days.${rowIndex}.events.${eventIndex}.type`);
        setFieldTouched(`days.${rowIndex}.events.${eventIndex}.performers`);
        // validateField(`days.${rowIndex}.events.${eventIndex}.to`);
        const newErrors = await validateForm();

        if (type && !get(newErrors, `days.${rowIndex}.events.${eventIndex}`)) {
          const dateTimestamp = `${rowData.day} ${
            from.length > 5 ? from : `${from}:00`
          }`;
          const dateEndTimestamp = `${rowData.day} ${
            to.length > 5 ? to : `${to}:00`
          }`;
          let promise;

          switch (typeEnum) {
            case "ministration": {
              const newMinistration = {
                objectTypeCode: type.value,
                performers: rebuildMinistrationPerformersBeforeSend(performers),
                dateTimestamp,
                dateEndTimestamp,
                description,
                isReport: true,
                published,
              };
              promise = createRequest(
                typeof uniqueId === "string"
                  ? // у помощника при сохранении богослужения указываем того священника, кому помогаем
                    overUser
                    ? `/api/ministration/schedule?authorId=${overUser.userId}`
                    : "/api/ministration/schedule"
                  : "/api/ministration",
                uniqueId,
                newMinistration
              );
              break;
            }
            case "slot": {
              const newSlot = {
                codeCommonAction: type.value,
                performerId:
                  //для помошника отправляем всегда того кому помагаем
                  overUser
                    ? overUser.value
                    : Array.isArray(performers)
                    ? performers[0].value
                    : null,
                isReport: true,
                fromTime: `${from}:00`,
                toTime: `${to}:59`,
                days: [rowData.day],
                comment: description,
                published,
              };
              promise = createRequest("/api/slots", uniqueId, newSlot);
              break;
            }
            default: {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const never: never = typeEnum;
            }
          }

          if (promise) {
            promise
              .then(({ data }): void => {
                if (data) {
                  if (Array.isArray(data)) {
                    setFieldValue(
                      `days.${rowIndex}.events`,
                      [
                        ...rowData.events.filter(
                          ({ uniqueId }) => typeof uniqueId === "number"
                        ),
                        ...mapEventsFromBackEnd(data),
                      ].sort(scheduleEventsNaturalOrder),
                      false
                    );
                  } else {
                    setFieldValue(
                      `days.${rowIndex}.events`,
                      [
                        ...rowData.events.filter(
                          (ev) => ev.uniqueId !== uniqueId
                        ),
                        {
                          ...data,
                          performers: mapPerformersFromBackEnd(data.performers),
                        },
                      ].sort(scheduleEventsNaturalOrder),
                      false
                    );
                  }

                  let objName = "";
                  switch (typeEnum) {
                    case "slot": {
                      objName = "Беседа";
                      break;
                    }
                    case "ministration": {
                      setEverythingPublished(false);
                      objName = "Богослужение";
                      break;
                    }
                    default: {
                      // eslint-disable-next-line @typescript-eslint/no-unused-vars
                      const never: never = typeEnum;
                    }
                  }
                  openSnackBarWithMessage(
                    `Объект "${objName}" был успешно ${
                      typeof uniqueId === "string" ? "создан" : "изменен"
                    }`
                  );
                }
              })
              .catch((reason): void => {
                defaultErrorHandler(reason);
              });
          }
        }
      }
    };
  }, [
    description,
    eventIndex,
    from,
    isPersonal,
    openSnackBarWithMessage,
    performers,
    published,
    rowData.day,
    rowData.events,
    rowIndex,
    setFieldTouched,
    setFieldValue,
    to,
    type,
    typeEnum,
    uniqueId,
    validateForm,
  ]);
}

export function openEventCard(
  uniqueId: number | string,
  typeEnum: EventType["typeEnum"],
  hasAdminRights: boolean,
  canEditEvent: boolean,
  openModal: OpenModalT,
  uuid: string,
  isPersonal: boolean,
  successCallback: () => void
) {
  if (typeof uniqueId === "number") {
    let mode = "";
    let readOnlyFields: string[] = [];
    if (typeEnum === "slot") {
      mode = "viewSlot";
    } else if (typeEnum === "ministration") {
      readOnlyFields = ["dateTimestamp", "dateEndTimestamp"];
      mode =
        hasAdminRights && canEditEvent
          ? "editMinistration"
          : "viewMinistration";
    } else if (typeEnum === "workItem") {
      readOnlyFields = ["dateTimestamp", "dateEndTimestamp"];
      mode = canEditEvent ? "editWorkItem" : "viewWorkItem";
    }
    openModal({
      category: "schedule",
      mode,
      item: { uniqueId, uuid, isPersonal },
      readOnlyFields,
      successCallback,
      initialValues: { isPersonal },
    });
  }
}
