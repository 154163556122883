import { computePropsForFormikMUI } from "../../../components/modalWrapper/utils/computePropsForForMikMUI";
import { viewSlot } from "../schedule/viewSlot";
import { PageT } from "../../types";

export const baseJournal: PageT = {
  requiresFunction: [
    {
      functionName: "administrator_hram_claim_view",
      allowedActions: ["view"]
    },
    {
      functionName: "priest_claim_view",
      allowedActions: ["view"]
    },
    {
      functionName: "assistant_claim_view",
      allowedActions: ["view"]
    }
  ],
  url: "/api/slot/journal/reserved",
  additionalModalProps: {
    allowedAllActions: true
  },
  filtersPanel: {
    filters: [
      {
        label: "Дата начала/окончания",
        type: "date-range",
        name: "rangeEvent",
        doBeforeSend: (value: string) => {
          const lastIndexOfDelimiter = value.lastIndexOf(" - ");
          if (
            lastIndexOfDelimiter !== -1 &&
            lastIndexOfDelimiter + 3 < value.length
          ) {
            return [
              value.slice(0, lastIndexOfDelimiter),
              value.slice(lastIndexOfDelimiter + 3)
            ];
          }
          return undefined;
        }
      },
      {
        name: "fullname",
        type: "text",
        label: "ФИО мирянина"
      }
    ]
  },
  tableHeader: [
    { path: "uniqueId", label: "Номер беседы" },
    { path: "fromTimestamp", label: "Время начала" },
    { path: "toTimestamp", label: "Время окончания" },
    { path: "fullname", label: "ФИО мирянина" },
  ],
  forms: {
    view: computePropsForFormikMUI({
      formObj: viewSlot,
      mode: "view"
    })
  }
};
