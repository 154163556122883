import { ScheduleJournal } from "../../../components/schedule/scheduleJournal";
import { adaptFormObjToModalWindow } from "../../utils";
import { createSlot } from "../schedule/createSlot";
import { createWorkItem } from "../schedule/createWorkItem";
import { copySchedule } from "../schedule/copySchedule";
import { computePropsForFormikMUI } from "../../../components/modalWrapper/utils/computePropsForForMikMUI";
import { viewSlot } from "../schedule/viewSlot";
import { viewMinistration } from "../schedule/viewMinistration";
import { viewWorkItem } from "../schedule/viewWorkItem";
import { PageT } from "../../types";

export const personalSchedule: PageT = {
  requiresFunction: [
    {
      functionName: "administrator_hram_schedule_modifier",
      allowedActions: ["view"]
    },
    {
      functionName: "priest_hram_schedule_view",
      allowedActions: ["view"]
    },
    {
      functionName: "assistant_hram_schedule_view",
      allowedActions: ["view"]
    }
  ],
  passProps: {
    confObj: {
      title: "Личное расписание богослужений и дежурств в храме:",
      urlToDownloadCollection: "/api/events/log/priest?",
      isPersonal: true
    }
  },
  Component: ScheduleJournal,
  forms: {
    createSlot: adaptFormObjToModalWindow({
      formObj: createSlot,
      maxWidth: "sm"
    }),
    createWorkItem: adaptFormObjToModalWindow({
      formObj: createWorkItem,
      maxWidth: "md"
    }),
    copySchedule: adaptFormObjToModalWindow({
      formObj: copySchedule,
      maxWidth: "sm"
    }),
    viewSlot: computePropsForFormikMUI({ formObj: viewSlot, mode: "view" }),
    viewMinistration: computePropsForFormikMUI({
      formObj: viewMinistration,
      mode: "view"
    }),
    viewWorkItem: computePropsForFormikMUI({
      formObj: viewWorkItem,
      mode: "view"
    }),
    editWorkItem: computePropsForFormikMUI({
      formObj: viewWorkItem,
      mode: "edit"
    })
  }
};
