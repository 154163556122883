import { AnyAction } from "redux";
import { FormikConfig, FormikValues } from "formik";
import { OptionsObject } from "notistack";
import {
  CLOSE_ALL_SNACKBARS,
  CLOSE_CONFIRM_DIALOG_FORMIK,
  CLOSE_SNACKBAR_BY_ID,
  DISABLE_PRIMARY_BTN_CONFIRM_DIALOG,
  HIDE_CONFIRM_DIALOG,
  OPEN_CONFIRM_DIALOG_FORMIK,
  SET_ALLOWED_ACTIONS,
  SET_BADGES_CONTENT,
  SET_IS_TEST_ENVIRONMENT,
  SET_UNREAD_MESSAGES_COUNT,
  TOGGLE_CONFIRM_DIALOG,
  TOGGLE_SNACKBAR
} from "../constants/actionTypeConstants";
import { AllowedActionsT, BlockFormObj } from "../constants/types";
import { FormikMUIProps } from "../components/formikMUi/formikNewRealisation";

const confirmDialog = {
  open: false,
  title: "Подтвердите действие",
  mainText: "",
  primaryButtonText: "Да",
  secondaryButtonText: "Нет",
  primaryButtonHandler: () => {}
};
const confirmDialogFormik: ConfirmDialogFormikT = {
  open: false,
  title: "Подтвердите действие",
  primaryButtonText: "Подтвердить",
  secondaryButtonText: "Отменить",
  primaryButtonHandler: () => {},
  formObj: {
    blocks: []
  }
};

export type ConfirmDialogFormikT = {
  open: boolean;
  title: string;
  primaryButtonText: string;
  secondaryButtonText?: string;
  primaryButtonHandler: FormikConfig<FormikValues>["onSubmit"];
  formObj: BlockFormObj;
} & Partial<FormikMUIProps>;

const initialState = {
  notifications: [],
  confirmDialog,
  confirmDialogFormik,
  allowedActions: {},
  badgesContent: {},
  unreadMessagesCount: 0,
  isTestEnvironment: false
};

export interface ConfirmDialogT {
  open: boolean;
  title: string;
  mainText: string;
  primaryButtonText: string;
  secondaryButtonText: string;
  primaryButtonHandler: FormikConfig<FormikValues>["onSubmit"];
  mainContent?: any;
  primaryButtonDisabled?: boolean;
}

export type AllowedJournalActionsT = Record<
  string,
  AllowedActionsT[] | Record<string, AllowedActionsT>
>;

export interface BadgesContentT {
  [x: string]:
    | number
    | {
        [y: string]: number;
      };
}

export interface NotificationT {
  key: string;
  message: string;
  options?: OptionsObject;
}

export interface WrapperReducerState {
  notifications: NotificationT[];
  confirmDialog: ConfirmDialogT;
  confirmDialogFormik: ConfirmDialogFormikT & Partial<FormikMUIProps>;
  allowedActions: AllowedJournalActionsT;
  badgesContent: BadgesContentT;
  unreadMessagesCount: number;
  isTestEnvironment: boolean;
}

export default function wrapperReducer(
  state: WrapperReducerState = initialState,
  action: AnyAction
): WrapperReducerState {
  switch (action.type) {
    case TOGGLE_SNACKBAR: {
      return {
        ...state,
        notifications: [
          ...(state.notifications ? [...state.notifications] : []),
          action.payload
        ]
      };
    }
    case CLOSE_ALL_SNACKBARS: {
      return { ...state, notifications: [] };
    }
    case CLOSE_SNACKBAR_BY_ID: {
      return {
        ...state,
        notifications: state.notifications.filter(
          ({ key }) => key !== action.payload
        )
      };
    }
    case TOGGLE_CONFIRM_DIALOG: {
      const {
        open,
        title,
        mainContent,
        primaryButtonHandler,
        mainText,
        primaryButtonText,
        secondaryButtonText,
        primaryButtonDisabled
      } = action.payload;
      return {
        ...state,
        confirmDialog: {
          ...state.confirmDialog,
          title,
          open,
          mainContent,
          primaryButtonHandler,
          mainText,
          primaryButtonText:
            primaryButtonText || confirmDialog.primaryButtonText,
          secondaryButtonText:
            secondaryButtonText || confirmDialog.secondaryButtonText,
          primaryButtonDisabled: !!primaryButtonDisabled
        }
      };
    }
    case DISABLE_PRIMARY_BTN_CONFIRM_DIALOG: {
      const { primaryButtonDisabled } = action.payload;
      return {
        ...state,
        confirmDialog: {
          ...state.confirmDialog,
          primaryButtonDisabled
        }
      };
    }
    case HIDE_CONFIRM_DIALOG: {
      return {
        ...state,
        confirmDialog: { ...confirmDialog }
      };
    }
    case OPEN_CONFIRM_DIALOG_FORMIK: {
      const { open, formObj, ...rest } = action.payload;
      return {
        ...state,
        confirmDialogFormik: {
          ...confirmDialogFormik,
          open,
          formObj,
          ...rest
        }
      };
    }
    case CLOSE_CONFIRM_DIALOG_FORMIK: {
      return {
        ...state,
        confirmDialogFormik
      };
    }
    case SET_ALLOWED_ACTIONS: {
      return {
        ...state,
        allowedActions: action.payload
      };
    }
    case SET_BADGES_CONTENT: {
      return {
        ...state,
        badgesContent: action.payload
      };
    }
    case SET_UNREAD_MESSAGES_COUNT: {
      return {
        ...state,
        unreadMessagesCount: action.payload
      };
    }
    case SET_IS_TEST_ENVIRONMENT: {
      return {
        ...state,
        isTestEnvironment: Boolean(action.payload)
      };
    }
    default:
      return state;
  }
}
