import * as PropTypes from "prop-types";
import React from "react";
import { Button } from "@material-ui/core";
import { store } from "../../../store";
import ShadowedOutlinedButton from "../../buttons/ShadowedOutlinedButton";
import { popHeadModalThenPushModal } from "../../../actions/typedActions";
import { ModalT } from "../../wrapper/types";

const style = { margin: "0 2px" };

export const cancelButton = (actionHandler = () => {}, text = "Закрыть") => (
  <ShadowedOutlinedButton
    style={style}
    key={text}
    variant="outlined"
    color="primary"
    onClick={() => actionHandler()}
  >
    {text}
  </ShadowedOutlinedButton>
);

export const PrimaryButton = ({
  actionHandler = () => {},
  text = "Сохранить",
  disabled = false
}) => {
  return (
    <Button
      variant="contained"
      style={style}
      color="primary"
      disabled={disabled}
      onClick={() => actionHandler()}
      key={text}
    >
      {text}
    </Button>
  );
};

PrimaryButton.propTypes = {
  actionHandler: PropTypes.func.isRequired,
  text: PropTypes.string,
  disabled: PropTypes.bool
};
PrimaryButton.defaultProps = {
  text: "Сохранить",
  disabled: false
};

export const deleteButton = (actionHandler: () => void, text = "Удалить") => (
  <Button
    style={style}
    variant="contained"
    color="secondary"
    onClick={() => actionHandler()}
    key={text}
  >
    {text}
  </Button>
);

export const openEditButton = ({
  text = "Редактировать",
  modal
}: {
  text?: string;
  modal: ModalT;
}) => (
  <Button
    style={style}
    key={text}
    variant="contained"
    color="primary"
    onClick={() => store.dispatch(popHeadModalThenPushModal(modal))}
  >
    {text}
  </Button>
);

openEditButton.propTypes = {
  modal: PropTypes.shape({}).isRequired,
  text: PropTypes.string
};
openEditButton.defaultProps = {
  text: "Редактировать"
};
