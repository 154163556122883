import React, { memo, useRef, useState } from "react";
import {
  FormControlLabel,
  IconButton,
  Popover,
  Switch,
  Typography
} from "@material-ui/core";
import { Settings } from "@material-ui/icons";
import { ActionButtonsStyle } from "../../../reducers/schedule";

interface PrivateSettingsProps {
  actionButtonsStyle: ActionButtonsStyle;
  setButtonsStyle: (style: ActionButtonsStyle) => void;
}

export const ScheduleSettings = memo(
  ({ actionButtonsStyle, setButtonsStyle }: PrivateSettingsProps) => {
    const [open, setOpen] = useState(false);
    const ref = useRef(null);
    return (
      <>
        <IconButton onClick={() => setOpen(true)} ref={ref}>
          <Settings />
        </IconButton>
        <Popover
          open={open}
          anchorEl={ref.current}
          onClose={() => setOpen(false)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
        >
          <div
            style={{
              padding: "8px 16px"
            }}
          >
            <Typography variant="h6" align="center">
              Настройки
            </Typography>
            <Typography variant="body1">Стиль управляющих кнопок</Typography>
            <FormControlLabel
              control={(
<Switch
                  value={actionButtonsStyle}
                  onChange={(e, checked) => {
                    if (typeof setButtonsStyle === "function") {
                      if (actionButtonsStyle === "text") {
                        setButtonsStyle("icon");
                      } else {
                        setButtonsStyle("text");
                      }
                    }
                  }}
                  checked={actionButtonsStyle === "text"}
                />
)}
              label={
                actionButtonsStyle === "text" ? "Текстовый" : "Графический"
              }
            />
          </div>
        </Popover>
      </>
    );
  }
);
