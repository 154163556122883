import CompletePromiseThenRenderComponent, {
  DownloadConfObj
} from "../../../components/completePromiseThenRenderComponent";
import { RenderModalCreateForm } from "../../../components/modalWrapper/renderModalContentWithOutTabs";
import { baseRebuildReceivedInitialValues } from "../../../components/formikMUi/utils/initialValuesUtils";
import { LinkBtn } from "../../../components/formikMUi/wrappedComponents/linkButton";
import { ExtendedTableFormObj } from "../../../components/modalWrapper/types";

const formObj: ExtendedTableFormObj = {
  title: "Просмотр объектов с непрочитанными сообщениями",
  fields: [
    {
      name: "a",
      type: "fieldArray",
      fields: [
        { name: "type", hidden: true, type: "text", width: 0 },
        {
          name: "object_id",
          type: "text",
          width: 3,
          label: "Идентификатор"
        },
        {
          name: "userName",
          type: "text",
          width: 6,
          label: "ФИО"
        },
        {
          name: "typeName",
          width: 6,
          type: "text",
          label: "Беседа / Вид таинства",
          rebuildReceivedValue: (v, values) => {
            switch (values.a[0].type) {
              case "slot": {
                return "Беседа";
              }
              case "workItem": {
                return "Дело";
              }
              case "claim": {
                return "Заявка";
              }
              default: {
                return "";
              }
            }
          }
        },
        {
          name: "linkBtn",
          type: "custom",
          width: 2,
          label: "Ссылка",
          component: LinkBtn
        },
        { name: "uniqueId", type: "text", hidden: true, width: 0 },
        { name: "uuid", type: "text", width: 0, hidden: true }
      ]
    }
  ]
};

export const unreadMessages = {
  component: CompletePromiseThenRenderComponent,
  forwardedProps: {
    readOnly: true,
    variant: "table",
    formObj,
    Component: RenderModalCreateForm,
    maxWidth: "md"
  },
  functionsToExecute: [
    {
      func: (): DownloadConfObj[] => {
        return [
          {
            urlToDownload: "/api/message/new",
            whereToStore: "initialValues",
            rebuildReceivedValue: v =>
              baseRebuildReceivedInitialValues(
                { blocks: [{ fields: formObj.fields }] },
                { a: v }
              )
          }
        ];
      },
      passResultAs: "config"
    }
  ]
};
