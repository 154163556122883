import { AnyAction } from "redux";
import { SET_ACTIVE_STEP } from "../constants/actionTypeConstants";

const initialState = {
  activeStep: 0,
  passProps: {}
};

export interface StepperState {
  activeStep: number;
  passProps: any;
}

export default function stepperReducer(
  state: StepperState = initialState,
  action: AnyAction
): StepperState {
  switch (action.type) {
    case SET_ACTIVE_STEP: {
      const {
        payload: { activeStep, passProps }
      } = action;
      return {
        ...state,
        activeStep,
        passProps
      };
    }
    default: {
      return state;
    }
  }
}
