import { AnyAction, combineReducers } from "redux";
import auth, { AuthActionT, AuthStateT } from "./auth";
import journalReducer, { JournalReducerStateT } from "./journal";
import filtersReducer, { FiltersStateT } from "./filtersReducer";
import collapsibleMenuReducer, {
  CollapsibleReducerState
} from "./collapsibleMenuReducer";
import wrapperReducer, { WrapperReducerState } from "./wrapperReducer";
import stepperReducer, { StepperState } from "./stepperReducer";
import { schedule, ScheduleActions, ScheduleState } from "./schedule";

export interface RootStateT {
  auth: AuthStateT;
  filtersReducer: FiltersStateT;
  journalReducer: JournalReducerStateT;
  wrapperReducer: WrapperReducerState;
  collapsibleMenuReducer: CollapsibleReducerState;
  schedule: ScheduleState;
  stepperReducer: StepperState;
  // todo закончить типизацию и удалить след строку
  [x: string]: any;
}

export default combineReducers<
  RootStateT,
  AnyAction | AuthActionT | ScheduleActions
>({
  // @ts-ignore todo понять как создать тип RootAction
  auth,
  // @ts-ignore todo понять как обработать
  journalReducer,
  filtersReducer,
  collapsibleMenuReducer,
  stepperReducer,
  wrapperReducer,
  // @ts-ignore
  schedule
});
