import React from "react";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import * as PropTypes from "prop-types";
import { FieldProps, useFormikContext } from "formik";

export type WrappedCheckBoxMUIProps = FieldProps<boolean>["field"] & {
  label?: string;
  error: boolean;
  helperText?: string;
  disabled: boolean;
  className?: string;
};

export type WrappedCheckBoxMUIT = React.FC<WrappedCheckBoxMUIProps>;

export const WrappedCheckBoxMUI: WrappedCheckBoxMUIT = ({
  name,
  value = false,
  onBlur,
  label,
  disabled = false,
  className
}: WrappedCheckBoxMUIProps) => {
  const { setFieldValue } = useFormikContext<any>();
  return (
    <FormControlLabel
      control={<Checkbox indeterminate={value === null} color="primary" />}
      onBlur={onBlur}
      onChange={() => {
        setFieldValue(name, !value);
      }}
      value={`${value}`}
      checked={value}
      label={label}
      disabled={disabled}
      className={className}
    />
  );
};

WrappedCheckBoxMUI.defaultProps = {
  label: " "
} as any;

WrappedCheckBoxMUI.propTypes = {
  field: PropTypes.shape({}).isRequired,
  form: PropTypes.shape({}).isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  customOnChange: PropTypes.func
} as any;
