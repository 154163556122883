import { fullWidth, halfWith } from "../../widths";
import {
  rebuildReceivedOptionsLabelAsLabelUniqueIdAsValue,
  rebuildReceivedOptionsLabelAsLabelUniqueIdAsValueAddMainPerformerFlag
} from "../../rebuildRecieved";
import {
  baseEmailProps,
  basePhoneProps,
  baseTextAreaProps,
  baseTimeFieldProps
} from "../../baseFieldProps";
import { localizedYup, timeSchema } from "../../yup";
import { rebuildValues } from "../../../components/modalWrapper/utils/rebuildValuesBeforeSending";
import { blocksT } from "../../types";
import { constructRequest } from "../../../components/modalWrapper/utils/baseRequests";
import { defaultErrorHandler } from "../../../utils/defaultErrorHandler";
import { doBeforeSend } from "../../baseFormProps";
import { ExtendedBlockFormObj } from "../../../components/modalWrapper/types";
import { dispatchOpenSnackBar, dispatchPopHeadModal } from "../../../store";

const blocks: blocksT = [
  {
    blockTitle: "Описание дела",
    fields: [
      {
        name: "commonAction",
        label: "Треба",
        type: "select",
        dictUrl: "/api/workitem/action",
        rebuildReceivedOptions: rebuildReceivedOptionsLabelAsLabelUniqueIdAsValue,
        rebuildReceivedValue: (v, { commonLabel }) => ({
          value: v,
          label: commonLabel
        }),
        placeholder: "Выберите тип требы/таинства",
        widths: halfWith
      },
      {
        label: "Священнослужитель",
        name: "performer",
        type: "select",
        dictUrl: ({ isPersonal }) =>
          isPersonal
            ? "/api/events/log/priestByUser?"
            : "/api/ministration/priest?",
        async: true,
        rebuildReceivedOptions: rebuildReceivedOptionsLabelAsLabelUniqueIdAsValueAddMainPerformerFlag,
        defaultValue: (functions, auth) => {
          if (functions.includes("assistant_hram_schedule_view")) {
            return null;
          }
          return {
            label: auth.surnameInitials,
            value: auth.userId,
            isMainPerformer: false
          };
        },
        rebuildReceivedValue: false,
        placeholder: "Выберите священнослужителя",
        widths: halfWith
      },
      {
        name: "isPersonal",
        type: "checkbox",
        hidden: true
      },
      {
        label: "Дата",
        name: "date",
        type: "date",
        minDate: new Date()
      },
      {
        name: "start",
        label: "Время начала",
        ...baseTimeFieldProps
      },
      {
        name: "end",
        label: "Время окончания",
        ...baseTimeFieldProps
      },
      {
        name: "userAddress",
        label: "Адрес проведения",
        ...baseTextAreaProps
      },
      {
        name: "description",
        label: "Описание дела",
        ...baseTextAreaProps
      },
      {
        name: "isReport",
        label: "Включить в отчёт",
        type: "checkbox",
        defaultValue: true,
        widths: fullWidth
      },
      {
        name: "claimId",
        type: "text",
        hidden: true,
      }
    ]
  },
  {
    blockTitle: "Данные о мирянине",
    fields: [
      {
        label: "ФИО мирянина",
        name: "userName",
        type: "text"
      },
      {
        name: "userPhone",
        label: "Телефон мирянина",
        ...basePhoneProps
      },
      {
        name: "userEmail",
        label: "E-mail мирянина",
        ...baseEmailProps
      }
    ]
  }
];

export const createWorkItem: ExtendedBlockFormObj = {
  title: "Новое дело",
  onSubmit: (values, { setSubmitting }, successCallback) => {
    const newValues = rebuildValues(values, { blocks, doBeforeSend });
    constructRequest(
      {
        method: "post",
        url: "/api/workitem/create",
        data: {
          ...newValues,
          dateTimestamp: `${newValues.date} ${newValues.start}`,
          dateEndTimestamp: `${newValues.date} ${newValues.end}`
        }
      },
      ({ status }) => {
        setSubmitting(false);
        if (status === 200) {
          dispatchOpenSnackBar("Объект Дела был успешно создан");
          dispatchPopHeadModal();
          if (typeof successCallback === "function") {
            successCallback({
              selectedDates: [values.date]
            });
          }
        }
      },
      er => {
        setSubmitting(false);
        if (er.response && er.response.status === 409) {
          dispatchOpenSnackBar(
            "На данное время уже есть запись в календаре, удалите эту запись или выберете другое время"
          );
        } else {
          defaultErrorHandler(er);
        }
      }
    );
  },
  blocks,
  validationSchema: localizedYup.object({
    isReport: localizedYup.bool(),
    commonAction: localizedYup
      .object()
      .nullable()
      .required(),
    performer: localizedYup
      .object()
      .nullable()
      .required(),
    date: localizedYup.string().required(),
    start: timeSchema.required(),
    // @ts-ignore
    end: timeSchema.compareTimeTo(localizedYup.ref("start")).required(),
    description: localizedYup.string()
  })
};
