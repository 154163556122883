import React, { memo, useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Paper, Theme, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import axios, { CancelTokenSource } from "axios";
import { isEqual } from "lodash";
import {
  CollectionType,
  PrivateScheduleJournalProps,
  SelectedRangeT,
} from "./types";
import { RootStateT } from "../../reducers";
import { ScheduleTable } from "./scheduleTable";
import {
  getEmptyOptionsMap,
  useCreationMaster,
  useGetInitialValues,
  useJournalConfiguration,
  useOptionsMapDownloader,
  useResetFilters,
} from "./utils";
import { OptionsMapType, OptionT } from "../../constants/types";
import { useMemorizedActions, useSuccessCallback } from "./utils/hooks";
import { ManagementPanel } from "./managementPanel";
import {
  closeConfirmDialog,
  openConfirmDialog,
  openSnackBar,
} from "../../actions/typedActions";
import { useRecoilValue } from "recoil";
import { overUserAtom, userChurchAtom } from "../../store/userStore";
import { loadChurchShifts, ShiftT } from "./scheduleStore";

const getStyles = makeStyles((theme: Theme) => ({
  header: {
    padding: theme.spacing(1),
    wordBreak: "keep-all",
    overflow: "hidden",
    textOverflow: "ellipsis",
    height: "80px",
  },
  mainContainer: {
    padding: theme.spacing(1),
    boxSizing: "border-box",
    overflow: "auto",
    display: "flex",
    flexWrap: "nowrap",
    flexDirection: "column",
  },
  tableContainer: { flex: "1 1 auto" },
}));

const PrivateScheduleJournal = ({
  confObj: { title, isPersonal = false, urlToDownloadCollection },
}: PrivateScheduleJournalProps) => {
  const overUser = useRecoilValue(overUserAtom);
  const {
    actionButtonsStyle,
    userFunctions,
    memberFullName,
    userId: currrentUserId,
    // username
  } = useSelector(
    (state: RootStateT) => ({
      username: state.auth.surnameInitials,
      memberFullName: state.auth.memberFullName,
      userFunctions: state.auth.functions,
      actionButtonsStyle: state.schedule.actionButtonsStyle,
      userId: state.auth.userId,
    }),
    isEqual
  );
  const userId = overUser ? overUser.value : currrentUserId;
  const dispatch = useDispatch();
  const { openSnackBarW, openConfirmDialogW, closeConfirmDialogW } = useMemo(
    () => ({
      openSnackBarW: (m: string) => dispatch(openSnackBar(m)),
      openConfirmDialogW: (m: Parameters<typeof openConfirmDialog>[0]) =>
        dispatch(openConfirmDialog(m)),
      closeConfirmDialogW: () => dispatch(closeConfirmDialog()),
    }),
    [dispatch]
  );
  const classes = getStyles();
  const [date, setDate] = useState(new Date());
  const [showAllEvents, setShowAllEvents] = useState(false);
  const [collectionIsLoading, setCollectionIsLoading] = useState(true);
  const [selectedEventType, setSelectedEventType] = useState<OptionT | null>(
    null
  );
  const [selectedPerformer, setSelectedPerformer] = useState<OptionT | null>(
    overUser as OptionT
  );

  const [selectedRange, setSelectedRange] = useState<SelectedRangeT>("week");
  const [collection, setCollection] = useState<CollectionType>([]);
  const [everythingPublished, setEverythingPublished] = useState(true);
  const [optionsMap, setOptionsMap] =
    useState<OptionsMapType>(getEmptyOptionsMap);
  const source = useMemo<CancelTokenSource>(
    (): CancelTokenSource => axios.CancelToken.source(),
    []
  );

  const successCallback = useSuccessCallback(
    selectedRange,
    date,
    setCollectionIsLoading,
    urlToDownloadCollection,
    isPersonal,
    showAllEvents,
    selectedPerformer,
    selectedEventType,
    setCollection,
    source,
    setEverythingPublished,
    userId
  );

  const {
    tableReadOnly,
    masterCreation,
    ministrationConfig,
    slotConfig,
    workItemConfig,
    copyBtnConfig,
    hiddenColumns,
    filters,
    hasAdminRights,
  } = useJournalConfiguration(
    overUser ? overUser.functions : userFunctions,
    isPersonal
  );

  //Для помощника устанавливает фильтра по священнослужителю
  useEffect(() => {
    setSelectedPerformer(overUser as OptionT);
  }, [isPersonal, overUser]);
  useResetFilters(
    setShowAllEvents,
    setSelectedEventType,
    setSelectedPerformer,
    isPersonal
  );

  const { openModal, setButtonsStyle } = useMemorizedActions();

  useCreationMaster(
    masterCreation,
    selectedRange,
    date,
    openModal,
    successCallback
  );

  // возможно в текущей реализации более не нужно передавать isPersonal т.к. изначально загружается исчерпывающее колличесво данных
  useOptionsMapDownloader(setOptionsMap);

  useGetInitialValues(
    setCollectionIsLoading,
    urlToDownloadCollection,
    selectedRange,
    date,
    isPersonal,
    showAllEvents,
    selectedPerformer,
    selectedEventType,
    setCollection,
    // source,
    setEverythingPublished,
    userId,
  );

  const userChurch = useRecoilValue(userChurchAtom);

  const [baseOnDuty, setBaseOnDuty] = useState<ShiftT[]>([]);
  // const baseOnDuty = useRecoilValue(loadDuties); //todo dima ошибка при вызове он делает тоже самое что и useEffect ниже
  useEffect(() => {
    loadChurchShifts(userChurch).then((r) => setBaseOnDuty(r));
  }, [setBaseOnDuty, userChurch]);

  return (
    <Paper square className={classes.mainContainer} elevation={5}>
      <Typography variant="h6" className={classes.header}>
        {`${title} ${memberFullName}`.toUpperCase()}
      </Typography>
      <ManagementPanel
        setDate={setDate}
        ministrationConfig={ministrationConfig}
        actionButtonsStyle={actionButtonsStyle}
        copyBtnConfig={copyBtnConfig}
        date={date}
        filters={filters}
        isPersonal={isPersonal}
        openModal={openModal}
        optionsMap={optionsMap}
        selectedEventType={selectedEventType}
        selectedPerformer={selectedPerformer}
        selectedRange={selectedRange}
        setButtonsStyle={setButtonsStyle}
        setSelectedEventType={setSelectedEventType}
        setSelectedPerformer={setSelectedPerformer}
        setSelectedRange={setSelectedRange}
        setShowAllEvents={setShowAllEvents}
        showAllEvents={showAllEvents}
        slotConfig={slotConfig}
        successCallback={successCallback}
        workItemConfig={workItemConfig}
        openSnackBar={openSnackBarW}
        openConfirmDialog={openConfirmDialogW}
        closeConfirmDialog={closeConfirmDialogW}
        everythingPublished={everythingPublished}
      />
      <ScheduleTable
        collection={collection}
        // Ожидание загрузки значений для всех выпадалок
        /*          isLoading={
            collectionIsLoading ||
            Object.values(optionsMap).some(({ loaded }) => !loaded)
          } */
        isLoading={collectionIsLoading}
        optionsMap={optionsMap}
        readOnly={tableReadOnly}
        hiddenColumns={hiddenColumns}
        isPersonal={isPersonal}
        showAllEvents={showAllEvents}
        successCallback={successCallback}
        openModal={openModal}
        actionButtonsStyle={actionButtonsStyle}
        hasAdminRights={hasAdminRights}
        userId={userId}
        setEverythingPublished={setEverythingPublished}
        baseOnDuty={baseOnDuty}
      />
    </Paper>
  );
};

export const ScheduleJournal = memo(PrivateScheduleJournal, isEqual);
