import { get } from "lodash";
import { InitialValuesT, titleFunctionT } from "../types";

/**
 *
 * Функция сторит заголовок
 * @param initialValues
 * @param title
 * @param headerText
 */
export const adaptTitle = (
  initialValues: InitialValuesT,
  title?: string | titleFunctionT,
  headerText?: string | string[]
): string => {
  let resultingTitle = title && typeof title !== "function" ? title : "";
  // получаем основной фрагмент зависящий от значений карточки
  if (typeof title === "function") {
    resultingTitle = title(initialValues);
  }
  if (Array.isArray(headerText)) {
    resultingTitle += headerText.reduce((res, next) => {
      if (next.startsWith("^m:")) {
        return `${res} ${next.slice(3)} `;
      }

      const currValue = get(initialValues, next);
      if (currValue && typeof currValue === "object" && currValue.label) {
        return `${res}${currValue.label} `;
      }
      if (currValue) {
        return `${res}${currValue} `;
      }
      return res;
    }, "");
  } else if (headerText) {
    const currValue = get(initialValues, headerText);
    if (currValue) {
      if (typeof currValue === "object" && currValue.label) {
        resultingTitle += currValue;
      } else {
        resultingTitle += currValue;
      }
    }
  }
  return resultingTitle.toUpperCase();
};
