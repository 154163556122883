import { FormikHelpers, FormikValues } from "formik";
import { AxiosError, AxiosResponse } from "axios";
import {
  cancelButton,
  deleteButton,
  openEditButton,
  PrimaryButton
} from "../buttons";
import CompletePromiseThenRenderComponent, {
  defaultPreloader
} from "../../completePromiseThenRenderComponent";
import { findFieldsWithDictThenConstructArrayToDownload } from "../../../utils/commonFormFunctions";
import { getInitialValues } from "./constructConfigArrayForLoaderComponent";
import { FormikMUI } from "../../formikMUi/formikNewRealisation";
import {
  allowedActions,
  callDefaultErrorHandlerThenPopModal,
  disableConfirmDialogPrimaryBtn,
  dispatchFetchJournalData,
  dispatchOpenSnackBar,
  dispatchPopHeadModal,
  store
} from "../../../store";
import { RenderModalContent } from "../renderModalContent";
import { RenderModalCreateForm } from "../renderModalContentWithOutTabs";
import { constructInitialValuesForEmptyForm } from "../../formikMUi/utils/initialValuesUtils";
import { rebuildValues } from "./rebuildValuesBeforeSending";
import { constructRequest } from "./baseRequests";
import { defaultErrorHandler } from "../../../utils/defaultErrorHandler";
import { constructHistoryTab } from "../historyTab";
import { AdditionalTabT, ConfigT, ExtendedBlockFormObj } from "../types";
import { ItemT, ModalT } from "../../wrapper/types";
import {
  closeConfirmDialog,
  MakePropertiesOptional,
  openConfirmDialog
} from "../../../actions/typedActions";

const constructTabsArray = ({
  formObj,
  passProps,
  functionToExecuteThenPassResultAsProps,
  tabButtons,
  historyTab
}: any): AdditionalTabT[] => {
  const { additionalTabs = [] } = formObj;
  const tabs = [
    {
      tabTitle: "Общая информация",
      tabContent: {
        Component: FormikMUI,
        passProps: { formObj, ...passProps },
        functionToExecuteThenPassResultAsProps
      },
      tabButtons
    },
    ...additionalTabs
  ];
  if (historyTab) {
    tabs.push(historyTab);
  }

  return tabs;
};

export interface ComputePropsForFormikMUIProps {
  formObj: ExtendedBlockFormObj;
  mode: "view" | "edit" | "create" | "profile";
  modal?: Omit<Partial<ModalT>, "item"> &
    MakePropertiesOptional<ItemT, "uniqueId">;
  maxWidth?: "xl" | "lg" | "md" | "sm" | "xs";
  addHistoryTab?: boolean;
}

// Эта жуткая функция выступает в роли "Адаптера" между кофиг-м объектом и необходимыми свойствами для отрисовки той или иной карточки
// Пустая функция нужна лишь для того чтобы отложить расчёт свойств и будет вызвана позднее при отрисовке журнала
export const computePropsForFormikMUI = ({
  formObj,
  mode,
  modal: modalToMerge,
  maxWidth,
  addHistoryTab = true
}: ComputePropsForFormikMUIProps) => () => {
  const resultingConfigObj: Required<ConfigT> = {
    component: CompletePromiseThenRenderComponent,
    forwardedProps: {
      Component: RenderModalContent,
      tabs: constructTabsArray({
        formObj,
        passProps: {
          readOnly: mode === "view"
        },
        functionToExecuteThenPassResultAsProps: ({ modal }: any) => {
          let computedModal: ModalT = {
            ...modal
          };
          if (modalToMerge) {
            computedModal = { ...modal, ...modalToMerge };
            computedModal.item = { ...modal.item, ...modalToMerge.item };
          }

          return {
            onSubmit: (
              values: FormikValues,
              { setSubmitting }: FormikHelpers<FormikValues>
            ) => {
              const urlId = formObj.customId
                ? modal.item[formObj.customId]
                : modal.item.uniqueId;

              constructRequest(
                {
                  url: formObj.primaryActionUrl
                    ? `${formObj.primaryActionUrl}/${urlId}`
                    : `${formObj.url}/${urlId}`,
                  method: formObj.method,
                  data: rebuildValues(values, formObj)
                },
                ({ status }) => {
                  if (status === 200 || status === 201) {
                    if (typeof modal.successCallback === "function") {
                      modal.successCallback(values);
                    }
                    dispatchOpenSnackBar(
                      `Объект "${formObj.titleItem ||
                        formObj.title}" успешно изменен`
                    );
                  }
                  setSubmitting(false);
                  dispatchPopHeadModal();
                  dispatchFetchJournalData();
                },
                error => {
                  defaultErrorHandler(error).finally(() =>
                    dispatchPopHeadModal()
                  );
                }
              );
            },
            modal: computedModal
          };
        },
        tabButtons:
          mode === "edit"
            ? [
                {
                  Btn: PrimaryButton,
                  requiresFormSubmitRef: true
                }
              ]
            : [],
        historyTab: addHistoryTab ? constructHistoryTab() : undefined
      }),
      // такая фигня появилась из-за струтуры БД (они намешали обеъкты разной природы в 1 таблицу....)
      title:
        typeof formObj.title === "function"
          ? (firstFormInitialValues: FormikValues) =>
              // @ts-ignore
              `Карточка просмотра ${formObj.title(firstFormInitialValues)}: `
          : `Карточка просмотра ${formObj.title}: `,
      actionButtons: [cancelButton(() => dispatchPopHeadModal())],
      additionalActionButtonsDependedOnProps:
        formObj.additionalActionButtonsDependedOnProps,
      PreloaderComponent: defaultPreloader,
      errorHandler: (er: any) => callDefaultErrorHandlerThenPopModal(er)
    },
    functionsToExecute: [],
    maxWidth: maxWidth || "xl"
  };
  if (mode === "create") {
    resultingConfigObj.forwardedProps = {
      Component: RenderModalCreateForm,
      formObj,
      title: `Карточка создания ${formObj.title}`,
      actionButtons: [cancelButton(() => dispatchPopHeadModal())],
      readOnly: false,
      PreloaderComponent: defaultPreloader,
      errorHandler: (er: AxiosError) => callDefaultErrorHandlerThenPopModal(er)
    };

    resultingConfigObj.forwardedProps.config = findFieldsWithDictThenConstructArrayToDownload(
      formObj
    );

    resultingConfigObj.functionsToExecute.push({
      func: (modal: ModalT) => ({
        ...constructInitialValuesForEmptyForm(formObj),
        ...modal.initialValues
      }),
      passResultAs: "initialValues"
    });

    resultingConfigObj.forwardedProps.onSubmit = (
      values: FormikValues,
      { setSubmitting }: FormikHelpers<FormikValues>
    ) =>
      constructRequest(
        {
          // @ts-ignore
          url: formObj.primaryActionUrl
            ? formObj.primaryActionUrl
            : formObj.url,
          method: formObj.method,
          data: rebuildValues(values, formObj)
        },
        ({ status }: AxiosResponse) => {
          if (status === 201) {
            dispatchOpenSnackBar(
              `Объект "${formObj.titleItem}" успешно создан`
            );
          } else if (status === 208) {
            dispatchOpenSnackBar(
              `Объект "${formObj.titleItem ||
                formObj.title}" с заданными параметрами был создан ранее`
            );
          }
          setSubmitting(false);
          if (status !== 208) {
            dispatchPopHeadModal();
            dispatchFetchJournalData();
          }
        },
        (error: AxiosError) => {
          defaultErrorHandler(error).finally(() => {
            setSubmitting(false);
            dispatchPopHeadModal();
          });
        }
      );
  }
  if (mode === "view") {
    resultingConfigObj.functionsToExecute = [
      ...resultingConfigObj.functionsToExecute,
      {
        func: modal => {
          const { category, page } = modal;
          const {
            forwardedProps: { actionButtons }
          } = resultingConfigObj;

          let resActionBtns = [...actionButtons];
          const allowedActionsVar = allowedActions();
          if (
            (allowedActionsVar[category] &&
              allowedActionsVar[category].includes &&
              allowedActionsVar[category].includes("delete")) ||
            (page &&
              allowedActionsVar[category] &&
              allowedActionsVar[category][page] &&
              allowedActionsVar[category][page].includes("delete"))
          ) {
            resActionBtns = [
              ...actionButtons,
              deleteButton(() => {
                store.dispatch(
                  openConfirmDialog({
                    mainText: `удалить объект "${
                      formObj.titleItem ? formObj.titleItem : formObj.title
                    }": ${modal.item &&
                      (modal.item.label ||
                        modal.item.accost ||
                        modal.item.surnameInitials ||
                        modal.item.uuid)}?`,
                    primaryButtonHandler: () => {
                      disableConfirmDialogPrimaryBtn(true);
                      constructRequest(
                        {
                          url: `${formObj.deleteUrl ||
                            formObj.url}/${modal.item && modal.item.uniqueId}`,
                          method: "delete"
                        },
                        ({ status }) => {
                          disableConfirmDialogPrimaryBtn(true);
                          if (status === 204) {
                            dispatchOpenSnackBar(
                              `Объект "${
                                formObj.titleItem
                                  ? formObj.titleItem
                                  : formObj.title
                              }" успешно удален`
                            );
                          }
                          store.dispatch(closeConfirmDialog());
                          dispatchPopHeadModal();
                          dispatchFetchJournalData();
                        },
                        defaultErrorHandler
                      );
                    }
                  })
                );
              })
            ];
          }
          if (allowedActionsVar[category]) {
            const currentCategoryConfig = allowedActionsVar[category];
            if (
              (currentCategoryConfig.includes &&
                allowedActionsVar[category].includes("edit")) ||
              (page &&
                currentCategoryConfig[page] &&
                currentCategoryConfig[page].includes("edit"))
            ) {
              resActionBtns = [
                ...resActionBtns,
                openEditButton({
                  modal: { ...modal, mode: "edit" }
                })
              ];
            }
          }
          return resActionBtns;
        },
        passResultAs: "actionButtons"
      }
    ];
  }
  if (mode === "view" || mode === "edit") {
    /* такая конструкция строит массив объектов на основе которых в последствии компонент "загрузчик" скачает необходимые данные,
     расчёт должен происходить позднее т.к. на момент вызова не известны некоторые данные необходимые для идентификации */
    // resultingConfigObj.forwardedProps.tabs = constructTabsArray(formObj);
    resultingConfigObj.functionsToExecute = [
      ...resultingConfigObj.functionsToExecute,
      {
        func: modal => [
          ...findFieldsWithDictThenConstructArrayToDownload(formObj),
          getInitialValues(
            modal,
            formObj,
            "firstFormInitialValues",
            modal.initialValues
          )
        ],
        passResultAs: "config"
      }
    ];
  }
  if (mode === "edit") {
    resultingConfigObj.forwardedProps.title = `Карточка редактирования ${formObj.title}: `;
  }

  if (mode === "profile") {
    resultingConfigObj.forwardedProps = {
      Component: RenderModalCreateForm,
      formObj,
      title: `Карточка редактирования профиля ${formObj.title}: `,
      actionButtons: [cancelButton(() => dispatchPopHeadModal())],
      readOnly: false,
      PreloaderComponent: defaultPreloader,
      errorHandler: (er: AxiosError) => callDefaultErrorHandlerThenPopModal(er)
    };
    resultingConfigObj.functionsToExecute = [
      ...resultingConfigObj.functionsToExecute,
      {
        func: modal => [
          ...findFieldsWithDictThenConstructArrayToDownload(formObj),
          getInitialValues(modal, formObj, "initialValues", modal.initialValues)
        ],
        passResultAs: "config"
      },
      {
        func: modal => (
          values: FormikValues,
          { setSubmitting }: FormikHelpers<FormikValues>
        ) =>
          constructRequest(
            {
              url: formObj.primaryActionUrl
                ? `${formObj.primaryActionUrl}/${modal.item &&
                    modal.item.uniqueId}`
                : `${formObj.url}/${modal.item && modal.item.uniqueId}`,
              method: formObj.method,
              data: rebuildValues(values, formObj)
            },
            ({ status }) => {
              if (status === 201) {
                dispatchOpenSnackBar(`Изменения были успешно применены`);
              }
              setSubmitting(false);
              dispatchPopHeadModal();
            }
          ),
        passResultAs: "onSubmit"
      }
    ];

    resultingConfigObj.forwardedProps.renderSubmitButton = true;
    resultingConfigObj.forwardedProps.isInitialValid = true;
  }
  return resultingConfigObj;
};
