import React, { memo, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEqual } from "lodash";
import { useSnackbar } from "notistack";
import IconButton from "@material-ui/core/IconButton";
import { closeSnackBarById } from "../../actions/typedActions";
import { RootStateT } from "../../reducers";

const PrivateSnackBarPanel = () => {
  const { notifications } = useSelector((state: RootStateT) => {
    return { notifications: state.wrapperReducer.notifications };
  }, isEqual);
  const dispatch = useDispatch();

  const displayedSnackbarsIds = useRef<string[]>([]);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  useEffect(() => {
    if (notifications) {
      notifications.forEach(({ key, message, options = {} }) => {
        // Do nothing if snackbar is already displayed
        if (!displayedSnackbarsIds.current.includes(key)) {
          // Display snackbar using notistack
          enqueueSnackbar(message, {
            ...options,
            onClose: () => {
              closeSnackbar(key);
              // Dispatch action to remove snackbar from redux store
              dispatch(closeSnackBarById(key));
            },
            action: id => (
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                size="small"
                onClick={() => {
                  closeSnackbar(id);
                  // @ts-ignore
                  dispatch(closeSnackBarById(id));
                }}
              >
                ✕
              </IconButton>
            )
          });
          // Keep track of snackbars that we've displayed
          displayedSnackbarsIds.current.push(key);
        }
      });
    }
  }, [closeSnackbar, dispatch, enqueueSnackbar, notifications]);

  return null;
};

export const SnackBarPanel = memo(PrivateSnackBarPanel, isEqual);

/*
//без использования notistack
const PrivateSnackBarPanel = () => {
  const { snackBars } = useSelector((state: RootStateT) => {
    return { snackBars: state.wrapperReducer.snackBars };
  });
  const dispatch = useDispatch();
  return (
    <>
      {snackBars &&
        snackBars.map(({ id, message }) => (
          <CustomSnackbar
            key={id}
            message={message}
            open
            onClose={() => dispatch(closeSnackBarById(id))}
          />
        ))}
    </>
  );
};

export const SnackBarPanel = memo(PrivateSnackBarPanel, isEqual);

 */
