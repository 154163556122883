import axios from "axios";
import { defaultErrorHandler } from "../../../utils/defaultErrorHandler";
import { dispatchOpenSnackBar } from "../../../store";

export const commonFilesUrl = "/api/file/download";

export const downloadFile = (
  url: string,
  fileName: string,
  errorHandler?: typeof defaultErrorHandler
) =>
  axios({
    url,
    method: "GET",
    responseType: "blob" // important
  })
    .then(({ data, status }) => {
      if (status === 204) {
        dispatchOpenSnackBar("Не найдено файла вложения");
      } else {
        const blobUrl = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = blobUrl;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      }
    })
    .catch(errorHandler || defaultErrorHandler);

export const claimAttachmentDownloadUrl = (
  claimUuid: string,
  fileName: string
) => `/api/claim/attachment/download/${claimUuid}/${fileName}`;

export const urlToDownLoadClaimAttachmentFromFtp = (
  value: { uuidClaim: string; label: string }[]
) => {
  const { uuidClaim, label: fileName } = value[0];
  return claimAttachmentDownloadUrl(uuidClaim, fileName);
};
