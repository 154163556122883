import React, { memo, useMemo } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { isEqual } from "lodash";
import { Journal } from "../journal";
import { DisplayErrorMessage } from "../no-match";
import { INITIAL_PARAMETERS } from "../../constants/intialParameters";
import {
  closeModalByKey,
  computeUrlThenFetchJournalData,
  openSnackBar,
  popHeadModal
} from "../../actions/typedActions";
import ModalWrapper from "../modalWrapper";
import { RootStateT } from "../../reducers";
import { ConfigT } from "../modalWrapper/types";
import { PrivateGetJournalMainContentProps } from "./types";
import { useShallowEqualSelector } from "../../store/hooks";

function useGetUseMemo() {
  const dispatch = useDispatch();
  return useMemo(
    () =>
      bindActionCreators(
        {
          computeUrlThenFetchJournalData,
          popHeadModal,
          openSnackBar,
          closeModalByKey
        },
        dispatch
      ),
    [dispatch]
  );
}

const PrivateGetJournalMainContent = ({
  initialParameters,
  page,
  category,
  allowedActions
}: PrivateGetJournalMainContentProps) => {
  const {
    openSnackBar: openSnackBarW,
    closeModalByKey: closeModalByKeyW,
    computeUrlThenFetchJournalData: computeUrlThenFetchJournalDataW,
    popHeadModal: popHeadModalW
  } = useGetUseMemo();

  const { openedModals } = useShallowEqualSelector((state: RootStateT) => ({
    openedModals: state.journalReducer.modalState.openedModals
  }));

  if (
    !initialParameters ||
    !allowedActions[category] ||
    !(Array.isArray(allowedActions[category])
      ? true
      : page && allowedActions[category][page])
  ) {
    return (
      <div style={{ height: "calc(100vh -  80px)" }}>
        <DisplayErrorMessage errorMessage="Такой страницы не существует" />
      </div>
    );
  }

  let content;
  if (initialParameters.Component) {
    const { Component, passProps = {} } = initialParameters;
    content = <Component {...passProps} />;
  } else {
    content = (
      <Journal
        category={category}
        page={page}
        initialParameters={initialParameters}
        computeUrlThenFetchJournalData={computeUrlThenFetchJournalDataW}
      />
    );
  }

  return (
    <>
      {content}
      {openedModals.map((modal, index) => {
        const { category: modalCategory, page: modalPage, key, mode } = modal;
        let config: ConfigT | undefined;
        if (modalCategory) {
          const modalCategoryParameters = INITIAL_PARAMETERS[modalCategory];
          if (modalCategoryParameters) {
            if (
              !modalPage &&
              modalCategoryParameters.page &&
              modalCategoryParameters.page.forms
            ) {
              const tempConfig = modalCategoryParameters.page.forms[mode];
              config =
                typeof tempConfig === "function" ? tempConfig() : tempConfig;
            }
            if (
              modalPage &&
              modalCategoryParameters.pages &&
              modalCategoryParameters.pages[modalPage]
            ) {
              const { forms } = modalCategoryParameters.pages[modalPage];
              if (forms) {
                const tempConfig = forms[mode];
                config =
                  typeof tempConfig === "function" ? tempConfig() : tempConfig;
              }
            }
          }
        }
        if (!config) {
          openSnackBarW("Не найдена карточка данного объекта");
          if (key) {
            closeModalByKeyW(key);
          }
          return null;
        }
        return (
          <ModalWrapper
            key={key}
            modal={modal}
            config={config}
            // кастыль чтоб верхушка всегда пересчитывалась, создавая фейковое измененеие свойства, но это приводит к пересчету 2-х модалок с макс индексами
            hideModal={
              openedModals.length - 1 === index
                ? () => popHeadModalW()
                : popHeadModalW
            }
            fetchJournalData={computeUrlThenFetchJournalDataW}
          />
        );
      })}
    </>
  );
};

export const GetJournalMainContent = memo(
  PrivateGetJournalMainContent,
  isEqual
);
