import { localizedYup } from "../../../yup";
import { computePropsForFormikMUI } from "../../../../components/modalWrapper/utils/computePropsForForMikMUI";
import { halfWith } from "../../../widths";
import { baseNumberProps, baseTextAreaProps } from "../../../baseFieldProps";

const baseForm = {
  title: "Тип события",
  titleItem: "Типа   события",
  headerText: "label",
  url: "/api/commonAction",
  method: "patch",
  blocks: [
    {
      blockTitle: "Основная информация",
      fields: [
        {
          label: "Наименование",
          name: "label",
          type: "text",
          widths: halfWith
        },
        {
          label: "Длительность по умолчанию, мин",
          name: "longTime",
          ...baseNumberProps(),
          widths: halfWith
        },
        {
          label: "Описание",
          name: "description",
          ...baseTextAreaProps
        },
        {
          name: "isWork",
          label: "Возможность использования в Делах",
          type: "checkbox"
        },
        {
          name: "isMinistration",
          label: "Возможность использования в Богослужениях",
          type: "checkbox"
        },
        {
          name: "isClaim",
          label: "Возможность использования в Заявках",
          type: "checkbox"
        }
      ]
    }
  ]
};

const forms = {
  view: computePropsForFormikMUI({ formObj: baseForm, mode: "view" }),
  edit: computePropsForFormikMUI({
    formObj: {
      ...baseForm,
      validationSchema: localizedYup.object({
        label: localizedYup.string().required()
      })
    },
    mode: "edit"
  })
};
export default forms;
