import React, { useState } from "react";
import {
  FullscreenControl,
  GeolocationControl,
  GeoObjectGeometry,
  Map,
  MapProps,
  ObjectManager,
  ObjectManagerFeatures,
  ObjectManagerProps,
  YMaps,
  YMapsApi,
  ZoomControl
} from "react-yandex-maps";
import * as PropTypes from "prop-types";
import { LinearProgress } from "@material-ui/core";

const clusterWithoutNumber = (ymaps: YMapsApi) => {
  return ymaps.templateLayoutFactory.createClass(
    '<div style="color: #FFFFFF; font-weight: bold;">{{ properties.geoObjects.length }}</div>'
  );
};

export type YandexEvent = Event & {
  originalEvent: React.MouseEvent<HTMLDivElement> & {
    position: any;
  };
  get: (name: string) => any;
};

export interface MapsProps {
  points?: GeoObjectGeometry[];
  getCoordsOnClick?: (coords: any) => void;
  clustersProps?: { showCount: boolean };
  mapProps: MapProps;
  balloonButton?: {
    onClickBalloonButton: Function;
    label: string;
    onClickBalloonButtonSecondary?: (e: Event) => void;
    labelSecondary?: string;
  };
  balloonProps?: {
    clusterCaption: Function;
    balloonContentHeader: ({ title }: GeoObjectGeometry) => string;
    balloonContentBody: ({ address }: GeoObjectGeometry) => string;
    clusterBalloonLeftColumnWidth?: number;
  };
  preloader?: Function;
  children?: React.ReactNode;
  bindYmapsObj?: (ymaps: YMapsApi) => void;
  modules?: string[];
}
export const Maps = ({
  mapProps,
  points = [],
  balloonButton = {
    label: "Подробнее",
    onClickBalloonButton: () => {}
  },
  balloonProps = {
    clusterCaption: () => {},
    balloonContentHeader: ({ title }) => title,
    balloonContentBody: ({ address }) => address
  },
  clustersProps = { showCount: true },
  getCoordsOnClick,
  preloader: Preloader,
  children,
  bindYmapsObj,
  modules = []
}: MapsProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [geoLocation, setGeoLocation] = useState({ position: undefined });
  const [ymapsObject, setYmapsObject] = useState({});
  const [objectManager, setObjectManager] = useState<ObjectManagerProps | null>(
    null
  );
  if (typeof bindYmapsObj === "function") {
    bindYmapsObj(ymapsObject);
  }
  const [isLoading, setIsLoading] = useState(false);
  const [clusters, setClusters] = useState({});
  const features: ObjectManagerFeatures = {
    type: "FeatureCollection",
    features: points.map((point, index) => {
      return {
        id: index,
        value: point,
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: point.coordinates
        },
        properties: {
          clusterCaption: balloonProps.clusterCaption(point),
          balloonContentHeader: balloonProps.balloonContentHeader(point),
          balloonContentBody: balloonProps.balloonContentBody(point),
          balloonContentFooter: `<button class="y-maps-button" onclick="window.onClickBalloonButton(${index});">${
            balloonButton.label
          }</button>${
            balloonButton.labelSecondary
              ? `<button
                class="y-maps-button y-maps-button-secondary"
                onClick="window.onClickBalloonButtonSecondary(${index});"
              >
                ${balloonButton.labelSecondary}
              </button>`
              : ""
          }`
        }
      };
    })
  };
  // @ts-ignore
  window.onClickBalloonButton = value => {
    if (objectManager && objectManager.objects) {
      balloonButton.onClickBalloonButton(
        objectManager.objects.getById(value).value
      );
    }
  };
  // @ts-ignore
  window.onClickBalloonButtonSecondary = value => {
    if (
      objectManager &&
      objectManager.objects &&
      typeof balloonButton.onClickBalloonButtonSecondary === "function"
    ) {
      balloonButton.onClickBalloonButtonSecondary(
        objectManager.objects.getById(value).value
      );
    }
  };
  return (
    <>
      {!isLoading && ((Preloader && <Preloader />) || <LinearProgress />)}
      <YMaps preload>
        <Map
          {...mapProps}
          onLoad={(ymaps: YMapsApi) => {
            setIsLoading(true);
            setYmapsObject(ymaps);
            if (!clustersProps.showCount) {
              setClusters({
                ...clusters,
                clusterIconContentLayout: clusterWithoutNumber(ymaps)
              });
            }
          }}
          onClick={(e: YandexEvent) => {
            if (getCoordsOnClick) {
              getCoordsOnClick(e.get("coords"));
            }
          }}
          modules={["ObjectManager", "templateLayoutFactory", ...modules]}
        >
          <ZoomControl
            options={{
              size: "auto",
              zoomDuration: 500
            }}
          />
          <FullscreenControl />
          <GeolocationControl
            options={{ float: "left" }}
            onLocationChange={({ originalEvent }: YandexEvent) => {
              setGeoLocation({ position: originalEvent.position });
            }}
          />
          <ObjectManager
            onOptionschange={(event: YandexEvent) => {
              setObjectManager(event.originalEvent.target);
            }}
            objects={{
              openBalloonOnClick: true,
              balloonPanelMaxMapArea: Infinity
            }}
            clusters={{
              ...clusters
            }}
            options={{
              clusterize: true,
              balloonPanelMaxMapArea: Infinity,
              clusterBalloonLeftColumnWidth:
                balloonProps.clusterBalloonLeftColumnWidth
            }}
            features={features}
            modules={[
              "objectManager.addon.objectsBalloon",
              "objectManager.addon.clustersBalloon"
            ]}
          />
          {children}
        </Map>
      </YMaps>
    </>
  );
};

Maps.propTypes = {
  mapProps: PropTypes.shape({
    width: PropTypes.string,
    height: PropTypes.string,
    defaultState: PropTypes.shape({})
  }),
  points: PropTypes.arrayOf(PropTypes.shape({})),
  balloonButton: PropTypes.shape({
    onClickBalloonButton: PropTypes.func,
    label: PropTypes.string
  }),
  balloonProps: PropTypes.shape({
    clusterCaption: PropTypes.func,
    balloonContentHeader: PropTypes.func,
    balloonContentBody: PropTypes.func,
    clusterBalloonLeftColumnWidth: PropTypes.number
  }),
  clustersProps: PropTypes.shape({
    showCount: PropTypes.bool
  }),
  getCoordsOnClick: PropTypes.func
} as any;
