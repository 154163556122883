import { Reducer } from "redux";
import { SET_SCHEDULE_JOURNAL_ACTION_BTNS_DISPLAY } from "../constants/actionTypeConstants";

export type ActionButtonsStyle = "text" | "icon";

export interface ScheduleState {
  actionButtonsStyle: ActionButtonsStyle;
}

export interface ScheduleActions {
  type: typeof SET_SCHEDULE_JOURNAL_ACTION_BTNS_DISPLAY;
  payload: ActionButtonsStyle;
}

export const schedule: Reducer<ScheduleState, ScheduleActions> = (
  state = {
    actionButtonsStyle: "text"
  },
  action
) => {
  switch (action.type) {
    case SET_SCHEDULE_JOURNAL_ACTION_BTNS_DISPLAY: {
      return {
        ...state,
        actionButtonsStyle: action.payload
      };
    }
    default: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const never: never = action.type;
      return state;
    }
  }
};
