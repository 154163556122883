import baseJournal from "../journal";
import { FiberNew } from "@material-ui/icons";
import { PageT } from "../../../types";

const claimNew: PageT = {
  ...baseJournal,
  icon: FiberNew,
  navigationLabel: "Новые заявки моего храма",
  url: "/api/getNewClaimObjectsList",
  badge: {
    url: "/api/getNewClaimObjectsList/count"
  }
};
export default claimNew;
