import React from "react";
import { BlockFormObj, OptionT } from "../constants/types";
import {
  Button,
  DialogActions,
  Paper,
  Theme,
  Typography
} from "@material-ui/core";
import { FormikMUI } from "./formikMUi/formikNewRealisation";
import { DependedOnOnClickStateDownloadButton } from "./buttons/dependedOnOnClickStateDownloadButton";
import axios from "axios";
import { v4 } from "uuid";
import { constructInitialValuesForEmptyForm } from "./formikMUi/utils/initialValuesUtils";
import { makeStyles } from "@material-ui/styles";
import { ContainsName } from "../constants/rebuildRecieved";

export const getSettingsStyles = makeStyles<Theme>(theme => ({
  header: {
    padding: theme.spacing(1)
  },
  cntr: { width: "100%", height: "100%" },
  mainContainer: {
    padding: "8px",
    marginBottom: "8px",
    height: " 100%",
    width: "100%",
    overflowY: "auto"
  }
}));

export type SettingsJournalProps = {
  confObj: { title: string };
  formObj: BlockFormObj;
};

export function mergeSettingsValues(
  this: BlockFormObj,
  data: Record<string, (OptionT & ContainsName)[]>
): BlockFormObj {
  return {
    ...this,
    blocks: this.blocks.map(value => ({
      ...value,
      fields: value.fields.map(field => {
        const datum = data[field.name];
        const foundField = datum[0];
        return {
          ...field,
          label: foundField.label,
          defaultValue: field.rebuildReceivedValue
            ? field.rebuildReceivedValue(foundField.value, {})
            : foundField.value
        };
      })
    }))
  };
}

export const SettingsJournal: React.FC<SettingsJournalProps> = ({
  confObj: { title },
  formObj
}) => {
  const classes = getSettingsStyles();
  const formId = v4();
  return (
    <Paper square className={classes.mainContainer} elevation={5}>
      <Typography variant="h6" className={classes.header}>
        {title}
      </Typography>
      <FormikMUI
        id={formId}
        initialValues={constructInitialValuesForEmptyForm(formObj)}
        // @ts-ignore
        onSubmit={formObj.onSubmit}
        formObj={formObj}
      />
      <DialogActions>
        <Button variant="contained" color="primary" form={formId} type="submit">
          Сохранить
        </Button>
      </DialogActions>
    </Paper>
  );
};
