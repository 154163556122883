import { atom } from "recoil";
import { SnackbarProps } from "@material-ui/core/Snackbar/Snackbar";

export const snackBarState = atom<SnackbarProps>({
  key: "snackBarState",
  default: { open: false },
  effects: [
    ({ resetSelf, onSet }) => {
      onSet(() => {
        setTimeout(() => {
          resetSelf();
        }, 5000);
      });
    }
  ]
});
