import baseJournal from "../journal";
import { IField, PageT, TableColumnT } from "../../../types";

const filters: IField[] = baseJournal.filtersPanel
  ? [
      ...baseJournal.filtersPanel.filters,
      {
        label: "Город",
        name: "townRef",
        type: "select",
        dictUrl: "/api/member/towns",
        rebuildReceivedOptions: false
      },
      {
        label: "Метро",
        name: "metroRef",
        type: "select",
        multiple: true,
        dictUrl: ({ townRef }) => `/api/member/metro/${townRef.value}`,
        conditionallyRender: ({ townRef }) => Boolean(townRef),
        async: true,
        rebuildReceivedOptions: false
      }
    ]
  : [];

function getTableHeader(columns?: TableColumnT[]): TableColumnT[] {
  if (!columns) {
    return [];
  }
  const result = columns.slice();
  const start = result.findIndex(({ path }) => path === "userAddress");

  if (start > -1) {
    result.splice(
      start,
      0,
      { label: "Город", path: "townLabel", width: 150 },
    );
  }
  return result;
}

const tableHeader: TableColumnT[] = getTableHeader(baseJournal.tableHeader);

const claimFree: PageT = {
  ...baseJournal,
  filtersPanel: {
    filters
  },
  tableHeader,
  navigationLabel: "Новые заявки всех храмов",
  url: "/api/getFreeClaimObjectsList",
  badge: {
    url: "/api/getFreeClaimObjectsList/count"
  }
};
export default claimFree;
