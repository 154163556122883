import { CustomField } from "../../types";
import { Button } from "@material-ui/core";
import React from "react";
import axios from "axios";
import { dispatchOpenSnackBar } from "../../../store";
import { useFormikContext } from "formik";

export const SetWeddingButton: CustomField["component"] = () => {
  const {
    values: { wedding_year }
  } = useFormikContext<any>();
  return (
    <Button
      variant="contained"
      color="primary"
      onClick={() => {
        axios.put(`/api/calendar_full/updateWeddings/${wedding_year}`)
          .then(({ status }) => {
            if (status === 200) {
              dispatchOpenSnackBar("Венчание успешно установлено");
            }
          })
          .catch((error) => {
            console.log(error);
            dispatchOpenSnackBar("Ошибка установки венчания");
          });
        }
      }
    >
      Установить венчание на указанный год
    </Button>
  )
}
