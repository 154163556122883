import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { connect } from "react-redux";
import * as PropTypes from "prop-types";
import { HIDE_CONFIRM_DIALOG } from "../constants/actionTypeConstants";

const ConfirmDialog = ({ confirmDialog, closeHandler, ...rest }) => {
  return (
    <Dialog
      open={confirmDialog.open}
      disableBackdropClick
      disableEscapeKeyDown
      {...rest}
      style={{ zIndex: 2000 }}
    >
      <DialogTitle>
        {confirmDialog.title ? confirmDialog.title : "Подтверждение операции"}
      </DialogTitle>
      <DialogContent>
        {confirmDialog.mainText
          ? `Вы действительно хотите ${confirmDialog.mainText}`
          : undefined}
        {confirmDialog.mainContent ? confirmDialog.mainContent : undefined}
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="outlined" onClick={closeHandler}>
          {confirmDialog.secondaryButtonText}
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={confirmDialog.primaryButtonHandler}
          disabled={confirmDialog.primaryButtonDisabled}
        >
          {confirmDialog.primaryButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  confirmDialog: PropTypes.shape({
    open: PropTypes.bool.isRequired,
    mainText: PropTypes.string,
    secondaryButtonText: PropTypes.string.isRequired,
    primaryButtonText: PropTypes.string.isRequired,
    primaryButtonHandler: PropTypes.func
  }).isRequired
};

const mapStateToProps = state => ({
  confirmDialog: state.wrapperReducer.confirmDialog
});

const mapDispatchToProps = dispatch => ({
  closeHandler: () =>
    dispatch({
      type: HIDE_CONFIRM_DIALOG
    })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmDialog);
