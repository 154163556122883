import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState
} from "react";
import {
  Button,
  LinearProgress,
  Paper,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Theme
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import * as PropTypes from "prop-types";
import { defaultPreloader } from "../completePromiseThenRenderComponent";
import {
  decreaseActiveStep,
  increaseActiveStep,
  init,
  reducer,
  setIsLoading,
  setStepPassProps
} from "./reducer";
import { createAxiosCancelToken, downLoadStuff } from "./until";
import { StepperMUIProps } from "./types";

const getStyles = makeStyles((theme: Theme) => ({
  button: {
    marginRight: theme.spacing(1)
  },
  actionsContainer: {
    marginLeft: theme.spacing(2),
    display: "flex",
    padding: "0 5px"
  },
  resetContainer: {
    padding: theme.spacing(3)
  }
}));

export const StepperMUI = ({ stepsConfig }: StepperMUIProps) => {
  const [state, dispatch] = useReducer(reducer, init(stepsConfig));

  const { activeStep, stepsPassProps, isLoading } = state;

  useEffect(() => {
    const source = createAxiosCancelToken();
    const t = stepsConfig[activeStep];
    const activeStepConfig = typeof t === "function" ? t() : t;
    const activeStepPassProp = stepsPassProps[activeStep];
    if (
      (activeStepConfig && activeStepConfig.config) ||
      (activeStepPassProp && activeStepPassProp.config)
    ) {
      if (!isLoading) {
        // @ts-ignore
        dispatch(setIsLoading());
      }
      downLoadStuff(
        activeStepConfig.config || activeStepPassProp.config,
        source
      ).then(downLoaded => {
        // @ts-ignore
        dispatch(setStepPassProps(activeStep, { ...downLoaded }));
      });
    }
    return () => {
      source.cancel("Cancelling in cleanup");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep]);

  const classes = getStyles();

  // Ссылка на на рефернс формика текущего шага
  const submitRef = useRef<Function | null>(null);
  const bindFormSubmit = useCallback((ref: Function) => {
    submitRef.current = ref;
  }, []);
  const [isDisabled, setIsDisabled] = useState(false);
  return (
    <Paper>
      <Stepper activeStep={activeStep} orientation="vertical">
        {stepsConfig.map((stepConfig, index) => {
          const {
            label,
            Component,
            additionalProps,
            requiresFormSubmitRef = false,
            stepButtons: StepButtons
          } = typeof stepConfig === "function" ? stepConfig() : stepConfig;

          const { onSubmit, ...restAdditionalProps } = additionalProps || {
            onSubmit: undefined
          };
          const currentStepPassProps = stepsPassProps[index];
          const wrappedSubmit = onSubmit
            ? onSubmit({
                passProps: currentStepPassProps,
                dispatch
              })
            : () => {};
          return (
            <Step active={activeStep >= index} key={label}>
              <StepLabel>{label}</StepLabel>
              {/*// @ts-ignore*/}
              <StepContent active={activeStep >= index}>
                {isLoading ? (
                  defaultPreloader({})
                ) : (
                  <>
                    <Component
                      onSubmit={wrappedSubmit}
                      {...restAdditionalProps}
                      bindFormSubmit={bindFormSubmit}
                      enableReinitialize
                      {...currentStepPassProps}
                      readOnly={activeStep !== index}
                      bindIsSubmitting={setIsDisabled}
                    />
                    {isDisabled && activeStep === index && <LinearProgress />}
                    {activeStep === index && (
                      <div className={classes.actionsContainer}>
                        <Button
                          disabled={activeStep === 0}
                          onClick={() => decreaseActiveStep(dispatch)}
                          className={classes.button}
                          variant="contained"
                        >
                          Назад
                        </Button>
                        {activeStep !== stepsConfig.length - 1 && (
                          <Button
                            variant="contained"
                            color="primary"
                            disabled={isDisabled}
                            onClick={() => {
                              if (requiresFormSubmitRef) {
                                // Удаленный вызов отправки в форме
                                if (typeof submitRef.current === "function") {
                                  submitRef.current();
                                }
                              } else {
                                // @ts-ignore
                                dispatch(increaseActiveStep({}));
                              }
                            }}
                            className={classes.button}
                          >
                            Далее
                          </Button>
                        )}
                        {StepButtons && (
                          <StepButtons
                            {...currentStepPassProps}
                            isDisabled={isDisabled}
                            setIsDisabled={setIsDisabled}
                          />
                        )}
                      </div>
                    )}
                  </>
                )}
              </StepContent>
            </Step>
          );
        })}
      </Stepper>
    </Paper>
  );
};

StepperMUI.propTypes = {
  stepsConfig: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        Component: PropTypes.func.isRequired,
        label: PropTypes.string
      }),
      PropTypes.func
    ])
  ).isRequired
} as any;
