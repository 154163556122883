import { Timer } from "@material-ui/icons";
import { baseJournal } from "./basejournal";
import { PageT } from "../../types";
import { addPerformer } from "../claim/inWork/journal";

const notwork: PageT = {
  ...baseJournal,
  icon: Timer,
  navigationLabel: "Просроченные беседы",
  url: () => addPerformer("/api/slot/journal/notwork")
};
export default notwork;
