import React from "react";
import { Formik, FormikConfig, FormikValues } from "formik";
import { connect } from "react-redux";
// @ts-ignore
import FormikErrorFocus from "formik-error-focus";
import { RenderTableForm } from "./variants/table/renderTableForm";
import { RenderBlockForm } from "./variants/block/blockForm";
import { FormObjT, OptionsMapType } from "../../constants/types";
import { RootStateT } from "../../reducers";
import { AuthStateT } from "../../reducers/auth";
import { ModalT } from "../wrapper/types";

export type FormVariant = "block" | "table" | "xmlToForm";

export type FormikMUIProps = FormikConfig<FormikValues> & {
  formObj: FormObjT;
  bindFormSubmit?: (f: Function) => void;
  optionsMap: OptionsMapType;
  readOnly?: boolean;
  variant?: FormVariant;
  additionalStatusProps?: {};
  bindIsSubmitting?: (v: boolean) => void;
  bindDirty?: (v: boolean) => void;
  style?: any;
  auth: AuthStateT;
  readOnlyFields?: string[];
  modal?: ModalT;
  id?: string;
};

const PrivateFormikMUI = ({
  formObj,
  onSubmit,
  bindFormSubmit,
  initialValues,
  optionsMap = {},
  readOnly = false,
  variant = "block",
  enableReinitialize = false,
  additionalStatusProps,
  bindIsSubmitting,
  bindDirty,
  style,
  auth,
  readOnlyFields,
  modal,
  id
}: FormikMUIProps) => {
  return (
    <Formik
      enableReinitialize={enableReinitialize}
      onSubmit={onSubmit}
      validateOnChange={false}
      validateOnBlur={!readOnly}
      validationSchema={formObj.validationSchema}
      initialValues={initialValues}
      initialStatus={{
        optionsMap,
        readOnly,
        auth,
        readOnlyFields,
        modal,
        ...additionalStatusProps
      }}
    >
      {({
        handleSubmit,
        submitForm,
        setFieldValue,
        values,
        isSubmitting,
        setStatus,
        status,
        dirty
      }) => {
        if (typeof bindFormSubmit === "function") {
          // bind the submission handler remotely
          bindFormSubmit(submitForm);
        }
        if (typeof bindIsSubmitting === "function") {
          bindIsSubmitting(isSubmitting);
        }
        if (typeof bindDirty === "function") {
          bindDirty(dirty);
        }

        return (
          <>
            {variant === "block" ? (
              <RenderBlockForm
                // @ts-ignore
                formObj={formObj}
                handleSubmit={handleSubmit}
                style={style}
                submitOnEnterPress={formObj.submitOnEnterPress}
                id={id}
              />
            ) : null}
            {variant === "table" ? (
              <RenderTableForm
                // @ts-ignore
                confObj={formObj}
                values={values}
                initialValues={initialValues}
                readOnly={readOnly}
                setFieldValue={setFieldValue}
                auth={auth}
                status={status}
                id={id}
              />
            ) : null}
            <FormikErrorFocus
              // See scroll-to-element for configuration options: https://www.npmjs.com/package/scroll-to-element
              offset={0}
              align="top"
              focusDelay={100}
              ease="linear"
              duration={500}
            />
          </>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state: RootStateT) => ({
  auth: state.auth
});

export const FormikMUI = connect(mapStateToProps)(PrivateFormikMUI);
