import {
  baseTextAreaProps,
  baseTimeFieldProps,
  timeDoBeforeSend
} from "../../baseFieldProps";
import {
  rebuildReceivedOptionsLabelAsLabelUniqueIdAsValue,
  rebuildReceivedOptionsLabelAsLabelUniqueIdAsValueAddMainPerformerFlag
} from "../../rebuildRecieved";
import { rebuildMinistrationPerformersBeforeSend } from "./createMinistration";
import { PerformerT } from "../../../components/schedule/types";
import { doBeforeSend } from "../../baseFormProps";
import { localizedYup, timeSchema } from "../../yup";
import { ExtendedBlockFormObj } from "../../../components/modalWrapper/types";
import { openPublishMinistrationConfirmDialog } from "../../../components/schedule/utils/journal";
import { popHeadModal } from "../../../actions/typedActions";

export interface Resp {
  isMainPerformer: boolean;
  isExternal: boolean;
  fullName: string;
  id: string;
}

export const mapPerformerFormBackEnd = ({
  isMainPerformer,
  isExternal,
  fullName,
  id
}: Resp): PerformerT => {
  const resultingPerformer: PerformerT = {
    label: fullName,
    value: isExternal ? id : Number.parseInt(id, 10),
    isMainPerformer
  };
  if (isExternal) {
    // eslint-disable-next-line no-underscore-dangle
    resultingPerformer.__isNew__ = isExternal;
  }
  return resultingPerformer;
};

export const viewMinistration: ExtendedBlockFormObj = {
  url: "/api/ministration",
  title: "Богослужения",
  headerText: "objectTypeLabel",
  method: "patch",
  doBeforeSend,
  blocks: [
    {
      fields: [
        { name: "objectTypeLabel", type: "text", hidden: true },
        { name: "uniqueId", type: "text", hidden: true },
        {
          label: "Название храма",
          name: "hramName",
          ...baseTextAreaProps,
          readOnly: true
        },
        {
          label: "Тип",
          name: "objectTypeCode",
          type: "select",
          placeholder: "Выберите тип",
          rebuildReceivedValue: (o, { objectTypeLabel, objectTypeCode }) => ({
            label: objectTypeLabel,
            value: objectTypeCode
          }),
          dictUrl: "/api/ministration/type",
          rebuildReceivedOptions: rebuildReceivedOptionsLabelAsLabelUniqueIdAsValue
        },
        {
          label: "Дата начала",
          name: "dateTimestamp",
          type: "date",
          doBeforeSend: (value, values) =>
            `${value.split(" ")[0]} ${timeDoBeforeSend(values.timeTimestamp)}`
        },
        { label: "Время начала", name: "timeTimestamp", ...baseTimeFieldProps },
        {
          label: "Дата окончания",
          name: "dateEndTimestamp",
          type: "date",
          doBeforeSend: (value, values) =>
            `${value.split(" ")[0]} ${timeDoBeforeSend(
              values.endTimeTimestamp
            )}`
        },
        {
          label: "Время окончания",
          name: "endTimeTimestamp",
          ...baseTimeFieldProps
        },
        {
          label: "Описание",
          name: "description",
          ...baseTextAreaProps
        },
        {
          label: "Включен в отчет",
          name: "isReport",
          readOnly: true,
          type: "checkbox"
        },
        { name: "isPublished", type: "checkbox", label: "Опубликован" },
        {
          label: "Священнослужитель",
          name: "performers",
          type: "select",
          placeholder: "Выберите священнослужителей",
          dictUrl: "/api/ministration/priest",
          isCreatable: true,
          multiple: true,
          rebuildReceivedOptions: rebuildReceivedOptionsLabelAsLabelUniqueIdAsValueAddMainPerformerFlag,
          doBeforeSend: rebuildMinistrationPerformersBeforeSend,
          multiValueFlipPath: "isMainPerformer",
          selectAllOptions: {
            label: "Всё духовенство"
          },
          rebuildReceivedValue: mapPerformerFormBackEnd
        },
        { name: "canEdit", type: "checkbox", hidden: true }
      ]
    }
  ],
  validationSchema: localizedYup.object({
    objectTypeCode: localizedYup
      .object()
      .nullable()
      .required(),
    performers: localizedYup.array(localizedYup.object().nullable()).min(1),
    description: localizedYup.string(),
    dateTimestamp: localizedYup.string().required(),
    dateEndTimestamp: localizedYup
      .string()
      // @ts-ignore
      .compareDateTo(localizedYup.ref("dateTimestamp"), undefined, true)
      .required(),
    timeTimestamp: timeSchema.required(),
    endTimeTimestamp: timeSchema
      // @ts-ignore
      .compareTimeTo(localizedYup.ref("timeTimestamp"))
      .required(),
    isReport: localizedYup.bool()
  }),
  additionalActionButtonsDependedOnProps: [
    {
      conditionallyRender: ({
        modal,
        firstFormInitialValues: { isPublished, canEdit }
      }) => !modal.readonly && !isPublished && canEdit,
      text: "Опубликовать",
      color: "primary",
      variant: "outlined",
      onClick: ({
        firstFormInitialValues,
        dispatch,
        modal: { successCallback }
      }) => {
        if (successCallback)
          openPublishMinistrationConfirmDialog(
            dispatch,
            firstFormInitialValues.uniqueId,
            () => {
              dispatch(popHeadModal());
              successCallback();
            }
          );
      }
    }
  ]
};
