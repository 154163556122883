import { format, parse } from "date-fns";
import { ru } from "date-fns/locale";

export const russianDatePattern = "dd.MM.yyyy";
export const isoDatePattern = "yyyy-MM-dd";
const timePattern = `HH:mm`;
export const russianDateTimePattern = `${russianDatePattern} ${timePattern}`;
export const baseDateFnsOptions = { locale: ru };

export const russianDateTime = (value: Date) =>
  format(value, `${russianDatePattern} HH:mm:ss`, baseDateFnsOptions);

export const parseRussianDate = (value: string) =>
  parse(value, russianDatePattern, new Date(), baseDateFnsOptions);

export const parseRussianDateTime = (value: string) =>
  parse(value, russianDateTimePattern, new Date(), baseDateFnsOptions);

export const formatToRussianDate = (date: Date | number) =>
  format(date, russianDatePattern, baseDateFnsOptions);

export const formatToISODate = (date: Date | number) =>
  format(date, isoDatePattern, baseDateFnsOptions);
