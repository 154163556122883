import { get, set } from "lodash";
import { isValid } from "date-fns";
import { BlockFormObj, IField } from "../../../constants/types";
import { AuthStateT } from "../../../reducers/auth";
import { parseRussianDate } from "../../../constants/formaters/formatters";
import { mapLabelAsLabelUniqueIdAsValue } from "../../../constants/rebuildRecieved";
import { getAuth } from "../../../store";

export const constructFieldInitialValue = (
  field: IField,
  authReducerState: AuthStateT
) => {
  const { defaultValue } = field;

  if (defaultValue) {
    if (typeof defaultValue === "function") {
      return defaultValue(authReducerState.functions, authReducerState);
    }
    return defaultValue;
  }

  switch (field.type) {
    case "text": {
      return "";
    }
    case "select": {
      if (field.type === "select" && field.multiple) {
        return [];
      }
      return null;
    }
    case "checkbox": {
      return false;
    }
    case "date": {
      return "";
    }
    case "date-range": {
      return [];
    }
    case "file": {
      return null;
    }
    case "fieldArray": {
      return [];
    }
    case "password": {
      return "";
    }
    case "formatted": {
      return "";
    }
    case "date-new": {
      return null;
    }
    case "custom": {
      break;
    }
    case "spaceFiller": {
      break;
    }
    case "address": {
      return "";
    }
    default: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const never: never = field;
    }
  }
};

export const createEmptyFieldArrayObj = (
  fields: IField[],
  auth: AuthStateT
) => {
  const result: {
    [x: string]: any;
  } = {};
  fields.forEach(field => {
    set(result, field.name, constructFieldInitialValue(field, auth));
  });
  return result;
};

export const constructInitialValuesForEmptyFormWithOutBlocks = (
  confObj: {
    fields: IField[];
  },
  auth: AuthStateT
) => {
  const initialValues = {};
  confObj.fields.forEach(field =>
    set(initialValues, field.name, constructFieldInitialValue(field, auth))
  );
  return initialValues;
};

export const constructInitialValuesForEmptyForm = (formObj: BlockFormObj) => {
  const initialValues = {};
  const authReducerState = typeof getAuth === "function" ? getAuth() : {};
  formObj.blocks.forEach(({ fields }) =>
    fields.forEach(field =>
      set(
        initialValues,
        field.name,
        constructFieldInitialValue(field, authReducerState)
      )
    )
  );
  return initialValues;
};

export const rebuildDownloadedInitialValue = (
  value: any,
  field: IField,
  authReducerState: AuthStateT,
  receivedInitialValues: any
) => {
  const { rebuildReceivedValue } = field;

  if (rebuildReceivedValue && field.type !== "select") {
    return rebuildReceivedValue(value, receivedInitialValues);
  }

  switch (field.type) {
    case "text": {
      if (value !== null && typeof value !== "undefined") {
        return value;
      }
      return constructFieldInitialValue(field, authReducerState);
    }
    case "select": {
      if (typeof value !== "undefined") {
        if (rebuildReceivedValue === false) {
          return value;
        }
        if (value !== null && field.multiple !== true) {
          // Специфичная обработка для этого типа из-за поддержки по-умолчанию object URI для SPRING DATA REST
          return rebuildReceivedValue
            ? rebuildReceivedValue(value, receivedInitialValues)
            : mapLabelAsLabelUniqueIdAsValue(value);
        }
        if (Array.isArray(value) && field.multiple === true) {
          return value.map(el =>
            rebuildReceivedValue
              ? rebuildReceivedValue(el, receivedInitialValues)
              : mapLabelAsLabelUniqueIdAsValue(el)
          );
        }
      }
      return constructFieldInitialValue(field, authReducerState);
    }
    case "checkbox": {
      if (value === true || value === false) {
        return value;
      }
      if (value === "true" || value === "false") {
        return value === "true";
      }
      return constructFieldInitialValue(field, authReducerState);
    }
    case "date-range": {
      return constructFieldInitialValue(field, authReducerState);
    }
    case "date": {
      if (value && value !== "") {
        return value;
      }
      return constructFieldInitialValue(field, authReducerState);
    }
    case "file": {
      if (value !== null || typeof value !== "undefined") {
        return value;
      }
      return constructFieldInitialValue(field, authReducerState);
    }
    case "spaceFiller": {
      return undefined;
    }
    case "custom": {
      return value;
    }
    case "date-new": {
      if (isValid(parseRussianDate(value))) {
        return value;
      }
      return null;
    }
    case "fieldArray": {
      if (Array.isArray(value)) {
        return value.map(fieldInArrayValue => {
          const resultingFieldInArrayValue = {};
          field.fields.forEach((fieldInArray): void => {
            set(
              resultingFieldInArrayValue,
              fieldInArray.name,
              rebuildDownloadedInitialValue(
                get(fieldInArrayValue, fieldInArray.name),
                fieldInArray,
                authReducerState,
                receivedInitialValues
              )
            );
          });
          return resultingFieldInArrayValue;
        });
      }
      return [];
    }
    case "formatted": {
      if (typeof value === "string" || typeof value === "number") {
        return value;
      }
      return "";
    }
    case "password": {
      if (typeof value === "string") {
        return value;
      }
      return "";
    }
    case "address": {
      if (typeof value === "string") {
        return value;
      }
      return "";
    }
    default: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const never: never = field;
      /* throw new Error(
        `не найдено обработчика для поля ${field.name} с типом ${type}`
      ); */
    }
  }
};

export const baseRebuildReceivedInitialValues = (
  formObj: BlockFormObj,
  receivedInitialValues: any
) => {
  const resultingInitialValues = {};
  const authReducerState = getAuth();
  formObj.blocks.forEach(({ fields }) =>
    fields.forEach(field => {
      set(
        resultingInitialValues,
        field.name,
        rebuildDownloadedInitialValue(
          get(receivedInitialValues, field.name),
          field,
          authReducerState,
          receivedInitialValues
        )
      );
    })
  );
  return resultingInitialValues;
};
