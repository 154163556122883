/* eslint-disable no-template-curly-in-string */
import * as Yup from "yup";
import { isBefore, isSameDay, isValid } from "date-fns";
import axios from "axios";
import { parseRussianDate } from "./formaters/formatters";
import {
  containsOneOrMoreDigitsRegExp,
  containsOneOrMoreLowerCaseCharRegExp,
  containsOneOrMoreSpecialSymbolRegExp,
  containsOneOrMoreUpperCaseCharRegExp,
  dateRegExp,
  passwordRegExp,
  timeRegExp,
  uuidV4RegExp
} from "./regularExpressions";

export const localizedYup = Yup;

Yup.setLocale({
  mixed: {
    required: "Обязательное поле"
  },
  number: {
    // default: "Значение поля ${number} должно быть числом",
    min: 'Значение поля "${value}" должно быть больше или равно ${min}',
    // max: '${path} must be less than or equal to ${max}',
    // lessThan: '${path} must be less than ${less}',
    // moreThan: '${path} must be greater than ${more}',
    // notEqual: '${path} must be not equal to ${notEqual}',
    positive: 'Значение поля "${value}" должно быть положительным числом',
    negative: 'Значение поля "${value}" должно быть отрицательным числом',
    integer: 'Значение поля "${value}" должно быть целым числом'
  },
  string: {
    matches: "Должно соответсвовать регуляроному выражению ${regex}",
    url: 'Значение поля "${value}" должно быть URL адресом',
    min: "Неоходимо ввести минимум ${min} символов",
    max: "Неоходимо ввести максимум ${max} символов"
  },
  array: {
    min: "Число элементов должно быть не меньше ${min}"
  }
});

export function isTimeAfter(
  value?: string,
  otherValue?: string,
  isTimeEqual: boolean = false
) {
  if (
    typeof value === "string" &&
    typeof otherValue === "string" &&
    timeRegExp.test(value) &&
    timeRegExp.test(otherValue)
  ) {
    const hourValue = Number.parseInt(value.slice(0, 2), 10);
    const hourOtherValue = Number.parseInt(otherValue.slice(0, 2), 10);
    // передать true в isTimeEqual если нужно, чтобы валидация проходила при совпадении часов и минут
    if (isTimeEqual) {
      if (hourValue === hourOtherValue) {
        return (
          Number.parseInt(value.slice(3, 5), 10) -
            Number.parseInt(otherValue.slice(3, 5), 10) >=
          0
        );
      }
      return hourValue - hourOtherValue >= 0;
    } else {
      if (hourValue === hourOtherValue) {
        return (
          Number.parseInt(value.slice(3, 5), 10) -
            Number.parseInt(otherValue.slice(3, 5), 10) >
          0
        );
      }
      return hourValue - hourOtherValue > 0;
    }
  }
  return true;
}

Yup.addMethod(Yup.string, "compareTimeTo", function compareTimeTo(ref, msg) {
  return this.test({
    name: "compareTimeTo",
    exclusive: false,
    message: msg || "Время окончания должно быть позже начала", // "${path} должно быть позже ${reference}"
    params: {
      reference: ref.path
    },
    test(value) {
      const otherValue = this.resolve(ref);
      return isTimeAfter(value, otherValue);
    }
  });
});

Yup.addMethod(Yup.string, "compareDateTo", function compareDateTo(
  ref,
  msg,
  allowEquality: true
) {
  return this.test({
    name: "compareDateTo",
    exclusive: false,
    message: msg || 'Дата "по" должна быть позже даты "с"', // "${path} должно быть позже ${reference}"
    params: {
      reference: ref.path
    },
    test(value) {
      const otherValue = this.resolve(ref);
      if (typeof value === "string" && typeof otherValue === "string") {
        const start = parseRussianDate(value);
        const end = parseRussianDate(otherValue);
        if (isValid(start) && isValid(end)) {
          if (allowEquality) {
            return isBefore(end, start) || isSameDay(end, start);
          }
          return isBefore(end, start);
        }
        return true;
      }
      return true;
    }
  });
});

/* localizedYup.addMethod(Yup.string, "time", (a: StringSchema) => {
  a.matches(/^\d{2}:\d{2}:\d{2}$/, {
    message: "Заполните поле времени полностью"
  });
  return a;
}); */

export const timeSchema = localizedYup.string().matches(timeRegExp, {
  message: "Заполните поле времени полностью"
});

export const passwordSchema = localizedYup
  .string()
  .matches(containsOneOrMoreDigitsRegExp, "Должен содержать минимум 1 цифру")
  .matches(
    containsOneOrMoreLowerCaseCharRegExp,
    "Должен содержать минимум букву в нижнем регистре"
  )
  .matches(
    containsOneOrMoreUpperCaseCharRegExp,
    "Должен содержать минимум букву в верхнем регистре"
  )
  .matches(
    containsOneOrMoreSpecialSymbolRegExp,
    "Должен содержать минимум 1 спецсимвол"
  )
  .min(8, "Длина должна быть минимум 8 символов")
  .matches(
    passwordRegExp,
    "Пароль должен быть длиной не менее 8 символов и содержать: минимум 1 цифру, 1 букву в нижнем регистре, 1 букву в верхнем, 1 спец символ"
  );

export const passwordSchemaAsyncCheckPreviousPasswordsForEquality = passwordSchema.test(
  "phone",
  "Пароль уже использовался ранее",
  async function checkIfEqualToOneOfPreviousPasswords(value) {
    const loginRef = localizedYup.ref("login");
    if (loginRef && value) {
      // @ts-ignore
      const login = loginRef.getValue(this);
      if (login) {
        const userIdRef = localizedYup.ref("id");
        const { data } = await axios.get(
          "/api/user/checkPreviousPasswordsForEquality",
          {
            params: {
              // @ts-ignore
              login,
              newPassword: value,
              // @ts-ignore
              userId: userIdRef ? userIdRef.getValue(this) : undefined
            }
          }
        );
        return data;
      }
    }
    return true;
  }
);

export const dateStringSchema = localizedYup.string().matches(dateRegExp);

export const uuidSchema = localizedYup
  .string()
  .transform(value => value.toLowerCase())
  .matches(uuidV4RegExp);
