import React from "react";
import { Button, Theme, Typography } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import { FieldProps } from "formik";
import clsx from "clsx";
import { red } from "@material-ui/core/colors";
import { CellProps } from "./types";
import { ModalT } from "../wrapper/types";

type MonthWordCellProps = CellProps &
  FieldProps & {
    openModal: (m: ModalT) => void;
    successCallback: () => void;
    hasAdminRights: boolean;
    isIE: boolean;
  };

const getStyles = makeStyles<Theme>(() => ({
  redColor: {
    color: red["500"]
  },
  grid: {
    display: "-ms-grid",
    // @ts-ignore
    // eslint-disable-next-line no-dupe-keys
    display: "grid",
    gridTemplateRows: "auto auto",
    msGridRows: "auto auto"
  },
  firstRow: {
    msGridRow: 1
  },
  secondRow: {
    msGridRow: 2
  },
}));

export const MonthWordCell: React.FC<MonthWordCellProps> = ({
  openModal,
  field: { value },
  rowData: { day, isHoliday },
  successCallback,
  hasAdminRights
}: MonthWordCellProps) => {
  const classes = getStyles();
  return (
    <div className={classes.grid}>
      {value && (
        <Typography
          className={clsx({ [classes.redColor]: isHoliday }, classes.firstRow)}
        >
          {value.label}
        </Typography>
      )}
      <Button
        className={classes.secondRow}
        variant="text"
        color="primary"
        size="small"
        onClick={() =>
          openModal({
            category: "schedule",
            mode: hasAdminRights ? "editMonthWord" : "viewMonthWord",
            initialValues: {
              date: day
            },
            readonly: !hasAdminRights,
            successCallback
          })
        }
      >
        <ExpandMore /> Раскрыть Месяцеслов
      </Button>
    </div>
  );
};
