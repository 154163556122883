import { OptionT, SelectValueT } from "../types";

export interface DataRestStructure<T = any> {
  ["_embedded"]: {
    [x: string]: T;
  };
  page: {
    totalElements: number;
  };
}

export interface ContainsLabel {
  label: string;
}

interface ContainsFullName {
  fullName: string;
}

interface ContainsSurnameInitials {
  surnameInitials: string;
}

export interface ContainsUniqueId {
  uniqueId: number;
}

interface ContainsEnum {
  enum: string;
}

interface ContainsAccost {
  accost: string;
}

interface ContainsCode {
  code: string;
}

export interface ContainsName {
  name: string;
}

export const extractPayloadFromBaseSpringDateRestStructure = <T = any>(
  data: DataRestStructure<T[]>
): T[] =>
  // eslint-disable-next-line no-underscore-dangle
  data._embedded ? Object.values(data._embedded)[0] : [];

export const rebuildReceivedValueLabelFullNameValueUniqueId = ({
  uniqueId,
  fullName
}: ContainsUniqueId & ContainsFullName) => ({
  label: fullName,
  value: uniqueId
});

export const rebuildReceivedValueLabelAccostFullNameValueId = ({
  id,
  accost,
  fullname
}: ContainsAccost & any) => ({
  label: `${accost} - ${fullname}`,
  value: id
});

export const rebuildReceivedValueLabelLabelValueUniqueId = ({
  uniqueId,
  label,
  enum: enumName
}: ContainsLabel & ContainsUniqueId & ContainsEnum) => ({
  label,
  value: uniqueId,
  enum: enumName
});

export const rebuildReceivedValueLabelLabelValueLabel = ({
  label
}: ContainsLabel) => ({
  label,
  value: label
});

export const rebuildReceivedOptionsLabelAsLabelUniqueIdAsValue = (
  data: (ContainsLabel & ContainsUniqueId & any)[]
) => data.map(rebuildReceivedValueLabelLabelValueUniqueId);

export const rebuildReceivedOptionsAccostAndFullNameAsLabelUniqueIdAsValue = (
  data: (ContainsUniqueId & ContainsAccost & any)[]
) => {
  const mappedPrists = data.map(priest => {
    return {
      fullName: priest.fullname,
      uniqueId: priest.id,
      ...priest
    };
  });
  return mappedPrists.map(mapAccostAndFullNameAsLabelUniqueIdAsValue);
};

export const constructRebuildReceivedOptions = <T = any>(
  functionToCallForEach: (p: any) => OptionT
) => (data: DataRestStructure<T[]>): OptionT[] =>
  extractPayloadFromBaseSpringDateRestStructure(data).map(
    functionToCallForEach
  );

export const constructRebuildReceivedOptionsLabelFullNameValueUniqueId = (
  data: any
) =>
  extractPayloadFromBaseSpringDateRestStructure(data).map(
    rebuildReceivedValueLabelFullNameValueUniqueId
  );

export const constructRebuildReceivedOptionsLabelLabelValueUniqueId = (
  data: any
) =>
  rebuildReceivedOptionsLabelAsLabelUniqueIdAsValue(
    extractPayloadFromBaseSpringDateRestStructure(data)
  );

export const rebuildOptionsMapLabelLabelValueLabel = (data: ContainsLabel[]) =>
  data.map(rebuildReceivedValueLabelLabelValueLabel);

export const constructRebuildReceivedOptionsLabelLabelValueLabel = (
  data: any
) =>
  extractPayloadFromBaseSpringDateRestStructure(data).map(
    rebuildReceivedValueLabelLabelValueLabel
  );

type ReceivedUserRole = ContainsLabel & ContainsUniqueId & ContainsName;

export const mapLabelAsLabelUniqueIdAsValueNameAsEnum = ({
  uniqueId,
  label,
  name
}: ReceivedUserRole): OptionT => ({
  label,
  value: uniqueId,
  enum: name
});

export const rebuildRecievedOptionsMapLabelAsLabelUniqueIdAsValueNameAsEnum = (
  data: ReceivedUserRole[]
) => data.map(mapLabelAsLabelUniqueIdAsValueNameAsEnum);

export const mapSurinameInitialsAsLabelUniqueIdAsValue = ({
  surnameInitials,
  uniqueId
}: ContainsSurnameInitials & ContainsUniqueId) => ({
  label: surnameInitials,
  value: uniqueId
});

export const constructRebuildReceivedOptionsMapSurinameInitialsAsLabelUniqueIdAsValue = (
  data: (ContainsUniqueId & ContainsSurnameInitials)[]
) => data.map(mapSurinameInitialsAsLabelUniqueIdAsValue);

export const OptionsMapSurinameInitialsAsLabelUniqueIdAsValueAddIsMainPerformer = (
  data: (ContainsUniqueId & ContainsSurnameInitials)[]
) =>
  data.map(({ uniqueId, surnameInitials }) => ({
    label: surnameInitials,
    value: uniqueId,
    isMainPerformer: false
  }));

export const mapAccostAsLabelUniqueIdAsValue = ({
  uniqueId,
  accost
}: ContainsAccost & ContainsUniqueId) => ({
  label: accost,
  value: uniqueId,
  isMainPerformer: false
});

export const mapAccostAndFullNameAsLabelUniqueIdAsValue = ({
  uniqueId,
  accost,
  fullName
}: ContainsUniqueId & ContainsAccost & ContainsFullName) => ({
  label: `${accost} - ${fullName}`,
  value: uniqueId
});

export const reduceLabelFromArrayOfObjectsWithLabel = (
  values: { label: string }[]
) => {
  return values
    .reduce((previousLabel, value) => {
      return (previousLabel += `${value.label}, `);
    }, "")
    .slice(0, -2);
};

export const OptionsMapAccostAsLabelUniqueIdAsValue = (
  data: (ContainsAccost & ContainsUniqueId)[]
) => data.map(mapAccostAsLabelUniqueIdAsValue);

export const mapLabelAsLabelCodeAsValue = ({
  label,
  code
}: ContainsCode & ContainsLabel) => ({ label, value: code });

export const OptionsMapLabelAsLabelCodeAsValue = (
  data: (ContainsLabel & ContainsCode)[]
) => data.map(mapLabelAsLabelCodeAsValue);

export const extractFromDataRestStructureOptionsMapLabelAsLabelCodeAsValue = (
  data: DataRestStructure<(ContainsLabel & ContainsCode)[]>
) =>
  OptionsMapLabelAsLabelCodeAsValue(
    extractPayloadFromBaseSpringDateRestStructure(data)
  );

export const rebuildReceivedOptionsLabelAsLabelUniqueIdAsValueAddMainPerformerFlag = (
  data: (ContainsLabel & ContainsUniqueId & any)[]
) => data.map(value => ({ ...value, isMainPerformer: false }));

export const rebuildReceivedOptionsLabelAsLabelUniqueIdAsValueAddMainPerformerFlagIsDailyDutyOnly = (
  data: (ContainsLabel & ContainsUniqueId & any)[]
) =>
  data
    .filter(value => value.isDailyDuty)
    .map(value => ({ ...value, isMainPerformer: false }));

export const mapAccostAsLabelIdAsValue = ({
  accost,
  id
}: ContainsAccost & { id: number }): OptionT => ({ value: id, label: accost });

export const mapLabelAsAccostValueAsId = ({
  label,
  value
}: OptionT): ContainsAccost & { id: number } => ({ accost: label, id: value });

type ContainsAccostAndID = ContainsAccost & { id: number };

export const rebuildRecivedMapAccostAsLabelIdAsValue = (
  data: ContainsAccostAndID[]
): OptionT[] => data.map(mapAccostAsLabelIdAsValue);

export const doBeforeSendMapLabelAsAccostValueAsId = (data: SelectValueT) =>
  Array.isArray(data) && data.map(mapLabelAsAccostValueAsId);

export const extractFromDataRestStructureOptionsMapLabelAsLabelNameAsValue = (
  data: any
) =>
  extractPayloadFromBaseSpringDateRestStructure(data).map(
    ({ label, name }) => ({
      label,
      value: name
    })
  );

export const mapLabelAsLabelAndValue = ({ label }: ContainsLabel) => ({
  label,
  value: label
});

export const optionsMapLabelAsLabelAndValue = (
  options: ContainsLabel[]
): OptionT[] => options.map(mapLabelAsLabelAndValue);

export const extractFromDataRestStructureMapLabelAsLabelAndValue = (
  data: DataRestStructure<ContainsLabel[]>
) =>
  optionsMapLabelAsLabelAndValue(
    extractPayloadFromBaseSpringDateRestStructure(data)
  );

export const mapLabelAsLabelNameAsValue = ({
  name,
  label
}: ContainsLabel & ContainsName): OptionT => ({ value: name, label });

export const optionsMapLabelAsLabelNameAsValue = (
  options: (ContainsLabel & ContainsName)[]
): OptionT[] => options.map(mapLabelAsLabelNameAsValue);

export const extractFromDataRestStructureMapLabelAsLabelNameAsValue = (
  data: DataRestStructure<(ContainsLabel & ContainsName)[]>
) =>
  optionsMapLabelAsLabelNameAsValue(
    extractPayloadFromBaseSpringDateRestStructure(data)
  );

export const mapLabelAsLabelUniqueIdAsValue = ({
  label,
  uniqueId
}: ContainsLabel & ContainsUniqueId): OptionT => ({ label, value: uniqueId });

export const optionsMapLabelAsLabelUniqueIdAsValue = (
  data: (ContainsLabel & ContainsUniqueId)[]
): OptionT[] => data.map(mapLabelAsLabelUniqueIdAsValue);

export const extractFromDataRestStructureMapLabelAsLabelUniqueIdsValue = (
  data: DataRestStructure<(ContainsLabel & ContainsUniqueId)[]>
) =>
  optionsMapLabelAsLabelUniqueIdAsValue(
    extractPayloadFromBaseSpringDateRestStructure(data)
  );
