import { TableColumnT } from "../../constants/types";

export function getWidth(
  tableHeader: TableColumnT[],
  columnWidth?: number | string
) {
  return (
    columnWidth ||
    (tableHeader.length < 5 ? `${100 / tableHeader.length}%` : "25%")
  );
}
