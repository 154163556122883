/* eslint-disable react/jsx-no-duplicate-props */
import React, { memo, useRef, useState } from "react";
import { IconButton, InputAdornment, Popover } from "@material-ui/core";
import "react-day-picker/lib/style.css";
import { Close, CalendarToday } from "@material-ui/icons";
import { TextFieldProps } from "@material-ui/core/TextField";
import { isValid } from "date-fns";
import { Modifier } from "react-day-picker";
import { createAutoCorrectedDatePipe } from "text-mask-addons/dist/textMaskAddons";
import {
  parseRussianDate,
  formatToRussianDate
} from "../../constants/formaters/formatters";
import { DayPickerMUI, DayPickerMUIProps } from "./DayPickerMUI";
import { MaskedInputMUI } from "../input/maskedInputMUI";

const dayReducer = (
  previousValue: any,
  currentValue: string | number | Date
): string => {
  if (currentValue && typeof currentValue === "string") {
    const date = parseRussianDate(currentValue);
    if (isValid(date)) {
      return `${previousValue}, ${formatToRussianDate(date)}`;
    }
  }
  return previousValue;
};

function getDisplayedText(value: Date | Date[] | string | string[]): string {
  let displayedText = "";
  if (typeof value === "string") {
    return value;
  }
  if (Array.isArray(value)) {
    // @ts-ignore проверил что это массив ТС в чем проблема?
    displayedText = value.reduce(dayReducer, "");
  } else if (isValid(value)) {
    const formattedDate = formatToRussianDate(value);
    if (formattedDate !== "Invalid Date") displayedText = formattedDate;
  }
  return displayedText;
}

// eslint-disable-next-line consistent-return
function getParsedDays(
  value: Date | Date[] | string | string[]
): Modifier | Modifier[] {
  if (typeof value === "string") {
    const parsedV = parseRussianDate(value);
    if (isValid(parsedV)) {
      return parsedV;
    }
    return undefined;
  }
  if (!Array.isArray(value) && isValid(value)) {
    return value;
  }
  if (Array.isArray(value)) {
    // @ts-ignore
    return value.map(
      (day: string | Date): Date => {
        if (typeof day === "string") {
          return parseRussianDate(day);
        }
        return day;
      }
    );
  }
}

export const dateMask = [
  /\d/,
  /\d/,
  ".",
  /\d/,
  /\d/,
  ".",
  /\d/,
  /\d/,
  /\d/,
  /\d/
];

export const DayPickerInputMUI = memo(
  ({
    helperText,
    error,
    inputProps,
    readOnly = false,
    value,
    label,
    onDayClick,
    clearValue,
    multiple = false,
    disabledDays,
    minDate,
    maxDate
  }: Omit<TextFieldProps, "value"> &
    DayPickerMUIProps & {
      value: Date | Date[] | string | string[];
      readOnly?: boolean;
      clearValue: () => void;
      multiple?: boolean;
      minDate?: Date;
      maxDate?: Date;
    }) => {
    const [open, setOpen] = useState(false);
    const id = open ? "simple-popover" : undefined;
    const inputRef = useRef(null);

    // @ts-ignore разработчики имеют типизацию createAutoCorrectedDatePipe(a: any): any ,а по факту там 2 аргумента
    const datePipe = createAutoCorrectedDatePipe("dd.mm.yyyy", {
      minYear: minDate && minDate.getFullYear(),
      maxYear: maxDate && maxDate.getFullYear()
    });

    const selectedDays = getParsedDays(value);

    return (
      <div>
        <MaskedInputMUI
          mask={dateMask}
          pipe={datePipe}
          keepCharPositions
          ref={inputRef}
          value={getDisplayedText(value)}
          label={label}
          error={error}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (e.currentTarget) {
              const { value: typedValue } = e.target;
              const parsedV = parseRussianDate(typedValue);
              if (isValid(parsedV)) {
                // @ts-ignore
                onDayClick(parsedV);
              }
            }
          }}
          helperText={helperText}
          inputProps={{ ...inputProps, readOnly }}
          InputProps={{
            readOnly,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  size="small"
                  onClick={() => {
                    if (!readOnly) {
                      setOpen(true);
                    }
                  }}
                >
                  <CalendarToday />
                </IconButton>
                <IconButton
                  onClick={event => {
                    if (!readOnly) {
                      event.stopPropagation();
                      if (typeof clearValue === "function") {
                        clearValue();
                      }
                    }
                  }}
                  size="small"
                >
                  <Close fontSize="small" rotate={90} />
                </IconButton>
              </InputAdornment>
            )
          }}
          fullWidth
        />
        <Popover
          id={id}
          open={open}
          anchorEl={inputRef.current}
          onClose={(event, reason) => {
            setOpen(!open);
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
        >
          <DayPickerMUI
            disabledDays={disabledDays}
            selectedDays={selectedDays}
            showWeekDays
            showOutsideDays
            onDayClick={(day, modifiers, e) => {
              if (!modifiers.disabled) {
                if (onDayClick) {
                  onDayClick(day, modifiers, e);
                }
                if (!multiple) {
                  setOpen(false);
                }
              }
            }}
          />
        </Popover>
      </div>
    );
  }
);
