import React, { CSSProperties } from "react";
import { Divider, Grid, Theme, Typography } from "@material-ui/core";
import { useFormikContext } from "formik";
import * as PropTypes from "prop-types";
import {v4} from "uuid";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import { RenderField } from "./renderField";
import { calcFieldWidth } from "./utils/calcFieldWidth";
import { defineFieldVisibility } from "./utils/fieldRenderHelpers";
import { RenderBlockT } from "./@types/formikMUi";

const defaultB: CSSProperties = { marginBottom: "8px", padding: "8px" };

const useStyles = makeStyles<Theme>(theme => ({
  divider: {
    marginLeft: theme.spacing(1),
    height: "1px",
    backgroundColor: "white",
    flex: "1  1 auto"
  },
  field: {
    width: "100%"
  },
  label: {
    position: "absolute",
    top: "-12px",
    zIndex: 100000,
    backgroundColor: "white",
    padding: "0 2px"
  },
  firstBlock: { marginTop: "8px", ...(defaultB as any) },
  block: { marginTop: "20px", ...(defaultB as any) },
  outlined: {
    border: "1px solid #ccc",
    borderRadius: "8px",
    position: "relative",
    paddingTop: "12px"
  },
  title: { fontSize: "15px" }
}));

export const RenderBlock: RenderBlockT = ({
  block: { blockTitle, description, fields, conditionallyRender, variant },
  bIndex
}) => {
  const classes = useStyles();
  const {
    status: { readOnly, auth },
    status,
    initialValues,
    setFieldValue,
    values
  } = useFormikContext<any>();

  if (conditionallyRender && !conditionallyRender(values, auth)) {
    return null;
  }

  return (
    <div
      className={clsx({
        [classes.firstBlock]: bIndex === 0,
        [classes.block]: bIndex !== 0,
        [classes.outlined]: variant === "outlined"
      })}
    >
      {blockTitle && (
        <>
          <Typography
            variant="subtitle2"
            className={clsx(
              { [classes.label]: variant === "outlined" },
              classes.title
            )}
          >
            {blockTitle.toUpperCase()}
          </Typography>
          <Divider className={classes.divider} />
        </>
      )}
      {description && <Typography>{description}</Typography>}
      <Grid container spacing={1}>
        {fields.map(field =>
          defineFieldVisibility(field, values, auth, field.name) ? (
            <Grid item key={field.name || v4()} {...calcFieldWidth(field)}>
              <RenderField
                field={field}
                classes={classes}
                readOnly={readOnly}
                initialValues={initialValues}
                setFieldValue={setFieldValue}
                status={status}
              />
            </Grid>
          ) : null
        )}
      </Grid>
    </div>
  );
};

RenderBlock.propTypes = {
  block: PropTypes.shape({}).isRequired,
  bIndex: PropTypes.number.isRequired
} as any;
