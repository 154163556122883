import { computePropsForFormikMUI } from "../../../components/modalWrapper/utils/computePropsForForMikMUI";
import {
  baseNumberProps,
  baseTextAreaProps,
  baseTimeFieldProps,
  constructValidateUsingUserFunctions,
  downLoadFileFromFileSystemProps,
  uploadFileInFileSystem
} from "../../baseFieldProps";
import { localizedYup, timeSchema } from "../../yup";
import { fullWidth, halfWith } from "../../widths";
import {
  constructRebuildReceivedOptionsLabelLabelValueUniqueId,
  rebuildReceivedOptionsAccostAndFullNameAsLabelUniqueIdAsValue,
  rebuildReceivedValueLabelAccostFullNameValueId,
  rebuildReceivedValueLabelLabelValueUniqueId
} from "../../rebuildRecieved";
import { hramTabFormObj } from "./hramTab";
import { PrimaryButton } from "../../../components/modalWrapper/buttons";
import { doBeforeSend } from "../../baseFormProps";
import { YmapsSelectCoordinates } from "../../../components/ymapsSelectCoordinates";
import { ExtendedBlockFormObj } from "../../../components/modalWrapper/types";
import { scheduleTab } from "../users/form";
import { HramTab } from "../../../components/HramTab";
import { PerformersTab } from "../../../components/structure/PerformersTab";
import { SubStructuresTab } from "../../../components/structure/SubStructuresTab";
import CompletePromiseThenRenderComponent, {
  defaultPreloader,
  DownloadConfObj
} from "../../../components/completePromiseThenRenderComponent";
import { callDefaultErrorHandlerThenPopModal } from "../../../store";

const isHram = (type?: {enum:string}) => type && type.enum === "HRAM";

const createForm: ExtendedBlockFormObj = {
  title: "организационной единицы",
  headerText: "label",
  url: "/api/strMember",
  primaryActionUrl: "/api/member/structureMembers",
  projection: "formMember",
  method: "post",
  doBeforeSend,
  blocks: [
    {
      blockTitle: "Основная информация",
      fields: [
        {
          label: "Наименование",
          name: "label",
          ...baseTextAreaProps
        },
        {
          label: "Адрес",
          name: "address",
          ...baseTextAreaProps
        },
        {
          label: "Тип",
          name: "type",
          type: "select",
          placeholder: "Выберите тип",
          dictUrl: "/api/strType/search/present",
          rebuildReceivedOptions: constructRebuildReceivedOptionsLabelLabelValueUniqueId,
          rebuildReceivedValue: rebuildReceivedValueLabelLabelValueUniqueId,
          widths: halfWith
        },
        {
          label: "Подчинение",
          name: "parentId",
          type: "select",
          dictUrl:
            "/api/member/structureObjects?withLimit=true&withoutHrams=true",
          readOnly: functions =>
            functions.includes("administrator_hram_member_modifier"),
          async: true,
          rebuildReceivedOptions: false,
          rebuildReceivedValue: rebuildReceivedValueLabelLabelValueUniqueId,
          validate: constructValidateUsingUserFunctions(
            "administrator_structure_member_modifier"
          ),
          widths: fullWidth
        },
        {
          label: "Руководство",
          name: "leadership",
          type: "select",
          dictUrl: "/api/user/priestsListLaconic?",
          dictUrlParamName: "name",
          conditionallyRender: ({ type }) => type && type.enum !== "HRAM",
          readOnly: functions =>
            functions.includes("administrator_hram_member_modifier"),
          async: true,
          rebuildReceivedOptions: rebuildReceivedOptionsAccostAndFullNameAsLabelUniqueIdAsValue,
          rebuildReceivedValue: false,
          validate: constructValidateUsingUserFunctions(
            "administrator_structure_member_modifier"
          ),
          widths: fullWidth
        },
        {
          label: "Город",
          name: "town",
          type: "select",
          placeholder: "Выберите город",
          dictUrl: "/api/user/hram/town?",
          async: true,
          rebuildReceivedOptions: false,
          rebuildReceivedValue: rebuildReceivedValueLabelLabelValueUniqueId,
          widths: halfWith
        },
        {
          label: "Метро",
          name: "metro",
          type: "select",
          placeholder: "Выберите метро",
          dictUrl: ({ town: { value } }) => `/api/member/metro/${value}?`,
          rebuildReceivedOptions: false,
          async: true,
          conditionallyRender: ({ town }) => Boolean(town),
          widths: halfWith,
          multiple: true
        },
        {
          label: "Широта",
          name: "coordinationLatitude",
          ...baseNumberProps({
            allowDecimal: true,
            decimalLimit: 50
          })
        },
        {
          label: "Долгота",
          name: "coordinationLongtitude",
          ...baseNumberProps({
            allowDecimal: true,
            decimalLimit: 50
          })
        },
        {
          name: "ignoreMe",
          type: "custom",
          component: YmapsSelectCoordinates,
          doBeforeSend: () => undefined
        }
      ]
    },
    {
      blockTitle: "Карточка храма",
      conditionallyRender: ({ type }) => type && type.enum === "HRAM",
      fields: [
        {
          label: "Фотография",
          name: "hramObjectLocal.photo",
          type: "file",
          doBeforeSetFieldValue: uploadFileInFileSystem("hram"),
          ...downLoadFileFromFileSystemProps,
          widths: fullWidth
        },
        {
          label: "Алтари храма",
          name: "hramObjectLocal.description",
          ...baseTextAreaProps
        },
        {
          label: "Веб-страница",
          name: "hramObjectLocal.webUrl",
          type: "text",
          widths: halfWith
        },
        {
          label: "Контакты",
          name: "hramObjectLocal.contacts",
          ...baseTextAreaProps
        },
        {
          label: "Адрес электронной почты",
          name: "hramObjectLocal.email",
          ...baseTextAreaProps
        },
        {
          label: "История",
          name: "hramObjectLocal.history",
          ...baseTextAreaProps
        },
        {
          label: "Святыни",
          name: "hramObjectLocal.religion",
          ...baseTextAreaProps
        },
        {
          label: "Время начало работы Храма",
          name: "hramObjectLocal.startWorkTime",
          ...baseTimeFieldProps
        },
        {
          label: "Время окончания работы Храма",
          name: "hramObjectLocal.finishWorkTime",
          ...baseTimeFieldProps
        },
        {
          label: "Отображение священнослужителей при записи на беседу",
          name: "hramObjectLocal.visiblePriests",
          type: "checkbox",
          widths: fullWidth
        }
      ]
    },
    {
      blockTitle: "Деятельность",
      conditionallyRender: ({ type }) => (type ? type.enum === "HRAM" : false),
      fields: [
        {
          label: "Образовательная",
          name: "hramObjectLocal.workEducation",
          ...baseTextAreaProps
        },
        {
          label: "Социальная",
          name: "hramObjectLocal.workSocial",
          ...baseTextAreaProps
        },
        {
          label: "Миссионерская",
          name: "hramObjectLocal.workMission",
          ...baseTextAreaProps
        },
        {
          label: "Другая",
          name: "hramObjectLocal.workElse",
          ...baseTextAreaProps
        }
      ]
    }
  ],
  validationSchema: localizedYup.object({
    label: localizedYup.string().required(),
    type: localizedYup
      .object()
      .nullable()
      .required(),
    parentId: localizedYup.object().nullable(),
    address: localizedYup.string(),
    town: localizedYup.object().nullable(),
    metro: localizedYup.object().nullable(),
    coordinationLatitude: localizedYup.number().when("type", {
      is: isHram,
      then: localizedYup.number().required()
    }),
    coordinationLongtitude: localizedYup.number().when("type", {
      is: isHram,
      then: localizedYup.number().required()
    }),
    hramObjectLocal: localizedYup.object().when("type", {
      is: isHram,
      then: localizedYup
        .object()
        .shape({
          photo: localizedYup.object().nullable(),
          description: localizedYup.string(),
          webUrl: localizedYup.string(),
          contacts: localizedYup.string(),
          history: localizedYup.string(),
          religion: localizedYup.string(),
          startWorkTime: timeSchema.required(),
          finishWorkTime: timeSchema
            // @ts-ignore
            .compareTimeTo(localizedYup.ref("startWorkTime"))
            .required(),
          visiblePriests: localizedYup.bool()
        })
        .nullable(),
      otherwise: localizedYup
        .object()
        .shape({
          photo: localizedYup.object().nullable(),
          description: localizedYup.string(),
          webUrl: localizedYup.string(),
          contacts: localizedYup.string(),
          history: localizedYup.string(),
          religion: localizedYup.string(),
          startWorkTime: localizedYup.string(),
          finishWorkTime: localizedYup.string(),
          visiblePriests: localizedYup.bool()
        })
        .nullable()
    })
  })
};

const viewEditForm: ExtendedBlockFormObj = {
  title: "организационной единицы",
  headerText: "label",
  url: "/api/strMember",
  doBeforeSend,
  primaryActionUrl: "/api/member/structureMembers",
  projection: "formMember",
  method: "patch",
  blocks: [
    {
      blockTitle: "Основная информация",
      fields: [
        {
          label: "Наименование",
          name: "label",
          ...baseTextAreaProps
        },
        {
          label: "Тип",
          name: "type",
          type: "select",
          placeholder: "Выберите тип",
          dictUrl: "/api/strType/search/present",
          rebuildReceivedOptions: constructRebuildReceivedOptionsLabelLabelValueUniqueId,
          rebuildReceivedValue: rebuildReceivedValueLabelLabelValueUniqueId,
          readOnly: true,
          widths: halfWith
        },
        {
          label: "Подчинение",
          name: "parentId",
          type: "select",
          dictUrl:
            "/api/member/structureObjects?withLimit=true&withoutHrams=true",
          readOnly: functions =>
            functions.includes("administrator_hram_member_modifier"),
          async: true,
          rebuildReceivedOptions: false,
          rebuildReceivedValue: rebuildReceivedValueLabelLabelValueUniqueId,
          validate: constructValidateUsingUserFunctions(
            "administrator_structure_member_modifier"
          ),
          widths: fullWidth
        },
        {
          label: "Руководство",
          name: "head",
          type: "select",
          dictUrl: "/api/user/priestsListLaconic?",
          dictUrlParamName: "name",
          conditionallyRender: ({ type }) => type && type.enum !== "HRAM",
          readOnly: functions =>
            functions.includes("administrator_hram_member_modifier"),
          async: true,
          rebuildReceivedOptions: rebuildReceivedOptionsAccostAndFullNameAsLabelUniqueIdAsValue,
          rebuildReceivedValue: rebuildReceivedValueLabelAccostFullNameValueId,
          validate: constructValidateUsingUserFunctions(
            "administrator_structure_member_modifier"
          ),
          widths: fullWidth
        },
        {
          label: "Адрес",
          name: "address",
          ...baseTextAreaProps
        },
        {
          label: "Город",
          name: "town",
          type: "select",
          placeholder: "Выберите город",
          dictUrl: "/api/user/hram/town?",
          async: true,
          rebuildReceivedOptions: false,
          rebuildReceivedValue: rebuildReceivedValueLabelLabelValueUniqueId,
          widths: halfWith
        },
        {
          label: "Метро",
          name: "metro",
          type: "select",
          placeholder: "Выберите метро",
          dictUrl: ({ town: { value } }) => `/api/member/metro/${value}?`,
          rebuildReceivedOptions: false,
          async: true,
          multiple: true,
          conditionallyRender: ({ town }) => Boolean(town),
          widths: halfWith,
          rebuildReceivedValue: false,
          additionalProps: {
            defaultOptions: false
          }
        },
        {
          label: "Широта",
          name: "coordinationLatitude",
          ...baseNumberProps({
            allowDecimal: true,
            decimalLimit: 50
          })
        },
        {
          label: "Долгота",
          name: "coordinationLongtitude",
          ...baseNumberProps({
            allowDecimal: true,
            decimalLimit: 50
          })
        },
        {
          name: "ignoreMe",
          type: "custom",
          component: YmapsSelectCoordinates,
          doBeforeSend: () => undefined
        },
        { name: "uniqueId", type: "text", hidden: true }
      ]
    }
  ],
  additionalTabs: [
    {
      tabTitle: "Карточка храма",
      tabContent: {
        Component: HramTab,
        passProps: {
          formObj: hramTabFormObj
        }
      },
      conditionallyRender: ({ firstFormInitialValues: { type } }) =>
        type && type.enum === "HRAM",
      tabButtons: [
        {
          Btn: PrimaryButton,
          requiresFormSubmitRef: true,
          conditionallyRender: ({ modal: { mode } }) => mode === "edit"
        }
      ]
    },
    {
      tabTitle: "Подчиненные ОЕ",
      tabContent: {
        Component: CompletePromiseThenRenderComponent,
        passProps: {
          Component: SubStructuresTab,
          errorHandler: callDefaultErrorHandlerThenPopModal,
          PreloaderComponent: defaultPreloader
        },
        functionToExecuteThenPassResultAsProps: ({
          firstFormInitialValues: { uniqueId }
        }) => {
          const config: DownloadConfObj[] = [
            {
              urlToDownload: `/api/member/getSubStructures/${uniqueId}`,
              whereToStore: "rawData",
              postLoadDoSomethingThenPassResultAsProp: {
                passResultAs: "data",
                func: data => {
                  const mappedData = data.map((item: any) => {
                    return {
                      id: item.value,
                      uniqueId: item.value,
                      ...item
                    };
                  });
                  return mappedData ? mappedData : [];
                }
              }
            }
          ];
          return { config };
        }
      },
      conditionallyRender: ({ firstFormInitialValues: { type } }) =>
        type && type.enum != "HRAM"
    },
    {
      tabTitle: "Служители",
      tabContent: {
        Component: CompletePromiseThenRenderComponent,
        passProps: {
          Component: PerformersTab,
          errorHandler: callDefaultErrorHandlerThenPopModal,
          PreloaderComponent: defaultPreloader
        },
        functionToExecuteThenPassResultAsProps: ({
          firstFormInitialValues: { uniqueId }
        }) => {
          const config: DownloadConfObj[] = [
            {
              urlToDownload: `/api/user/priestByMemberSortRole/${uniqueId}`,
              whereToStore: "rawData",
              postLoadDoSomethingThenPassResultAsProp: {
                passResultAs: "data",
                func: data => {
                  const mappedData = data.map((item: any) => {
                    return { uniqueId: item.id, ...item };
                  });
                  return mappedData ? mappedData : [];
                }
              }
            }
          ];
          return { config };
        }
      }
    },
    scheduleTab
  ],
  validationSchema: localizedYup.object({
    label: localizedYup.string().required(),
    type: localizedYup
      .object()
      .nullable()
      .required(),
    parentId: localizedYup.object().nullable(),
    address: localizedYup.string(),
    town: localizedYup.object().nullable(),
    metro: localizedYup.object().nullable(),
    coordinationLatitude: localizedYup.number().when("type", {
      is: isHram,
      then: localizedYup.number().required()
    }),
    coordinationLongtitude: localizedYup.number().when("type", {
      is: isHram,
      then: localizedYup.number().required()
    })
  })
};

export default {
  create: computePropsForFormikMUI({ formObj: createForm, mode: "create" }),
  view: computePropsForFormikMUI({ formObj: viewEditForm, mode: "view" }),
  edit: computePropsForFormikMUI({ formObj: viewEditForm, mode: "edit" })
};
