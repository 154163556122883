import React from "react";
import { TextFieldProps } from "@material-ui/core/TextField";
import { FieldProps } from "formik";
import { MaskedInputProps } from "react-text-mask";
import { MaskedInputMUI } from "../../input/maskedInputMUI";
import { checkIfFieldHasValidationError } from "../utils/checkIfFieldHasValidationError";

export const WrappedMaskedInputMUI = ({
  field,
  form: { errors, touched },
  ...rest
}: TextFieldProps & FieldProps<any> & MaskedInputProps) => {
  const textFieldProps = checkIfFieldHasValidationError(
    field.name,
    touched,
    errors
  );
  return <MaskedInputMUI {...field} {...textFieldProps} {...rest} />;
};
