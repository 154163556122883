import { set } from "lodash";
import {
  AllowedActionsT,
  BlockFormObj,
  IField,
  InitialParametersT,
  OptionT,
  PagesT,
  RequiresFunction,
  SelectField
} from "../constants/types";
import { AllowedJournalActionsT } from "../reducers/wrapperReducer";
import { DownloadConfObj } from "../components/completePromiseThenRenderComponent";
import {
  DataRestStructure,
  extractFromDataRestStructureMapLabelAsLabelUniqueIdsValue
} from "../constants/rebuildRecieved";

export const doSomethingWithFields = (
  formObj: BlockFormObj,
  functionToExecute: (field: IField) => void
) => {
  formObj.blocks.forEach(block =>
    block.fields.forEach(field => {
      functionToExecute(field);
    })
  );
};

export const getAllowedActionsOnPageForCurrentUser = (
  functions: string[],
  requiresFunction?: RequiresFunction[]
): AllowedActionsT[] => {
  const actionsSet: AllowedActionsT[] = [];
  if (!requiresFunction) {
    return ["view", "create", "edit", "delete"];
  }
  requiresFunction
    .filter(
      ({ functionName }) =>
        functions.includes(functionName) || functionName === "any"
    )
    .forEach(({ allowedActions }) =>
      allowedActions.forEach(action => {
        if (!actionsSet.includes(action)) {
          actionsSet.push(action);
        }
      })
    );
  return actionsSet;
};

const getAllAllwedActionForCategory = (pages: PagesT, functions: string[]) => {
  const pageKeys = Object.keys(pages);
  const result: {
    [pgeName: string]: AllowedActionsT[];
  } = {};
  pageKeys.forEach(page => {
    const allAllowedActionsOnPage = getAllowedActionsOnPageForCurrentUser(
      functions,
      pages[page].requiresFunction
    );
    if (allAllowedActionsOnPage.length > 0) {
      result[page] = allAllowedActionsOnPage;
    }
  });
  return result;
};

export const constructAllowedActionsForAllJournals = (
  functions: string[],
  initParams: InitialParametersT
) => {
  const result: AllowedJournalActionsT = {};
  // const categories = Object.keys(initParams);
  Object.entries(initParams).forEach(([categoryName, category]) => {
    // если в категории нет страницы то значением будет массив разрешенных действий
    const { pages } = category;
    if (pages) {
      const allowedActionsForCategory = getAllAllwedActionForCategory(
        pages,
        functions
      );
      if (Object.keys(allowedActionsForCategory).length > 0) {
        // @ts-ignore
        result[categoryName] = allowedActionsForCategory;
      }
    } else if (category.page) {
      const allowedActions = getAllowedActionsOnPageForCurrentUser(
        functions,
        category.page.requiresFunction
      );
      if (allowedActions.length > 0) {
        // @ts-ignore
        result[categoryName] = allowedActions;
      }
    }
  });
  return result;
};

const wrapBaseDictReceivedReceivedOptions = (data: DataRestStructure) => ({
  options: extractFromDataRestStructureMapLabelAsLabelUniqueIdsValue(data),
  loaded: true
});

const wrappedRebuildReceivedOptions = (
  rebuildReceivedOptions: (data: any) => OptionT[]
) => (data: any) => ({
  options: rebuildReceivedOptions(data),
  loaded: true
});

const wrappedOptions = (data: OptionT[]) => ({
  options: data,
  loaded: true
});

export const findFieldsWithDictThenConstructArrayToDownload = ({
  blocks
}: BlockFormObj) => {
  const configArray: DownloadConfObj[] = [];

  function hanldeSelect(
    type: SelectField["type"],
    dictUrl: SelectField["dictUrl"],
    name: string,
    rebuildReceivedOptions?: SelectField["rebuildReceivedOptions"]
  ) {
    if (type === "select" && dictUrl && typeof dictUrl === "string") {
      if (rebuildReceivedOptions === false) {
        configArray.push({
          whereToStore: `optionsMap.${name}`,
          urlToDownload: dictUrl,
          rebuildReceivedValue: wrappedOptions
        });
      } else {
        configArray.push({
          whereToStore: `optionsMap.${name}`,
          urlToDownload: dictUrl,
          rebuildReceivedValue: rebuildReceivedOptions
            ? wrappedRebuildReceivedOptions(rebuildReceivedOptions)
            : wrapBaseDictReceivedReceivedOptions
        });
      }
    }
  }

  blocks.forEach(({ fields }) =>
    fields.forEach(field => {
      const { name } = field;
      if (field.type === "fieldArray") {
        const { fields: arrayFields } = field;
        arrayFields.forEach(arrayField => {
          if (arrayField.type === "select" && !arrayField.async) {
            hanldeSelect(
              arrayField.type,
              arrayField.dictUrl,
              arrayField.name,
              arrayField.rebuildReceivedOptions
            );
          }
        });
      }
      if (field.type === "select" && field.dictUrl && !field.async) {
        hanldeSelect(
          field.type,
          field.dictUrl,
          name,
          field.rebuildReceivedOptions
        );
      }
    })
  );
  return configArray;
};

export const findFieldsWithDictThenConstructArrayToDownloadWithOutBlocks = ({
  fields
}: {
  fields: IField[];
}) => {
  let configArray: DownloadConfObj[] = [];
  fields.forEach(field => {
    const { name } = field;
    if (field.type === "select" && typeof field.dictUrl === "string") {
      if (field.rebuildReceivedOptions === false) {
        configArray.push({
          whereToStore: `optionsMap.${name}`,
          urlToDownload: field.dictUrl,
          rebuildReceivedValue: wrappedOptions
        });
      } else {
        configArray.push({
          whereToStore: `optionsMap.${name}`,
          urlToDownload: field.dictUrl,
          rebuildReceivedValue: field.rebuildReceivedOptions
            ? wrappedRebuildReceivedOptions(field.rebuildReceivedOptions)
            : wrapBaseDictReceivedReceivedOptions
        });
      }
    }
    if (field.type === "fieldArray") {
      configArray = [
        ...configArray,
        ...findFieldsWithDictThenConstructArrayToDownloadWithOutBlocks(field)
      ];
    }
  });
  return configArray;
};

export const getOptionsMapBeforeLoading = (fields: IField[]) => {
  const optionMap = {};
  fields.forEach(field => {
    const { name } = field;
    if (field.type === "select" && field.dictUrl) {
      set(optionMap, name, { loaded: false, options: [] });
    }
  });
  return optionMap;
};
