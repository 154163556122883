import { Assignment } from "@material-ui/icons";
import { FormikValues } from "formik";
import { FormikMUI } from "../../../../components/formikMUi/formikNewRealisation";
import { findFieldsWithDictThenConstructArrayToDownload } from "../../../../utils/commonFormFunctions";
import { constructInitialValuesForEmptyForm } from "../../../../components/formikMUi/utils/initialValuesUtils";
import { StepperMUI } from "../../../../components/stepperMUI";
import {
  DownloadReportButtons,
  DownLoadReportStep
} from "../../../../components/reports/DownloadReportButtons";
import { filters } from "./filters";
import { increaseActiveStep } from "../../../../components/stepperMUI/reducer";
import { BlockFormObj, PageT } from "../../../types";
import { fullWidth } from "../../../widths";

import { localizedYup } from "../../../yup";

const firstStep: BlockFormObj = {
  blocks: [
    {
      fields: [
        {
          name: "report",
          label: "Отчет",
          type: "select",
          options: [
            { label: "Отчет о работе в системе", value: "work_report" }
          ],
          widths: fullWidth
        }
      ]
    }
  ],
  validationSchema: localizedYup.object({
    report: localizedYup
      .object()
      .nullable()
      .required()
  })
};

const stepsConfig = [
  () => ({
    label: "Выбор отчёта",
    Component: FormikMUI,
    config: findFieldsWithDictThenConstructArrayToDownload(firstStep),
    additionalProps: {
      initialValues: constructInitialValuesForEmptyForm(firstStep),
      formObj: firstStep,
      onSubmit: ({ dispatch }: { dispatch: Function }) => (
        {
          report: { value }
        }: {
          report: { value: string };
        },
        { setSubmitting }: { setSubmitting: (v: boolean) => void }
      ) => {
        setSubmitting(false);
        // @ts-ignore
        const foundFiltersForReport = filters[value];
        if (typeof foundFiltersForReport === "undefined") {
          throw new Error(
            `Не найдено фильтров для отчёта с таким именем: ${value}`
          );
        }
        dispatch(
          increaseActiveStep({
            formObj: foundFiltersForReport,
            initialValues: constructInitialValuesForEmptyForm(
              foundFiltersForReport
            ),
            config: findFieldsWithDictThenConstructArrayToDownload(
              foundFiltersForReport
            ),
            reportName: value
          })
        );
      },
      enableStatusReinitialize: true
    },
    requiresFormSubmitRef: true
  }),
  () => ({
    label: "Выбор параметров фильтрации",
    Component: FormikMUI,
    additionalProps: {
      onSubmit: ({
        dispatch,
        passProps: { reportName, formObj }
      }: {
        dispatch: Function;
        passProps: { reportName: string; formObj: BlockFormObj };
      }) => (
        values: FormikValues,
        { setSubmitting }: { setSubmitting: (v: boolean) => void }
      ) => {
        setSubmitting(false);
        dispatch(
          // increaseActiveStep({ templateUrl: "/api" })
          increaseActiveStep({ reportName, filters: values, filterFO: formObj })
        );
      },
      enableStatusReinitialize: true
    },
    requiresFormSubmitRef: true
  }),
  () => ({
    label: "Выбор формата отчета",
    Component: DownLoadReportStep,
    stepButtons: DownloadReportButtons
  })
];

export type ReportUrlsMap = Record<string, (type: "xlsx" | "pdf") => string>;

export const baseUrlsToDownLoadReport = {
  work_report: (
    type: string,
    isKeepingSchedule: boolean,
    isCreatingSlots: boolean
  ) => `/api/reports/work_report?format=${type}`
};

type ExcludedButtonsMapT = Partial<
  Record<keyof typeof baseUrlsToDownLoadReport, ("pdf" | "xlsx")[]>
>;

export const excludedButtonsMap: ExcludedButtonsMapT = {
  // Внеси данные чтоб не отрисовывать не нужную кнопку
};

const statMV: PageT = {
  navigationLabel: "Отчёты",
  icon: Assignment,
  requiresFunction: [
    {
      functionName: "administrator_member_modifier",
      allowedActions: ["view"]
    },
    {
      functionName: "administrator_structure_member_modifier",
      allowedActions: ["view"]
    },
    {
      functionName: "administrator_hram_member_modifier",
      allowedActions: ["view"]
    },
  ],
  Component: StepperMUI,
  passProps: {
    stepsConfig
  }
};
export default statMV;
