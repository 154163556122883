import { BlockFormObj } from "../types";
import CompletePromiseThenRenderComponent from "../../components/completePromiseThenRenderComponent";
import { RenderModalCreateForm } from "../../components/modalWrapper/renderModalContentWithOutTabs";
import { findFieldsWithDictThenConstructArrayToDownload } from "../../utils/commonFormFunctions";
import { constructInitialValuesForEmptyForm } from "../../components/formikMUi/utils/initialValuesUtils";
import { getInitialValues } from "../../components/modalWrapper/utils/constructConfigArrayForLoaderComponent";
import { AuthStateT } from "../../reducers/auth";
import { ConfigT } from "../../components/modalWrapper/types";
import { ModalT } from "../../components/wrapper/types";
import { isBefore } from "date-fns";
import { parseRussianDateTime } from "../formaters/formatters";

interface BaseT {
  readOnly?: boolean;
  formObj: BlockFormObj;
}

type CreateT = BaseT & {
  mode?: "create";
};

type EditT = BaseT & {
  mode?: "edit";
};

type ViewT = BaseT & {
  mode?: "view";
};

type UnionT = CreateT | ViewT | EditT;

export type AdaptFormObjToModalWindowArgs = UnionT & Pick<ConfigT, "maxWidth">;

export const adaptFormObjToModalWindow = ({
  formObj,
  mode = "create",
  readOnly = false,
  ...rest
}: AdaptFormObjToModalWindowArgs): (() => ConfigT) => () => {
  const configToDownload =
    formObj.blocks &&
    findFieldsWithDictThenConstructArrayToDownload({
      blocks: formObj.blocks
    });
  const resultingConfig: ConfigT = {
    component: CompletePromiseThenRenderComponent,
    forwardedProps: {
      Component: RenderModalCreateForm,
      config: configToDownload,
      formObj,
      initialValues: constructInitialValuesForEmptyForm(formObj),
      readOnly: readOnly || mode === "view"
    },
    functionsToExecute: [],
    ...rest
  };

  if (mode === "edit" || mode === "view") {
    resultingConfig.functionsToExecute = [
      {
        func: (modal: ModalT) => [
          ...findFieldsWithDictThenConstructArrayToDownload(formObj),
          getInitialValues(modal, formObj, "initialValues", modal.initialValues)
        ],
        passResultAs: "config"
      }
    ];
  }
  if (mode === "create") {
    resultingConfig.functionsToExecute = [
      {
        func: (modal: ModalT) => ({
          ...constructInitialValuesForEmptyForm(formObj),
          ...modal.initialValues
        }),
        passResultAs: "initialValues"
      }
    ];
  }
  return resultingConfig;
};

export const userHasAnyOfRole = (
  auth: AuthStateT,
  ...expectedToHas: string[]
): boolean =>
  auth &&
  Array.isArray(auth.functions) &&
  auth.functions.some(x => expectedToHas.includes(x));

export const isDatePast = (dateStr: string) => {
  const dateObj = parseRussianDateTime(dateStr);
  return isBefore(dateObj, new Date());
};
