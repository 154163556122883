import React, { useMemo } from "react";
import { CssBaseline, Divider, Paper, Snackbar } from "@material-ui/core";
import CollapsableMenuContainer from "../CollapsableMenuContainer";
import ConfirmDialog from "../confirmDialog";
import ConfirmDialogFormik from "../confirmDialogFormik";
import CustomAppBar from "../appBar/AppBar";
import { GetJournalMainContent } from "./getJournalMainContent";
import { INITIAL_PARAMETERS } from "../../constants/intialParameters";
import { RootStateT } from "../../reducers";
import { PageT } from "../../constants/types";
import { WrapperProps } from "./types";
import {
  useOnCategoryOrPageChange,
  useOpenModalIfUrlContainsUniqueId
} from "./hooks";
import { SnackBarPanel } from "./SnackBarPanel";
import style from "./style.module.css";
import { Footer } from "./footer";
import { useShallowEqualSelector } from "../../store/hooks";
import { snackBarState } from "../../store/snackBar";
import { useRecoilValue } from "recoil";

const emptyObj = {};

const Wrapper: React.FC<WrapperProps> = ({
  match: {
    params: { category, page, uniqueId }
  },
  history
}: WrapperProps) => {
  const showSnackBar = useRecoilValue(snackBarState);
  const {
    isMenuMinimized,
    confirmDialogIsOpen,
    confirmDialogFormikIsOpen,
    allowedActions,
    auth
  } = useShallowEqualSelector((state: RootStateT) => ({
    isMenuMinimized: state.collapsibleMenuReducer.sideMenuMaximized,
    confirmDialogIsOpen: state.wrapperReducer.confirmDialog.open,
    confirmDialogFormikIsOpen: state.wrapperReducer.confirmDialogFormik.open,
    allowedActions: state.wrapperReducer.allowedActions,
    auth: state.auth
  }));

  // @ts-ignore
  const memorizedInitialParameters: PageT = useMemo(() => {
    if (INITIAL_PARAMETERS[category]) {
      const { pages } = INITIAL_PARAMETERS[category];
      if (page && pages && pages[page]) {
        return pages[page];
      }
      return INITIAL_PARAMETERS[category].page;
    }
    return {};
  }, [category, page]);

  useOnCategoryOrPageChange(auth, category, page, memorizedInitialParameters);

  useOpenModalIfUrlContainsUniqueId(
    uniqueId,
    memorizedInitialParameters || emptyObj,
    category,
    page
  );

  return (
    <div className={style.wrapper}>
      <CssBaseline />
      <CustomAppBar />
      <div className={style.main}>
        <Paper
          style={{
            width: "auto",
            boxShadow:
              "0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12)"
          }}
          elevation={8}
        >
          <CollapsableMenuContainer sideMenuMaximized={isMenuMinimized} />
          <Divider />
        </Paper>
        <GetJournalMainContent
          allowedActions={allowedActions}
          initialParameters={memorizedInitialParameters}
          category={category}
          page={page}
        />
      </div>
      <SnackBarPanel />
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        autoHideDuration={6000}
        {...showSnackBar}
      />
      {confirmDialogIsOpen && <ConfirmDialog />}
      {confirmDialogFormikIsOpen && <ConfirmDialogFormik />}
      <Footer auth={auth} showFaq />
    </div>
  );
};

export default Wrapper;
