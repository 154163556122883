import forms from "./form";
import { constructRebuildReceivedOptionsLabelLabelValueLabel } from "../../rebuildRecieved";
import { PageT } from "../../types";

const baseJournal: PageT = {
  navigationLabel: "",
  requiresFunction: [
    {
      functionName: "administrator_hram_claim_view",
      allowedActions: ["view"]
    },
    {
      functionName: "priest_claim_view",
      allowedActions: ["view"]
    },
    {
      functionName: "assistant_claim_view",
      allowedActions: ["view"]
    }
  ],
  url: "/api/journalClaimObjects",
  filtersPanel: {
    filters: [
      {
        label: "Категория требы/таинства",
        name: "commonAction",
        type: "select",
        dictUrl: "/api/getCommonActionForClaimList",
        placeholder: "Выберите тип требы/таинства",
        rebuildReceivedOptions: constructRebuildReceivedOptionsLabelLabelValueLabel
      },
      {
        label: "ФИО мирянина",
        name: "userFullName",
        type: "text"
      },
      {
        label: "Телефон мирянина",
        name: "phone",
        type: "text"
      },
      {
        label: "Адрес оказания требы/таинства",
        name: "userAddress",
        type: "text"
      },
      {
        label: "Время проведения требы/таинства",
        name: "rangeEvent",
        type: "date-range"
      }
    ]
  },
  tableHeader: [
    {
      label: "Номер заявки",
      width: 115,
      sortable: true,
      path: "uniqueId"
    },
    {
      label: "Дата заявки",
      sortable: true,
      width: 105,
      path: "createdTimestamp"
    },
    {
      label: "Категория требы/таинства",
      sortable: true,
      width: 200,
      path: "commonAction"
    },
    {
      label: "Желаемое время проведения требы/таинства",
      sortable: true,
      width: 320,
      path: "rangeEventTimestamp"
    },
    {
      label: "В храме",
      sortable: true,
      width: 80,
      path: "isHramText"
    },
    {
      label: "Адрес оказания требы/таинства",
      sortable: true,
      width: 250,
      path: "userAddress"
    },
    {
      label: "ФИО мирянина",
      sortable: true,
      width: 200,
      path: "userFullName"
    },
    {
      label: "Телефон мирянина",
      sortable: true,
      width: 160,
      path: "phone"
    }
  ],
  forms
};
export default baseJournal;
