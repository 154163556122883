import { PageT } from "../../types";
import { computePropsForFormikMUI } from "../../../components/modalWrapper/utils/computePropsForForMikMUI";
import { ExtendedBlockFormObj } from "../../../components/modalWrapper/types";
import { baseTextAreaProps } from "../../baseFieldProps";
import { localizedYup } from "../../yup";
import { optionsMapLabelAsLabelUniqueIdAsValue } from "../../rebuildRecieved";
import { doBeforeSend } from "../../baseFormProps";

const formObj: ExtendedBlockFormObj = {
  url: "/api/faq",
  title: "Вопроса и Ответа",
  method: "post",
  doBeforeSend,
  blocks: [
    {
      fields: [
        { name: "uniqueId", type: "text", hidden: true },
        { name: "uuid", type: "text", hidden: true },
        { name: "question", label: "Вопрос", ...baseTextAreaProps },
        { name: "answer", label: "Ответ", ...baseTextAreaProps },
        {
          name: "typeId",
          label: "Категория",
          type: "select",
          dictUrl: "/api/faq/type",
          rebuildReceivedOptions: optionsMapLabelAsLabelUniqueIdAsValue,
          rebuildReceivedValue: (value, values) => ({
            label: values.typeLabel,
            value
          })
        }
        // { name: "typeLabel", label: "Категория", type: "text" }
      ]
    }
  ],
  validationSchema: localizedYup.object().shape({
    question: localizedYup.string().required(),
    answer: localizedYup.string().required(),
    typeId: localizedYup
      .object()
      .nullable()
      .required()
  })
};

const editFormObj: ExtendedBlockFormObj = { ...formObj, method: "patch" };

export const faq: PageT = {
  navigationLabel: "Все",
  requiresFunction: [
    {
      functionName: "administration_global_modifier",
      allowedActions: ["create", "view", "delete", "edit"]
    },
    { functionName: "any", allowedActions: ["redirect"] }
  ],
  filtersPanel: {
    filters: [
      { name: "question", label: "Вопрос", type: "text" },
      { name: "answer", label: "Ответ", type: "text" },
      {
        name: "typeRef",
        label: "Категория",
        type: "select",
        dictUrl: "/api/faq/type",
        rebuildReceivedOptions: optionsMapLabelAsLabelUniqueIdAsValue
      }
    ]
  },
  url: "/api/faq/journalfaq",
  tableHeader: [
    { label: "Вопрос", path: "question" },
    { label: "Ответ", path: "answer" },
    { label: "Категория", path: "label" }
  ],
  forms: {
    create: computePropsForFormikMUI({ formObj, mode: "create" }),
    view: computePropsForFormikMUI({ formObj, mode: "view" }),
    edit: computePropsForFormikMUI({ formObj: editFormObj, mode: "edit" })
  }
};
