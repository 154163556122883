import { baseJournal } from "./basejournal";
import { PageT } from "../../types";
import { Done } from "@material-ui/icons";
import { addPerformer } from "../claim/inWork/journal";

const end: PageT = {
  ...baseJournal,
  icon: Done,
  navigationLabel: "Прошедшие беседы",
  url: () => addPerformer("/api/slot/journal/end")
};
export default end;
