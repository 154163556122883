import { FastField, FieldArray } from "formik";
import React, { memo, MouseEvent } from "react";
import { Theme, Typography } from "@material-ui/core";
import clsx from "clsx";
import { isEqual } from "lodash";
import { makeStyles } from "@material-ui/styles";
import { green, red } from "@material-ui/core/colors";
import { FieldProps } from "formik/dist/Field";
import { MonthWordCell } from "../monthWordCell";
import { CustomOnChangeArg, OptionT } from "../../../constants/types";
import {
  getInternetExplorerEventRequiredStyles,
  getInternetExplorerRequiredStyles
} from "../utils/ie";
import { PerformerT, RenderRowsProps } from "../types";
import { openEventCard } from "../utils";
import { WrappedReactSelect } from "../../formikMUi/wrappedComponents/select/wrappedReactSelect";
import { DayButtons } from "./dayButtons";
import { baseTimeFieldProps } from "../../../constants/baseFieldProps";
import { WrappedMaskedInputMUI } from "../../formikMUi/wrappedComponents/wrappedMaskedInputMUI";
import { WrappedTextField } from "../../formikMUi/wrappedComponents/wrappedTextField";
import { ActionButtons } from "../actionButtons";
import { TableStateType } from "../scheduleTable";
import { DutyManager } from "./DutyManager";

export const publishBorderColor = "#a60c9a";

const getStyles = makeStyles<Theme>(theme => ({
  table: {
    fontFamily: theme.typography.fontFamily
  },
  flexContainer: {
    boxSizing: "border-box"
  },
  tableRow: {
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
    display: "flex",
    flex: "1 1 auto"
  },
  tableRowHover: {
    "&:hover": {
      backgroundColor: theme.palette.grey[200]
    }
  },
  sticky: {
    position: "sticky",
    top: 0,
    zIndex: 1,
    backgroundColor: "white"
  },
  tableCell: {
    flex: 1,
    flexDirection: "column"
  },
  TableCellRoot: {
    borderBottom: "none"
  },
  noClick: {
    cursor: "initial"
  },
  sign: {
    padding: theme.spacing(1)
  },
  redHighLight: {
    backgroundColor: "rgb(251, 200, 202)",
    height: "min-content",
    textAlign: "center"
  },
  greenHighLight: {
    borderStyle: "solid",
    borderWidth: "0.1rem",
    borderColor: green["400"],
    margin: "0px 0px 3px 0",
    padding: "4px 0",
    // borderRadius: "45%",
    height: "min-content",
    textAlign: "center"
  },
  blueHighLight: {
    borderStyle: "solid",
    borderWidth: "0.1rem",
    borderColor: "#74bcff",
    margin: "0px 0px 3px 0",
    padding: "4px 0",
    // borderRadius: "45%",
    // backgroundColor: "#74bcff",
    height: "min-content",
    // borderRadius: "10px",
    textAlign: "center"
  },
  notPublishedMinistrationHighLight: {
    borderStyle: "solid",
    borderWidth: "0.1rem",
    borderColor: publishBorderColor,
    margin: "0px 0px 3px 0",
    padding: "4px 0",
    // borderRadius: "45%",
    height: "min-content",
    textAlign: "center"
  },
  fullWidth: {
    width: "100%"
  },
  redColor: {
    color: red["500"]
  },
}));

const priestValueGetter = (mas: PerformerT[]) =>
  Array.isArray(mas) ? mas[0] : null;

const RenderRowsPrivate = ({
  hiddenColumns,
  hasAdminRights,
  openModal,
  successCallback,
  readOnly,
  actionButtonsStyle,
  isPersonal,
  userId,
  setEverythingPublished
}: RenderRowsProps) => {
  const classes = getStyles();
  return (
    <FieldArray
      name="days"
      validateOnChange={false}
      render={dayArrayHelpers => {
        const { values }: { values: TableStateType } = dayArrayHelpers.form;
        return values.days.map((row, rowIndex) => {
          const { day, monthWord, events = [], isHoliday } = row;
          // todo при отказе от IE удалить
          const msStyles = getInternetExplorerRequiredStyles(
            rowIndex + 2,
            hiddenColumns
          );
          return (
            <React.Fragment key={day}>
              {!hiddenColumns.day && (
                <div style={msStyles.day}>
                  {monthWord && (
                    <Typography
                      className={clsx({
                        [classes.redColor]: isHoliday
                      })}
                    >
                      {monthWord.label}
                    </Typography>
                  )}
                  <DayButtons
                    actionButtonsStyle={actionButtonsStyle}
                    disabled={readOnly}
                    push={event => {
                      dayArrayHelpers.form.setFieldValue(
                        `days.${rowIndex}.events`,
                        [...events, event]
                      );
                    }}
                    day={day}
                    isPersonal={isPersonal}
                    openModal={openModal}
                    userId={userId}
                    successCallback={successCallback}
                  />
                </div>
              )}
              {!hiddenColumns.monthWord && (
                <div style={msStyles.mothWord}>
                  <FastField
                    name={`days.${rowIndex}.monthWord`}
                    rowData={row}
                    rowIndex={rowIndex}
                    component={MonthWordCell}
                    openModal={openModal}
                    successCallback={successCallback}
                    hasAdminRights={hasAdminRights}
                  />
                  <DayButtons
                    actionButtonsStyle={actionButtonsStyle}
                    disabled={readOnly}
                    push={event => {
                      dayArrayHelpers.form.setFieldValue(
                        `days.${rowIndex}.events`,
                        [...events, event]
                      );
                    }}
                    day={day}
                    isPersonal={isPersonal}
                    openModal={openModal}
                    successCallback={successCallback}
                    userId={userId}
                    duties={row.duties}
                    clearDuty={() =>
                      dayArrayHelpers.form.setFieldValue(
                        `days.${rowIndex}.duties`,
                        []
                      )
                    }
                  />
                </div>
              )}
              {!hiddenColumns.duties && (
                <div style={msStyles.duties}>
                  <FieldArray
                    name={`days.${rowIndex}.duties`}
                    //@ts-ignore
                    component={DutyManager}
                  />
                </div>
              )}
              <FieldArray
                name={`days.${rowIndex}.events`}
                validateOnChange={false}
                render={eventHelpers => {
                  const eventGridStyle = {
                    ...msStyles.events,
                    /* todo тут кастыль с границей ибо я уезжал в больницу */
                    borderTop: isPersonal
                      ? "1px solid rgba(224, 224, 224, 1)"
                      : undefined
                  };
                  return (
                    <div className="eventGrid" style={eventGridStyle}>
                      {/* Когда событий нет, нужны кнопки добавления и копирования */}
                      {events.length === 0 && (
                        <>
                          <div />
                          <div />
                          <div />
                          <div />
                        </>
                      )}

                      {events.map((event, eventIndex) => {
                        // todo при отказе от IE удалить
                        const msEventStyles = getInternetExplorerEventRequiredStyles(
                          eventIndex + 2
                        );
                        const {
                          typeEnum,
                          free,
                          canEdit: canEditEvent,
                          published
                        } = event;
                        const eventTimeClassName = clsx(
                          {
                            [classes.redHighLight]: typeEnum === "slot" && !free
                          },
                          {
                            [classes.greenHighLight]:
                              typeEnum === "slot" && !!free
                          },
                          {
                            [classes.blueHighLight]: typeEnum === "workItem"
                          },
                          {
                            [classes.notPublishedMinistrationHighLight]:
                              typeEnum === "ministration" && !published
                          }
                        );
                        const eventRowLevelReadOny =
                          !canEditEvent ||
                          (typeEnum !== "ministration" && !isPersonal) ||
                          readOnly;

                        function onEventClick(e: MouseEvent<HTMLDivElement>) {
                          const target = e.target as HTMLElement;
                          if (target.classList.contains("event-cell-marker")) {
                            openEventCard(
                              event.uniqueId,
                              typeEnum,
                              hasAdminRights,
                              canEditEvent,
                              openModal,
                              event.uuid,
                              isPersonal,
                              successCallback
                            );
                          }
                        }

                        const descriptionField = (
                          <FastField
                            name={`days.${rowIndex}.events.${eventIndex}.description`}
                            InputProps={{
                              readOnly: !canEditEvent || readOnly
                            }}
                            component={WrappedTextField}
                            multiline
                            rowsMax={3}
                            className={classes.fullWidth}
                            style={
                              !isPersonal ? { margin: "8px 0" } : undefined
                            }
                          />
                        );
                        return (
                          <React.Fragment key={event.uniqueId}>
                            <div
                              className="event-cell-marker"
                              onClick={onEventClick}
                            >
                              <div
                                className="event-time"
                                style={msEventStyles.time}
                              >
                                <FastField
                                  name={`days.${rowIndex}.events.${eventIndex}.from`}
                                  {...baseTimeFieldProps}
                                  keepCharPositions
                                  InputProps={{
                                    readOnly: eventRowLevelReadOny,
                                    classes: {
                                      input: eventTimeClassName
                                    }
                                  }}
                                  component={WrappedMaskedInputMUI}
                                />
                                <Typography
                                  style={{
                                    // @ts-ignore
                                    msGridColumn: 2
                                  }}
                                  className={classes.sign}
                                >
                                  -
                                </Typography>
                                <FastField
                                  name={`days.${rowIndex}.events.${eventIndex}.to`}
                                  {...baseTimeFieldProps}
                                  keepCharPositions
                                  InputProps={{
                                    readOnly: eventRowLevelReadOny,
                                    classes: {
                                      input: eventTimeClassName
                                    }
                                  }}
                                  style={{
                                    // @ts-ignore
                                    msGridColumn: 3
                                  }}
                                  component={WrappedMaskedInputMUI}
                                />
                              </div>
                            </div>
                            <div
                              className="event-cell-marker"
                              onClick={onEventClick}
                              style={msEventStyles.type}
                            >
                              <FastField
                                name={`days.${rowIndex}.events.${eventIndex}.type`}
                              >
                                {({
                                  field,
                                  meta,
                                  ...rest
                                }: FieldProps<OptionT | null>) => (
                                  <WrappedReactSelect
                                    customOptionsName={
                                      isPersonal
                                        ? "slotEventTypes"
                                        : "ministrationEventTypes"
                                    }
                                    placeholder=" "
                                    isDisabled={eventRowLevelReadOny}
                                    {...field}
                                    error={meta.error}
                                    {...rest}
                                  />
                                )}
                              </FastField>
                              {!isPersonal && descriptionField}
                            </div>
                            <div
                              className="event-cell-marker"
                              onClick={onEventClick}
                              style={msEventStyles.performers}
                            >
                              {!isPersonal ? (
                                <FastField
                                  name={`days.${rowIndex}.events.${eventIndex}.performers`}
                                >
                                  {({
                                    field,
                                    meta,
                                    ...rest
                                  }: FieldProps<OptionT | null>) => (
                                    <WrappedReactSelect
                                      isMulti={typeEnum === "ministration"}
                                      isCreatable={typeEnum === "ministration"}
                                      multiValueFlipPath={
                                        typeEnum === "ministration"
                                          ? "isMainPerformer"
                                          : undefined
                                      }
                                      selectAllOptions={
                                        typeEnum === "ministration"
                                          ? {
                                              label: "Всё духовенство"
                                            }
                                          : undefined
                                      }
                                      customOptionsName={
                                        isPersonal
                                          ? "personalPriests"
                                          : "hramPriests"
                                      }
                                      placeholder=" "
                                      isDisabled={eventRowLevelReadOny}
                                      customValueGetter={
                                        typeEnum !== "ministration"
                                          ? priestValueGetter
                                          : undefined
                                      }
                                      customOnChange={
                                        typeEnum !== "ministration"
                                          ? ({
                                              name,
                                              setFieldValue,
                                              value
                                            }: CustomOnChangeArg) =>
                                              setFieldValue(name, [value])
                                          : undefined
                                      }
                                      {...field}
                                      error={meta.error}
                                      {...rest}
                                    />
                                  )}
                                </FastField>
                              ) : (
                                descriptionField
                              )}
                            </div>
                            <div style={msEventStyles.buttons}>
                              <ActionButtons
                                rowData={row}
                                rowIndex={rowIndex}
                                currentEvent={event}
                                eventIndex={eventIndex}
                                arrayHelpers={eventHelpers}
                                successCallback={successCallback}
                                hasAdminRights={hasAdminRights}
                                setEverythingPublished={setEverythingPublished}
                              />
                            </div>
                          </React.Fragment>
                        );
                      })}
                    </div>
                  );
                }}
              />
            </React.Fragment>
          );
        });
      }}
    />
  );
};

export const RenderRows = memo(RenderRowsPrivate, isEqual);
