import { FormikValues } from "formik";
import { AnyAction } from "redux";
import {
  ON_PAGE_CHANGE_RESET_FILTERS,
  RESET_COMPUTED_FILTERS,
  SET_COMPUTED_FILTERS,
  SET_FILTERS_APPLIED,
  SET_FILTERS_CONFIG,
  SET_FILTERS_OPTIONS_MAP,
  TOGGLE_IS_COLLAPSED
} from "../constants/actionTypeConstants";
import { IField, OptionsMapType } from "../constants/types";

const initialState: FiltersStateT = {
  computedFilters: {},
  isCollapsed: true,
  optionsMap: {},
  filtersApplied: false,
  onPageChangeResetFilters: true
};

export interface FiltersStateT {
  filtersApplied: boolean;
  computedFilters: FormikValues;
  isCollapsed: boolean;
  optionsMap: OptionsMapType;
  filters?: IField[];
  onPageChangeResetFilters: boolean;
}

export default function filtersReducer(
  state: FiltersStateT = initialState,
  action: AnyAction
) {
  switch (action.type) {
    case TOGGLE_IS_COLLAPSED: {
      return {
        ...state,
        isCollapsed:
          action.payload && action.payload.isCollapsed
            ? action.payload.isCollapsed
            : !state.isCollapsed
      };
    }
    case RESET_COMPUTED_FILTERS: {
      return {
        ...state,
        computedFilters: {},
        isCollapsed: true,
        onPageChangeResetFilters: true
      };
    }
    case SET_COMPUTED_FILTERS: {
      return {
        ...state,
        computedFilters: { ...action.payload }
      };
    }
    case SET_FILTERS_OPTIONS_MAP: {
      return {
        ...state,
        optionsMap: action.payload
      };
    }
    case SET_FILTERS_CONFIG: {
      return {
        ...state,
        filters: action.payload
      };
    }
    case SET_FILTERS_APPLIED: {
      return {
        ...state,
        filtersApplied: action.payload
      };
    }
    case ON_PAGE_CHANGE_RESET_FILTERS: {
      return {
        ...state,
        onPageChangeResetFilters: action.payload ?? true
      };
    }
    default:
      return state;
  }
}
