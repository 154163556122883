import DayPicker, {
  CaptionElementProps,
  DayPickerProps
} from "react-day-picker";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { MenuItem, TextField } from "@material-ui/core";
import { isValid } from "date-fns";

const WEEKDAYS_SHORT = {
  ru: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
  it: ["Do", "Lu", "Ma", "Me", "Gi", "Ve", "Sa"]
};
const MONTHS = {
  ru: [
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "Октябрь",
    "Ноябрь",
    "Декабрь"
  ],
  it: [
    "Gennaio",
    "Febbraio",
    "Marzo",
    "Aprile",
    "Maggio",
    "Giugno",
    "Luglio",
    "Agosto",
    "Settembre",
    "Ottobre",
    "Novembre",
    "Dicembre"
  ]
};

const WEEKDAYS_LONG = {
  ru: [
    "Воскресенье",
    "Понедельник",
    "Вторник",
    "Среда",
    "Четверг",
    "Пятница",
    "Суббота"
  ],
  it: [
    "Domenica",
    "Lunedì",
    "Martedì",
    "Mercoledì",
    "Giovedì",
    "Venerdì",
    "Sabato"
  ]
};

const FIRST_DAY_OF_WEEK = {
  ru: 1,
  it: 1
};
// Translate aria-labels
const LABELS = {
  ru: { nextMonth: "следующий месяц", previousMonth: "предыдущий месяц" },
  it: { nextMonth: "Prossimo mese", previousMonth: "Mese precedente" }
};

const modifiersStyles = {
  today: {
    color: "inherit"
  }
};

export interface DayPickerMUIProps extends Partial<DayPickerProps> {
  locale?: "ru";
}

const CaptionElement = ({
  months,
  date,
  setDisplayedMoth
}: CaptionElementProps & { setDisplayedMoth: (d: Date) => void }) => {
  const month = date.getMonth();
  const [year, setYear] = useState(date.getFullYear());

  useEffect(() => {
    setYear(date.getFullYear());
  }, [date]);

  return (
    <div className="DayPicker-Caption">
      <TextField
        select
        value={month}
        onChange={event => {
          const { target } = event;
          event.stopPropagation();
          if (target) {
            setDisplayedMoth(
              new Date(date.getFullYear(), Number(target.value))
            );
          }
        }}
        style={{ marginRight: "8px" }}
      >
        {months &&
          months.map((m, monthIndex) => (
            <MenuItem key={m} value={monthIndex}>
              {m}
            </MenuItem>
          ))}
      </TextField>
      <TextField
        value={year}
        onChange={event => {
          const { target } = event;
          if (target) {
            setYear(Number(target.value.match(/[0-9]{0,4}/)));
          }
        }}
        onBlur={(event: SyntheticEvent<any>) => {
          const { target } = event;
          if (target) {
            setDisplayedMoth(new Date(year, date.getMonth()));
          }
        }}
        style={{ width: "40px" }}
      />
    </div>
  );
};

export const DayPickerMUI = ({
  locale = "ru",
  disabledDays,
  selectedDays,
  onDayClick,
  ...rest
}: DayPickerMUIProps) => {
  // @ts-ignore
  const [displayedMoth, setDisplayedMoth] = useState<Date>(() => {
    if (
      Array.isArray(selectedDays) &&
      selectedDays.length > 0 &&
      isValid(selectedDays[0])
    ) {
      return selectedDays[0];
    }
    if (selectedDays && !Array.isArray(selectedDays) && isValid(selectedDays)) {
      return selectedDays;
    }
    if (selectedDays && "from" in selectedDays && selectedDays.from) {
      return selectedDays.from;
    }
    return new Date();
  });

  useEffect(() => {
    if (
      Array.isArray(selectedDays) &&
      selectedDays.length > 0 &&
      isValid(selectedDays[0])
    ) {
      setDisplayedMoth(selectedDays[0] as Date);
    } else if (selectedDays && isValid(selectedDays)) {
      // @ts-ignore
      setDisplayedMoth(selectedDays);
    }
  }, [selectedDays]);

  return (
    <DayPicker
      month={displayedMoth}
      captionElement={props => (
        <CaptionElement {...props} setDisplayedMoth={setDisplayedMoth} />
      )}
      modifiersStyles={modifiersStyles}
      months={MONTHS[locale]}
      weekdaysLong={WEEKDAYS_LONG[locale]}
      weekdaysShort={WEEKDAYS_SHORT[locale]}
      firstDayOfWeek={FIRST_DAY_OF_WEEK[locale]}
      labels={LABELS[locale]}
      locale="ru"
      selectedDays={selectedDays}
      onDayClick={onDayClick}
      disabledDays={disabledDays}
      {...rest}
    />
  );
};
