import { Action, AnyAction, Dispatch } from "redux";
import axios, { AxiosError, CancelTokenSource } from "axios";
import { FormikValues } from "formik";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { get, set, throttle } from "lodash";
import { v4 } from "uuid";
import { OptionsObject } from "notistack";
import ym from "react-yandex-metrika";
import {
  CLOSE_ALL_SNACKBARS,
  CLOSE_CONFIRM_DIALOG_FORMIK,
  CLOSE_MODAL_BY_KEY,
  CLOSE_SNACKBAR_BY_ID,
  HIDE_CONFIRM_DIALOG,
  ON_PAGE_CHANGE_RESET_FILTERS,
  OPEN_CONFIRM_DIALOG_FORMIK,
  POP_HEAD_MODAL,
  PUSH_MODAL,
  REPLACE_HEAD_MODAL,
  RESET_COMPUTED_FILTERS,
  RESET_SORTABLE,
  SAVE_FETCHED_DATA,
  SET_ACCESS_TOKEN,
  SET_ACTIVE_STEP,
  SET_ALLOWED_ACTIONS,
  SET_AUTH,
  SET_AUTHENTICATED,
  SET_BADGES_CONTENT,
  SET_BASE_URL,
  SET_COMPUTED_FILTERS,
  SET_COMPUTED_URL,
  SET_CURRENT_USER_OBJECT,
  SET_EXPANDED_INDEX,
  SET_FILTERS_APPLIED,
  SET_FILTERS_CONFIG,
  SET_FILTERS_OPTIONS_MAP,
  SET_JOURNAL_IS_LOADING_CONTENT,
  SET_LOGIN_ERROR_MESSAGE,
  SET_PAGE,
  SET_ROWS_PER_PAGE,
  SET_SCHEDULE_JOURNAL_ACTION_BTNS_DISPLAY,
  SET_SHOW_CHANGE_USER_SCRT_FORM,
  SET_SORTABLE,
  SET_UNREAD_MESSAGES_COUNT,
  TOGGLE_CONFIRM_DIALOG,
  TOGGLE_IS_COLLAPSED,
  TOGGLE_SNACKBAR,
  TOOGLE_MENU_COLLAPSED
} from "../constants/actionTypeConstants";
import { ModalT } from "../components/wrapper/types";
import { RootStateT } from "../reducers";
import {
  BadgesContentT,
  ConfirmDialogFormikT,
  ConfirmDialogT
} from "../reducers/wrapperReducer";
import { FormikMUIProps } from "../components/formikMUi/formikNewRealisation";
import {
  MESSAGE_400_500,
  MESSAGE_403,
  MESSAGE_404_204,
  MESSAGE_409
} from "../utils/defaultErrorHandler";
import { DownloadConfObj } from "../components/completePromiseThenRenderComponent";
import { constructAllowedActionsForAllJournals } from "../utils/commonFormFunctions";
import { INITIAL_PARAMETERS } from "../constants/intialParameters";
import { BlockFormObj, IField, PageT } from "../constants/types";
import {
  constructInitialValuesForEmptyForm,
  constructInitialValuesForEmptyFormWithOutBlocks
} from "../components/formikMUi/utils/initialValuesUtils";
import { AuthStateT } from "../reducers/auth";
import { rebuildValues } from "../components/modalWrapper/utils/rebuildValuesBeforeSending";
import { doBeforeSend } from "../constants/baseFormProps";
import customGlobalHistory from "../customGlobalHistory";
import { store } from "../store";
import {
  DataRestStructure,
  extractPayloadFromBaseSpringDateRestStructure
} from "../constants/rebuildRecieved";
import { setRecoilExternalState } from "../store/recoilAdapter";
import {
  overUserAtom,
  overUsersAtom,
  userChurchAtom
} from "../store/userStore";

// перехватчик запросов, добавляет access token ко всем запросам
axios.interceptors.request.use(
  config => {
    const token = store.getState().auth.accessToken;

    if (token != null) {
      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${token}`
        }
      };
    }
    return config;
  },
  error => Promise.reject(error)
);

// перехватчик ответов, проверяет если HTTP статус ответа равен 401 вызывает действие обнуляющее объект аутентификации
axios.interceptors.response.use(
  response => response,
  error => {
    if (typeof error.response === "undefined") {
      return Promise.reject(error);
    }
    if (
      error.response.data.message === "invalid_token" ||
      error.response.status === 401
    ) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      store.dispatch(logout());
      customGlobalHistory.push("/");
    }
    // срабатывает когда бек не отвечает
    if (error.response.status === 502 || error.response.status === 504) {
      customGlobalHistory.push("/error/Проводятся технические работы");
    }

    // срабатывает в dev режиме когда бек не отвечает
    if (
      typeof error.response.data === "string" &&
      error.response.data.search("Proxy error: Could not proxy request") !== -1
    ) {
      customGlobalHistory.push("/error/Проводятся технические работы");
    }
    return Promise.reject(error);
  }
);

export type getStateT = () => RootStateT;

export interface SetComputedUrlT {
  type: "SET_COMPUTED_URL";
  payload?: any;
}

export interface ToogleLeftMenuT {
  type: typeof TOOGLE_MENU_COLLAPSED;
}

export interface SaveFetchedDataT {
  type: "SAVE_FETCHED_DATA";
  payload?: any;
}

interface PopHeadModalT {
  type: "POP_HEAD_MODAL";
}

interface PushModal {
  type: "PUSH_MODAL";
  payload: ModalT;
}

export type ModalWithOptionalKey = MakePropertiesOptional<ModalT, "key">;

export type PushModalT = (modal: ModalWithOptionalKey) => void;

interface OpenSnackBarActionT {
  type: "TOGGLE_SNACKBAR";
  payload: { message: string; isSnackBarOpened: boolean };
}

export type OpenModalT = (p: ModalT) => void;

export type OpenSnackBarT = (message: string) => void;

export type TActions =
  | SetComputedUrlT
  | SaveFetchedDataT
  | PopHeadModalT
  | PushModal;

export interface SortT {
  path: string;
  value: string;
}

/**
 * делалет часть свойств не обязательными
 */
export type MakePropertiesOptional<T1, T2 extends keyof T1> = Omit<T1, T2> &
  {
    [Z in T2]?: T1[Z];
  };

export type ConfirmDialogFormikTPartial = MakePropertiesOptional<
  ConfirmDialogFormikT,
  "open" | "primaryButtonText" | "secondaryButtonText"
> &
  Partial<FormikMUIProps>;

export const openConfirmDialogFormik = (
  payload: ConfirmDialogFormikTPartial
): AnyAction => ({
  type: OPEN_CONFIRM_DIALOG_FORMIK,
  payload: {
    open: true,
    ...payload,
    initialValues:
      payload.initialValues ||
      constructInitialValuesForEmptyForm(payload.formObj)
  }
});

export const closeConfirmDialogFormik = (): AnyAction => ({
  type: CLOSE_CONFIRM_DIALOG_FORMIK
});

/* export const openSnackBar = createStandardAction(TOGGLE_SNACKBAR).map(
  (message: string) => ({
    payload: { message, isSnackBarOpened: true }
  })
); */

export const closeSnackBarById = (id: string) => ({
  type: CLOSE_SNACKBAR_BY_ID,
  payload: id
});

export const closeSnackBars = () => ({
  type: CLOSE_ALL_SNACKBARS
});

export type ThunkDispatchTyped = ThunkDispatch<RootStateT, any, AnyAction>;

export const openSnackBar = (message: string, options?: OptionsObject) => (
  dispatch: ThunkDispatchTyped
) => {
  const uuid = v4();
  dispatch({
    type: TOGGLE_SNACKBAR,
    payload: { key: uuid, message, options }
  });
};

export const filtersReducer = (
  accumulator: string,
  currentValue: [string, any]
) => {
  const [key, value] = currentValue;
  if (Array.isArray(value)) {
    let joo = accumulator;
    value.forEach((item: any) => {
      if (item) {
        joo += `&${key}=${encodeURIComponent(item)}`;
      }
    });
    return joo;
  }
  if (value && value !== "" && value !== null) {
    return `${accumulator}&${key}=${encodeURIComponent(value)}`;
  }
  return accumulator;
};

export function addFiltersToUrl(
  computedFilters: {
    [x: string]: any;
  },
  url: string,
  filters: IField[] | BlockFormObj
) {
  const resultingFilters = rebuildValues(computedFilters, {
    doBeforeSend,
    blocks: Array.isArray(filters)
      ? [
          {
            fields: filters
          }
        ]
      : filters.blocks
  });
  return Object.entries(resultingFilters).reduce(
    filtersReducer,
    url.includes("?") ? url : `${url}?`
  );
}

const sortsReducer = (accumulator: string, currentValue: SortT) =>
  `${accumulator}&s=${currentValue.path},${currentValue.value}`;

export const setJournalLoadingContent = (loading: boolean) => ({
  type: SET_JOURNAL_IS_LOADING_CONTENT,
  payload: loading
});

export const fetchJournalData = (
  dispatch: Dispatch<AnyAction>,
  getState: getStateT
) => {
  dispatch(setJournalLoadingContent(true));
  return axios
    .get<DataRestBaseJournal>(getState().journalReducer.computedUrl)
    .then(({ data }) => {
      dispatch(setJournalLoadingContent(false));
      dispatch({
        type: SAVE_FETCHED_DATA,
        payload: {
          data: extractPayloadFromBaseSpringDateRestStructure<BaseJournalRow>(
            data
          ),
          totalElements: data.page.totalElements
        }
      });
    })
    .catch(error => {
      console.log(error);
    });
};

export type BaseJournalRow = {
  uniqueId: string;
  uuid: string;
  [x: string]: any;
};

export type DataRestBaseJournal = DataRestStructure<BaseJournalRow[]>;

export const fetchRowForJournalData = (row: BaseJournalRow) => (
  dispatch: Dispatch<AnyAction>,
  getState: getStateT
) => {
  dispatch(setJournalLoadingContent(true));
  const { fetchedData } = getState().journalReducer;
  return axios
    .get<DataRestBaseJournal>(
      `${getState().journalReducer.computedUrl}&uuid=${row.uuid}`
    )
    .then(({ data }) => {
      dispatch(setJournalLoadingContent(false));
      const newData = extractPayloadFromBaseSpringDateRestStructure<
        BaseJournalRow
      >(data);
      const rowIndexByUuid = fetchedData.findIndex(
        ({ uuid }) => uuid === newData[0].uuid
      );

      const result = fetchedData.slice();
      if (rowIndexByUuid > -1) {
        result[rowIndexByUuid] = newData[0];
      }
      dispatch({
        type: SAVE_FETCHED_DATA,
        payload: {
          data: result,
          totalElements: data.page.totalElements
        }
      });
    })
    .catch(error => {
      console.log(error);
    });
};

export const computeUrl = (dispatch: Dispatch, getState: getStateT) => {
  const {
    journalReducer: {
      sortable,
      pagination: { rowsPerPage, page },
      baseUrl
    },
    filtersReducer: { computedFilters, filters }
  } = getState();

  // кастыль придуманный женей чтоб сделать захаркоженную фильтрацию
  let finalUrl = typeof baseUrl === "function" ? baseUrl() : baseUrl.slice();

  if (!finalUrl.includes("?")) {
    finalUrl += "?";
  }
  if (sortable.length > 0) {
    finalUrl = sortable.reduce(sortsReducer, finalUrl);
  }
  if (filters) {
    finalUrl = addFiltersToUrl(computedFilters, finalUrl, filters);
  }
  finalUrl += `&p=${page - 1}&l=${rowsPerPage}`;
  return Promise.resolve(
    dispatch({ type: SET_COMPUTED_URL, payload: finalUrl })
  );
};

export const computeUrlThenFetchJournalData = () => (
  dispatch: Dispatch<TActions>,
  getState: getStateT
) => {
  return computeUrl(dispatch, getState).then(() =>
    fetchJournalData(dispatch, getState)
  );
};

export const popHeadModal = () => ({ type: POP_HEAD_MODAL });

export const closeModalByKey = (key: string) => ({
  type: CLOSE_MODAL_BY_KEY,
  payload: key
});

export const pushHeadModal = (modal: ModalWithOptionalKey) => (
  dispatch: Dispatch,
  getState: getStateT
) => {
  if (
    getState().journalReducer.modalState.openedModals.some(
      (x: ModalT) =>
        x.category === modal.category &&
        x.page === modal.page &&
        x.item &&
        modal.item &&
        x.item.uniqueId === modal.item.uniqueId
    )
  ) {
    return dispatch({
      type: TOGGLE_SNACKBAR,
      payload: {
        message: "Карточка данного объекта уже открыта",
        isSnackBarOpened: true
      }
    });
  }
  return dispatch({ type: PUSH_MODAL, payload: modal });
};

export const popHeadModalThenPushModal = (modal: ModalWithOptionalKey) => (
  dispatch: ThunkDispatchTyped
) => {
  dispatch(popHeadModal());
  dispatch(pushHeadModal(modal));
};

export const replaceHeadModal = (modal: ModalWithOptionalKey) => (
  dispatch: ThunkDispatch<RootStateT, any, AnyAction>
) => {
  dispatch({ type: REPLACE_HEAD_MODAL, payload: modal });
};

export const saveFilters = (values: FormikValues) => {
  return {
    type: SET_COMPUTED_FILTERS,
    payload: values
  };
};

export const initializeFilters = (filters: IField[], auth: AuthStateT) => (
  dispatch: ThunkDispatchTyped,
  state: () => RootStateT
) => {
  if (state().filtersReducer.onPageChangeResetFilters) {
    dispatch(
      saveFilters(
        constructInitialValuesForEmptyFormWithOutBlocks(
          { fields: filters },
          auth
        )
      )
    );
  }
};

export const resetFilters = (): ThunkAction<
  Promise<any>,
  RootStateT,
  null,
  Action
> => async dispatch => {
  dispatch(saveFilters({}));
  return dispatch(computeUrlThenFetchJournalData());
};

export function forbidFiltersValueReset(v?: boolean): AnyAction {
  return {
    type: ON_PAGE_CHANGE_RESET_FILTERS,
    payload: !v
  };
}

export const toggleLeftMenu = (): ToogleLeftMenuT => ({
  type: TOOGLE_MENU_COLLAPSED
});

export const setExpandedIndex = (index: number) => ({
  type: SET_EXPANDED_INDEX,
  payload: { expandedIndex: index }
});

export type ScheduleButtonsVariants = "text" | "icon";

export const setScheduleButtonsStyle = (v: ScheduleButtonsVariants) => (
  dispatch: Dispatch
) =>
  dispatch({
    type: SET_SCHEDULE_JOURNAL_ACTION_BTNS_DISPLAY,
    payload: v
  });

export const setBadgesContent = (content: BadgesContentT) => ({
  type: SET_BADGES_CONTENT,
  payload: content
});

export const loadBadgesContent = throttle(
  async (dispatch: ThunkDispatchTyped, getState: () => RootStateT) => {
    const { allowedActions } = getState().wrapperReducer;

    const badgesToDownLoad: { url: string; path: string }[] = [];

    for (const [firstLevelName, value] of Object.entries(allowedActions)) {
      const firstLevelPageParameters: PageT | undefined = get(
        INITIAL_PARAMETERS,
        firstLevelName
      );
      if (firstLevelPageParameters && firstLevelPageParameters.badge) {
        const badgeUrl = firstLevelPageParameters.badge.url;
        badgesToDownLoad.push({
          url:
            badgeUrl && typeof badgeUrl === "function"
              ? await badgeUrl()
              : badgeUrl,
          // "_category" добавил чтобы отделить от вложенных не меняя структуру
          path: `${firstLevelName}_category`
        });
      }
      if (INITIAL_PARAMETERS[firstLevelName].pages) {
        if (typeof value === "object") {
          for (const [secondLevelName, nestedP] of Object.entries(value)) {
            if (Array.isArray(nestedP)) {
              const pageParameters: PageT | undefined = get(
                INITIAL_PARAMETERS,
                `${firstLevelName}.pages.${secondLevelName}`
              );
              if (pageParameters && pageParameters.badge) {
                const badgeUrl = pageParameters.badge.url;
                badgesToDownLoad.push({
                  url:
                    badgeUrl && typeof badgeUrl === "function"
                      ? await badgeUrl()
                      : badgeUrl,
                  path: `${firstLevelName}.${secondLevelName}`
                });
              }
            }
          }
        }
      }
    }
    axios
      .all(badgesToDownLoad.map(({ url }) => axios.get<number>(url)))
      .then(responses => {
        const result = {};
        responses.forEach(({ data }, responseIndex) => {
          set(result, badgesToDownLoad[responseIndex].path, data);
        });
        dispatch(setBadgesContent(result));
      })
      .catch(reason => {
        if (!axios.isCancel(reason)) {
          dispatch(openSnackBar("Произошла ошибка при загрузке напоминаний"));
        }
      });
  },
  60000
);

export const throttledLoadBadgesContent = () => (
  dispatch: ThunkDispatchTyped
) => dispatch(loadBadgesContent);

export const toggleFilterPanel = (isCollapsed: boolean) => (
  dispatch: Dispatch
) => dispatch({ type: TOGGLE_IS_COLLAPSED, payload: { isCollapsed } });

export const resetFilterPanel = () => ({ type: RESET_COMPUTED_FILTERS });

export const resetSortable = () => ({ type: RESET_SORTABLE });

export const applySortAndFetchJournalData = (name: string) => (
  dispatch: ThunkDispatchTyped
) => {
  dispatch(setSortable(name));
  return dispatch(computeUrlThenFetchJournalData());
};

export const setSortable = (name: string) => (dispatch: Dispatch) =>
  dispatch({ type: SET_SORTABLE, payload: name });

export const setPage = (page: number) => ({ type: SET_PAGE, payload: page });

export const setRowsPerPage = (rowsPerPage: number) => (dispatch: Dispatch) =>
  dispatch({ type: SET_ROWS_PER_PAGE, payload: rowsPerPage });

export const setBaseUrl = (url: string) => ({
  type: SET_BASE_URL,
  payload: url
});

export const setFilterPanelOptionsMap = (optionsMap: {}) => ({
  type: SET_FILTERS_OPTIONS_MAP,
  payload: optionsMap
});

export const loadFiltersOptions = (
  config: DownloadConfObj[],
  source: CancelTokenSource
) => (dispatch: ThunkDispatchTyped) => {
  axios
    .all(
      config.map(item =>
        axios.get(item.urlToDownload, {
          cancelToken: source.token
        })
      )
    )
    .then(response => {
      const downloaded = {
        optionsMap: {}
      };
      config.forEach(
        (
          {
            whereToStore,
            rebuildReceivedValue,
            postLoadDoSomethingThenPassResultAsProp
          },
          index
        ) => {
          const { data } = response[index];

          set(
            downloaded,
            whereToStore,
            rebuildReceivedValue
              ? rebuildReceivedValue(data)
              : { options: data, loaded: true }
          );

          if (postLoadDoSomethingThenPassResultAsProp) {
            const {
              func,
              passResultAs
            } = postLoadDoSomethingThenPassResultAsProp;
            // @ts-ignore
            set(downloaded, passResultAs, func(data));
          }
        }
      );
      return downloaded;
    })
    .then(map => dispatch(setFilterPanelOptionsMap(map.optionsMap)))
    .catch(error => {
      if (!axios.isCancel(error)) {
        dispatch(defaultErrorHandler(error));
      }
    });
};

export const setAuth = (
  isAuthenticated: boolean,
  accessToken: string | null
) => ({
  type: SET_AUTH,
  payload: { isAuthenticated, accessToken }
});

export const setUnreadMessagesCount = (count: number) => ({
  type: SET_UNREAD_MESSAGES_COUNT,
  payload: count
});

export const logout = () => (dispatch: Dispatch) => {
  dispatch(setAuth(false, null));
  dispatch(closeSnackBars());
  dispatch(setUnreadMessagesCount(0));
  dispatch(setBadgesContent({}));
  dispatch(setShowChangePasswordForm(false));
  setRecoilExternalState(overUserAtom, null);
  setRecoilExternalState(userChurchAtom, null);
};

export const loadUnreadMessagesCount = () => (dispatch: ThunkDispatchTyped) => {
  axios
    .get<number>("/api/message/new/count")
    .then(({ data }) => {
      if (typeof data === "number") {
        dispatch(setUnreadMessagesCount(data));
      }
    })
    .catch(reason => {
      if (!axios.isCancel(reason)) {
        dispatch(
          openSnackBar("Произошла ошибка при загрузке непрочитанных сообщений")
        );
      }
    });
};

export const throttledLoadUnreadMessagesCount = () =>
  throttle(loadUnreadMessagesCount, 60000);

export const setActiveStep = (step: number, passProps: any) => ({
  type: SET_ACTIVE_STEP,
  payload: { activeStep: step, passProps }
});

export const incrementActiveStep = (passProps: any) => (
  dispatch: Dispatch,
  getState: getStateT
) => {
  const {
    stepperReducer: { activeStep }
  } = getState();
  dispatch(setActiveStep(activeStep + 1, passProps));
};

export const decrementActiveStep = (passProps: any) => (
  dispatch: Dispatch,
  getState: getStateT
) => {
  const {
    stepperReducer: { activeStep }
  } = getState();
  dispatch(setActiveStep(activeStep - 1, passProps));
};

export const setAccessToken = (accessToken: string | null) => ({
  type: SET_ACCESS_TOKEN,
  payload: accessToken
});

export const loadUserInfo = () => axios.get("/api/user/getUserRolesFunctions");

export const setErrorMessage = (errorMessage: string) => ({
  type: SET_LOGIN_ERROR_MESSAGE,
  payload: {
    errorMessage
    // errorMessage: e.response.data.error_description
  }
});

export const setCurrentUsersInfo = (
  userId: number,
  surnameInitials: string,
  role: number,
  functions: string[],
  memberId: number,
  memberFullName: string,
  memberType: string,
  trainingLink: string | null
) => ({
  type: SET_CURRENT_USER_OBJECT,
  payload: {
    role,
    functions,
    surnameInitials,
    userId,
    memberId,
    memberFullName,
    memberType,
    trainingLink
  }
});

export const setAllowedActions = (functions: string[]) => ({
  type: SET_ALLOWED_ACTIONS,
  payload: constructAllowedActionsForAllJournals(functions, INITIAL_PARAMETERS)
});

export const setIsAuthenticated = (v: boolean) => ({
  type: SET_AUTHENTICATED,
  payload: v
});

export const getAccessToken = (username: string, password: string) => {
  const params = new URLSearchParams();
  params.append("grant_type", "password");
  params.append("username", username);
  params.append("password", password);
  return axios({
    method: "post",
    url: "/oauth/token",
    auth: {
      username:
        "e2c2ded61b3ebff84b65e78c0f15e6ce38187c521b13c94c20f2de168895d555",
      password:
        "3f55062869ebad5af93d27a0cfff957f73d8a01bf505f66d5cf69a77d11e6909"
    },
    headers: {
      "Content-type": "application/x-www-form-urlencoded; charset=utf-8"
    },
    data: params
  });
};

export const setTokenThenLoadUserInfo = (token: string) => (
  dispatch: Dispatch,
  getState: getStateT
) => {
  return Promise.resolve(dispatch(setAccessToken(token)))
    .then(() => loadUserInfo())
    .then(({ data }) => {
      const {
        roles,
        surnameInitials,
        userId,
        memberId,
        memberFullName,
        memberType,
        trainingLink
      } = data;
      const { role, functions } = roles[0];
      return dispatch(
        setCurrentUsersInfo(
          userId,
          surnameInitials,
          role,
          functions,
          memberId,
          memberFullName,
          memberType,
          trainingLink
        )
      );
    })
    .then(() => dispatch(setAllowedActions(getState().auth.functions)))
    .then(() => dispatch(setErrorMessage("")))
    .then(() => dispatch(setIsAuthenticated(true)))
    .catch(() =>
      Promise.all([
        dispatch(setAuth(false, null)),
        dispatch(setErrorMessage("Неверный логин или пароль"))
      ])
    );
};

export function setShowChangePasswordForm(value: boolean) {
  return {
    type: SET_SHOW_CHANGE_USER_SCRT_FORM,
    payload: value
  };
}

export const changeUserPassword = (
  username: string,
  password: string,
  newPassword: string
) => async (dispatch: ThunkDispatchTyped) => {
  await axios.patch(
    "/api/user/changePassword",
    {},
    {
      params: {
        login: username,
        newPassword,
        previousPassword: password
      }
    }
  );
  return dispatch(authenticateUser(username, newPassword));
};

export const authenticateUser = (username: string, password: string) => async (
  dispatch: ThunkDispatchTyped,
  getState: getStateT
): Promise<any> => {
  try {
    const { data: tokenResponse } = await getAccessToken(username, password);
    if (tokenResponse && tokenResponse.access_token) {
      dispatch(setAccessToken(tokenResponse.access_token));
      const { data: userObj } = await loadUserInfo();
      const {
        roles,
        surnameInitials,
        userId,
        memberId,
        memberFullName,
        memberType,
        trainingVideoLink: trainingLink,
        overUsers: rawOverUsers
      } = userObj;

      const { role, functions } = roles[0];
      dispatch(
        setCurrentUsersInfo(
          userId,
          surnameInitials,
          role,
          functions,
          memberId,
          memberFullName,
          memberType,
          trainingLink
        )
      );
      const {
        collapsibleMenuReducer: { value, expandedIndex }
      } = getState();
      dispatch(setAllowedActions(functions));
      if (!value) {
        dispatch(setExpandedIndex(expandedIndex));
      }
      dispatch(setShowChangePasswordForm(false));
      dispatch(setErrorMessage(""));
      dispatch(setIsAuthenticated(true));
      ym("reachGoal", "authenticated");

      setRecoilExternalState(
        overUsersAtom,
        rawOverUsers
          ? rawOverUsers.map((v: any) => ({
              ...v,
              value: v.userId,
              label: v.surnameInitials
            }))
          : []
      );
      setRecoilExternalState(userChurchAtom, {
        label: memberFullName,
        value: memberId
      });
    }
  } catch (error) {
    // @ts-ignore
    if (error.response && error.response.data) {
      const {
      // @ts-ignore
        data: { error_description }
      } = ((error as AxiosError)).response ;
      if (error_description === "User account is locked") {
        dispatch(setAuth(false, null));
        dispatch(
          setErrorMessage(
            "Было произведено более 10 неудачных попыток входа. Учётная запись заблокирована, попробуйте позже"
          )
        );
      } else if (error_description === "User credentials have expired") {
        dispatch(setAuth(false, null));
        dispatch(
          setErrorMessage(
            "Пароль не изменялся более 120 дней, требуется смена пароля"
          )
        );
        dispatch(setShowChangePasswordForm(true));
      } else {
        dispatch(setAuth(false, null));
        dispatch(setErrorMessage("Неверный логин или пароль"));
      }
    }
  }
};

export function setIsTestEnviroment(v: boolean) {
  return {
    type: "SET_IS_TEST_ENVIRONMENT",
    payload: v
  };
}

export const loadIsTestEnvironment = () => (dispatch: ThunkDispatchTyped) => {
  axios
    .get("/api/member/checkwork")
    .then(({ data: isTestEnv }) => dispatch(setIsTestEnviroment(isTestEnv)));
};

export function setFiltersPanelFiltersConfig(filters: IField[]) {
  return function(dispatch: ThunkDispatchTyped) {
    return dispatch({
      type: SET_FILTERS_CONFIG,
      payload: filters
    });
  };
}

export const openConfirmDialog = (
  payload: MakePropertiesOptional<
    ConfirmDialogT,
    "open" | "primaryButtonText" | "secondaryButtonText" | "title" | "mainText"
  >
) => (dispatch: Dispatch) =>
  dispatch({
    type: TOGGLE_CONFIRM_DIALOG,
    payload: { open: true, ...payload }
  });

export const closeConfirmDialog = () => (dispatch: Dispatch) =>
  dispatch({ type: HIDE_CONFIRM_DIALOG });

const getErrorMessageDependingOnStatus = (status: number) => {
  if (status === 500 || status === 400) {
    return MESSAGE_400_500;
  }
  if (status === 404 || status === 204) {
    return MESSAGE_404_204;
  }
  if (status === 403) {
    return MESSAGE_403;
  }
  if (status === 409) {
    return MESSAGE_409;
  }
};

export const defaultErrorHandler = (error: AxiosError) => (
  dispatch: ThunkDispatchTyped
) => {
  let errorMessage: string | undefined;
  if (
    error &&
    error.response &&
    error.response.data &&
    error.response.data.status
  ) {
    errorMessage = getErrorMessageDependingOnStatus(error.response.data.status);
  } else if (error && error.response && error.response.status) {
    errorMessage = getErrorMessageDependingOnStatus(error.response.status);
  }
  if (errorMessage) {
    return dispatch(openSnackBar(errorMessage));
  }
};

export function setFiltersAppliedActionsCreator(v: boolean) {
  return { type: SET_FILTERS_APPLIED, payload: v };
}


