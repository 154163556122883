import { Button } from "@material-ui/core";
import React from "react";
import { useFormikContext } from "formik";
import { passwordRegExp } from "../../constants/pages/users/regExp";
import { CustomField } from "../../constants/types";

function generateRandomString(
  length: number,
  characters: string,
  charactersLength: number
) {
  let result = "";
  Array.from(Array(length)).forEach(() => {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  });
  return result;
}

function randomString(length: number) {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let result = "";

  do {
    result = generateRandomString(length, characters, charactersLength);
  } while (!passwordRegExp.test(result));

  return result;
}

export const GeneratePasswordButton: CustomField["component"] = ({
  readOnly
}) => {
  const { setFieldValue, setFieldTouched } = useFormikContext<any>();
  return (
    <div style={{ height: "100%", display: "flex", alignItems: "end" }}>
      <Button
        disabled={readOnly}
        size="small"
        onClick={() => {
          setFieldTouched("password");
          setFieldValue("password", randomString(8));
        }}
        variant="outlined"
        color="primary"
      >
        Сгенерировать пароль
      </Button>
    </div>
  );
};
