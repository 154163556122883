import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import { GridSize } from "@material-ui/core/Grid/Grid";

export const fullWidth: Partial<Record<Breakpoint, boolean | GridSize>> = {
  md: 12,
  lg: 12,
  xl: 12
};
export const halfWith: Partial<Record<Breakpoint, boolean | GridSize>> = {
  md: 6,
  lg: 6,
  xl: 6
};
