import React from "react";
import { useFormikContext } from "formik";
import { DateRangePickerInput } from "../../../picker/dateRangePickerInput";
import { getDisabledDays } from "./utils";
import { WrappedDateRangePickerProps } from "./index";

export const WrappedDateRangePickerInput: React.FC<WrappedDateRangePickerProps> = ({
  name,
  onBlur,
  value,
  label,
  minDate,
  maxDate,
  readOnly,
  error,
  helperText
}: WrappedDateRangePickerProps) => {
  const { setFieldValue } = useFormikContext<any>();
  return (
    <DateRangePickerInput
      label={label}
      value={value}
      onBlur={onBlur}
      disabledDays={getDisabledDays(minDate, maxDate)}
      clearValue={() => setFieldValue(name, "")}
      readOnly={readOnly}
      onSelected={(selectedDays: string[]) => setFieldValue(name, selectedDays)}
      error={error}
      helperText={helperText}
      variant="standard"
    />
  );
};
