import { atom } from "recoil";
import { localStorageEffect } from "./effects";
import { OptionT } from "../constants/types";

const overUserKey = "overUser";
export type OverUserT = OptionT & {
  functions: string[];
};

export type OverUserValueT = OverUserT | null | undefined;

export const overUserAtom = atom<OverUserValueT>({
  key: overUserKey,
  default: null,
  effects: [localStorageEffect(overUserKey)]
});

const overUsers = "overUsers";

export const overUsersAtom = atom<OverUserT[]>({
  key: overUsers,
  default: [],
  effects: [localStorageEffect(overUsers)]
});

export const userChurchAtom = atom<OptionT | null>({
  key: "userChurch",
  default: null,
  effects: [localStorageEffect("userChurch")]
});
