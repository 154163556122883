import forms from "./forms";
import { constructRebuildReceivedOptionsLabelLabelValueUniqueId } from "../../rebuildRecieved";
import {OptionT, PageT} from "../../types";

export const churchStructure: PageT = {
  requiresFunction: [
    {
      functionName: "administrator_member_modifier",
      allowedActions: ["create", "view", "edit", "delete"]
    },
    {
      functionName: "administrator_structure_member_modifier",
      allowedActions: ["create", "view", "edit", "delete"]
    },
    {
      functionName: "administrator_hram_member_modifier",
      allowedActions: ["view", "edit"]
    },
    {
      functionName: "priest_member_view",
      allowedActions: ["view"]
    }
  ],
  filtersPanel: {
    filters: [
      {
        label: "Город",
        name: "townRef",
        type: "select",
        dictUrl: "/api/member/towns",
        rebuildReceivedOptions: false
      },
      {
        label: "Тип",
        placeholder: "Выберите тип",
        name: "typeCode",
        type: "select",
        dictUrl: "/api/strType/search/present",
        rebuildReceivedOptions: constructRebuildReceivedOptionsLabelLabelValueUniqueId
      },
      {
        label: "Подчинение",
        placeholder: "Введите наименование",
        name: "parentId",
        type: "select",
        dictUrl({townRef}) {
          let url = "/api/member/searchMemberOE?";
          if (townRef) {
            url += "&townRef=" + townRef.value;
          }
          return url;
        },
        deps: ["townRef"],
        async: true,
        rebuildReceivedOptions: false,
        additionalProps: {
          defaultOptions: false,
          cacheOptions: false
        }
      },
      {
        label: "Наименование",
        name: "label",
        type: "select",
        placeholder: "Введите наименование",
        dictUrl({ parentId, typeCode, townRef }) {
          let url = "/api/member/searchMember?";
          if (parentId) {
            url += "&parentId=" + parentId.value;
          }
          if (typeCode) {
            url += "&type=" + typeCode.value;
          }
          if (townRef) {
            url += "&townRef=" + townRef.value;
          }
          return url;
        },
        deps: ["parentId", "typeCode", "townRef"],
        async: true,
        rebuildReceivedOptions: data =>data.map((v:OptionT)=>({...v, value: v.label})) ,
        isCreatable: true,
        additionalProps: {
          defaultOptions: false,
          cacheOptions: false
        }
      },
      {
        label: "Адрес",
        name: "address",
        type: "text"
      },
      {
        label: "Активность",
        placeholder: "Введите активность",
        name: "isActive",
        type: "select",
        options: [
          { label: "Да", value: "true" },
          { label: "Нет", value: "false" }
        ],
        conditionallyRender: ({ typeCode }) => {
          return typeCode?.enum === "HRAM";
        }
      }
    ]
  },
  url: "/api/journalMembers",
  tableHeader: [
    {
      label: "Наименование",
      sortable: true,
      path: "label",
      width: "35%"
    },
    {
      label: "Тип",
      sortable: true,
      path: "type",
      width: "10%"
    },
    {
      label: "Активность",
      sortable: true,
      path: "isActive",
      width: "10%"
    },
    {
      label: "Подчинение",
      sortable: true,
      path: "parentLabel",
      width: "20%"
    },
    {
      label: "Адрес",
      sortable: true,
      path: "address",
      width: "35%"
    }
  ],
  forms
};
