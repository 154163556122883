export const phoneMask = [
  "+",
  "7",
  "(",
  /\d/,
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/
];

export const snilsMask = [
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/
];

export const time24HMaskWithOutSeconds = (value: string) => {
  if (value.charAt(0) === "2") {
    return [/[0-2]/, /[0-3]/, ":", /[0-5]/, /[0-9]/];
  }
  return [/[0-2]/, /[0-9]/, ":", /[0-5]/, /[0-9]/];
};

export const time24HMask = (value: string) => {
  if (value.charAt(0) === "2") {
    return [/[0-2]/, /[0-3]/, ":", /[0-5]/, /[0-9]/, ":", "0", "0"];
  }
  return [/[0-2]/, /[0-9]/, ":", /[0-5]/, /[0-9]/, ":", "0", "0"];
};
export const time24HMaskWithSeconds = time24HMask;
