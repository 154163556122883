import baseJournal from "../journal";
import { Work } from "@material-ui/icons";
import { PageT } from "../../../types";
import { getRecoilExternalLoadable } from "../../../../store/recoilAdapter";
import {
  overUserAtom,
  OverUserT,
  OverUserValueT
} from "../../../../store/userStore";

export async function addPerformer(url: string) {
  const overUserAt = await getRecoilExternalLoadable<OverUserValueT>(
    overUserAtom
  );
  if (overUserAt) {
    if (overUserAt.contents) {
      url += "?performer=" + overUserAt.contents.value;
    }
  }
  return url;
}

const claimInWork: PageT = {
  ...baseJournal,
  icon: Work,
  navigationLabel: "Ожидают подтверждения",
  url: async () => await addPerformer("/api/getProgressClaimObjectsList")
};
export default claimInWork;
