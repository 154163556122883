import React, { useMemo, useRef, useState } from "react";
import {
  Button,
  CircularProgress,
  Menu,
  MenuItem,
  Theme
} from "@material-ui/core";
import { PrintOutlined } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import { SelectedRangeT } from "./types";
import { downloadFile } from "../formikMUi/utils/downLoad";
import { constructParams } from "./constructParams";
import { OptionT } from "../../constants/types";

const getStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    position: "relative"
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
}));

export function DownloadScheduleButton({
  ministration,
  selectedRange,
  showAllEvents,
  date,
  user,
  isPersonal,
  isIconOnly,
  style
}: {
  date: Date;
  user: OptionT | null;
  ministration: OptionT | null;
  selectedRange: SelectedRangeT;
  showAllEvents: boolean;
  isPersonal: boolean;
  isIconOnly?: boolean;
  style?: any;
}) {
  const classes = getStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const onClick = useMemo(
    () => (format: "xlsx" | "pdf"): void => {
      setOpen(false);
      setIsLoading(true);
      downloadFile(
        `/api/reports/${isPersonal ? "priest" : "hram"}?${constructParams(
          selectedRange,
          date,
          isPersonal,
          showAllEvents,
          user,
          ministration
        )}&format=${format}`,
        `schedule.${format}`
      ).finally(() => setIsLoading(false));
    },
    [date, isPersonal, ministration, selectedRange, showAllEvents, user]
  );

  const ref = useRef(null);
  return (
    <div className={classes.wrapper}>
      <Button
        style={style}
        aria-controls="download-menu"
        aria-haspopup="true"
        variant="outlined"
        onClick={() => setOpen(true)}
        disabled={isLoading}
        ref={ref}
      >
        <PrintOutlined />
        {!isIconOnly && "Печать"}
      </Button>
      {isLoading && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
      <Menu
        id="download-menu"
        anchorEl={ref.current}
        open={open}
        onClose={() => setOpen(false)}
      >
        <MenuItem onClick={() => onClick("pdf")}>Pdf</MenuItem>
        <MenuItem onClick={() => onClick("xlsx")}>Xlsx</MenuItem>
      </Menu>
    </div>
  );
}
