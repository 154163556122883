import React, { useCallback, useMemo } from "react";
import { NavLink } from "react-router-dom";
import { Badge, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { Inbox } from "@material-ui/icons";
import { PageT } from "../../constants/types";

export interface RenderCollapsibleMenuItemProps {
  category: string;
  page: string;
  classes: any;
  customIcon: PageT["icon"];
  activeCategory?: string;
  activePage?: string;
  menuMinimized: boolean;
  navigationLabel: string;
  badgeContent?: number;
}

export function RenderCollapsibleMenuItem({
  category,
  page,
  classes,
  customIcon: CustomIcon,
  activeCategory,
  activePage,
  menuMinimized,
  navigationLabel,
  badgeContent
}: RenderCollapsibleMenuItemProps) {
  const icon = useMemo(
    () => (
      <ListItemIcon
        classes={{
          root: classes.listIconRoot
        }}
      >
        {CustomIcon ? (
          <CustomIcon
            color={
              activeCategory === category && activePage === page
                ? "primary"
                : undefined
            }
          />
        ) : (
          <Inbox
            color={
              activeCategory === category && activePage === page
                ? "primary"
                : undefined
            }
          />
        )}
      </ListItemIcon>
    ),
    [
      CustomIcon,
      activeCategory,
      activePage,
      category,
      classes.listIconRoot,
      page
    ]
  );

  const listItemText = useMemo(
    () => (
      <ListItemText
        inset={false}
        primaryTypographyProps={{
          color:
            activeCategory === category && activePage === page
              ? "primary"
              : undefined,
          variant: "body1"
        }}
        className={classes.listText}
      >
        {navigationLabel}
      </ListItemText>
    ),
    [
      activeCategory,
      activePage,
      category,
      classes.listText,
      navigationLabel,
      page
    ]
  );

  const getBadge = useCallback(
    function getBadge(child: JSX.Element) {
      return (
        <Badge
          badgeContent={badgeContent}
          max={99}
          color="primary"
          classes={{
            badge: classes.badge
          }}
        >
          {child}
        </Badge>
      );
    },
    [badgeContent, classes.badge]
  );

  return (
    <NavLink
      to={`/journal/${category}/${page}`}
      exact
      className={classes.navlink}
      // activeClassName={classes.navlinkActive}
      aria-current="page"
    >
      <ListItem
        button
        className={classes.nested}
        disableGutters
        component="span"
        dense
        divider
      >
        {badgeContent && !menuMinimized ? getBadge(icon) : icon}
        {menuMinimized && badgeContent
          ? getBadge(listItemText)
          : menuMinimized && listItemText}
      </ListItem>
    </NavLink>
  );
}
