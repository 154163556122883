import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Link, Popover, Typography } from "@material-ui/core";
import { ExpandLess, ExpandMore, Help } from "@material-ui/icons";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import axios from "axios";
import style from "./style.module.css";
import {
  ContainsLabel,
  ContainsUniqueId,
} from "../../constants/rebuildRecieved";
import {
  computeUrlThenFetchJournalData,
  defaultErrorHandler,
  forbidFiltersValueReset,
  saveFilters,
} from "../../actions/typedActions";
import { AuthStateT } from "../../reducers/auth";
import { constructInitialValuesForEmptyFormWithOutBlocks } from "../formikMUi/utils/initialValuesUtils";
import { INITIAL_PARAMETERS } from "../../constants/intialParameters";

type FooterProps = { auth: AuthStateT; showFaq: boolean; className?: string };

type FaqDataT = (ContainsLabel & ContainsUniqueId)[];

export function Footer({ auth, showFaq, className }: FooterProps) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const open = Boolean(anchorEl);
  const id = open ? "faqHelper" : undefined;
  const dispatch = useDispatch();

  const [faqData, setFaqData] = useState<FaqDataT | null>(null);

  useEffect(() => {
    if (showFaq && !faqData) {
      axios.get<FaqDataT>("/api/faq/type").then(
        ({ data }) => setFaqData(data),
        (er) => dispatch(defaultErrorHandler(er))
      );
    }
  }, [dispatch, faqData, showFaq]);

  return (
    <div className={clsx(style.footer, className)}>
      <div className={style.footer__item}>
        <p className={style.footer__item__title}>Помощь и поддержка</p>
        <p>support@hramonline.ru</p>
        {auth.trainingLink && (
          <Button
            aria-describedby={id}
            href={auth.trainingLink}
            target="_blank"
            rel="nofollow noopener"
            color="inherit"
            size="small"
            style={{ paddingLeft: "0px" }}
          >
            Видеолекции по работе с системой
            <Help style={{ marginLeft: "4px" }} />
          </Button>
        )}
      </div>
      {/*      <div className={style.footer__item}>
        <p className={style.footer__item__title}>Мобильное приложение</p>
        <a
          href="https://apps.apple.com/ru/app/id1479539873"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="/images/icons/AppStore.png"
            alt="Ios приложение"
            className={style.footer__item_icon}
          />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=ru.patriarchia.app.priest"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="/images/icons/GooglePlay.jpg"
            alt="Andr приложение"
            className={style.footer__item_icon}
          />
        </a>
      </div>*/}
      {showFaq && (
        <div className={style.footer__item}>
          <Button
            className={style.footer__item__title}
            onClick={(e) => setAnchorEl(e.currentTarget)}
          >
            Вопросы и ответы по работе с системой
            {anchorEl ? <ExpandLess /> : <ExpandMore />}
          </Button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={(_) => setAnchorEl(null)}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            keepMounted
          >
            {faqData ? (
              <div className={style.faq_popup}>
                <Typography variant="body1">Выберите категорию:</Typography>
                {faqData.map(({ label, uniqueId }) => (
                  <Link
                    className={style.faq_link}
                    component={RouterLink}
                    key={uniqueId}
                    to="/journal/faq"
                    onClick={() => {
                      const fields =
                        INITIAL_PARAMETERS?.faq?.page?.filtersPanel?.filters;
                      if (fields) {
                        dispatch(forbidFiltersValueReset(true));
                        dispatch(
                          saveFilters({
                            ...constructInitialValuesForEmptyFormWithOutBlocks(
                              {
                                fields,
                              },
                              auth
                            ),
                            typeRef: { label, value: uniqueId },
                          })
                        );
                        dispatch(computeUrlThenFetchJournalData());
                        setAnchorEl(null);
                      }
                    }}
                  >
                    {label}
                  </Link>
                ))}
              </div>
            ) : (
              <p className={style.faq_preloader}>Идет загрузка данных</p>
            )}
          </Popover>
        </div>
      )}
    </div>
  );
}
