import { ExtendedBlockFormObj } from "../../../../components/modalWrapper/types";
import { doBeforeSend } from "../../../baseFormProps";
import { baseUrlsToDownLoadReport } from "./journal";
import { localizedYup } from "../../../yup";
import { IField } from "../../../types";

const doBeforeSendCustom = {
  ...doBeforeSend,
  "date-range": (dates: string[]) => {
    if (Array.isArray(dates) && dates.length === 2) {
      return [`${dates[0]} 00:00:00`, `${dates[1]} 23:59:59`];
    }
  },
};
export type ReportFiltersMapT = Record<
  keyof typeof baseUrlsToDownLoadReport,
  ExtendedBlockFormObj
>;

const roles = ["administrator"];

export const fields: IField[] = [
  {
    name: "from",
    label: "С",
    type: "date",
  },
  {
    name: "to",
    label: "По",
    type: "date",
  },
  {
    name: "spaceFillerOne",
    type: "spaceFiller",
  },
  {
    name: "isKeepingSchedule",
    label: "Отображать только храмы, которые ведут расписание",
    type: "checkbox",
    widths: { xs: 4, sm: 4, md: 4, lg: 4, xl: 4 },
    conditionallyRender: (values, auth) => roles.includes(auth.role),
  },
  {
    name: "spaceFillerTwo",
    type: "spaceFiller",
    widths: { xs: 8, sm: 8, md: 8, lg: 8, xl: 8 },
  },
  {
    name: "isCreatingSlots",
    label: "Отображать только храмы, которые создают слоты на беседы",
    type: "checkbox",
    conditionallyRender: (values, auth) => roles.includes(auth.role),
  },
  {
    label: "Наименование",
    name: "structureId",
    type: "select",
    placeholder: "Введите наименование",
    dictUrl: "/api/member/structureObjects?withLimit=true",
    defaultValue: (userFunctions, auth) => {
      if (!roles.includes(auth.role)) {
        return {
          label: auth.memberFullName,
          value: auth.memberId,
        };
      }
    },
    conditionallyRender: (values, auth) => roles.includes(auth.role),
    async: true,

    rebuildReceivedOptions: false,
  },
];

export const work_report: ExtendedBlockFormObj = {
  doBeforeSend: doBeforeSendCustom,
  blocks: [{ fields }],
  validationSchema: localizedYup.object({
    from: localizedYup.string().required(),
    to: localizedYup.string().required(),
    structureId: localizedYup.object().required(),
  }),
};

export const filters: ReportFiltersMapT = {
  work_report,
};
