import { FormikValues } from "formik";
import { computePropsForFormikMUI } from "../../../components/modalWrapper/utils/computePropsForForMikMUI";
import { fullWidth, halfWith } from "../../widths";
import { constructRebuildReceivedOptionsLabelLabelValueUniqueId } from "../../rebuildRecieved";
import {
  baseTextAreaProps,
  constructValidateUsingUserFunctions
} from "../../baseFieldProps";
import {
  disableConfirmDialogPrimaryBtn,
  dispatchFetchJournalData,
  dispatchOpenConfirmDialogFormik,
  dispatchOpenSnackBar,
  dispatchPopHeadModal,
  store
} from "../../../store";
import {
  closeConfirmDialog,
  openConfirmDialog
} from "../../../actions/typedActions";
import { constructRequest } from "../../../components/modalWrapper/utils/baseRequests";
import { ChatMui } from "../../../components/chat";
import {
  closeConfirmDialogFormik,
  openConfirmDialogFormik,
  pushHeadModal
} from "../../../actions/typedActions";
import { baseRebuildReceivedInitialValues } from "../../../components/formikMUi/utils/initialValuesUtils";
import { createWorkItem } from "../schedule/createWorkItem";
import { isDatePast, userHasAnyOfRole } from "../../utils";
import { ExtendedBlockFormObj } from "../../../components/modalWrapper/types";
import { AuthStateT } from "../../../reducers/auth";
import { getRecoilExternalLoadable } from "../../../store/recoilAdapter";
import { overUserAtom } from "../../../store/userStore";

const completeConfig = {
  title: "Подтверждение заявки",
  formObj: {
    blocks: [
      {
        fields: [
          {
            label: "Комментарий",
            name: "comment",
            ...baseTextAreaProps
          }
        ]
      }
    ]
  },
  initialValues: {
    comment: ""
  },
  primaryButtonText: "Подтвердить",
  primaryButtonHandler: (uniqueId: number) => (values: FormikValues) =>
    constructRequest(
      {
        url: `/api/claim/confirm/${uniqueId}`,
        method: "post",
        data: values
      },
      ({ status }) => {
        disableConfirmDialogPrimaryBtn(true);
        if (status === 200) {
          dispatchOpenSnackBar(`Заявка № "${uniqueId}" подтверждена`);
        }
        store.dispatch(closeConfirmDialogFormik());
        dispatchPopHeadModal();
        dispatchFetchJournalData();
      }
    )
};

export const cancelConfig = {
  title: "Отмена заявки",
  formObj: {
    blocks: [
      {
        description: "Заявка была подтверждена мирянину. Вы сможете оправить мирянину комментарий о причинах отмены",
        fields: [
          {
            label: "Комментарий",
            name: "comment",
            ...baseTextAreaProps
          }
        ]
      }
    ]
  },
  initialValues: {
    comment: ""
  },
  primaryButtonText: "Отменить проведение таинства",
  primaryButtonHandler: (
    uniqueId: number,
    successCallback?: () => void
  ) => ({ comment }: FormikValues) =>
    constructRequest(
      {
        url: `/api/claim/cancel/${uniqueId}?comment=${comment}`,
        method: "post"
      },
      ({ status }) => {
        disableConfirmDialogPrimaryBtn(true);
        if (status === 200) {
          dispatchOpenSnackBar(`Заявка № "${uniqueId}" отменена`);
          dispatchPopHeadModal();
          dispatchFetchJournalData();
          store.dispatch(closeConfirmDialogFormik());
          if (successCallback) {
            successCallback();
          }
        } else {
          dispatchOpenSnackBar("Произошла ошибка при отмене заявки");
        }
      }
    ),
  secondaryButtonText: "Вернуться к заявке",
};

const baseForm: ExtendedBlockFormObj = {
  url: "/api/claim/getById",
  title: "Заявки ",
  titleItem: "Заявка",
  method: "get",
  headerText: "uniqueId",
  blocks: [
    {
      blockTitle: "Основная Информация",
      fields: [
        {
          label: "Категория требы/таинства",
          name: "commonAction",
          type: "select",
          placeholder: "Выберите категорию",
          dictUrl: "/api/getCommonActionForClaimList",
          rebuildReceivedOptions: constructRebuildReceivedOptionsLabelLabelValueUniqueId,
          widths: halfWith
        },
        {
          label: "Статус заявки",
          name: "claimDictStatus",
          type: "select",
          placeholder: "Выберите статус",
          dictUrl: "/api/claimDictStatus/search/present",
          rebuildReceivedOptions: constructRebuildReceivedOptionsLabelLabelValueUniqueId,
          widths: halfWith
        },
        {
          label: "Номер заявки",
          name: "uniqueId",
          type: "text",
          widths: halfWith
        },
        {
          label: "В храме",
          name: "isHram",
          type: "checkbox",
          widths: halfWith
        },
        {
          label: "Желаемая дата и время начала",
          name: "firstTimestamp",
          type: "text",
          rebuildReceivedValue: (v) => v.slice(0, -3),
        },
        {
          label: "Желаемая дата и время окончания",
          name: "endTimestamp",
          type: "text",
          rebuildReceivedValue: (v) => v.slice(0, -3),
        },
        {
          name: "createdTimestamp",
          type: "text",
          label: "Дата и время создания заявки",
          rebuildReceivedValue: (v) => v.slice(0, -3),
        },
        {
          label: "ФИО мирянина",
          name: "userFullName",
          type: "text",
          widths: fullWidth
        },
        {
          label: "Телефон мирянина",
          name: "phone",
          type: "text",
          widths: halfWith
        },
        {
          label: "E-mail мирянина",
          name: "email",
          type: "text",
          widths: halfWith
        },
        {
          label: "Адрес мирянина",
          name: "address",
          ...baseTextAreaProps
        },
        {
          label: "Описание",
          name: "description",
          ...baseTextAreaProps
        },
        {
          label: "Дата успешного анкетирования",
          name: "anketa",
          type: "text",
          widths: fullWidth
        },
        {
          label: "Исполнитель",
          name: "performer",
          type: "text",
          widths: halfWith
        },
        {
          label: "Комментарий",
          name: "comment",
          ...baseTextAreaProps
        },
        {
          name: "isFree",
          type: "checkbox",
          hidden: true
        },
        {
          name: "claimDictStatusName",
          type: "text",
          hidden: true
        }
      ]
    }
  ],
  additionalActionButtonsDependedOnProps: [
    {
      text: "Создать дело",
      stickTo: "left",
      variant: "outlined",
      style: {
        color: "#055d7a",
        borderColor: "#055d7a"
      },
      onClick: ({ firstFormInitialValues: { uniqueId } }) => {
        constructRequest(
          { url: `/api/workitem/create/claim/${uniqueId}` },
          ({ data: initialValues }) => {
            store.dispatch(
              pushHeadModal({
                initialValues: baseRebuildReceivedInitialValues(
                  createWorkItem,
                  {
                    ...initialValues,
                    performer: { ...initialValues.performerRef },
                    claimId: uniqueId,
                  }
                ),
                readOnlyFields: ["performer"],
                category: "schedule",
                mode: "createWorkItem"
              })
            );
          }
        );
      },
      conditionallyRender: ({
        firstFormInitialValues: { claimDictStatusName, endTimestamp },
        modal: { mode }
      }) => {
        const isOverdue = isDatePast(endTimestamp);
        return (
          claimDictStatusName === "closed" &&
          mode === "view" &&
          !isOverdue
        )
      }
    },
    {
      text: "Взять в работу",
      stickTo: "left",
      variant: "contained",
      style: {
        backgroundColor: "#357a38",
        color: "white"
      },
      onClick: ({ firstFormInitialValues: { uniqueId } }) => {
        store.dispatch(
          openConfirmDialogFormik({
            primaryButtonText: "Да",
            secondaryButtonText: "Нет",
            primaryButtonHandler: (values, { setSubmitting }) => {
              setSubmitting(true);
              const overUserLoadable = getRecoilExternalLoadable(overUserAtom);
              const params = {
                overUserId: values.overUserId
                  ? values.overUserId.value
                  : undefined
              };
              const url = `/api/claim/toWork/${uniqueId}/`;
              constructRequest(
                {
                  url:
                    overUserLoadable && overUserLoadable.contents
                      ? url + "?overUserId=" + overUserLoadable.contents.value
                      : url,
                  method: "post",
                  params
                },
                ({ status }) => {
                  if (status === 200) {
                    dispatchOpenSnackBar(
                      `Заявка № "${uniqueId}" успешно взята в работу`
                    );
                  }
                  setSubmitting(false);
                  store.dispatch(closeConfirmDialogFormik());
                  dispatchPopHeadModal();
                  dispatchFetchJournalData();
                }
              );
            },
            title: "Подтвердите действие",
            initialValues: {
              overUserId: null
            },
            formObj: {
              blocks: [
                {
                  fields: [
                    {
                      name: "overUserId",
                      type: "select",
                      dictUrl: "/api/events/log/priestByUser",
                      rebuildReceivedOptions: false,
                      widths: fullWidth,
                      validate: constructValidateUsingUserFunctions(
                        "assistant_claim_view"
                      ),
                      conditionallyRender(
                        values: FormikValues,
                        auth: AuthStateT
                      ) {
                        return userHasAnyOfRole(auth, "assistant_claim_view");
                      }
                    }
                  ],
                  description: `Взять в работу заявку № "${uniqueId}"?`
                }
              ]
            }
          })
        );
      },
      conditionallyRender: ({
        firstFormInitialValues: { claimDictStatusName },
        modal: { mode }
      }) => claimDictStatusName === "new" && mode === "view"
    },
    {
      text: "Подтвердить",
      stickTo: "left",
      variant: "contained",
      style: {
        backgroundColor: "#357a38",
        color: "white"
      },
      onClick: ({ firstFormInitialValues: { uniqueId } }) => {
        dispatchOpenConfirmDialogFormik({
          ...completeConfig,
          primaryButtonHandler: completeConfig.primaryButtonHandler(uniqueId)
        });
      },
      conditionallyRender: ({
        firstFormInitialValues: { claimDictStatusName },
        modal: { mode, openedTab }
      }) => {
        return (
          claimDictStatusName === "in_progress" &&
          mode === "view" &&
          openedTab === 0
        );
      }
    },
    {
      text: "Выполнено",
      stickTo: "left",
      variant: "contained",
      color: "primary",
      onClick: ({ firstFormInitialValues: { uniqueId } }) => {
        store.dispatch(
          openConfirmDialog({
            mainText: `выполнить заявку № "${uniqueId}"?`,
            primaryButtonHandler: () => {
              disableConfirmDialogPrimaryBtn(true);
              constructRequest(
                {
                  url: `/api/claim/hide/${uniqueId}`,
                  method: "post"
                },
                ({ status }) => {
                  disableConfirmDialogPrimaryBtn(true);
                  if (status === 200) {
                    dispatchOpenSnackBar(
                      `Заявка № "${uniqueId}" успешно выполнена`
                    );
                  }
                  store.dispatch(closeConfirmDialog());
                  dispatchPopHeadModal();
                  dispatchFetchJournalData();
                }
              );
            }
          })
        );
      },
      conditionallyRender: ({
        firstFormInitialValues: { claimDictStatusName },
        modal: { mode }
      }) => claimDictStatusName === "closed" && mode === "view"
    },
    {
      text: "Отменить заявку",
      stickTo: "left",
      variant: "contained",
      color: "secondary",
      onClick: ({ firstFormInitialValues: { uniqueId } }) => {
        dispatchOpenConfirmDialogFormik({
          ...cancelConfig,
          primaryButtonHandler: cancelConfig.primaryButtonHandler(uniqueId)
        });
      },
      conditionallyRender: ({
        firstFormInitialValues: { claimDictStatusName, endTimestamp },
        modal: { mode}
      }) => {
        const isOverdue = isDatePast(endTimestamp);
        return (
          claimDictStatusName === "closed" &&
          mode === "view" &&
          !isOverdue
        );
      }
    },
    {
      text: "Заблокировать мирянина",
      stickTo: "left",
      variant: "contained",
      style: {
        backgroundColor: "indigo",
        color: "white"
      },
      onClick: ({ firstFormInitialValues: { uniqueId, userFullName } }) => {
        store.dispatch(
          openConfirmDialog({
            mainText: `заблокировать мирянина ${userFullName || ""} ?`,
            primaryButtonHandler: () => {
              disableConfirmDialogPrimaryBtn(true);
              constructRequest(
                {
                  url: `/api/claim/block/${uniqueId}`,
                  method: "post"
                },
                ({ status }) => {
                  disableConfirmDialogPrimaryBtn(true);
                  if (status === 200) {
                    dispatchOpenSnackBar(
                      `Мирянин ${userFullName || ""} заблокирован`
                    );
                  }
                  store.dispatch(closeConfirmDialog());
                  dispatchPopHeadModal();
                  dispatchFetchJournalData();
                }
              );
            }
          })
        );
      },
      conditionallyRender: ({
        firstFormInitialValues: { claimDictStatusName },
        modal: { mode }
      }) => claimDictStatusName === "closed" && mode === "view"
    },
    {
      text: "Отменить требу",
      stickTo: "left",
      variant: "contained",
      color: "secondary",
      onClick: ({ firstFormInitialValues: { uniqueId } }) => {
        store.dispatch(
          openConfirmDialog({
            mainText: `отменить заявку № "${uniqueId}"?`,
            primaryButtonHandler: () => {
              disableConfirmDialogPrimaryBtn(true);
              constructRequest(
                {
                  url: `/api/claim/cancel/${uniqueId}`,
                  method: "post"
                },
                ({ status }) => {
                  disableConfirmDialogPrimaryBtn(true);
                  if (status === 200) {
                    dispatchOpenSnackBar(`Заявка № "${uniqueId}" отменена`);
                  }
                  store.dispatch(closeConfirmDialog());
                  dispatchPopHeadModal();
                  dispatchFetchJournalData();
                }
              );
            }
          })
        );
      },
      conditionallyRender: ({
        firstFormInitialValues: { claimDictStatusName },
        modal: { mode, openedTab }
      }) => {
        return (
          claimDictStatusName === "in_progress" &&
          mode === "view" &&
          openedTab === 0
        );
      }
    }
  ],
  additionalTabs: [
    {
      tabTitle: "Переписка",
      tabContent: {
        Component: ChatMui,
        passProps: {
          urlToGetAll: "/api/claim/getAllMessagesByClaimId",
          urlToSendNewMessage: "/api/claim/newMessage"
        }
      },
      conditionallyRender: ({ firstFormInitialValues }) =>
        firstFormInitialValues &&
        (firstFormInitialValues.claimDictStatusName === "in_progress" ||
          firstFormInitialValues.claimDictStatusName === "closed")
    }
  ]
};

const forms = {
  view: computePropsForFormikMUI({ formObj: baseForm, mode: "view" })
};
export default forms;
