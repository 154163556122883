import React, { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Badge, Button, Grid, Paper } from "@material-ui/core";
import { AutoSizer } from "react-virtualized";
import "react-virtualized/styles.css"; // only needs to be imported once
import { makeStyles } from "@material-ui/styles";
import { FormikValues } from "formik";
import { FilterList } from "@material-ui/icons";
import { isEqual } from "lodash";
import FilterPanel, { FilterPanelProps } from "../filter-panel/index";
import PaginatedTable from "../PaginatedTable";
import ShadowedOutlinedButton from "../buttons/ShadowedOutlinedButton";
import { AdditionalButtons } from "./additionalButtons";
import { defaultPreloader } from "../completePromiseThenRenderComponent";
import {
  applySortAndFetchJournalData,
  ModalWithOptionalKey,
  pushHeadModal,
  saveFilters,
  setFiltersAppliedActionsCreator,
  setPage,
  ThunkDispatchTyped,
  toggleFilterPanel
} from "../../actions/typedActions";
import { getWidth } from "./utils";
import { RootStateT } from "../../reducers";
import { PageT } from "../../constants/types";
import { AllowedJournalActionsT } from "../../reducers/wrapperReducer";
import { ItemT } from "../wrapper/types";
import { constructInitialValuesForEmptyFormWithOutBlocks } from "../formikMUi/utils/initialValuesUtils";

const useStyles = makeStyles(theme => ({
  rowHighLight: {
    // borderTop: `1px solid ${theme.palette.primary.dark}`,
    // borderBottom: `1px solid ${theme.palette.primary.dark}`,
    backgroundColor: "#799dcb61"
  }
}));

export type PrivateJournalProps = {
  category: string;
  page?: string;
  initialParameters: PageT;
  computeUrlThenFetchJournalData: Function;
};

export type PrivateJournalT = React.FC<PrivateJournalProps>;

function getCurrentPageAllowedActions(
  allowedActions: AllowedJournalActionsT,
  category: string,
  page?: string | number
) {
  const categoryObj = allowedActions[category];
  if (Array.isArray(categoryObj)) {
    return categoryObj;
  }
  if (page) {
    // @ts-ignore
    return categoryObj[page];
  }
  return [];
}

const callbackfn = (
  accumulator: number,
  currentValue: string | null | any[] | boolean | number
) => {
  if (
    currentValue === null ||
    (Array.isArray(currentValue) && currentValue.length === 0) ||
    currentValue === ""
  ) {
    return accumulator;
  }
  return accumulator + 1;
};

function getBadgeContent(
  computedFilters: FormikValues,
  filtersApplied: boolean
): number {
  if (!filtersApplied && !computedFilters) {
    return 0;
  }
  return Object.values(computedFilters).reduce(callbackfn, 0);
}

const PrivateJournal: PrivateJournalT = ({
  initialParameters: {
    additionalActionPanelButtons: { leftPanel = [], rightPanel = [] } = {},
    filtersPanel,
    tableHeader,
    additionalModalProps
  },
  category,
  page,
  computeUrlThenFetchJournalData
}: PrivateJournalProps) => {
  const classes = useStyles();
  const dispatch = useDispatch<ThunkDispatchTyped>();

  const {
    allowedActions,
    computedFilters,
    fetchedData,
    totalElements,
    auth,
    isCollapsed,
    loadingContent,
    sortable,
    optionsMap,
    filtersApplied
  } = useSelector(
    (state: RootStateT) => ({
      computedFilters: state.filtersReducer.computedFilters,
      filtersApplied: state.filtersReducer.filtersApplied,
      fetchedData: state.journalReducer.fetchedData,
      totalElements: state.journalReducer.totalElements,
      sortable: state.journalReducer.sortable,
      isCollapsed: state.filtersReducer.isCollapsed,
      auth: state.auth,
      allowedActions: state.wrapperReducer.allowedActions,
      loadingContent: state.journalReducer.loadingContent,
      optionsMap: state.filtersReducer.optionsMap
    }),
    isEqual
  );
  const mappedFetchedData = fetchedData.map(item => {
    //Определяем значения для колонки Активность в журнале ОЕ
    if (item.type) {
      return {
        ...item,
        isActive:
          item.isActive && item.type === "Храм"
            ? "Да"
            : item.type === "Храм"
              ? "Нет"
              : ""
      };
    }
    //Убираем секунды в журнале бесед и таинств
    if (item.fromTimestamp) {
      return {
        ...item,
        fromTimestamp: item.fromTimestamp.slice(0, -3),
        toTimestamp: item.toTimestamp.slice(0, -3),
      };
    }
    if (item.rangeEventTimestamp) {
      return {
        ...item,
        rangeEventTimestamp:
          `${item.firstTimestamp.slice(0, -3)} - ${item.endTimestamp.slice(0, -3)}`,
      };
    }
    return item;
  });
  const {
    pushModal,
    // setSort,
    setSortableThenFetJournalData,
    toggleFiltersPanel,
    setPageW,
    saveFiltersW,
    setFiltersApplied
  } = useMemo(
    () => ({
      setFiltersApplied: (v: boolean) =>
        dispatch(setFiltersAppliedActionsCreator(v)),
      // setSort: (path: string) => dispatch(setSortable(path)),
      toggleFiltersPanel: () => dispatch(toggleFilterPanel(false)),
      setSortableThenFetJournalData: (name: string) =>
        dispatch(applySortAndFetchJournalData(name)),
      pushModal: (modal: ModalWithOptionalKey) =>
        dispatch(pushHeadModal(modal)),
      setPageW: (n: number) => dispatch(setPage(n)),
      saveFiltersW: (v: FormikValues) => dispatch(saveFilters(v))
    }),
    [dispatch]
  );

  const initialValuesMemorized = useMemo(
    () =>
      filtersPanel
        ? constructInitialValuesForEmptyFormWithOutBlocks(
            { fields: filtersPanel.filters },
            auth
          )
        : {},
    [auth, filtersPanel]
  );

  const [currentRow, setCurrentRow] = useState<null | ItemT>(null);
  const currentPageAllowedActions = getCurrentPageAllowedActions(
    allowedActions,
    category,
    page
  );

  const onSubmit: FilterPanelProps["onSubmit"] = useCallback(
    (values, { setSubmitting }) => {
      setPageW(1);
      saveFiltersW(values);
      computeUrlThenFetchJournalData().then(() => setSubmitting(false));
      setFiltersApplied(true);
    },
    [computeUrlThenFetchJournalData, saveFiltersW, setFiltersApplied, setPageW]
  );
  const onReset: FilterPanelProps["onReset"] = useCallback(
    (v, { setSubmitting }) => {
      setPageW(1);
      saveFiltersW(initialValuesMemorized);
      computeUrlThenFetchJournalData().then(() => setSubmitting(false));
      setFiltersApplied(false);
    },
    [
      computeUrlThenFetchJournalData,
      initialValuesMemorized,
      saveFiltersW,
      setFiltersApplied,
      setPageW
    ]
  );
  return (
    <Grid
      direction="column"
      container
      wrap="nowrap"
      style={{ width: "100%", height: "calc(100vh -  80px)" }}
    >
      {/* Панель действий - начало */}
      <Paper
        square
        style={{ padding: "8px", marginBottom: "8px" }}
        elevation={5}
      >
        <Grid container justify="space-between" direction="row">
          {/* Левая часть панели */}
          <Grid>
            <Grid container spacing={1}>
              <Grid item>
                <Badge
                  badgeContent={getBadgeContent(
                    computedFilters,
                    filtersApplied
                  )}
                  color="primary"
                >
                  <ShadowedOutlinedButton
                    color={filtersApplied ? "primary" : undefined}
                    onClick={() => {
                      toggleFiltersPanel();
                    }}
                  >
                    <FilterList />
                    Фильтрация
                  </ShadowedOutlinedButton>
                </Badge>
              </Grid>
              <AdditionalButtons buttons={leftPanel} dispatch={dispatch} />
            </Grid>
          </Grid>
          {/* Правая часть панели */}
          <Grid>
            <Grid container spacing={1}>
              <AdditionalButtons buttons={rightPanel} dispatch={dispatch} />
              {currentPageAllowedActions.includes("create") && (
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      pushModal({
                        category,
                        page,
                        mode: "create"
                      })
                    }
                  >
                    Создать
                  </Button>
                </Grid>
              )}
              {currentPageAllowedActions.includes("wizard") && (
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      pushModal({
                        category,
                        page,
                        mode: "wizard"
                      })
                    }
                  >
                    Создать
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      {/* Панель действий - конец */}

      {/* Панель Фильтрации - начало */}
      {filtersPanel && !isCollapsed && filtersPanel.filters && (
        <FilterPanel
          auth={auth}
          computedFilters={
            (Object.keys(computedFilters).length > 0 && computedFilters) ||
            initialValuesMemorized
          }
          filters={filtersPanel.filters}
          validationSchema={filtersPanel.validationSchema}
          onReset={onReset}
          onSubmit={onSubmit}
          optionsMap={optionsMap}
        />
      )}

      {/* Панель Фильтрации - конец */}

      {/* //todo вынести следующий див в Отдельный компонент TableWrapper */}
      {tableHeader && (
        <Paper
          square
          elevation={5}
          style={{ height: "100%", padding: "8px", flex: "0 1 auto" }}
        >
          <AutoSizer>
            {({ width, height }) =>
              loadingContent ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width,
                    height
                  }}
                >
                  {defaultPreloader({})}
                </div>
              ) : (
                <PaginatedTable
                  fetchJournalData={computeUrlThenFetchJournalData}
                  data={mappedFetchedData}
                  count={totalElements || 0}
                  columns={tableHeader
                    .filter(x => !x.hidden)
                    .map(({ label, path, width: columnWidth }) => ({
                      name: path,
                      header: label,
                      width: getWidth(tableHeader, columnWidth),
                      cellProps: { style: { paddingRight: 0 } }
                    }))}
                  width={width}
                  height={height - 50}
                  style={{ backgroundColor: "white" }}
                  onCellClick={(column: unknown, item: any) => {
                    setCurrentRow(item);
                    pushModal({
                      category,
                      page,
                      mode: "view",
                      item,
                      additionalModalProps
                    });
                  }}
                  onHeaderClick={({ name }: any) =>
                    setSortableThenFetJournalData(name)
                  }
                  orderBy={sortable.length > 0 ? sortable[0].path : undefined}
                  orderDirection={
                    sortable.length > 0 ? sortable[0].value : undefined
                  }
                  // @ts-ignore
                  cellProps={(column, data) => {
                    if (Object.entries(data).length === 0) {
                      return {
                        style: {
                          fontSize: "15px",
                          fontWeight: "bold",
                          overflow: "hidden"
                        }
                      };
                    }
                    if (
                      currentRow &&
                      currentRow.uuid &&
                      currentRow.uuid === data.uuid
                    )
                      return {
                        className: classes.rowHighLight
                      };
                  }}
                />
              )
            }
          </AutoSizer>
        </Paper>
      )}
    </Grid>
  );
};

export const Journal = React.memo(PrivateJournal);
