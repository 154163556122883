import { addDays, differenceInCalendarDays, eachDayOfInterval } from "date-fns";
import { blocksT, IField } from "../../types";
import { dateStringSchema, localizedYup } from "../../yup";
import { fullWidth } from "../../widths";
import { constructRequest } from "../../../components/modalWrapper/utils/baseRequests";
import { defaultErrorHandler } from "../../../utils/defaultErrorHandler";
import { parseRussianDate } from "../../formaters/formatters";
import { ExtendedBlockFormObj } from "../../../components/modalWrapper/types";
import { dispatchOpenSnackBar, dispatchPopHeadModal } from "../../../store";
import { getRecoilExternalLoadable } from "../../../store/recoilAdapter";
import { overUserAtom, OverUserValueT } from "../../../store/userStore";

const fromField: IField = {
  label: "Записи с",
  name: "start",
  type: "date",
  widths: fullWidth
};

const toField: IField = {
  label: "Записи по",
  name: "end",
  type: "date",
  widths: fullWidth
};

const otherFields: IField[] = [
  {
    label: "Копировать на",
    name: "destination",
    type: "date",
    minDate: new Date(),
    widths: fullWidth
  },
  {
    type: "checkbox",
    name: "isPersonal",
    hidden: true
  }
];

const blocks: blocksT = [
  {
    fields: [fromField, toField, ...otherFields]
  }
];

export const copySchedule: ExtendedBlockFormObj = {
  title: "Копирование календаря",
  onSubmit: async (values, { setSubmitting }, successCallback) => {
    const overUser = await getRecoilExternalLoadable<OverUserValueT>(
      overUserAtom
    );
    const params = {
      ...values,
      end: values.end === "" ? undefined : values.end,
      jtype: values.isPersonal ? "calendar" : "ministration",
      id: overUser?.contents?.value
    };
    constructRequest(
      {
        url: "/api/copy",
        method: "post",
        params
      },
      ({ status }) => {
        setSubmitting(false);
        if (status === 200) {
          dispatchOpenSnackBar("Записи успешно скопированы");
          dispatchPopHeadModal();
          if (typeof successCallback === "function") {
            const destination = parseRussianDate(values.destination);
            successCallback({
              selectedDates: values.end
                ? eachDayOfInterval({
                    start: destination,
                    end: addDays(
                      destination,
                      -differenceInCalendarDays(
                        parseRussianDate(values.start),
                        parseRussianDate(values.end)
                      )
                    )
                  })
                : [destination]
            });
          }
        }
      },
      er => {
        setSubmitting(false);
        const { status } = er.request;
        switch (status) {
          case 498: {
            dispatchOpenSnackBar(
              "Невозможно скопировать, нарушены правила копирования"
            );
            break;
          }
          case 499: {
            dispatchOpenSnackBar(
              "При копировании события каждого дня недели исходного периода времени копируются в соответствующий день недели последующего периода времени (понедельник - в понедельник, вторник - во вторник и т.д.)",
              {
                style: {
                  maxWidth: "600px"
                }
              }
            );
            break;
          }
          case 400:
            dispatchOpenSnackBar("Копирование на заданную дату невозможно", {
              variant: "error"
            });
            break;
          default:
            defaultErrorHandler(er);
            break;
        }
      }
    );
  },
  blocks,
  validationSchema: localizedYup.object({
    start: dateStringSchema.required(),
    // @ts-ignore
    end: dateStringSchema.compareDateTo(localizedYup.ref("start")),
    destination: dateStringSchema
      .required()
      // @ts-ignore
      .compareDateTo(
        localizedYup.ref("start"),
        'Дата "на" должна быть позже даты "с"',
      )
      // @ts-ignore
      .compareDateTo(
        localizedYup.ref("end"),
        'Дата "на" должна быть позже даты "по"',
      ),
  }),
  primaryButtonText: "Копировать"
};

export const copyScheduleDay: ExtendedBlockFormObj = {
  ...copySchedule,
  blocks: [{ fields: [fromField, ...otherFields] }]
};
