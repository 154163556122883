import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { NavLink, RouteComponentProps, withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import {
  Badge,
  Collapse,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Theme
} from "@material-ui/core";
import clsx from "clsx";
import { INITIAL_PARAMETERS } from "../../constants/intialParameters";
import { RenderCollapsibleMenuItem } from "./RenderCollapsibleMenuItem";
import { RootStateT } from "../../reducers";
import { setExpandedIndex } from "../../actions/typedActions";
import { getArrowSymbol, getCategoryIcon, getLabel } from "./utils";
import { useShallowEqualSelector } from "../../store/hooks";
import { InitialParametersT } from "../../constants/types";
import { AllowedJournalActionsT } from "../../reducers/wrapperReducer";

const getStyles = makeStyles((theme: Theme) => ({
  nested: {
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2)
  },
  navlink: {
    color: "black",
    textDecoration: "none",
    "&:visited": {
      color: "black"
    }
  },
  navlinkActive: {
    borderLeft: `3px solid ${theme.palette.primary.main}`,
    display: "block",
    opacity: 0.9
  },
  activeCategory: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    color: "inherit"
  },
  firstLevelListItem: {
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5)
  },
  listIconRoot: {
    margin: theme.spacing(0.5),
    marginRight: 0,
    minWidth: "31px"
  },
  activeListItem: {
    color: theme.palette.primary.light
  },
  listText: {
    paddingRight: theme.spacing(0.5)
  },
  root: {
    transition: theme.transitions.create(["width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  badge: {
    border: `1px solid ${
      theme.palette.type === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[900]
    }`,
    boxSizing: "border-box",
    opacity: 0.9,
    top: "5px",
    right: "6px"
  },
  fullWidth: {
    width: "100%"
  }
}));

type CollapsibleMenuContainerProps = RouteComponentProps & {
  sideMenuMaximized: boolean;
};

function checkIfcategoryNotOnlyRedirectable(
  allowedActions: AllowedJournalActionsT,
  category: keyof InitialParametersT
) {
  const allowedAction = allowedActions[category];
  return (
    Array.isArray(allowedAction) &&
    allowedAction.length === 1 &&
    allowedAction.includes("redirect")
  );
}

const CollapsibleMenuContainer = ({
  sideMenuMaximized,
  match: {
    // @ts-ignore todo дописать типизацию параметров
    params: { category: activeCategory, page: activePage }
  }
}: CollapsibleMenuContainerProps) => {
  const classes = getStyles();
  const {
    authState,
    value,
    allowedActions,
    expandedIndex,
    badgesContent
  } = useShallowEqualSelector((state: RootStateT) => ({
    authState: state.auth,
    expandedIndex: state.collapsibleMenuReducer.expandedIndex,
    value: state.collapsibleMenuReducer.value,
    allowedActions: state.wrapperReducer.allowedActions,
    badgesContent: state.wrapperReducer.badgesContent
  }));
  const dispatch = useDispatch();

  const wrapWithBadge = useCallback(
    function getBadge(category: string, listItemText: JSX.Element) {
      return (
        <Badge
          badgeContent={badgesContent[`${category}_category`]}
          max={99}
          color="primary"
          classes={{
            badge: classes.badge
          }}
        >
          {listItemText}
        </Badge>
      );
    },
    [badgesContent, classes.badge]
  );

  return (
    <List disablePadding className={classes.root} component="div">
      {Object.keys(allowedActions)
        .filter(
          category =>
            INITIAL_PARAMETERS[category] &&
            !INITIAL_PARAMETERS[category].hidden &&
            !checkIfcategoryNotOnlyRedirectable(allowedActions, category)
        )
        .map((category: string, index) => {
          const CustomCategoryIcon = INITIAL_PARAMETERS[category].icon;
          const icon = getCategoryIcon(
            classes,
            CustomCategoryIcon,
            category,
            activeCategory
          );

          const listItemText = (
            <ListItemText
              inset={false}
              className={classes.listText}
              primaryTypographyProps={{
                color: category === activeCategory ? "primary" : undefined,
                variant: "body1"
              }}
              primary={<Grid container>{getLabel(category, authState)}</Grid>}
            />
          );

          if (!INITIAL_PARAMETERS[category].pages) {
            return (
              <NavLink
                key={category}
                to={`/journal/${category}/`}
                exact
                className={classes.navlink}
                activeClassName={classes.navlinkActive}
                aria-current="page"
              >
                <ListItem
                  component="li"
                  className={clsx(classes.firstLevelListItem, {
                    [classes.activeCategory]: category === activeCategory
                  })}
                  divider
                  button
                >
                  {badgesContent && !sideMenuMaximized
                    ? wrapWithBadge(category, icon)
                    : icon}
                  {sideMenuMaximized && badgesContent
                    ? wrapWithBadge(category, listItemText)
                    : sideMenuMaximized && listItemText}
                </ListItem>
              </NavLink>
            );
          }
          return (
            <React.Fragment key={category}>
              <ListItem
                component="li"
                className={clsx(classes.firstLevelListItem, {
                  [classes.activeCategory]: category === activeCategory
                })}
                divider
                button
                onClick={() => dispatch(setExpandedIndex(index))}
              >
                {badgesContent && !sideMenuMaximized
                  ? wrapWithBadge(category, icon)
                  : icon}
                {sideMenuMaximized && badgesContent ? (
                  <div className={classes.fullWidth}>
                    {wrapWithBadge(category, listItemText)}
                  </div>
                ) : (
                  sideMenuMaximized && listItemText
                )}
                <ListItemIcon
                  classes={{
                    root: classes.listIconRoot
                  }}
                >
                  {getArrowSymbol(index, expandedIndex, value)}
                </ListItemIcon>
              </ListItem>

              <Collapse in={index === expandedIndex && value} unmountOnExit>
                <List disablePadding component="ul">
                  {Object.keys(allowedActions[category]).map(page => {
                    const { pages } = INITIAL_PARAMETERS[category];
                    const CustomIcon =
                      pages && pages[page] ? pages[page].icon : undefined;
                    let label = "";
                    if (pages && pages[page]) {
                      const { navigationLabel, hidden } = pages[page];
                      if (hidden) {
                        return null;
                      }
                      if (navigationLabel) {
                        label = navigationLabel;
                      }
                    }
                    let nestedBadgesContent;
                    if (badgesContent) {
                      const firstLevelBadge = badgesContent[category];
                      if (
                        firstLevelBadge &&
                        typeof firstLevelBadge !== "number"
                      ) {
                        nestedBadgesContent = firstLevelBadge[page];
                      }
                    }
                    return (
                      <RenderCollapsibleMenuItem
                        category={category}
                        page={page}
                        classes={classes}
                        key={`${category}-${page}`}
                        customIcon={CustomIcon}
                        activeCategory={activeCategory}
                        activePage={activePage}
                        menuMinimized={sideMenuMaximized}
                        navigationLabel={label}
                        badgeContent={nestedBadgesContent}
                      />
                    );
                  })}
                </List>
              </Collapse>
            </React.Fragment>
          );
        })}
    </List>
  );
};

export default withRouter(CollapsibleMenuContainer);
