import { localizedYup } from "../../yup";
import { computePropsForFormikMUI } from "../../../components/modalWrapper/utils/computePropsForForMikMUI";
import { phoneMask } from "../../masks";
import { fullWidth, halfWith } from "../../widths";
import {
  doBeforeSendMapLabelAsAccostValueAsId,
  extractFromDataRestStructureOptionsMapLabelAsLabelCodeAsValue,
  mapLabelAsLabelCodeAsValue,
  mapLabelAsLabelUniqueIdAsValueNameAsEnum,
  rebuildReceivedValueLabelLabelValueUniqueId,
  rebuildRecievedOptionsMapLabelAsLabelUniqueIdAsValueNameAsEnum,
  reduceLabelFromArrayOfObjectsWithLabel
} from "../../rebuildRecieved";
import {
  baseEmailProps,
  basePhoneProps,
  constructValidateUsingUserFunctions,
  downLoadFileFromFileSystemProps,
  uploadFileInFileSystem
} from "../../baseFieldProps";
import { doBeforeSend } from "../../baseFormProps";
import { AuthStateT } from "../../../reducers/auth";
import {
  AdditionalTabT,
  ExtendedBlockFormObj
} from "../../../components/modalWrapper/types";
import { GeneratePasswordButton } from "../../../components/buttons/generatePasswordButton";
import { passwordRegExp } from "./regExp";
import { ScheduleView } from "../../../components/schedule/view";

export const scheduleTab: AdditionalTabT = {
  conditionallyRender: ({ firstFormInitialValues }) =>
    firstFormInitialValues?.type?.enum === "HRAM" ||
    firstFormInitialValues?.memberType === "HRAM",
  tabTitle: "Расписание",
  tabContent: { Component: ScheduleView }
};

export const isAssistant = (role?:{enum: string}) => role && role.enum === "assistant";
const isAdmin = (role?:{enum: string}) => role && role.enum === "administrator";

const baseForm: ExtendedBlockFormObj = {
  title: "Пользователя",
  titleItem: "Пользователь",
  headerText: "fullname",
  // url: "/api/user",
  url: "/api/sysUsers",
  projection: "formSysUser",
  primaryActionUrl: "/api/user/sysUser",
  deleteUrl: "/api/user/sysUser",
  method: "post",
  doBeforeSend,
  blocks: [
    {
      blockTitle: "Основная информация",
      fields: [
        {
          label: "Фотография",
          name: "photo",
          type: "file",
          doBeforeSetFieldValue: uploadFileInFileSystem("user"),
          ...downLoadFileFromFileSystemProps,
          accept: "image/*",
          widths: fullWidth
        },
        {
          name: "accost",
          type: "text",
          label: "Обращение",
          widths: halfWith,
          additionalProps: {
            placeholder: "Пример заполнения : «Отец Антоний», «Владыка Петр»"
          }
        },
        {
          name: "sanCode",
          label: "Сан",
          placeholder: "Выберите сан",
          type: "select",
          dictUrl: "/api/sysDictSan/search/present",
          rebuildReceivedOptions: extractFromDataRestStructureOptionsMapLabelAsLabelCodeAsValue,
          rebuildReceivedValue: mapLabelAsLabelCodeAsValue,
          widths: halfWith
        },
        {
          name: "surname",
          type: "text",
          label: "Фамилия"
        },
        {
          name: "firstname",
          type: "text",
          label: "Имя",
          additionalProps: {
            placeholder: "Укажите мирское имя"
          }
        },
        {
          name: "middlename",
          type: "text",
          label: "Отчество"
        },
        {
          name: "fullname",
          type: "text",
          label: "ФИО",
          hidden: true
        },
        {
          name: "birthday",
          label: "Дата рождения",
          type: "date",
          widths: halfWith
        },
        {
          name: "hirotonyday",
          label: "Дата хиротонии",
          type: "date",
          widths: halfWith
        },
        {
          name: "role",
          label: "Роль",
          placeholder: "Выберите роль",
          type: "select",
          dictUrl: "/api/user/role",
          customOnChange: ({ status, setFieldValue }) => {
            if (
              status.auth.role === "administrator" ||
              status.auth.role === "administrator_structure"
            ) {
              setFieldValue("member", null);
            }
          },
          rebuildReceivedOptions: rebuildRecievedOptionsMapLabelAsLabelUniqueIdAsValueNameAsEnum,
          rebuildReceivedValue: mapLabelAsLabelUniqueIdAsValueNameAsEnum,
          widths: halfWith
        },
        {
          name: "specializationCode",
          label: "Специализация",
          placeholder: "Выберите специализацию",
          type: "select",
          dictUrl: "/api/sysDictSpecialization/search/present",
          rebuildReceivedOptions: extractFromDataRestStructureOptionsMapLabelAsLabelCodeAsValue,
          rebuildReceivedValue: mapLabelAsLabelCodeAsValue,
          widths: halfWith
        },
        {
          name: "member",
          label: "Место служения",
          placeholder: "Введите место служения",
          type: "select",
          dictUrl: ({ role }, auth) => {
            if (auth.role === "administrator_structure")
              return `/api/member/searchMember?&type=4&parentId=${auth.memberId}`;
            if (role?.enum === "administrator_structure")
              return " /api/member/searchMemberOE?";
            if (role?.enum !== "administrator" || role?.enum !== "administrator_structure")
              return "/api/member/searchMember?&type=4";
            return "";
          },
          deps: ["role"],
          async: true,
          additionalProps: {
            defaultOptions: false,
            cacheOptions: false
          },
          rebuildReceivedOptions: false,
          rebuildReceivedValue: rebuildReceivedValueLabelLabelValueUniqueId,
          validate: constructValidateUsingUserFunctions(
            "administrator_structure_user_modifier",
            "administrator_hram_user_modifier"
          ),
          defaultValue: (functions: string[], auth: AuthStateT) => {
            if (
              auth.functions.some(
                funcName =>
                  funcName === "administrator_structure_user_modifier" ||
                  funcName === "administrator_hram_user_modifier"
              )
            ) {
              return {
                label: auth.memberFullName,
                value: auth.memberId
              };
            }

            return null;
          },
          readOnly: (
            functions,
            { auth, modal }
          ) => {
            if (auth.role === "administrator") {
              return false;
            }
            // запрет админу ОЕ менять свое место служения
            if (auth.memberId === modal?.item?.memberId) {
              return true;
            }
            return auth.role !== "administrator_structure";
          },
          widths: fullWidth,
          conditionallyRender: (
            { role: fieldRole }, { role: authRole }
          ) => {
            if (
              authRole === "administrator" ||
              authRole === "administrator_structure"
            ) {
              return !!fieldRole && fieldRole?.enum !== "administrator";
            }
            return true;
          },
        },
        { name: "memberType", type: "text", hidden: true },
        {
          name: "head",
          readOnly: true,
          rebuildReceivedValue: values =>
            values
              ? reduceLabelFromArrayOfObjectsWithLabel(values)
              : "Не назначен руководителем",
          label: "Руководитель организационной единицы",
          type: "text",
          widths: fullWidth,
          conditionallyRender: ({ head }) => head !== "",
        }
      ]
    },
    {
      blockTitle: "",
      fields: [
        {
          label: "Священнослужители",
          placeholder: "Выберите священнослужителя",
          name: "mainUsers",
          type: "select",
          multiple: true,
          dictUrl: ({ member: { value } }) =>
            `/api/user/priestByMember/${value}?`,
          rebuildReceivedOptions: false,
          async: true,
          deps: ["member"],
          rebuildReceivedValue: v => {
            return v;
          },
          doBeforeSend: false
        }
      ],
      conditionallyRender: ({ role, member }) =>
        role && role.enum === "assistant" && member
    },
    {
      blockTitle: "Авторизационная информация",
      fields: [
        {
          name: "phone",
          label: "Телефон\\Имя для входа",
          ...basePhoneProps,
          uniqueCheck: {
            url: "/api/sysAuthen/search/existsByName",
            constructParams: (v, value) => ({
              name: value
            })
          }
        },
        {
          name: "password",
          type: "password",
          label: "Пароль"
        },
        {
          name: "generatePassword",
          type: "custom",
          component: GeneratePasswordButton
        },
        {
          name: "isActiveLogin",
          label: "Активная учётная запись",
          type: "checkbox",
          defaultValue: true
        }
      ]
    },
    {
      blockTitle: "Дополнительная информация",
      fields: [
        {
          name: "email",
          label: "E-mail",
          ...baseEmailProps
        },
        {
          name: "isSendEmail",
          label: "Использовать e-mail уведомления ",
          type: "checkbox"
        },
        {
          name: "isSendMessage",
          label: "Использовать push уведомления",
          type: "checkbox"
        }
      ]
    }
  ],
  validationSchema: localizedYup.object({
    surname: localizedYup.string().required(),
    firstname: localizedYup.string().required(),
    middlename: localizedYup.string(),
    // fullName: localizedYup.string(),
    accost: localizedYup.string().required(),
    phone: localizedYup
      .string()
      .matches(/^\+7\(\d{3}\) \d{3}-\d{2}-\d{2}$/, "Заполните поле полностью")
      .required(),
    email: localizedYup.string(),
    password: localizedYup
      .string()
      .matches(
        passwordRegExp,
        "Пароль должен содержать минимум 8 символов - только буквы латинского алфавита и цифры (не менее 1)"
      )
      .required(),
    sanCode: localizedYup.object().nullable(),
    role: localizedYup
      .object()
      .nullable()
      .required(),
    isActiveLogin: localizedYup.bool(),
    specializationCode: localizedYup.object().nullable(),
    member: localizedYup
      .object()
      .when("role", {
        is: isAdmin,
        then: localizedYup.object().nullable(),
        otherwise: localizedYup.object().nullable().required()
      }),
    mainUsers: localizedYup
      .array(localizedYup.object())
      .nullable()
      .when("role", {
        is: isAssistant,
        then: localizedYup
          .array(localizedYup.object())
          .min(1)
          .required(),
        otherwise: localizedYup.array(localizedYup.object()).nullable()
      })
  }),
  additionalTabs: [scheduleTab]
};

const profile: ExtendedBlockFormObj = {
  title: "Пользователя",
  titleItem: "Пользователь",
  headerText: "fullname",
  // url: "/api/user",
  url: "/api/sysUsers",
  projection: "formSysUser",
  primaryActionUrl: "/api/user/sysUser",
  method: "patch",
  doBeforeSend,
  blocks: [
    {
      blockTitle: "Основная информация",
      fields: [
        {
          label: "Фотография",
          name: "photo",
          type: "file",
          doBeforeSetFieldValue: uploadFileInFileSystem("user"),
          ...downLoadFileFromFileSystemProps,
          accept: "image/*",
          widths: fullWidth
        },
        {
          name: "surname",
          type: "text",
          label: "Фамилия"
        },
        {
          name: "firstname",
          type: "text",
          label: "Имя",
          additionalProps: {
            placeholder: "Укажите мирское имя"
          }
        },
        {
          name: "middlename",
          type: "text",
          label: "Отчество"
        },
        {
          name: "accost",
          type: "text",
          label: "Обращение",
          additionalProps: {
            placeholder: "Пример заполнения : «Отец Антоний», «Владыка Петр»"
          }
        },
        {
          name: "fullname",
          type: "text",
          label: "ФИО",
          hidden: true
        },
        {
          name: "birthday",
          label: "Дата рождения",
          type: "date",
          widths: { md: 2, lg: 2, xl: 2 }
        },
        {
          name: "sanCode",
          label: "Сан",
          placeholder: "Выберите сан",
          type: "select",
          dictUrl: "/api/sysDictSan/search/present",
          rebuildReceivedOptions: extractFromDataRestStructureOptionsMapLabelAsLabelCodeAsValue,
          rebuildReceivedValue: mapLabelAsLabelCodeAsValue,
          widths: halfWith,
          readOnly: true
        },
        {
          name: "hirotonyday",
          label: "Дата хиротонии",
          type: "date"
        },
        {
          name: "member",
          label: "Место служения",
          placeholder: "Выберите место служения",
          type: "select",
          dictUrl: "/api/member/structureHrams?withLimit=true",
          async: true,
          rebuildReceivedOptions: false,
          rebuildReceivedValue: rebuildReceivedValueLabelLabelValueUniqueId,
          widths: fullWidth,
          readOnly: true
        },
        {
          name: "specializationCode",
          label: "Специализация",
          placeholder: "Выберите специализацию",
          type: "select",
          dictUrl: "/api/sysDictSpecialization/search/present",
          rebuildReceivedOptions: extractFromDataRestStructureOptionsMapLabelAsLabelCodeAsValue,
          rebuildReceivedValue: mapLabelAsLabelCodeAsValue,
          widths: halfWith
        }
      ]
    },
    {
      blockTitle: "",
      fields: [
        {
          label: "Священнослужители",
          placeholder: "Выберите священнослужителя",
          name: "mainUsers",
          type: "select",
          readOnly: true,
          multiple: true,
          dictUrl: ({ member: { value } }) =>
            `/api/user/priestByMember/${value}?`,
          async: true,
          deps: ["member"],
          rebuildReceivedOptions: false,
          rebuildReceivedValue: false,
          doBeforeSend: doBeforeSendMapLabelAsAccostValueAsId
        }
      ],
      conditionallyRender: ({ role, member }) =>
        role && role.enum === "assistant" && member
    },
    {
      blockTitle: "Авторизационная информация",
      fields: [
        {
          name: "phone",
          label: "Телефон\\Имя для входа",
          type: "formatted",
          mask: phoneMask,
          uniqueCheck: {
            url: "/api/sysAuthen/search/existsByName",
            constructParams: (v, value) => ({
              name: value
            })
          }
        },
        {
          name: "password",
          type: "password",
          label: "Пароль"
        },
        {
          name: "generatePassword",
          type: "custom",
          component: GeneratePasswordButton
        },
        {
          name: "role",
          label: "Роль",
          placeholder: "Выберите роль",
          type: "select",
          dictUrl: "/api/user/role",
          rebuildReceivedOptions: rebuildRecievedOptionsMapLabelAsLabelUniqueIdAsValueNameAsEnum,
          rebuildReceivedValue: mapLabelAsLabelUniqueIdAsValueNameAsEnum,
          readOnly: true,
          widths: halfWith
        }
      ]
    },
    {
      blockTitle: "Дополнительная информация",
      fields: [
        {
          name: "email",
          label: "E-mail",
          ...baseEmailProps
        },
        {
          name: "isSendEmail",
          label: "Использовать e-mail уведомления ",
          type: "checkbox"
        },
        {
          name: "isSendMessage",
          label: "Использовать push уведомления",
          type: "checkbox"
        }
      ]
    }
  ],
  validationSchema: localizedYup.object({
    surname: localizedYup.string().required(),
    firstname: localizedYup.string().required(),
    middlename: localizedYup.string(),
    // fullName: localizedYup.string(),
    accost: localizedYup.string().required(),
    phone: localizedYup
      .string()
      .matches(/^\+7\(\d{3}\) \d{3}-\d{2}-\d{2}$/, "Заполните поле полностью")
      .required(),
    email: localizedYup.string(),
    password: localizedYup
      .string()
      .matches(
        passwordRegExp,
        "Пароль должен содержать минимум 8 символов - только буквы латинского алфавита и цифры (не менее 1)"
      ),
    san: localizedYup.object().nullable(),
    role: localizedYup
      .object()
      .nullable()
      .required(),
    specializationCode: localizedYup.object().nullable(),
    member: localizedYup.object().nullable()
  })
};

const forms = {
  create: computePropsForFormikMUI({ formObj: baseForm, mode: "create" }),
  view: computePropsForFormikMUI({ formObj: baseForm, mode: "view" }),
  edit: computePropsForFormikMUI({
    formObj: {
      ...baseForm,
      method: "patch",
      doBeforeSend,
      validationSchema: localizedYup.object({
        surname: localizedYup.string().required(),
        firstname: localizedYup.string().required(),
        middlename: localizedYup.string(),
        // fullName: localizedYup.string(),
        accost: localizedYup.string().required(),
        phone: localizedYup
          .string()
          .matches(
            /^\+7\(\d{3}\) \d{3}-\d{2}-\d{2}$/,
            "Заполните поле полностью полностью"
          )
          .required(),
        email: localizedYup.string(),
        password: localizedYup
          .string()
          .matches(
            passwordRegExp,
            "Пароль должен содержать минимум 8 символов - только буквы латинского алфавита и цифры (не менее 1)"
          ),
        san: localizedYup.object().nullable(),
        role: localizedYup
          .object()
          .nullable()
          .required(),
        isActiveLogin: localizedYup.bool(),
        specializationCode: localizedYup.object().nullable(),
        member: localizedYup.object().nullable(),
        mainUsers: localizedYup
          .array(localizedYup.object())
          .nullable()
          .when("role", {
            is: isAssistant,
            then: localizedYup
              .array(localizedYup.object())
              .min(1)
              .required(),
            otherwise: localizedYup.array(localizedYup.object()).nullable()
          })
      })
    },
    mode: "edit"
  }),
  profile: computePropsForFormikMUI({ formObj: profile, mode: "profile" })
};
export default forms;
