import React, { useEffect, useState } from "react";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import { set } from "lodash";
import { useDispatch } from "react-redux";
import { defaultErrorHandler } from "../actions/typedActions";

export const defaultPreloader = ({
  message = "Идет загрузка данных с сервера",
  size = 120
}: {
  message?: string;
  size?: number;
}) => (
  <Grid
    justify="center"
    alignContent="center"
    direction="column"
    wrap="nowrap"
    alignItems="center"
    container
    style={{
      height: "300px"
    }}
  >
    <Typography variant="h6">{message}</Typography>
    <CircularProgress size={size} />
  </Grid>
);

export interface BaseDownloadConfig {
  urlToDownload: string;
  whereToStore: string;
  rebuildReceivedValue?: (v: any) => any;
  handleStatus?: (status: 200 | 204 | 201 | 400 | 500 | number) => void;
}

export interface DownloadConfObj extends BaseDownloadConfig {
  method?: AxiosRequestConfig["method"];
  data?: any;
  postLoadDoSomethingThenPassResultAsProp?: {
    func: (data: any, onAdditionalDemandConfig: DownloadConfObj[]) => any;
    passResultAs: string;
  };
  extendAdditionalOnDemandConfig?: (
    allReceivedValues: any
  ) => DownloadConfObj[];
}

export type CompletePromiseThenRenderComponentProps<T = any> = Partial<T> & {
  config: DownloadConfObj[];
  errorHandler?: (er: AxiosError) => void;
  PreloaderComponent?: Function;
  Component: React.FC<T>;
  rebuildDownloadedObj?: (allReceivedValues: any) => Partial<T>;
  [x: string]: any;
};

/**
 * hook для загрущки данных на основе кофигурации
 * @param config
 */
export function useDownload(config: BaseDownloadConfig[]) {
  const [isLoading, setIsLoading] = useState(true);
  const [downloaded, setDownloaded] = useState({});
  useEffect(() => {
    const source = axios.CancelToken.source();
    const localDownloaded = {};
    axios
      .all(
        config.map(item =>
          axios.get(item.urlToDownload, {
            cancelToken: source.token
          })
        )
      )
      .then(response =>
        config.forEach(({ whereToStore, rebuildReceivedValue }, index) => {
          const { data } = response[index];

          set(
            localDownloaded,
            whereToStore,
            rebuildReceivedValue ? rebuildReceivedValue(data) : data
          );
          setDownloaded(localDownloaded);
          setIsLoading(false);
        })
      )
      .then();
  }, [config, downloaded]);

  return { isLoading, downloaded };
}

export const CompletePromiseThenRenderComponent: React.FC<CompletePromiseThenRenderComponentProps> = ({
  config,
  errorHandler,
  PreloaderComponent = defaultPreloader,
  Component,
  rebuildDownloadedObj,
  ...rest
}: CompletePromiseThenRenderComponentProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [downloaded, setDownloaded] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    let localDownloaded = {};
    let onAdditionalDemandConfig: DownloadConfObj[] = [];
    // скачивает все на основе концфигурации из props
    /*    loadStuffDependingOnConfig(
      config,
      localDownloaded,
      onAdditionalDemandConfig
    ) */
    const source = axios.CancelToken.source();
    axios
      .all(
        config.map(item =>
          axios(item.urlToDownload, {
            cancelToken: source.token,
            data: item.data,
            method: item.method || "get"
          })
        )
      )
      .then(response =>
        config.forEach(
          (
            {
              whereToStore,
              rebuildReceivedValue,
              postLoadDoSomethingThenPassResultAsProp,
              extendAdditionalOnDemandConfig,
              handleStatus
            },
            index
          ) => {
            const { data, status } = response[index];
            if (handleStatus) handleStatus(status);
            set(
              localDownloaded,
              whereToStore,
              rebuildReceivedValue ? rebuildReceivedValue(data) : data
            );

            if (postLoadDoSomethingThenPassResultAsProp) {
              const {
                func,
                passResultAs
              } = postLoadDoSomethingThenPassResultAsProp;
              set(
                localDownloaded,
                passResultAs,
                func(data, onAdditionalDemandConfig)
              );
            }
            if (extendAdditionalOnDemandConfig) {
              onAdditionalDemandConfig = [
                ...onAdditionalDemandConfig,
                ...extendAdditionalOnDemandConfig(localDownloaded)
              ];
            }
          }
        )
      )
      // скачивает все на основе массива построеннго по требованию
      .then(_ =>
        // loadStuffDependingOnConfig(onAdditionalDemandConfig, localDownloaded)
        axios
          .all(
            onAdditionalDemandConfig.map(item =>
              axios.get(item.urlToDownload, { cancelToken: source.token })
            )
          )
          .then(response =>
            onAdditionalDemandConfig.forEach(
              (
                {
                  whereToStore,
                  rebuildReceivedValue,
                  postLoadDoSomethingThenPassResultAsProp
                },
                index
              ) => {
                const { data } = response[index];

                set(
                  localDownloaded,
                  whereToStore,
                  rebuildReceivedValue ? rebuildReceivedValue(data) : data
                );

                if (postLoadDoSomethingThenPassResultAsProp) {
                  const {
                    func,
                    passResultAs
                  } = postLoadDoSomethingThenPassResultAsProp;
                  set(
                    localDownloaded,
                    passResultAs,
                    func(data, onAdditionalDemandConfig)
                  );
                }
              }
            )
          )
      )
      .then(_ => {
        // Вызываем функцию для оцпионального перестроения скаченных значений
        if (
          rebuildDownloadedObj &&
          typeof rebuildDownloadedObj === "function"
        ) {
          localDownloaded = {
            ...localDownloaded,
            ...rebuildDownloadedObj(localDownloaded)
          };
        }
        setDownloaded(localDownloaded);
        setIsLoading(false);
      })
      .catch(error => {
        if (!axios.isCancel(error)) {
          if (errorHandler) {
            errorHandler(error);
          }
          dispatch(defaultErrorHandler(error));
          console.error(error);
        }
      });
    return () => {
      source.cancel("Cancelling in cleanup");
    };
  }, [config, dispatch, errorHandler, rebuildDownloadedObj]);
  if (!Component) {
    console.error("Не было передано необходимое свойство 'Component'");
  }
  return isLoading ? (
    <PreloaderComponent />
  ) : (
    <Component {...rest} {...downloaded} />
  );
};

export default CompletePromiseThenRenderComponent;
