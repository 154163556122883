import { useEffect, useMemo, useRef } from "react";
import axios from "axios";
import { get, isEqual, throttle } from "lodash";
import { FormikProps, FormikValues } from "formik";
import {
  AsyncSelectField,
  CustomOnChangeFncType,
  OptionsMapType,
  OptionT,
  SelectField
} from "../../../../constants/types";
import { SetInternalOptionsMapT } from "./types";
import { defaultErrorHandler } from "../../../../utils/defaultErrorHandler";
import { wrappedSetStatus } from "../../utils/wrappedFunctions";
import { ReactSelectMUIProps } from "../../../select/types";
import { AuthStateT } from "../../../../reducers/auth";

export function useLoadOptions(
  values: Record<string, any>,
  auth: AuthStateT,
  rawFieldConfig?: SelectField
) {
  let computedDeps = [];
  const fieldConfig = rawFieldConfig as AsyncSelectField;
  if (fieldConfig && fieldConfig.deps) {
    const { deps } = fieldConfig;
    computedDeps = deps.map((dep: string) => values[dep]);
  }
  return useMemo(() => {
    if (fieldConfig && fieldConfig.async && fieldConfig.dictUrl) {
      return throttle(async (inputValue: string) => {
        try {
          const url =
            typeof fieldConfig.dictUrl === "function"
              ? fieldConfig.dictUrl(values, auth)
              : fieldConfig.dictUrl;
          const { data } = await axios.get<OptionT[]>(
            `${url}&${fieldConfig.dictUrlParamName ?? "label"}=${inputValue}`
          );
          return fieldConfig.rebuildReceivedOptions
            ? fieldConfig.rebuildReceivedOptions(data)
            : data;
        } catch (e) {
          defaultErrorHandler(e);
        }
      }, 800);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldConfig, ...computedDeps]);
}

export function useMemorizedOnChange(
  setFieldValue: FormikProps<FormikValues>["setFieldValue"],
  name: string,
  options: OptionT[],
  initialValues: FormikValues,
  status: any,
  setStatus: FormikProps<FormikValues>["setStatus"],
  customOnChange?: CustomOnChangeFncType
): ReactSelectMUIProps["onChange"] {
  return useMemo(() => {
    const onChange: ReactSelectMUIProps["onChange"] = selected => {
      if (
        selected &&
        Array.isArray(selected) &&
        selected.some(
          ({ value: selectedValue }) => selectedValue === "selectAllOptions"
        )
      ) {
        setFieldValue(name, options);
      } else {
        setFieldValue(name, selected);
      }
      if (customOnChange) {
        customOnChange({
          name,
          //@ts-ignore
          value: selected,
          initialValues,
          setFieldValue,
          status,
          setStatus: wrappedSetStatus(setStatus, status)
        });
      }
    };
    return onChange;
  }, [
    customOnChange,
    initialValues,
    name,
    options,
    setFieldValue,
    setStatus,
    status
  ]);
}

export function useAsyncOptions(
  values: FormikValues,
  setInternalOptionsMap: SetInternalOptionsMapT,
  active: boolean,
  auth: AuthStateT,
  fieldConfig?: SelectField
) {
  const computedUrl =
    fieldConfig && typeof fieldConfig.dictUrl === "function"
      ? fieldConfig.dictUrl(values, auth)
      : null;
  useEffect(() => {
    if (active && computedUrl && fieldConfig) {
      axios
        .get(computedUrl)
        .then(({ data }) => {
          setInternalOptionsMap({
            options:
              typeof fieldConfig.rebuildReceivedOptions === "function"
                ? fieldConfig.rebuildReceivedOptions(data)
                : data,
            loaded: true
          });
        })
        .catch(defaultErrorHandler);
    }
  }, [active, computedUrl, fieldConfig, setInternalOptionsMap]);
}

export function useUpdateOptionsDependingOnOptionsMap(
  name: string,
  optionsMap: OptionsMapType,
  options: OptionT[],
  setInternalOptionsMap: SetInternalOptionsMapT,
  customOptionsName: string | undefined,
  active: boolean
) {
  useEffect(() => {
    const newOptionsMapItem = get(optionsMap, customOptionsName || name);
    if (
      active &&
      newOptionsMapItem &&
      newOptionsMapItem.loaded &&
      !isEqual(options, newOptionsMapItem.options)
    ) {
      setInternalOptionsMap(newOptionsMapItem);
    }
  }, [
    active,
    customOptionsName,
    name,
    options,
    optionsMap,
    setInternalOptionsMap
  ]);
}

export function useTraceUpdate(props: any) {
  const prev = useRef(props);
  useEffect(() => {
    const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
      if (prev.current[k] !== v) {
        // @ts-ignore
        ps[k] = [prev.current[k], v];
      }
      return ps;
    }, {});
    if (Object.keys(changedProps).length > 0) {
      console.log("Changed props:", props.name, changedProps);
    }
    prev.current = props;
  });
}
