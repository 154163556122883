import React, { memo, useMemo } from "react";
import { isEqual } from "lodash";
import { HiddenColumnsT } from "./types";
import { HeaderCell } from "./headerRenderer";
import {
  getInternetExplorerEventRequiredStyles,
  getInternetExplorerRequiredStyles
} from "./utils/ie";

export const RenderHeader = memo(
  ({
    hiddenColumns
  }: {
    className?: string;
    hiddenColumns: HiddenColumnsT;
    style?: any;
    scrollLeft?: number;
  }) => {
    const msStyles = useMemo(
      () => getInternetExplorerRequiredStyles(1, hiddenColumns),
      [hiddenColumns]
    );
    const msEventStyles = useMemo(
      () => getInternetExplorerEventRequiredStyles(1),
      []
    );
    return (
      <>
        {!hiddenColumns.day && <HeaderCell label="День" style={msStyles.day} />}
        {!hiddenColumns.monthWord && (
          <HeaderCell label="День" style={msStyles.mothWord} />
        )}
        {!hiddenColumns.duties && (
          <HeaderCell label="Дежурство" style={msStyles.duties} />
        )}
        <div className="eventGrid header" style={msStyles.events}>
          <HeaderCell label="Время" style={msEventStyles.time} />
          <HeaderCell label="Событие" style={msEventStyles.type} />
          <HeaderCell
            label={!hiddenColumns.priest ? "Духовенство" : "Описание"}
            style={msEventStyles.performers}
          />
          <HeaderCell label="" style={msEventStyles.buttons} />
        </div>
      </>
    );
  },
  isEqual
);
