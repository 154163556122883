import React from "react";
import {
  Redirect,
  Route,
  RouteComponentProps,
  RouteProps
} from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import * as PropTypes from "prop-types";
import { isEqual } from "lodash";
import { RootStateT } from "../reducers";

export type PrivateRouteProps<
  T extends RouteProps = RouteProps,
  R extends RouteComponentProps = RouteComponentProps
> = Omit<T, "component"> & {
  component: React.FC<R> | React.ComponentClass<R>;
};

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const { isAuthenticated } = useSelector<
    RootStateT,
    { isAuthenticated: boolean }
  >(
    state => ({
      isAuthenticated: state.auth.isAuthenticated
    }),
    isEqual
  );
  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired
} as any;

export default React.memo(PrivateRoute, shallowEqual);
