import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import * as PropTypes from "prop-types";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import LockIcon from "@material-ui/icons/LockOutlined";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { hasIn, isEqual } from "lodash";
import { makeStyles } from "@material-ui/styles";
import { Popover, Theme } from "@material-ui/core";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import {
  authenticateUser,
  changeUserPassword,
  loadIsTestEnvironment,
  setTokenThenLoadUserInfo
} from "../actions/typedActions";
import { FormikMUI } from "./formikMUi/formikNewRealisation";
import { phoneMask } from "../constants/masks";
import { fullWidth } from "../constants/widths";
import { localizedYup } from "../constants/yup";
import { RootStateT } from "../reducers";
import { BlockFormObj } from "../constants/types";
import { AllowedJournalActionsT } from "../reducers/wrapperReducer";
import { getSystemName } from "./appBar/utils";
import { Footer } from "./wrapper/footer";

const getStyles = makeStyles<Theme>(theme => ({
  container: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  main: {
    position: "relative",
    top: "50%",
    left: "50%",
    transform: "translateX(-50%) translateY(-50%)"
  },
  footer: {
    position: "absolute",
    bottom: 0,
    boxSizing: "border-box",
    width: "100%"
  },
  paper: {
    maxWidth: "400px",
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    marginTop: theme.spacing(3)
  },
  icon: {
    height: "30px",
    marginTop: "-6px",
    marginBottom: "-6px",
    marginLeft: theme.spacing(1)
  },
  wrapper: {
    height: "100vh"
  },
  badBrowsers: {
    padding: "16px"
  },
  badBrowsersCnt: { display: "flex", justifyContent: "center" },
  browserLink: {
    display: "inline-block",
    padding: "20px 20px 15px",
    margin: "0 10px",
    borderRadius: "2px",
    fontWeight: 500,
    fontSize: "14px",
    textDecoration: "none",
    textAlign: "center"
  },
  browserIcon: {
    height: "80px",
    width: "80px",
    background: "url(/browsers.png) no-repeat 0 0",
    marginBottom: "10px"
  }
}));

const getFirstOwnPropertyKey = (o: any) => Object.keys(o)[0];

const getFirstAllowedJournal = (obj: AllowedJournalActionsT) => {
  const firstCategoryKey = getFirstOwnPropertyKey(obj);
  const objElement = obj[firstCategoryKey];
  const base = `/journal/${firstCategoryKey}`;
  if (Array.isArray(objElement)) {
    return base;
  }
  return `${base}/${getFirstOwnPropertyKey(objElement)}`;
};

const baseValidationFields = {
  login: localizedYup
    .string()
    .matches(/^\+7\(\d{3}\) \d{3}-\d{2}-\d{2}$/, "Заполните телефон полностью")
    .required("Введите телефон"),
  password: localizedYup.string().required("Введите пароль")
};

const formObj: BlockFormObj = {
  submitOnEnterPress: true,
  blocks: [
    {
      fields: [
        {
          name: "login",
          type: "formatted",
          label: "Логин (Телефон)",
          mask: phoneMask,
          widths: fullWidth
        },
        {
          name: "password",
          label: "Пароль",
          type: "password",
          widths: fullWidth
        }
      ]
    }
  ],
  validationSchema: localizedYup.object(baseValidationFields)
};

const changePasswordFormObj: BlockFormObj = {
  ...formObj,
  blocks: formObj.blocks.concat({
    fields: [
      {
        name: "newPassword",
        label: "Новый пароль",
        type: "password",
        widths: fullWidth
      },
      {
        name: "reiterationOfNewPassword",
        label: "Повторите пароль",
        type: "password",
        widths: fullWidth
      }
    ]
  }),
  validationSchema: localizedYup.object({
    ...baseValidationFields,
    // newPassword: passwordSchemaAsyncCheckPreviousPasswordsForEquality.required(),
    newPassword: localizedYup.string().required(),
    reiterationOfNewPassword: localizedYup
      .string()
      .oneOf([localizedYup.ref("newPassword"), ""], "Пароли должны совпадать")
  })
};

export type SignInProps = RouteComponentProps<
  any,
  any,
  {
    from?: {
      pathname?: string;
    };
  }
>;

const style = { paddingLeft: 0, paddingRight: 0 };

// @ts-ignore
const isIE = /* @cc_on!@ */ false || !!document.documentMode;

const initialValues = { login: "", password: "" };

const optionsMap = {};

const SignIn = ({ match, location }: SignInProps) => {
  const {
    allowedActions,
    errorMessage,
    isAuthenticated,
    testEnv,
    showPasswordChangeForm,
    auth
  } = useSelector(
    (state: RootStateT) => ({
      isAuthenticated: state.auth.isAuthenticated,
      errorMessage: state.auth.errorMessage,
      allowedActions: state.wrapperReducer.allowedActions,
      testEnv: state.wrapperReducer.isTestEnvironment,
      showPasswordChangeForm: state.auth.showChangePasswordForm,
      auth: state.auth
    }),
    isEqual
  );
  const anchorEl = useRef(null);

  const [open, setOpen] = useState(isIE);

  const classes = getStyles();
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch<ThunkDispatch<RootStateT, any, AnyAction>>();

  const { authenticateUserNormally, changePasswordThenAuthenticate } = useMemo(
    () => ({
      authenticateUserNormally: (
        login: string,
        password: string
      ): Promise<void> => dispatch(authenticateUser(login, password)),
      changePasswordThenAuthenticate: (
        login: string,
        password: string,
        newPassword: string
      ): Promise<void> =>
        dispatch(changeUserPassword(login, password, newPassword))
    }),
    [dispatch]
  );

  // в компонент отправляют токен редиректом с ЕСИА
  if (match) {
    const {
      // @ts-ignore
      params: { token }
    } = match;
    if (token) {
      dispatch(setTokenThenLoadUserInfo(token));
    }
  }

  const loadIsTestEnvironmentLocal = useMemo(
    () => () => {
      return dispatch(loadIsTestEnvironment());
    },
    [dispatch]
  );

  useEffect(() => {
    loadIsTestEnvironmentLocal();
  }, [loadIsTestEnvironmentLocal]);

  if (isAuthenticated) {
    let from = {};
    if (
      location &&
      location.state &&
      location.state.from &&
      location.state.from.pathname &&
      location.state.from.pathname !== "/"
    ) {
      const {
        state: {
          from: { pathname }
        }
      } = location;

      // todo провверить ситуацию когда авторизованный пользователь с имеющимися на разрешениями открыл модалку,
      //  к примеру затем у него закончился токен и его редиректнуло на лог, он авторизуется под другим пользователем
      //  и попадает обратно и у него откроется модалка к которой у него нет доступа.....
      from = hasIn(
        allowedActions,
        pathname.replace("/journal/", "").replace("/", ".")
      )
        ? location.state.from
        : { pathname: getFirstAllowedJournal(allowedActions) };
    } else {
      from = { pathname: getFirstAllowedJournal(allowedActions) };
    }
    return <Redirect to={from} />;
  }
  return (
    <div className={classes.wrapper}>
      <Grid
        container
        alignItems="center"
        justify="space-between"
        alignContent="center"
        direction="column"
        className={classes.main}
      >
        <Grid>
          <img
            src="/logo_login.png"
            alt="logo"
            style={{
              height: "100px",
              color: "inherit"
            }}
          />
        </Grid>
        <Grid>
          <Typography align="center" variant="h6" style={{ width: "690px" }}>
            {getSystemName(testEnv)}
          </Typography>
        </Grid>
        <Grid item className={classes.layout}>
          <Paper className={classes.paper}>
            <Avatar className={classes.avatar} ref={anchorEl}>
              <LockIcon />
            </Avatar>
            <Typography variant="h6">ВХОД В СИСТЕМУ</Typography>
            <FormikMUI
              style={style}
              initialValues={initialValues}
              formObj={
                (showPasswordChangeForm && changePasswordFormObj) || formObj
              }
              optionsMap={optionsMap}
              bindIsSubmitting={setLoading}
              onSubmit={(
                { login, password, newPassword },
                { setSubmitting }
              ) => {
                try {
                  if (!showPasswordChangeForm) {
                    authenticateUserNormally(login, password);
                  } else {
                    changePasswordThenAuthenticate(
                      login,
                      password,
                      newPassword
                    );
                  }
                } catch (e) {
                  console.error(e);
                } finally {
                  setSubmitting(false);
                }
              }}
              id="loginForm"
            />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              disabled={loading}
              form="loginForm"
              type="submit"
            >
              Войти
            </Button>
            <h3 style={{ color: "red" }}>{errorMessage}</h3>
          </Paper>
        </Grid>
      </Grid>
      <Footer auth={auth} showFaq={false} className={classes.footer} />
      <Popover
        open={open}
        anchorEl={anchorEl.current || undefined}
        onClose={() => {
          setOpen(!open);
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <div className={classes.badBrowsers}>
          <Typography>
            Вы используете устаревший браузер, в дальнейшем поддержка может быть
            прекращена, загрузите и установите один из этих браузеров:
          </Typography>
          <div className={classes.badBrowsersCnt}>
            {/*                  <a
                    href=""
                    target="_blank"
                    className={classes.browserLink}
                  >
                    <div className={classes.browserIcon} />
                    Safari
                  </a> */}
            <a
              href="https://www.mozilla.org/ru/"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.browserLink}
            >
              <div
                className={classes.browserIcon}
                style={{ backgroundPositionY: "-83px" }}
              />
              Firefox
            </a>
            <a
              href="https://www.google.com/chrome/"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.browserLink}
            >
              <div
                className={classes.browserIcon}
                style={{ backgroundPositionY: "-164px" }}
              />
              Chrome
            </a>
            <a
              href="https://www.opera.com/ru"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.browserLink}
            >
              <div
                className={classes.browserIcon}
                style={{ backgroundPositionY: "-247px" }}
              />
              Opera
            </a>
            <a
              href="https://browser.yandex.ru/"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.browserLink}
            >
              <div
                className={classes.browserIcon}
                style={{ backgroundPositionY: "bottom" }}
              />
              Яндекс
            </a>
            <div />
          </div>
        </div>
      </Popover>
    </div>
  );
};

SignIn.propTypes = {
  history: PropTypes.shape({}).isRequired
} as any;

export default memo(SignIn);
