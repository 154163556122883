import React, { useMemo, useState } from "react";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  LinearProgress,
  Tab,
  Tabs,
  Typography
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/styles";
import { useDispatch } from "react-redux";
import clsx from "clsx";
import { FormikValues } from "formik";
import { adaptTitle } from "./utils/adaptTitle";
import {
  RenderAdditionalActionButtonsDependedOnProps,
  RenderTabButtons
} from "./buttons/buttonsRenderer";
import { OptionsMapType } from "../../constants/types";
import { AdditionalButtonDependedOnPropsT, AdditionalTabT } from "./types";
import { ModalT } from "../wrapper/types";
import { ThunkDispatchTyped } from "../../actions/typedActions";

const getStyles = makeStyles({
  DialogTitleRoot: {
    paddingBottom: "10px"
  },
  closeBtn: {
    display: "flex",
    alignSelf: "baseline"
  },
  title: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    maxHeight: "5.4rem",
    lineHeight: "1.8rem"
  },
  tabTitle: {
    fontSize: "15px"
  },
  dialogContent: {
    overflowX: "hidden",
    paddingTop: 0
  },
  buttonsEditOffset: { marginRight: "8px" },
  buttonsContainer: {
    justifyContent: "space-between"
  }
});

export interface RenderModalContentT<
  T extends {
    modal: ModalT;
    firstFormInitialValues: FormikValues;
    dispatch: ThunkDispatchTyped;
  } = any
> {
  title: string;
  closeHandler: () => void;
  tabs: AdditionalTabT<T>[];
  firstFormInitialValues: FormikValues;
  optionsMap: OptionsMapType;
  actionButtons: JSX.Element;
  additionalActionButtonsDependedOnProps?: AdditionalButtonDependedOnPropsT[];
  modal: ModalT;
}

export const RenderModalContent = ({
  title,
  closeHandler,
  tabs: initialTabs,
  firstFormInitialValues,
  optionsMap,
  actionButtons,
  additionalActionButtonsDependedOnProps,
  modal
}: RenderModalContentT) => {
  const dispatch = useDispatch();
  const tabs = useMemo(
    () =>
      initialTabs.filter(
        ({ conditionallyRender }) =>
          !(
            conditionallyRender &&
            typeof conditionallyRender === "function" &&
            !conditionallyRender({ firstFormInitialValues, modal })
          )
      ),
    [firstFormInitialValues, initialTabs, modal]
  );
  const [activeTab, setActiveTab] = useState(
    (modal.openedTab && tabs.length > modal.openedTab && modal.openedTab) || 0
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const arrayOfRefsToFormSubmitFunc: (null | Function)[] = tabs.map(() => null);
  const classes = getStyles();
  const {
    tabContent: { functionToExecuteThenPassResultAsProps }
  } = tabs[activeTab];
  const propsDependedOnFirstFormInitialValuesOrModal = functionToExecuteThenPassResultAsProps
    ? functionToExecuteThenPassResultAsProps({
        firstFormInitialValues,
        modal
      })
    : {};
  const actualModal = { ...modal, openedTab: activeTab };
  return (
    <>
      <DialogTitle classes={{ root: classes.DialogTitleRoot }}>
        <Grid
          container
          justify="space-between"
          alignItems="center"
          wrap="nowrap"
        >
          <Typography variant="h6" className={classes.title}>
            {adaptTitle(
              firstFormInitialValues,
              title || tabs[0]?.tabContent?.passProps?.formObj.title,
              tabs[0]?.tabContent?.passProps?.formObj.headerText
            )}
          </Typography>
          <IconButton className={classes.closeBtn} onClick={closeHandler}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <Tabs
          value={activeTab}
          onChange={(e, v) => setActiveTab(v)}
          indicatorColor="primary"
          textColor="primary"
          variant={tabs.length > 3 ? "scrollable" : "fullWidth"}
        >
          {tabs.map(({ tabTitle, conditionallyDisable, disabled }) => {
            const computedIsDisabled =
              disabled ||
              (conditionallyDisable &&
                conditionallyDisable({ firstFormInitialValues, modal })) ||
              false;
            return (
              <Tab
                fullWidth
                label={tabTitle}
                key={tabTitle}
                disabled={computedIsDisabled}
                classes={{ wrapper: classes.tabTitle }}
              />
            );
          })}
        </Tabs>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {tabs.map(
          (
            { tabContent: { Component, passProps }, tabTitle, disabled },
            index
          ) => {
            if (disabled === true || activeTab !== index) {
              return null;
            }
            if (index === 0) {
              return (
                <Component
                  key={tabTitle}
                  bindFormSubmit={(refToFormSubmitFunc: Function) => {
                    arrayOfRefsToFormSubmitFunc[index] = refToFormSubmitFunc;
                  }}
                  bindIsSubmitting={setIsSubmitting}
                  {...passProps}
                  initialValues={firstFormInitialValues}
                  optionsMap={optionsMap}
                  modal={modal}
                  {...propsDependedOnFirstFormInitialValuesOrModal}
                  readOnlyFields={modal.readOnlyFields}
                  dispatch={dispatch}
                />
              );
            }
            return (
              <Component
                dispatch={dispatch}
                key={tabTitle}
                style={{ height: "100%" }}
                bindFormSubmit={(refToFormSubmitFunc: Function) => {
                  arrayOfRefsToFormSubmitFunc[index] = refToFormSubmitFunc;
                }}
                bindIsSubmitting={setIsSubmitting}
                {...passProps}
                firstFormInitialValues={firstFormInitialValues}
                modal={modal}
                {...propsDependedOnFirstFormInitialValuesOrModal}
              />
            );
          }
        )}
        {isSubmitting && <LinearProgress />}
      </DialogContent>
      <DialogActions
        className={clsx([
          { [classes.buttonsEditOffset]: modal.mode === "edit" },
          classes.buttonsContainer
        ])}
      >
        <div>
          <RenderAdditionalActionButtonsDependedOnProps
            additionalActionButtonsDependedOnProps={
              additionalActionButtonsDependedOnProps
            }
            side="left"
            firstFormInitialValues={firstFormInitialValues}
            modal={actualModal}
            isSubmitting={isSubmitting}
            dispatch={dispatch}
          />
        </div>
        <div>
          {/* Рендерит общие кнопки для всей модалки */}
          {actionButtons}
          <RenderAdditionalActionButtonsDependedOnProps
            additionalActionButtonsDependedOnProps={
              additionalActionButtonsDependedOnProps
            }
            side="right"
            firstFormInitialValues={firstFormInitialValues}
            modal={actualModal}
            isSubmitting={isSubmitting}
            dispatch={dispatch}
          />
          <RenderTabButtons
            tabs={tabs}
            activeTab={activeTab}
            modal={modal}
            firstFormInitialValues={firstFormInitialValues}
            isSubmitting={isSubmitting}
            arrayOfRefsToFormSubmitFunc={arrayOfRefsToFormSubmitFunc}
            dispatch={dispatch}
          />
        </div>
      </DialogActions>
    </>
  );
};
