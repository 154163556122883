import { CustomField } from "../../types";
import { Button } from "@material-ui/core";
import React from "react";
import axios from "axios";
import { dispatchOpenSnackBar } from "../../../store";
import { useFormikContext } from "formik";

export const CalendarSyncButton: CustomField["component"] = () => {
  const {
    values: { sync_server_host }
  } = useFormikContext<any>();
  return (
    <Button
      variant="contained"
      color="primary"
      onClick={() => {
        axios.patch("/api/settings", { sync_server_host })
          .then(({ status }) => {
              if (status === 200) {
                dispatchOpenSnackBar("Адрес сервиса успешно сохранен");
              }
            })
              .catch((error) => {
                console.log(error);
                dispatchOpenSnackBar("Ошибка сохранения адреса сервиса");
              });
        axios.get("/api/calendar_full")
          .then(({ status }) => {
            if (status === 200) {
              dispatchOpenSnackBar("Календарь успешно синхронизирован");
            }
          })
          .catch((error) => {
            console.log(error);
            dispatchOpenSnackBar("Ошибка синхронизации календаря");
          });
        }
      }
    >
      Синхронизировать календарь
    </Button>
  )
}
