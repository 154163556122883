import {v4 as uuid} from "uuid";
import { computePropsForFormikMUI } from "../../../components/modalWrapper/utils/computePropsForForMikMUI";
import {
  baseTextAreaProps,
  uploadFileInFileSystemByUuid
} from "../../baseFieldProps";
import { localizedYup } from "../../yup";
import { fullWidth, halfWith } from "../../widths";
import {
  constructRebuildReceivedOptions,
  rebuildReceivedValueLabelLabelValueUniqueId
} from "../../rebuildRecieved";
import { doBeforeSend } from "../../baseFormProps";
import { ExtendedBlockFormObj } from "../../../components/modalWrapper/types";
import { defaultErrorHandler } from "../../../utils/defaultErrorHandler";

const baseForm: ExtendedBlockFormObj = {
  title: "Информации",
  titleItem: "Информации",
  headerText: "name",
  url: "/api/library",
  method: "post",
  doBeforeSend,
  blocks: [
    {
      blockTitle: "Основная информация",
      fields: [
        {
          label: "Тематика",
          name: "typeId",
          type: "select",
          dictUrl: "/api/libraryDictType/search/present",
          rebuildReceivedOptions: constructRebuildReceivedOptions(
            rebuildReceivedValueLabelLabelValueUniqueId
          ),
          rebuildReceivedValue: (v, values) => ({
            value: values.typeId,
            label: values.typeName
          }),
          /*   rebuildReceivedOptions: constructRebuildReceivedOptions(
            ({ label, uniqueId }) => ({
              label,
              value: `/api/libraryDictType/${uniqueId}`
            })
          ) */
          /* rebuildReceivedValue: ({ label, code }) => ({
            label,
            value: `/api/libraryDictType/${code}`
          }) */
          placeholder: "Выберите тематику",
          widths: halfWith
        },
        {
          label: "Автор",
          name: "authorRef",
          type: "text",
          widths: halfWith
        },
        {
          label: "Наименование",
          name: "name",
          ...baseTextAreaProps
        },
        {
          label: "Ссылка на внешний ресурс",
          name: "url",
          type: "text",
          widths: fullWidth
        },
        {
          label: "Описание",
          name: "description",
          ...baseTextAreaProps
        },
        {
          name: "published",
          label: "Опубликовано",
          type: "checkbox"
        },
        {
          name: "fileRef",
          label: "Ссылка на файл",
          type: "file",
          onDrop: (files, field, setFieldValue, status, initialValues) => {
            const data = new FormData();
            data.append("file", files[0]);
            const computedUuid = initialValues.uuid || uuid();
            if (!initialValues.uuid) {
              setFieldValue("uuid", computedUuid);
            }
            uploadFileInFileSystemByUuid("library", undefined, {
              uuid: computedUuid
            })(data)
              .then(({ data: fileId }) => {
                setFieldValue(field.name, {
                  label: files[0].name,
                  value: fileId
                });
              })
              .catch(error => {
                defaultErrorHandler(error);
              });
          },
          customUrlToDownLoadFile: ({ value }) => value,
          rebuildReceivedValue: (v, values) => ({
            label: values.fileName,
            value: values.fileRef
          }),
          widths: fullWidth
        },
        {
          name: "tubeUrl",
          label: "Сслыка на внешний хостинг",
          type: "text",
          widths: halfWith
        },
        {
          name: "uuid",
          type: "text",
          hidden: true
        }
      ]
    }
  ],
  validationSchema: localizedYup.object({
    name: localizedYup.string().required(),
    typeId: localizedYup
      .object()
      .nullable()
      .required()
  })
};

export default {
  create: computePropsForFormikMUI({ formObj: baseForm, mode: "create" }),
  view: computePropsForFormikMUI({ formObj: baseForm, mode: "view" }),
  edit: computePropsForFormikMUI({
    formObj: {
      ...baseForm,
      method: "patch"
    },
    mode: "edit"
  })
};
