import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { get } from "lodash";
import { FormikValues } from "formik";
import { UniqueCheck } from "../../../constants/types";
import { dispatchOpenSnackBar } from "../../../store";

type resp = string | undefined;

export const fieldLevelUniqueCheck = (
  axiosConfig: AxiosRequestConfig,
  value: any
): Promise<resp> =>
  axios(axiosConfig)
    .then(({ data }: AxiosResponse<boolean>) => {
      if (data === true) {
        return `Значение "${value}" должно быть уникальным`;
      }
      return undefined;
    })
    .catch(er => {
      console.log(er);
      dispatchOpenSnackBar("произошла внутренняя ошибка");
      return undefined;
    });

export function createUniqueCheckFunction(
  initialValues: FormikValues,
  name: string,
  uniqueCheck: UniqueCheck
) {
  return (value: any): Promise<resp> | undefined => {
    if (value !== get(initialValues, name)) {
      const { constructParams, url } = uniqueCheck;
      const axiosConfig: AxiosRequestConfig = {
        method: "get",
        url,
        params: constructParams(initialValues, value)
      };
      return fieldLevelUniqueCheck(axiosConfig, value);
    }
    return undefined;
  };
}
