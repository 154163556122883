import { FormikValues } from "formik";
import { baseRebuildReceivedInitialValues } from "../../formikMUi/utils/initialValuesUtils";
import { ModalT } from "../../wrapper/types";
import { ExtendedBlockFormObj } from "../types";

const baseWhereToStore = "firstFormInitialValues";

const getUrlToDownload = (formObj: ExtendedBlockFormObj, modal: ModalT) => {
  if (typeof formObj.url === "function") {
    return formObj.url(modal);
  }
  if (typeof formObj.url === "string") {
    let identifier: string | number = "";
    if (formObj.customId && modal.item) {
      identifier = modal.item[formObj.customId];
    } else if (modal.item) {
      identifier = modal.item.uniqueId;
    }

    const urlToDownloadWithOutProjection = `${formObj.url}/${identifier}`;

    return formObj.projection
      ? `${urlToDownloadWithOutProjection}?projection=${formObj.projection}`
      : urlToDownloadWithOutProjection;
  }
  console.log(Error("Ожидалось что свойсвто url имеет тип string или функции"));
};

export const getInitialValues = (
  modal: ModalT,
  formObj: ExtendedBlockFormObj,
  whereToStore: string,
  initialValuesToMerge?: FormikValues
) => {
  return {
    whereToStore: whereToStore || baseWhereToStore,
    urlToDownload: getUrlToDownload(formObj, modal) as string,
    rebuildReceivedValue: (data: any) =>
      initialValuesToMerge
        ? {
            ...baseRebuildReceivedInitialValues(formObj, data),
            ...initialValuesToMerge
          }
        : baseRebuildReceivedInitialValues(formObj, data)
  };
};
