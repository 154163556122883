import {
  baseEmailProps,
  basePhoneProps,
  baseTextAreaProps,
  baseTimeFieldProps
} from "../../baseFieldProps";
import {
  rebuildReceivedOptionsLabelAsLabelUniqueIdAsValue,
  rebuildReceivedOptionsLabelAsLabelUniqueIdAsValueAddMainPerformerFlag
} from "../../rebuildRecieved";
import { halfWith } from "../../widths";
import { doBeforeSend } from "../../baseFormProps";
import { localizedYup, timeSchema } from "../../yup";
import { ExtendedBlockFormObj } from "../../../components/modalWrapper/types";
import { dispatchOpenConfirmDialogFormik } from "../../../store";
import { cancelConfig } from "../claim/form";
import { isDatePast } from "../../utils";

export const viewWorkItem: ExtendedBlockFormObj = {
  url: "/api/workitem",
  title: "дела",
  headerText: "commonLabel",
  primaryActionUrl: "/api/workitem/update",
  method: "put",
  doBeforeSend,
  blocks: [
    {
      fields: [
        {
          label: "Дата начала",
          name: "dateTimestamp",
          type: "date",
          doBeforeSend: (value, values) =>
            `${value.split(" ")[0]} ${values.parsedStartTime}`,
          widths: halfWith
        },
        {
          label: "Время начала",
          name: "parsedStartTime",
          ...baseTimeFieldProps,
          rebuildReceivedValue: (v, values) => {
            const arr =
              (values.dateTimestamp && values.dateTimestamp.split(" ")) || [];
            return arr.length > 0 ? arr[1] : "";
          },
          doBeforeSend: () => undefined,
          widths: halfWith
        },
        {
          label: "Дата окончания",
          name: "dateEndTimestamp",
          type: "date",
          doBeforeSend: (value, values) =>
            `${value.split(" ")[0]} ${values.parsedEndTime}`,
          widths: halfWith
        },
        {
          label: "Время окончания",
          name: "parsedEndTime",
          ...baseTimeFieldProps,
          doBeforeSend: () => undefined,
          rebuildReceivedValue: (v, values) => {
            const arr =
              (values.dateEndTimestamp && values.dateEndTimestamp.split(" ")) ||
              [];
            return arr.length > 0 ? arr[1] : "";
          },
          widths: halfWith
        },
        {
          name: "commonLabel",
          type: "text",
          hidden: true
        },
        {
          name: "commonAction",
          label: "Треба",
          type: "select",
          placeholder: "Выберите требу",
          dictUrl: "/api/workitem/action",
          rebuildReceivedOptions: rebuildReceivedOptionsLabelAsLabelUniqueIdAsValue,
          rebuildReceivedValue: (v, values) => ({
            label: values.commonLabel,
            value: values.commonAction
          }),
          widths: halfWith
        },
        {
          label: "Священнослужитель",
          name: "performer",
          type: "select",
          placeholder: "Выберите священнослужителя",
          rebuildReceivedValue: (v, values) => values.performerRef,
          dictUrl: ({ isPersonal }) =>
            isPersonal
              ? "/api/events/log/priestByUser?"
              : "/api/ministration/priest?",
          async: true,
          rebuildReceivedOptions: rebuildReceivedOptionsLabelAsLabelUniqueIdAsValueAddMainPerformerFlag,
          widths: halfWith
        },
        {
          name: "isPersonal",
          type: "checkbox",
          hidden: true
        },
        {
          label: "Включен в отчеты",
          name: "isReport",
          readOnly: true,
          type: "checkbox"
        },
        {
          name: "description",
          label: "Описание дела",
          ...baseTextAreaProps
        },
        {
          name: "claimId",
          type: "text",
          hidden: true,
        },
      ]
    },
    {
      blockTitle: "Данные о мирянине",
      fields: [
        {
          label: "ФИО мирянина",
          name: "userName",
          type: "text"
        },
        {
          name: "userPhone",
          label: "Телефон мирянина",
          ...basePhoneProps
        },
        {
          name: "userEmail",
          label: "E-mail мирянина",
          ...baseEmailProps
        }
      ]
    }
  ],
  additionalActionButtonsDependedOnProps: [
    {
      text: "Отменить заявку",
      stickTo: "left",
      variant: "contained",
      color: "secondary",
      onClick: ({
        firstFormInitialValues: { claimId },
        modal: { successCallback }
      }) => {
        dispatchOpenConfirmDialogFormik({
          ...cancelConfig,
          primaryButtonHandler: cancelConfig.primaryButtonHandler(claimId, successCallback)
        });
      },
      conditionallyRender: ({
        firstFormInitialValues: { dateEndTimestamp }
      }) => {
        const isOverdue = isDatePast(dateEndTimestamp.slice(0, -3));
        return !isOverdue;
      }
    },
  ],
  validationSchema: localizedYup.object({
    isReport: localizedYup.bool(),
    commonAction: localizedYup
      .object()
      .nullable()
      .required(),
    performer: localizedYup
      .object()
      .nullable()
      .required(),
    parsedStartTime: timeSchema.required(),
    parsedEndTime: timeSchema
      // @ts-ignore
      .compareTimeTo(localizedYup.ref("parsedStartTime"))
      .required(),
    description: localizedYup.string().required()
  })
};
